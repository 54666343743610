import { Pipe, PipeTransform } from '@angular/core';
import { IMarketSelectionOdd, IMarketSport } from '@bs/models';

/**
 * selecting jackpot matches if any been stored as selected
 */
@Pipe({name: 'oddByOutcome'})
export class OddByOutcomePipe implements PipeTransform {

  /**
   * return us the selected market odds
   * @param markets {Array<IMarketSport>} array of markets values
   * @param marketId {number} the market id which is used to find the element in the markets array
   * @param outcomeId {number} the outcomeId value that will be compared with marketId to return the selected market match
   */
  transform(markets: Array<IMarketSport>, marketId: number, outcomeId: number): Partial<IMarketSelectionOdd> {

    const market = markets.find(m => m.id === marketId);

    if (!market) {
      return {value: null};
    }

    const odd = market.selections.find(o => o.outcomeId === outcomeId);

    if (!odd) {
      return {value: null};
    }

    // odd.marketId = marketId;

    return odd;
  }
}
