import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppSettings } from '@bs/models';
import { AppSettingsService } from '../services/core/app-settings.service';

import { AuthService } from '../services/core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class KycGuard  {

  settings: AppSettings;

  constructor(private router: Router, private authService: AuthService, appSettingsService: AppSettingsService) {

    appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    });
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> | UrlTree {

    if (route.paramMap.has('mode') && route.paramMap.get('mode') === 'fun') {
      return true;
    }

    const me = this.authService.accountLogged$.getValue();

    if (me?.kyc) {
      return true;
    }

    return this.router.navigate([this.settings.languageCode, 'not-authorized']);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): boolean | Promise<boolean> | UrlTree {
    return this.canActivate(childRoute);
  }
}
