import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedDatePipe } from '@bs/common';

/**
 * pipe not been used, returning us an Array of items filtered by some entity
 */
@Pipe({name: 'filterBy'})
export class FilterByPipe implements PipeTransform {

  /**
   * The constructor
   */
  constructor(private localizedDatePipe: LocalizedDatePipe) {
  }

  /**
   * return us a newly filtered array
   * @param items {Array<T>} array with items to be filtered
   * @param what {string}
   * @param entity {any}
   */
  transform<T>(items: Array<T>, what: string, entity: any): Array<T> {

    // type EntityType = ReturnType<typeof entity>;

    const couldBeDate = new Date(items[0][what]);
    const isDate = !isNaN(couldBeDate.getTime());

    return items.filter(m => isDate ? (this.ymd(m[what]) === entity) : (m[what].id === entity.id));
  }

  private ymd(date: Date): string {
    return this.localizedDatePipe.transform(date, 'YYYY-MM-dd');
  }
}
