import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { BsCommonModule } from '@bs/common';
import { BsFormsModule, RestrictInputDirective, TreeTableComponent } from '@bs/forms';
import { EnvironmentConfig } from '@bs/models';
import { MaterialComponentsModule } from '@homer/mat-components';
import { TranslateModule } from '@ngx-translate/core';
import { BirthPlaceComponent } from './components/birth-place/birth-place.component';
import { CausalGroupsComponent } from './components/causal-groups/causal-groups.component';
import { DataTableBuilderComponent } from './components/data-table-builder/data-table-builder.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { DeliveriesComponent } from './components/deliveries/deliveries.component';
import { FieldErrorComponent } from './components/field-error/field-error.component';
import { FieldComponent } from './components/field/field.component';
import { FormBuilderComponent } from './components/form/form-builder.component';
import { GeoInfosComponent } from './components/geo-infos/geo-infos.component';
import { MobilePrefixComponent } from './components/mobile-prefix/mobile-prefix.component';
import { PinInputComponent } from './components/pin-input/pin-input.component';
import { PromotionBonusComponent } from './components/promotion-bonus/promotion-bonus.component';
import { RegistrationShopDialog } from '../../../common/src/lib/dialogs/registration-shop/registration-shop-dialog.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { RegistrationNewComponent } from './components/registration-new/registration-new.component';
import { StakeComponent } from './components/stake/stake.component';
import { OtpDialog } from './dialogs/otp/otp.dialog';
import { FileUrlComponent } from './components/file-url/file-url.component';
import { InputTextModule } from 'primeng/inputtext';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

/**
 * array of all the components that goes in NgModule exports and declaration
 */
const components = [
  BirthPlaceComponent,
  CausalGroupsComponent,
  DateSelectorComponent,
  DataTableBuilderComponent,
  DateRangeComponent,
  DeliveriesComponent,
  FieldErrorComponent,
  FieldComponent,
  GeoInfosComponent,
  MobilePrefixComponent,
  PinInputComponent,
  RegistrationComponent,
  RegistrationNewComponent,
  FormBuilderComponent,
  PromotionBonusComponent,
  OtpDialog,
  StakeComponent,
  FileUrlComponent,
  RegistrationShopDialog
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BsFormsModule,
    BsCommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialComponentsModule,
    MatBadgeModule,
    InputTextModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatTabsModule,
    MatRadioModule,
    MatCheckboxModule,
    TextFieldModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components,
    BsFormsModule,
    TreeTableComponent,
    RestrictInputDirective // todo: remove once all forms are builded
  ]
})
export class HomerFormsModule {
  static forRoot(config: any): ModuleWithProviders<HomerFormsModule> {
    return {
      ngModule: HomerFormsModule,
      providers: [
        {
          provide: EnvironmentConfig,
          useValue: config
        }
      ]
    };
  }
}

