import { ITicket } from '../ticket';

export interface IBetbonus {
  minimumRequiredMultiplier: number;
  includeDiscarded: number;
  prematch: any;
  live: any;
}

/*export interface IRisk {
  parameters: IParameters;
  limits: any;
}

interface IParameters {
  enableBetBonus: boolean;
  enableBonusSystem: boolean;
  placingBets: boolean;
  placingLiveBets: boolean;
  placingMixBets: boolean;
  cancellingBets: boolean;
  playSystems: boolean;
}*/

export interface ITicketPlaced {
  ticket: ITicket,
  odds?: ITicketPlacedOdds<number>;
  code: string;
  /**
   * @deprecated old sportsbook
   */
  issuedAt: string;
}

interface ITicketPlacedOdds<T> {
  [id: string]: T;
}
