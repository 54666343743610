import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ISport } from '@bs/models';
import { SportsbookService } from '@bs/sportsbook';

/**
 * Resolver is returning us the categories of sport
 *
 * see {@link ISport} for more details of the model
 */
export const SportsbookSportsResolver: ResolveFn<ISport> = () => inject(SportsbookService).getSports();
