import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'auto-exclusion-dialog',
  templateUrl: 'auto-exclusion.dialog.html'
})
/**
 * @deprecated use ConfirmationModal please
 */
export class AutoExclusionDialog {

  constructor(public dialogRef: MatDialogRef<AutoExclusionDialog>) {
  }
}
