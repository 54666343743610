<ng-template #formTpl>
  <form (ngSubmit)="submit()" [formGroup]="form" class="dialog-body">

    <div class="row">
      <div class="col-12">
        <pin-input formControlName="otp"></pin-input>
      </div>
    </div>

    <div class="row mt-4 justify-content-end">
      <div class="col-sm-auto">
        <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit">
          <span>{{ 'verify' | translate }}</span>
          <svg icon="check" lib="global" [size]="{w:10, h:10}" class="ms-2"></svg>
        </button>
      </div>
    </div>
    @if (responseError) {
      <div class="alert alert-danger mt-3">{{ responseError | translate }}</div>
    }
  </form>
</ng-template>

<ng-template #confirmationTpl>
  <div class="col-12 loading-container py-5">
    <div class="d-flex justify-content-center">
      <div [translate]="'optConfirmation'">Otp code is Valid</div>
      <svg icon="check" lib="global" [size]="{w:10, h:10}" class="ms-2"></svg>
    </div>
  </div>
</ng-template>

<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <div class="d-flex align-items-center">
        <svg icon="log-in" lib="global" [size]="{w:14, h:14}" class="me-2"></svg>
        <span [translate]="'otp'">OTP</span>
      </div>
      <button (click)="dialogRef.close(false)" class="close-icon me-2" mat-icon-button tabindex="-1">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>
    <div class="dialog-wrapper">
      <ng-template [ngTemplateOutlet]="confirmed ? confirmationTpl : formTpl"></ng-template>
    </div>
  </mat-card>
</div>
