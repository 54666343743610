import { EnvironmentConfig } from '@bs/models';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TagEventHandlerService } from './services/core/tag-event-handler.service';
import { UniversalModule } from '@bs/universal';

@NgModule({
  imports: [
    UniversalModule
  ]
})
export class ServicesModule {
  static forRoot(config: EnvironmentConfig): ModuleWithProviders<ServicesModule> {
    const optionalProviders = []
    if (config.externalScripts.some(x => ['gtm-integration'].includes(x.name))) {
      optionalProviders.push(TagEventHandlerService);
    }

    /* if (config.externalScripts.some(x => ['smartico-integration'].includes(x.name))) {
       console.log('smartico-service-launch')
       optionalProviders.push(SmarticoService);
     }*/

    // if (config.trackers.some(x => ['adCash'].includes(x.name))) {
    //   optionalProviders.push(AdCashService)
    // }
    return {
      ngModule: ServicesModule,
      providers: [
        ...optionalProviders,
        {
          provide: EnvironmentConfig,
          useValue: config
        }
      ]
    };
  }
}
