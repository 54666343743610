import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { emailExists, mailFormat, matchPassword, ValidationService } from '@bs/forms';
import { RegistrationBaseRequest } from '@bs/models';
import { AccountsService } from '@bs/services';
import { WindowService } from '@bs/universal';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

class MyData {
  currencyId: number;
  parentId: number;
}

@Component({
  selector: 'registration-shop-dialog',
  templateUrl: './registration-shop-dialog.component.html'
})
export class RegistrationShopDialog {
  loading: boolean;
  isMobile: any;
  subs = new Subscription();

  form: FormGroup;

  constructor(fb: FormBuilder, windowService: WindowService, private accountsService: AccountsService,
              public dialogRef: MatDialogRef<RegistrationShopDialog>,
              validationService: ValidationService,
              @Inject(MAT_DIALOG_DATA) public data: MyData,
              private snackBar: MatSnackBar, private translate: TranslateService) {
    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));

    this.form = fb.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.required],
      repeatPassword: ['', Validators.compose([Validators.required, matchPassword])],
      email: ['', Validators.compose([Validators.required, mailFormat]), emailExists(validationService, false)],
    });
  }

  submit() {
    this.loading = true;
    const req: RegistrationBaseRequest = {
      typeId: 20,
      autoLogin: false,
      username: this.form.get('username').value,
      password: this.form.get('password').value,
      currencyId: this.data.currencyId,
      parentId: this.data.parentId,
      deliveries: [{typeId: 3, delivery: this.form.get('email').value, isConfirmed: false, isPrimary: true}],
    }

    this.accountsService.register(req).subscribe({
      next: response => {
        this.dialogRef.close()
        this.snackBar.open(this.translate.instant('registration-message-successful-title'), this.translate.instant('close'));
      },
      error: error => {
        this.snackBar.open(this.translate.instant(error.message), this.translate.instant('close'));
      }
    }).add(() =>
      this.loading = false
    )
  }


}
