import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConfigModule, TranslationModule } from '@bs/common';
import { AppSettingsService, CookieService, CordovaService, ServicesModule, WebappService, ZendeskService } from '@bs/services';
import { BannerSliderComponent, HomerCommonModule } from '@homer/common';
import { HomerGamesModule } from '@homer/games';
import { MaterialComponentsModule } from '@homer/mat-components';
import { WidgetIsReadyService } from '@livechat/widget-angular';
import { CashbackPage } from '@maggie/app/pages/cashback/cashback.page';
import { LogoutPage } from '@maggie/app/pages/logout/logout.page';
import { VipPage } from '@maggie/app/pages/vip/vip.page';
import { environment } from '@maggie/environments/environment';
import { register } from 'swiper/element/bundle';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppComponentsModule } from './components/app.components.module';
import { DownloadPage } from './pages/download/download.page';
import { GamesModule } from './pages/games/games.module';

// Pages
import { RegisterPage } from './pages/register/register.page';
import { HomerSmarticoModule } from '@homer/smartico';
import localeJa from '@angular/common/locales/ja';

register();
registerLocaleData(localeJa, 'ja');

const optionalProviders: Array<Provider> = []; // todo: to be moved in config.module

let optionalModules = [
  HomerSmarticoModule.forRoot(environment)
];

if (!environment.externalScripts.some(x => x.name === 'smartico-integration')) {
  optionalModules = []
}

if (environment.mobileApp) {
  optionalProviders.push(CordovaService);
}

if (environment.liveChats?.liveChatInc) {
  optionalProviders.push(WidgetIsReadyService);
}

if (environment.externalScripts.some(x => x.name === 'ze-snippet')) {
  optionalProviders.push(ZendeskService);
}

@NgModule({
  declarations: [
    AppComponent,
    DownloadPage,
    LogoutPage,
    RegisterPage,
    VipPage,
    CashbackPage
  ],
  imports: [
    BrowserModule.withServerTransition({appId: environment.project.split('.')[0]}),
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    // Internal
    ...optionalModules,
    MaterialComponentsModule.forRoot(environment),
    AppRoutingModule,
    AppComponentsModule,
    ConfigModule.forRoot(environment),
    HomerCommonModule.forRoot(environment),
    GamesModule, // todo: should not be here
    ServicesModule.forRoot(environment),
    TranslationModule.forRoot(environment),
    ServiceWorkerModule.register('custom-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
      //registrationStrategy: 'registerWhenStable:30000'
    }),
    HomerGamesModule,
    BannerSliderComponent,
    MatCardModule,
    MatSidenavModule,
  ],
  providers: [
    ...optionalProviders,
    AppSettingsService,
    CookieService,
    WebappService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [WebappService],
      useFactory: (appConfigService: WebappService) => () => appConfigService.webapp()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
