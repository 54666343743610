import { IAuthor } from '../common/author';
import { IFeedProvider } from './feed';
import { IFixture } from './fixture';
import { MappingStatus } from './mapping-status';

export interface IMapping extends IFixture {
  externalMappingFixtureId: number;
  betOfferId: number;
  mappingStatusId: MappingStatus;
  feedProvider: IFeedProvider;
  entityTypeId: number; // 15, torneo
  entityId: number; // id torneo
  feedKey: string; // chiave del feed
  unifiedFeedId: number; // chiave feed
  unifiedFeed: string; // feed torneo
  fromDate: string;
  toDate: string
  author: IAuthor;
  date: string;
}
