<div class="games-lite" [class.featured]="isFeaturedGames">
  <div class="game-thumbnail">
    <div class="img-container">
      <img loading="lazy" [src]="game.thumbnail" onload="this.style.opacity=1" defaultImage="./assets/images/casino/thumbnail.png" class="img-fluid">
    </div>
  </div>
  <div class="overlay text-center game-container">
    <div class="overlay-container d-flex flex-column h-100 justify-content-center">
      <div class="provider-name mt-1 mb-1 d-none d-md-block">{{ game.provider.name }}</div>
      <div class="favourites-icon" (click)="toggleFavorite(game)">
        <svg [icon]="isFavorite(game) ? 'heart': 'heart-outline'" [class.fill]="isFavorite(game)" lib="global" class="me-1" [size]="{w:18, h:18}"></svg>
      </div>
      <div class="play-container d-flex align-items-center justify-content-center flex-column">
        <a [routerLink]="[baseUrl, 'real']" class="as-button " color="primary" mat-flat-button>{{ 'play-now' | translate }}</a>
        @if (game.forFun && forFun) {
          <a [routerLink]="[baseUrl, 'fun']" class="as-button try-now" mat-flat-button>{{ 'try-now' | translate }}</a>
        }
      </div>
    </div>
  </div>
  @if (!isFeaturedGames) {
    <h3 class="game-name ellipsis">{{ game.name }}</h3>
  }
</div>
