import { Pipe, PipeTransform } from '@angular/core';

/**
 * this pipe groups elements by a certain field, and returns the newly grouped array
 */
@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

  /**
   * returns us the newly grouped array
   * @param values {T[]} requires an array of items
   * @param iteratee {string | any} the object property name which will be group the array by
   */
  transform<T>(values: T[], iteratee: string | any): Array<{ index: number; values: T[] }> {

    if (!values) { // precondition
      return [{index: 0, values: []}];
    }

    const group = values.reduce((acc, item) => {

      let val;
      if (typeof iteratee === 'string') {
        if (iteratee.includes('.')) {
          val = this.getDotVal(item, iteratee);
        } else {
          val = item[iteratee];
        }
      } else {
        val = iteratee(item);
      }

      const found = acc.find(x => x.index === val);
      if (found) {
        found.values.push(item);
      } else {
        acc.push({index: val, values: [item]})
      }

      return acc;
    }, []);

    /*const groups: Array<T> = groupBy(values, iteratee);

    return Object.keys(groups).slice(0, limit).map((k) => {
      return {index: k, values: groups[k]};
    });*/

    return group;
  }

  /**
   *
   * @ignore
   */
  private getDotVal(item: any, iteratee: string): any {
    const a = iteratee.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in item) {
        item = item[k];
      } else {
        return;
      }
    }
    return item;
  }
}
