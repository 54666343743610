<form (ngSubmit)="submits.next(form.value)" [formGroup]="form" [ngClass]="{'row': !vertical, 'd-flex flex-column gx-0':vertical}" class="grid">
  <ng-content select=".overlay"></ng-content>
  <!--<pre class="floating">{{form.value | json}}</pre>-->
  <!--  DEBUG FORM-->
  <ng-template #fieldsetTpl let-fieldset>
    <!--<p-fieldset *ngFor="let set of fieldset" [collapsed]="set.collapsed" [legend]="set.legend | translate" [toggleable]="set.toggleable">
    <ng-template [ngTemplateOutletContext]="{$implicit: set.inputs}" [ngTemplateOutlet]="fieldTpl"></ng-template>
  </p-fieldset>-->
</ng-template>

<ng-template #fieldTpl let-inputs>
  @for (input of (inputs | hasPermission); track input) {
    @if (!input.empty) {
      <field
        [input]="input"
        [values]="values | formValues:input"
        [formControlName]="input.name"
        [class.col-md-3]="!vertical && !input.rowspan"
        [class.col-md-4]="!vertical && input.rowspan === 2"
        [class.col-md-6]="!vertical && input.rowspan === 3"
        [ngClass]="{'col-12': !vertical, 'grid col flex-column': vertical }" class="p-field">
        @if (input.icon) {
          <span class="pre">icon</span>
        }
      </field>
    }
    @if (input.empty) {
      <div [ngClass]="{'col-12': !vertical, 'grid col flex-column': vertical }"
        [class.col-md-3]="!vertical && !input.rowspan"
        [class.col-md-4]="!vertical && input.rowspan === 2"
        [class.col-md-6]="!vertical && input.rowspan === 3"
      class="empty"></div>
    }
  }

</ng-template>

<ng-template [ngTemplateOutletContext]="{$implicit: config.fieldset ? config.fieldset : config.inputs}" [ngTemplateOutlet]="config.fieldset ? fieldsetTpl : fieldTpl"></ng-template>
@if (!disabled) {
  <ng-content select=".button-container"></ng-content>
}
<mat-error [control]="form"></mat-error>

</form>
