import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MobilePrefixValidator, phoneExists, ValidationService } from '@bs/forms';
import { EnvironmentConfig } from '@bs/models';
import { PasswordService } from '@bs/services';
import { emailRecovery, OtpDialog } from '@homer/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PasswordRecoveryDialog } from '../password-recovery/password-recovery-dialog.component';

@Component({
  selector: 'forgot-password-dialog',
  templateUrl: 'forgot-password-dialog.component.html',
  styleUrls: ['forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialog implements OnDestroy {

  loading: boolean;
  phoneForm: FormGroup;
  emailConfig = emailRecovery;
  modes = [];

  subs = new Subscription();

  constructor(private config: EnvironmentConfig, fb: FormBuilder, private dialog: MatDialog, public dialogRef: MatDialogRef<ForgotPasswordDialog>, private passwordService: PasswordService,
              private snackBar: MatSnackBar, private translate: TranslateService, validationService: ValidationService) {
    Object.entries(config.features.passwordRecovery).forEach(([key, val]) => {
      if (val) {
        this.modes.push(key);
      }
    });

    this.phoneForm = fb.group({
      phone: ['', Validators.compose([Validators.required, MobilePrefixValidator.number(config.features.validators.phone)]), phoneExists(validationService, true)],
      validationType: ['sms']
    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  submitEmail(formValues) {
    this.loading = true;
    this.passwordService.recoveryEmail(formValues).then(
      () => {
        // toast check your email
        this.dialogRef.close();
        this.snackBar.open(this.translate.instant('check-your-email'), this.translate.instant('close'));
      },
      () => {
        this.dialogRef.close();
        this.snackBar.open(this.translate.instant('cannot-recovery-password'), this.translate.instant('close'), {panelClass: 'error'});
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  submitPhone() {
    this.loading = true;

    // const value = {phone: this.phoneForm.value.phone.phone, prefixId: this.phoneForm.value.phone.prefix.id, validationType: this.phoneForm.value.validationType};

    this.passwordService.recoveryPhone(this.phoneForm.value).then(transactionId => {
        this.dialogRef.close();

        let dialogRef = this.dialog.open(OtpDialog, {
          data: {
            ...this.phoneForm.value, // {{phone,prefix}, validationType}
            transactionId
          }
        });

        dialogRef.afterClosed().subscribe(
          {
            next: result => {
              this.passwordService.recoveryPhoneCheck(result).then(
                token => this.openRecovery(token),
                error => {

                  dialogRef = this.dialog.open(OtpDialog, {
                    data: {
                      ...this.phoneForm.value,
                      transactionId
                    }
                  });

                  dialogRef.componentInstance.responseError = this.translate.instant(error.message);
                  dialogRef.afterClosed().subscribe({
                    next: result => {
                      this.passwordService.recoveryPhoneCheck(result).then(token => this.openRecovery(token));
                    }
                  });
                }
              );
            }
          });
      },
      error => {
        this.snackBar.open(this.translate.instant(error.message), this.translate.instant('close'), {panelClass: 'error'});
      }).finally(() => this.loading = false);
  }

  private openRecovery(token: string) {
    this.dialogRef.close();
    this.dialog.open(PasswordRecoveryDialog, {
      data: {
        token,
        mode: 'pin'
      }
    });
  }
}
