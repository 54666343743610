import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedDatePipe } from '@bs/common';

/**
 * creates a new array with all sub-array elements concatenated into it recursively up to the specified depth. outputs: flat: { element: {}, element2: {},  element 3 }, INTO~  element: {},  element2: {},  element3: {}
 */
@Pipe({name: 'flatBy'})
export class FlatByPipe implements PipeTransform {

  /**
   * The constructor
   * @param localizedDatePipe
   */
  constructor(private localizedDatePipe: LocalizedDatePipe) {
  }

  /**
   * return us the flat array
   * @param items {Array[T]} array with items with sub items
   * @param what {string} which key to flat by
   */
  transform<T>(items: Array<T>, what: string): Array<any> {

    if(!items.length){
      return items;
    }

    const couldBeDate = new Date(items[0][what]);
    const isDate = !isNaN(couldBeDate.getTime());

    const flattened = items.sort((a, b) => {

      if (isDate) {
        const aa = new Date(a[what]);
        const bb = new Date(b[what]);
        return aa.getTime() - bb.getTime();
      } else {
        return a[what].id - b[what].id;
      }

    }).flatMap(m => isDate ? this.ymd(m[what]) : m[what]);

    return flattened.reduce((acc, item) => {
      if (!acc.find(s => isDate ? (s === item) : (s.id === item.id))) {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  private ymd(date: Date): string {
    return this.localizedDatePipe.transform(date, 'YYYY-MM-dd');
  }
}
