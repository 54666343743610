import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsCommonModule } from '@bs/common';
import { EnvironmentConfig } from '@bs/models';
/* RESOLVERS */
/* SERVICES */
import { DynamicScriptLoaderService } from '@bs/services';
/* SHARED */
import { UniversalModule } from '@bs/universal';
import { HomerCommonModule } from '@homer/common';
import { MaterialComponentsModule } from '@homer/mat-components';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BrandHeaderSelectorComponent } from './components/brand-header-selector/brand-header-selector.component';
import { BrandsDropdownComponent } from './components/brands-dropdown/brands-dropdown.component';
/* COMPONENTS */
import { CasinoFilterDialog } from './dialogs/casino-filter-dialog/casino-filter.dialog';
import { CasinoFiltersComponent } from './components/casino-filters/casino-filters.component';
import { CasinoGameTileComponent } from './components/casino-game-tile/casino-game-tile.component';
import { CasinoGamesGridComponent } from './components/casino-games-grid/casino-games-grid.component';
import { CategoriesMenuComponent } from './components/categories-menu/categories-menu.component';
import { GameTileComponent } from './components/game-tile/game-tile.component';
import { GamesGridComponent } from './components/games-grid/games-grid.component';
import { GamesListComponent } from './components/games-list/games-list.component';
/* PAGES */
import { SortByDropdownComponent } from './components/sort-by-dropdown/sort-by-dropdown.component';
import { CasinoCalendarDialog } from './dialogs/casino-calendar/casino-calendar.dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

/* GUARDS */

@NgModule({
  imports: [
    BsCommonModule,
    CommonModule,
    FormsModule,
    MaterialComponentsModule,
    // AccountModule,
    LazyLoadImageModule,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule,
    BsCommonModule,
    HomerCommonModule,
    UniversalModule,
    MatMenuModule,
    MatCardModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  declarations: [
    BrandHeaderSelectorComponent,
    BrandsDropdownComponent,
    CasinoCalendarDialog,
    CasinoFilterDialog,
    CasinoFiltersComponent,
    CasinoGameTileComponent,
    CasinoGamesGridComponent,
    CategoriesMenuComponent,
    GameTileComponent,
    GamesGridComponent,
    GamesListComponent,
    SortByDropdownComponent,
  ],
  exports: [
    CasinoGameTileComponent,
    CasinoGamesGridComponent,
    CasinoFiltersComponent,
    CasinoFilterDialog,
    SortByDropdownComponent,
    GamesListComponent,
    GameTileComponent
  ]
})
export class HomerGamesModule {
  static forRoot(config: any): ModuleWithProviders<HomerGamesModule> {
    return {
      ngModule: HomerGamesModule,
      providers: [
        DynamicScriptLoaderService,
        {
          provide: EnvironmentConfig,
          useValue: config
        }
      ]
    };
  }
}
