import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LocalizedDatePipe } from '@bs/common';
import { EnvironmentConfig } from '@bs/models';
import { EventsCountPipe } from './pipes/events-count.pipe';
import { FilterByPipe } from './pipes/filter-by.pipe';
import { FlatByPipe } from './pipes/flat-by.pipe';
import { OddByOutcomePipe } from './pipes/odd-by-outcome.pipe';
import { UpdateParamsPipe } from './pipes/update-params.pipe';
import { WinningOddPipe } from './pipes/winning-odd.pipe';
import { SportsbookService } from './services/sportsbook.service';

/* PIPES  */
/**
 * array of all the pipes that goes in NgModule exports and declaration, so they can be read in another module
 */
const collection = [
  EventsCountPipe,
  FlatByPipe,
  FilterByPipe,
  OddByOutcomePipe,
  UpdateParamsPipe,
  WinningOddPipe
];

@NgModule({
  declarations: collection,
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: collection,
  providers: [LocalizedDatePipe, SportsbookService]
})
export class BsSportsbookModule {
  static forRoot(config: EnvironmentConfig): ModuleWithProviders<BsSportsbookModule> {
    return {
      ngModule: BsSportsbookModule,
      providers: [
        {
          provide: EnvironmentConfig,
          useValue: config
        }
      ]
    };
  }
}
