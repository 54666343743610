import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GameAttributeType, IGameType } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { map, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'casino-filters',
  templateUrl: 'casino-filters.component.html'
})

export class CasinoFiltersComponent implements OnInit {

  @Input()
  data: IGameType;

  @Output()
  params = new EventEmitter<Params>(null);

  form: FormGroup;
  isDarkTheme$: Observable<boolean>;

  private subs = new Subscription();

  constructor(private fb: FormBuilder, private route: ActivatedRoute, appSettingsService: AppSettingsService) {
    this.form = this.fb.group({
      providers: this.fb.array([]),
      features: this.fb.array([]),
      lines: this.fb.array([]),
      volatility: this.fb.array([]),
      themes: this.fb.array([]),
      stakeLevels: this.fb.array([])
    });
    this.isDarkTheme$ = appSettingsService.appSettings$.pipe(map(x => x.settings.darkTheme));
  }

  ngOnInit() {
    if (this.data.providers) {
      this.data.providers.sort((a, b) => a.name.localeCompare(b.name)).forEach(provider => {
        const group = this.fb.group({
          name: [provider.name],
          id: [provider.id],
          slug: [provider.slug],
          selected: [false]
        });
        this.providersArray.push(group);
      });
    }
    if (this.data.attributes) {
      this.data.attributes.forEach(attr => {
        const group = this.fb.group({
          name: [attr.name],
          id: [attr.id],
          typeId: [attr.typeId],
          selected: [false]
        });
        switch (attr.typeId) {
          case GameAttributeType.features:
            this.featuresArray.push(group);
            break;
          case GameAttributeType.lines:
            this.lineArray.push(group);
            break;
          case GameAttributeType.themes:
            this.themeArray.push(group);
            break;
          case GameAttributeType.volatility:
            this.volatilityArray.push(group);
            break;
          case GameAttributeType.stakeLevels:
            this.stakeLevelsArray.push(group);
            break;
        }
      })
    }

    this.subs.add(this.route.queryParamMap.subscribe({
      next: params => {
        const providers = params.getAll('providers').map(x=>+x);
        const attributes = params.getAll('attributes').map(x=>+x);

        this.providersArray.controls.forEach(c => {
          if (providers.includes(c.get('id').value)) {
            c.get('selected').setValue(true);
          }
        });

        this.themeArray.controls.forEach(c => {
          if (attributes.includes(c.get('id').value)) {
            c.get('selected').setValue(true);
          }
        });
        this.volatilityArray.controls.forEach(c => {
          if (attributes.includes(c.get('id').value)) {
            c.get('selected').setValue(true);
          }
        });
        this.featuresArray.controls.forEach(c => {
          if (attributes.includes(c.get('id').value)) {
            c.get('selected').setValue(true);
          }
        });
        this.lineArray.controls.forEach(c => {
          if (attributes.includes(c.get('id').value)) {
            c.get('selected').setValue(true);
          }
        });
        this.stakeLevelsArray.controls.forEach(c => {
          if (attributes.includes(c.get('id').value)) {
            c.get('selected').setValue(true);
          }
        });
      }
    }))
  }

  get providersArray(): FormArray {
    return this.form.get('providers') as FormArray;
  }

  get featuresArray(): FormArray {
    return this.form.get('features') as FormArray;
  }

  get lineArray(): FormArray {
    return this.form.get('lines') as FormArray;
  }

  get volatilityArray(): FormArray {
    return this.form.get('volatility') as FormArray;
  }

  get themeArray(): FormArray {
    return this.form.get('themes') as FormArray;
  }

  get stakeLevelsArray(): FormArray {
    return this.form.get('stakeLevels') as FormArray;
  }


  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  resetForm() {
    this.providersArray.controls.forEach(c => {
      c.get('selected').setValue(false);
    });
    this.themeArray.controls.forEach(c => {
      c.get('selected').setValue(false);
    });
    this.volatilityArray.controls.forEach(c => {
      c.get('selected').setValue(false);
    });
    this.featuresArray.controls.forEach(c => {
      c.get('selected').setValue(false);
    });
    this.lineArray.controls.forEach(c => {
      c.get('selected').setValue(false);
    });
    this.stakeLevelsArray.controls.forEach(c => {
      c.get('selected').setValue(false);
    });
    this.params.emit({});
  }

  submit() {
    const keys = ['providers', 'features', 'lines', 'volatility', 'themes', 'stakeLevels'];
    const queryParams: Params = {};

    keys.forEach(k => {
      const append = this.form.value[k].filter(x => x.selected).flatMap(x => x.id);
      if (append.length) {
        if (k === 'providers') {
          queryParams[k] = append;
        } else {
          queryParams.attributes = append;
        }
      }
    });

    this.params.emit(queryParams);
  }
}
