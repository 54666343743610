import { ICmsLanguage } from '../cms/footer';
import { ICmsPageLanguage } from '../cms/page';
import { ICmsImage } from '../cms/slider';
import { IAccountBase } from '../wallet/movement';
import { IPromoActionType } from './actionType';
import { PromoActionType, PromoStatus, PromoStepType, PromotionType } from './enum-promo';

export interface IPromotion extends IPromotionBase {
  // currencies?: Array<ICurrency>
  player?: IPromoPlayer;
}

export interface IPromoPlayer extends IAccountBase {
  promoStatusId: number;
  startDate: string;
  endDate?: string;
}

export interface IPromoPlayerDetails extends IPromoPlayer {
  none: string;
}

export interface IStepType {
  id: number;
  name: string;
  actionTypes: Array<IPromoActionType>;
}

export interface IPromotionBase {
  id?: number
  typeId: PromotionType;
  statusId?: PromoStatus

  bookmakerId: number

  name: string;
  imageUrl?: string;
  description?: string;
  terms?: string;

  /**
   * @description contains all languages (only for operators)
   */
  infos?: Array<IPromoInfo>;

  images?: Array<ICmsImage>;

  names?: Array<ICmsLanguage>;
  descriptions?: Array<ICmsLanguage>;
  rules?: Array<ICmsLanguage>;

  creationDate?: string;
  startDate: string;
  endDate: string;

  actionTypeRegistrationId?: PromoActionType;

  steps?: Array<IPromoStep>;
  percentage: number;

  playersCounters?: IPromoPlayerCounters;
  outputsSummary?: IPromoOutputSummary;
  profiling?: IPromoTargetProfiling;
}

export interface IPromoTargetProfiling {
  id: number;
  networks?: IAccountBase;
  profiling?: any;
}

export interface IPromoOutputSummary {
  accredited: number;
  available: number;
  used: number;
}

export interface IPromoPlayerCounters {
  open: number;
  close: number;
}

export interface IPromoRequirement {
  key: 'bettingTypeId' | 'bettingGroupTypeId' | 'minSelectionOdd' | 'minMultiplierOdd' | 'minEventsCount';
  keyType: 'percentage' | 'currency' | 'array' | 'odds' | 'number';
  value: number //| string | Array<number>;

  isCompleted?: boolean
}

export interface IPromoStep {
  id?: number;
  position: number;
  typeId: PromoStepType;
  actionTypeId: PromoActionType;
  //activationTypeId: PromoStepActivationType;
  //infos: Array<IPromoInfo>;
  names: Array<ICmsPageLanguage>;
  descriptions: Array<ICmsPageLanguage>;
  rules: Array<ICmsPageLanguage>;
  //promoCode?: string;
  outputs?: Array<IPromoStepOutput>;
  requirements?: Array<IPromoRequirement>;
}

export interface IPromoStepOutput {
  outputTemplateId: number;
  outputTemplate: string;
  outputModelId: number;
}

export interface IPromoInfo {
  languageId: number;
  name: string;
  description: string;
  terms: string;
}

export interface IPromoSearchRequest {
  displayCurrencyId: number;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}
