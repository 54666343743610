import { Pipe, PipeTransform } from '@angular/core';

/**
 * this pipe count 2 level nested arrays, count x levels
 */
@Pipe({name: 'count'})

export class CountPipe implements PipeTransform {

  /**
   * returns us the count value
   * @param items {any} ISport items representing us the sports matches, mostly used in sport-bar.components
   * @param extras {string[]} extras let us iterate and counts that categories matches
   */
  transform(items: any, ...extras: string[]): number {
    return items[extras[0]].reduce((count, item) => {
      count += item[extras[1]].length;
      return count;
    }, 0);
  }
}
