import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { EnvironmentConfig, Webapps } from '@bs/models';
import { MatAutoOpenDirective } from './directives/mat-auto-open.directive';
import { SelectMobilePositionTopDirective } from './directives/select-mobile-position-top.directive';
import { MatNativeDateModule } from '@angular/material/core';

/**
 * array of all the Angular Material components that goes in NgModule exports and declaration, so they can be read in another module
 */
// const components = [
//   MatTabsModule,
//   MatButtonModule,
//   MatInputModule,
//   MatCheckboxModule,
//   MatFormFieldModule,
//   MatSlideToggleModule,
//   MatSliderModule,
//   MatMenuModule,
//   MatSidenavModule,
//   MatCardModule,
//   MatExpansionModule,
//   MatListModule,
//   MatTreeModule,
//   MatBadgeModule,
//   MatDialogModule,
//   MatTooltipModule,
//   MatSelectModule,
//   MatProgressBarModule,
//   MatProgressSpinnerModule,
//   MatRadioModule,
//   MatDatepickerModule,
//   MatNativeDateModule,
//   MatTableModule,
//   MatSortModule,
//   MatPaginatorModule,
//   MdIconComponent,
//   MatAutocompleteModule,
//   MatButtonToggleModule,
//   MatToolbarModule,
//   MatSnackBarModule,
//   MatStepperModule,
//   MatAutoOpenDirective,
//   SelectMobilePositionTopDirective
// ];

@NgModule({
  imports: [
    CommonModule,
    MatNativeDateModule
  ],
  declarations: [
    MatAutoOpenDirective,
    SelectMobilePositionTopDirective,
  ],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {verticalPosition: 'top'}},
    {provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}}
  ],
  exports: [
    MatAutoOpenDirective,
    SelectMobilePositionTopDirective,
    MatNativeDateModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MaterialComponentsModule {
  static forRoot(config: any): ModuleWithProviders<MaterialComponentsModule> {
    return {
      ngModule: MaterialComponentsModule,
      providers: [
        {
          provide: EnvironmentConfig,
          useValue: config
        },
        {
          provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
          useValue: {
            appearance: config?.webAppId === Webapps.lisa || config?.webAppId === Webapps.maggie ? 'outline' : 'fill',
            floatLabel: config?.webAppId === Webapps.lisa || config?.webAppId === Webapps.maggie ? 'always' : 'auto'
          }
        }
      ]
    };
  }
}
