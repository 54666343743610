<ng-template #formTpl>
  <form (ngSubmit)="submit()" [formGroup]="form" class="dialog-body">
    <div class="row">
      <div class="col-12">
        <pin-input formControlName="otp"></pin-input>
      </div>
    </div>
    <div class="row m-2 justify-content-center">
      <div class="col-sm-auto">
        <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit"><span>{{ 'verify' | translate }}</span></button>
      </div>
    </div>
    @if (counter > 1) {
      <div>
        <div class="alert alert-danger">
          <span [translate]="'otp-code-is-not-valid-try-again'">OTP not valid. Please try again.</span>
        </div>
      </div>
    }
  </form>
</ng-template>

<ng-template #confirmationTpl>
  <div class="col-12 loading-container py-5">
    <div class="d-flex justify-content-center">
      <div [translate]="'otp-code-is-valid'">Otp code is Valid</div>
    </div>
  </div>
</ng-template>

<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span [translate]="'otp'">OTP</span>
      <button (click)="dialogRef.close(false)" class="close-icon me-2" mat-icon-button tabindex="-1">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>
    <div class="dialog-wrapper">
      <ng-template [ngTemplateOutlet]="success ? confirmationTpl : formTpl"></ng-template>
    </div>
  </mat-card>
</div>
