import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IdentityWalletService } from '@bs/services';
import { Subscription } from 'rxjs';

interface MyData {
  isDeposit: boolean,
  paymentMethodId: number;
  transactionId: number;
  otp?: string;
}

@Component({
  selector: 'deposit-otp-check-dialog',
  templateUrl: 'deposit-otp-check.component.html'
})
export class DepositOtpCheckDialog implements OnDestroy {

  form: FormGroup;
  success: boolean;
  counter = 1;

  subs = new Subscription();

  constructor(fb: FormBuilder, private identityWalletService: IdentityWalletService, public dialogRef: MatDialogRef<DepositOtpCheckDialog>,
              @Inject(MAT_DIALOG_DATA) public data: MyData) {
    this.form = fb.group({
      otp: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)])],
      paymentMethodId: [this.data.paymentMethodId],
      transactionId: [this.data.transactionId]
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  submit() {

    if (this.data.isDeposit) {
      this.subs.add(this.identityWalletService.depositOtpCheck(this.form.value).subscribe({
        next: response => {
          console.log(response)
          console.log(this.counter)
          // mostra template di conferma
          if (response) {
            this.success = true;
          } else {
            if (this.counter < 3) {
              this.showRetryMessage()
            } else {
              this.dialogRef.close('tooManyAttempts')
            }
          }
        },

        error: () => {
          this.dialogRef.close('requestFailed');
          // console.log(error)
        }
      }));
    } else {
      this.subs.add(this.identityWalletService.withdrawalOtpCheck(this.form.value).subscribe({
        next: response => {
          console.log(response)
          console.log(this.counter)
          // mostra template di conferma
          if (response) {
            this.success = true;
          } else {
            if (this.counter < 3) {
              this.showRetryMessage()
            } else {
              this.dialogRef.close('tooManyAttempts')
            }
          }
        },

        error: () => {
          this.dialogRef.close('requestFailed');
          // console.log(error)
        }
      }));
    }

  }

  private showRetryMessage() {
    this.form.reset()
    this.counter++
    // console.log(this.counter)
  }
}
