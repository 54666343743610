import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '@bs/models';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SettingFixtureLimitService {
  readonly url = `${this.config.api.sportsbook}/setting-selection`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  patch(params: Params) {
    return this.http.patch(`${this.url}`, params);
  }

}
