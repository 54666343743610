import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EnvironmentConfig, registrationMode } from '@bs/models';
import { WindowService } from '@bs/universal';
import { Subscription } from 'rxjs';

interface MyData {
  mode: registrationMode;
  me: { currencyId: number, parentId: number, selfRegistration: boolean };
}

@Component({
  selector: 'registration-dialog',
  templateUrl: 'registration-dialog.component.html',
  styleUrls: ['registration-dialog.component.scss']
})
export class RegistrationDialog implements OnDestroy {
  isMobile: any;
  mode: registrationMode;

  subs = new Subscription();

  constructor(private config: EnvironmentConfig, private router: Router, windowService: WindowService, public dialogRef: MatDialogRef<RegistrationDialog>,
              @Inject(MAT_DIALOG_DATA) public data: MyData) {

    this.mode = data.mode ? data.mode : config.features.registrationModes
    console.log(this.mode)

    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onRegistration() {
    this.router.navigate(['/']).then(r => {
    });
    this.dialogRef.close();
  }
}
