import { Component } from '@angular/core';

@Component({
  selector: 'section.register',
  templateUrl: 'register.page.html'
})

export class RegisterPage {

  registrationSuccess: boolean;

  onRegistration(regSuccess: boolean): void {
    if (regSuccess) {
      this.registrationSuccess = true;
    }
  }
}
