import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMe, IPaymentMethod, IWithdrawalRequest } from '@bs/models';
import { AuthService, IdentityWalletService } from '@bs/services';
import { WithdrawalBaseDialog } from '../withdrawal-base.dialog';

interface MyData {
  me: IMe;
  amount: number;
  provider: IPaymentMethod;
}

@Component({
  selector: 'manual-withdrawal-dialog',
  templateUrl: 'manual-withdrawal.dialog.html',
})

export class ManualWithdrawalDialog extends WithdrawalBaseDialog implements OnInit {

  constructor(public identityWalletService: IdentityWalletService, public authService: AuthService,
              public dialogRef: MatDialogRef<ManualWithdrawalDialog>,
              @Inject(MAT_DIALOG_DATA) public data: MyData) {
    super(identityWalletService, authService);
  }

  ngOnInit() {
    const request: IWithdrawalRequest = {
      amount: this.data.amount,
      paymentMethodId: this.data.provider.id
    };
    this.createMovement(request);
  }

}
