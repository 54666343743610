<ng-template #flag let-lang>
  @if (headerLayout !== 'header4') {
    <div class="flag d-flex align-items-center justify-content-center">
      <svg [icon]="lang.code | lowercase | slice:-2" lib="flags" [size]="{w:18, h:18}"></svg>
    </div>
  }
</ng-template>

<mat-form-field>
  @if (headerLayout === 'header1') {
    <mat-label>
      <span [translate]="'language'">Language</span>
    </mat-label>
  }
  <mat-select [value]="currentLang?.code" (valueChange)="changeLanguage($event)" disableOptionCentering panelClass="language-dropdown" panelWidth="">
    <mat-select-trigger class="d-flex align-items-center">
      <ng-template [ngTemplateOutletContext]="{$implicit:currentLang}" [ngTemplateOutlet]="flag"></ng-template>
      @if (headerLayout === 'header1' || headerLayout === 'header4') {
        <span>&nbsp;{{currentLang?.name}}</span>
      }
    </mat-select-trigger>
    @for (lang of languages; track lang) {
      <mat-option [value]="lang.code">
        <div class="d-flex aligh-items-center">
          <ng-template [ngTemplateOutletContext]="{$implicit:lang}" [ngTemplateOutlet]="flag"></ng-template>
          <span>&nbsp;{{lang.name}}</span>
        </div>
      </mat-option>
    }
  </mat-select>
</mat-form-field>
