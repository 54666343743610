import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MobilePrefixValidator } from '@bs/forms';
import { EnvironmentConfig, ICmsBanner, IMe, Webapps } from '@bs/models';
import { AuthService, CordovaService } from '@bs/services';
import { WINDOW } from '@bs/universal';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ForgotPasswordDialog } from '../../dialogs/forgot-password/forgot-password-dialog.component';
import { ZeroBalanceDialog } from '../../dialogs/zero-balance/zero-balance.dialog';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styles: [`:host {
    display: block;
  }`],
  animations: [
    trigger(
      'fadeInOut', [
        transition(':enter', [
          style({opacity: 0}),
          animate('600ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('200ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class LoginComponent implements OnDestroy, OnInit {

  @Input()
  data: any;

  @Input()
  isPhoneReg: boolean = this.config.features.registrationModes.includes('phone');

  @Output()
  outcome: EventEmitter<IMe | false> = new EventEmitter<IMe | false>();

  @Output()
  isLoadingBar = new EventEmitter<boolean>(false);

  form: FormGroup;
  formGroupErrorMessage = '';
  isLoading = false;
  me: IMe;
  loginBanner: ICmsBanner;
  // isPhoneReg: boolean = this.config.features.registrationModes.includes('phone');
  liveChatUrl: string = this.config.liveChats?.tawkUrl;
  // allowLogin: boolean = this.config.allowLogin;
  hide = true;

  subs = new Subscription();

  fingerprintType: string | boolean = false;
  saveCredentials = false;
  hasCredentials = false;
  totalBalance = 0;

  constructor(private config: EnvironmentConfig, private authService: AuthService,
              private dialog: MatDialog, private translate: TranslateService, @Inject(WINDOW) private window: Window,
              private fb: FormBuilder, @Optional() private cordovaService: CordovaService) {

    this.subs.add(authService.accountLogged$.subscribe({
      next: account => this.me = account
    }));

    this.form = fb.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      username: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(20)])]
    });

    if (cordovaService) {
      cordovaService.hasFingerprint().then(result => this.fingerprintType = result, error => console.log(error));
      this.hasCredentials = cordovaService.hasCredentials();
    }

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    if (this.data) {
      this.formGroupErrorMessage = this.data.errorMessage;
      if (this.data.username?.prefix) {
        this.form.patchValue({username: `+${this.data.username.prefix.prefix}${this.data.username.phone}`});
      } else if (this.data.username) {
        this.form.patchValue({username: this.data.username});
      }
    }
    if (this.isPhoneReg) {

      this.form.get('username').setValidators(Validators.compose([Validators.required, MobilePrefixValidator.number(this.config.features?.validators?.phone)]));
    }
  }

  submit() {
    this.isLoading = true;
    this.isLoadingBar.emit(true);
    const values = Object.assign({}, this.form.value);

    if (typeof this.form.value.username !== 'string') {
      const mobileObj = this.form.value.username;
      Object.assign(values, {username: `+${mobileObj.prefix.prefix}${mobileObj.phone}`});
    }

    this.authService.login(values).subscribe({
      next: response => {
        if (response.token) {
          if (this.config.externalScripts.some(x => x.name === 'ze-snippet')) {
            this.window['zE']('webWidget', 'helpCenter:reauthenticate');
          }
          if (this.saveCredentials) {
            this.cordovaService.saveCredentials(values)
          }
          const me = response.me;
          this.calculateTotalBalance()
          this.openZeroBalanceDialog(me)
          setTimeout(() => this.outcome.emit(me), 350);
        } else if (response.tfa) {
          this.form.addControl('tfa', this.fb.group({
            otp: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
            id: [response.tfa.id]
          }))
        }
      },
      error: error => {
        switch (error.status.code) {

          case 500:
            this.formGroupErrorMessage = error.status.text;
            break;
          default:
            this.formGroupErrorMessage = this.translate.instant(error.message);
        }
      }
    }).add(() => {
      this.isLoading = false;
      setTimeout(() => {
        this.isLoadingBar.emit(false);
      }, 350);
    });
  }

  forgotPassword() {
    this.outcome.emit(false);
    const config = {};
    if (this.config.webAppId === Webapps.lisa || this.config.webAppId === Webapps.maggie) {
      Object.assign(config, {width: '420px', panelClass: 'forgot-password-dialog'});
    }
    this.dialog.open(ForgotPasswordDialog, config);
  }

  signUp() {
    this.outcome.emit(false);
  }

  private openZeroBalanceDialog(me: IMe) {
    if (this.config.features.showNoBalanceDialog && this.totalBalance < 100) {
      this.dialog.open(ZeroBalanceDialog, {
        data: {me},
        width: '600px',
      });
    }
  }

  private calculateTotalBalance() {
    this.totalBalance = this.me.wallets[0].balance.available;
    this.me.wallets[0].balance.bonuses?.forEach(x => this.totalBalance = x.available + this.totalBalance)
  }

}
