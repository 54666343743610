<div class="games-list-container">
  <div class="row games-row mx-0 mb-3">
    @for (game of games; track game) {
      <div class="col-game" [class.active]="game.id === activeGameId">
        <game-tile [game]="game" [isMobile]="isMobile"></game-tile>
        <div class="game-info pb-1">
          <strong class="game-name d-block ellipsis text-uppercase">{{game.name}}</strong>
        </div>
      </div>
    }
  </div>
</div>
