import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ICurrency, IPaymentMethod } from '@bs/models';
import { CatalogService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';

interface MyData {
  paymentMethod: IPaymentMethod;
  response: any;
  amount: number;
}

@Component({
  selector: 'deposit-qrcode-dialog',
  templateUrl: 'deposit-qrcode.dialog.html',
  styles: [`
    .divider-container {
      position: relative;
      top: 0.5rem;
    }
  `],
})

export class DepositQrCode {
  qrCode: string;
  currentCurrency: ICurrency;

  constructor(public dialogRef: MatDialogRef<DepositQrCode>,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: MyData,
              catalogService: CatalogService) {
    this.qrCode = data.response.url;
    this.currentCurrency = catalogService.currentCurrency;
  }

  copy() {
    navigator.clipboard.writeText(this.qrCode)
      .then(() => {
        this.snackBar.open(this.translate.instant('code-copied', {code: this.qrCode}), this.translate.instant('close'),
          {panelClass: 'success', horizontalPosition: 'end', duration: 3500});
      })
      .catch(() => {
        this.snackBar.open(this.translate.instant('code-copied-failed'), this.translate.instant('close'),
          {panelClass: 'error', horizontalPosition: 'end', duration: 3500});
      });
  }
}
