import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IPromotion, OddFormat, PromotionType } from '@bs/models';
import { PromoService } from '@bs/promotions';
import { AppSettingsService } from '@bs/services';
import { map, Observable } from 'rxjs';

interface IPromotionBonus {
  choosePromotion: boolean;
  selectedPromotion: number;
}

@Component({
  selector: 'promotion-bonus',
  templateUrl: 'promotion-bonus.component.html',
  providers: [
    PromoService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PromotionBonusComponent),
      multi: true
    }
  ]
})

export class PromotionBonusComponent implements ControlValueAccessor, OnInit {

  model: IPromotionBonus = {choosePromotion: false, selectedPromotion: null};
  promotions$: Observable<IPromotion[]>;
  oddFormat$: Observable<OddFormat>;

  constructor(private promoService: PromoService, appSettingsService: AppSettingsService) {
    this.oddFormat$ = appSettingsService.appSettings$.pipe(map(({ settings }) => settings.oddFormat))
  }

  ngOnInit() {
    this.promotions$ = this.promoService.all().pipe(map(promotions => promotions.filter(x => x.typeId === PromotionType.registration)));
  }

  registerOnChange(fn): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  writeValue(_model: IPromotionBonus): void {
    if (_model) {
      this.model = _model;
      setTimeout(() => this.propagateChange(this.model));
    } else {
      setTimeout(() => this.propagateChange({choosePromotion: false, selectedPromotion: null}));
    }
  }

  update(promotion: IPromotionBonus) {
    if(!promotion.choosePromotion) {
      promotion.selectedPromotion = null;
    }
    this.model = promotion;
    this.onTouched();
    this.propagateChange(this.model);
  }

  propagateChange(_model: IPromotionBonus) {
  }

  onTouched() {
  }

}
