import { ComboAction, ComboCheckboxType } from './combo-action-type';
import { BetType } from './bet-type';
import { Bonus } from './bonus';
import { Integral } from './integral';
import { Multiple } from './multiple';
import { Multiplier } from './multiplier';
import { IBetbonus } from './responses';
import { Selection } from '../selection';
import { Single } from './single';
import { System } from './system';
import { Winning } from './winning';
import { WinTax } from './wintax';

export type BetslipContext = 'prematch' | 'mixed' | 'live';

export class Betslip {

  selections: Selection[] = [];
  betType: BetType;
  type: string;
  context: BetslipContext;
  bonus: Bonus = new Bonus();
  maxPlayability = -1;
  acceptOddsChanges = true;

  constructor(init?: Partial<Betslip>) {
    Object.assign(this, init);
  }

  set stakeTax(amount: number) {
    this.betType.stakeTax = amount;
  }

  set winTax(winTax: WinTax) {
    this.betType.winTax = winTax;
  }

  isType(type: string): boolean {
    return type === this.type;
  }

  calculateBonus(bonusSetting: IBetbonus): void {

    if (!this.selections.length) {
      this.bonus.reset();
      return;
    }

    const percentagesSetting = this.guessPercentagesSettings(bonusSetting);

    this.bonus = this.betType.calculateBonus(
      percentagesSetting,
      bonusSetting.minimumRequiredMultiplier,
      this.selections,
      this.bonus
    );
  }

  calculateCombinations(bonusSetting: IBetbonus): void {
    const percentagesSetting = this.guessPercentagesSettings(bonusSetting);
    return this.betType.calculateCombinations(this.selections, percentagesSetting, bonusSetting.minimumRequiredMultiplier);
  }

  calculateMultiplier(): Multiplier {
    return this.betType.calculateMultiplier(this.selections);
  }

  calculateWinning(): Winning {
    return this.betType.calculateWinning(this.bonus);
  }

  guessContext(): number {
    let type = null;

    for (let i = 0, l = this.selections.length; i < l; i++) {
      if (!type) {
        type = this.selections[i].type;
        continue;
      }

      if (this.selections[i].type !== type) {
        type = 3;
        break;
      }
    }

    return type;
  }

  guessBetType(): string {
    const len = this.selections.length;
    const groups = [];
    this.selections.forEach((selection) => {
      if (!groups[selection.eventId]) {
        groups[selection.eventId] = [];
      }
      groups[selection.eventId].push(selection);
    });
    const duplicates = Object.values(groups).filter((group) => group.length > 1).length;

    if (len === 1) {
      this.betType = new Single(this.betType.amount);
      return 'single';
    } else if (len > 1) {
      if (duplicates) {
        this.betType = new Integral(this.betType.amount);
        return 'integral';
      } else {
        if (this.isType('system')) {
          // if (this.betType.hydrated) {
          this.betType = System.fromJSON(this.betType);
          // } else {
          //   this.betType = new System(this.betType.amount);
          // }
          return 'system';
        }
        this.betType = new Multiple(this.betType.amount);
        return 'multiple';
      }
    }

    console.warn('len minus than 1', len);
    return '';
  }

  removeSelectionBy(id: number, type: string): void {
    this.selections = this.selections.filter((s) => {
      return s[type] !== id;
    });

    if (!this.selections.length) {
      this.reset();
      return;
    }
  }

  reset(): void {
    this.selections = [];
    this.betType = new BetType();
    this.type = null;
    this.context = null;
    this.bonus = new Bonus();
    this.maxPlayability = -1;
  }

  setAmount(amount: number): void {
    this.betType.amount = +amount;
  }

  setBetslipPlayability(): void {
    if (this.selections.length === 0) {
      this.maxPlayability = -1;
    }

    this.selections.forEach(
      (s) => {
        if (s.oddPlayability === 0) {
          this.maxPlayability = 0;
          return;
        } else if (this.maxPlayability !== 0 && s.oddPlayability > this.maxPlayability) {
          this.maxPlayability = s.oddPlayability;
        }
      }
    );
  }

  setGetBetType(type: string): string {
    if (type === 'multiple') {
      this.betType = new Multiple(this.betType.amount);
      return 'multiple';
    }
    if (type === 'system') {
      this.betType = new System(this.betType.amount);
      return 'system';
    }

    console.warn('Unknown t', type);
    return '';
  }

  setComboAmount(type: number, amount: number): void {
    const combo = this.betType.combinations.find(x => x.type === type);

    if (combo) {
      combo.amount = amount;
      combo.enabled = true;
      combo.calculateBonus();
      combo.calculateWinning();
    }
  }

  setContext(context: BetslipContext): void {
    this.context = context;
  }

  setHydrated(hydrated: boolean): void {
    this.betType.hydrated = hydrated;
  }

  toggleComboSelection(type: number, action: number): void {

    (this.betType as System).totalStake.forced = false;

    this.betType.combinations.forEach((c) => {
      if (type === ComboCheckboxType.PlayAll) {
        if (action === ComboAction.Add) {
          c.amount = this.betType.amount;
          c.enabled = true;
        } else {
          c.enabled = false;
        }
      } else if (type === c.type) {
        c.enabled = !!action;
      }
    });

    (this.betType as System).calculateTotal();
  }

  private guessPercentagesSettings(bonusSetting): any {
    let percentagesSetting: any;

    if (this.context === 'live') {
      percentagesSetting = bonusSetting.live;
    } else {
      percentagesSetting = bonusSetting.prematch;
    }

    return percentagesSetting;
  }
}
