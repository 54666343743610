import { Directive } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { integerValidator } from '@bs/forms';
import { AuthService, IPaymentMethod, MeService, PaymentMethodsService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { amountWithdrawableValidator } from '../../../../bs/forms/src/lib/validators/amount-withdrawable.validator';

@Directive()
export class CashierBaseClass {

  me = toSignal(this.authService.accountLogged$.pipe(map(me => me)));

  loading: boolean;

  items: Array<IPaymentMethod> = [];

  selected: IPaymentMethod;
  itemsFiltered: Array<IPaymentMethod>;
  groupId: number;
  form: FormGroup;
  subs = new Subscription();

  constructor(protected route: ActivatedRoute,
              protected authService: AuthService,
              protected translate: TranslateService, protected snackBar: MatSnackBar,
              protected dialog: MatDialog,
              protected paymentMethodsService: PaymentMethodsService) {
  }

  all(isDeposit: boolean) {
    this.paymentMethodsService.getAll(isDeposit).subscribe(data => {
      this.items = data
    }, error => {
      console.log(error)
    }).add(() => {
      this.selectGroup(this.items[0].group.name)
    })
  }

  selectItem(id: number) {
    this.selected = this.itemsFiltered.find(x => x.id === id)
    this.selectType(this.selected.isDeposit)

    if (this.selected.group.id === 5) {
      this.form.get('amount').addValidators(integerValidator);
    }
  }

  selectGroup($event: any) {
    this.itemsFiltered = this.items.filter(x => x.group.name === $event)
    if (this.itemsFiltered.length === 1) {
      this.selected = this.itemsFiltered[0]
      this.selectType(this.selected.isDeposit)
    } else {
      this.selected = null
    }
  }

  selectType(isDeposit: boolean) {

    this.form.get('amount').setValidators([
      Validators.required,
      Validators.min(this.selected.validators.min / 100),
      Validators.max(this.selected.validators.max / 100)
    ]);

    if (!isDeposit) {
      this.form.get('amount').addValidators(amountWithdrawableValidator(
        this.authService.accountLogged$.value.wallets[0].balance.withdrawable,
        this.selected.validators.min,
        this.selected.validators.max
      ));
      this.form.get('amount').updateValueAndValidity(); // Aggiorna lo stato di validità del campo
    }
  }

  setAmount(amount: number) {
    this.form.get('amount').setValue(amount / 100);
    this.form.get('amount').markAsTouched();
  }

  showSnackbar(key: string, panelClass: string): void {
    this.snackBar.open(this.translate.instant(key), this.translate.instant('close'), {panelClass});
  }
}
