import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IBaseGame, IGame } from '@bs/models';
import { LocalStorage, WindowService } from '@bs/universal';
import { BehaviorSubject } from 'rxjs';
import { CatalogService } from '../core/catalog.service';

@Injectable({
  providedIn: 'root'
})
export class GamesFavouritesService {
  favourites$ = new BehaviorSubject<IBaseGame[]>([]);
  private STORAGE_KEY = 'GamesFavourites';


  constructor(private config: EnvironmentConfig, private catalogService: CatalogService, private http: HttpClient,
              private windowService: WindowService,
              private localStorage: LocalStorage) {

    this.restoreFavourites();

    this.favourites$.subscribe({
      next: favourites => {
        this.favourites = favourites
      }
    })
  }

  set favourites(favourites) {
    this.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(favourites));
  }

  toggle(game: IBaseGame) {
    const favourites = this.favourites$.getValue();
    const idx = favourites.findIndex(g => g.id === game.id);

    if (idx > -1) {
      favourites.splice(idx, 1);
    } else {
      favourites.push(game);
    }
    this.favourites$.next(favourites);
  }

  private restoreFavourites() {
    const favourites = JSON.parse(this.localStorage.getItem(this.STORAGE_KEY)) || [];
    this.favourites$.next(favourites);
  }
}
