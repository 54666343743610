import { Selection } from '../selection';

// @dynamic
export class MyMath {
  static combinations(selections: Array<Selection>,
                      grouped: number,
                      minimumRequiredMultiplier: number,
                      bonusPercentages: any) {
    const combinations = [];
    const selectionsBlocked = selections.filter(s => s.blocked).length;
    const moreBlockedThanGrouped = selectionsBlocked >= grouped;

    return function* run(level, start) {

      for (let i = start; i < selections.length - grouped + level + 1; i++) {

        combinations[level] = {oddValue: selections[i].oddValue, blocked: selections[i].blocked};

        if (level < grouped - 1) {
          yield* run(level + 1, i + 1);
        } else {

          if (!combinations.length) {
            continue;
          }

          const combinationsBlocked = combinations.reduce((t, s) => s.blocked ? t + 1 : t, 0);

          if (selectionsBlocked && (moreBlockedThanGrouped || !combinationsBlocked || combinationsBlocked < selectionsBlocked)) {
            continue;
          }

          const c = combinations.reduce((m, b) => {
            m.multiplier = MyMath.round(m.multiplier * b.oddValue, 6);
            if (b.oddValue >= minimumRequiredMultiplier) {
              m.validsForBonus.push(b.oddValue);
            }
            return m;
          }, {multiplier: 1, validsForBonus: []});

          const bonusPercentage = bonusPercentages[c.validsForBonus.length];
          c.bonus = bonusPercentage
            ? MyMath.round(c.validsForBonus.reduce((sum, v) => sum * v, 1) * (bonusPercentage / 100), 6)
            : 0;

          yield c;
        }
      }

    }(0, 0);
  }

  static factorial(n: number): number {
    if (n === 0) {
      return 1;
    }
    return n * this.factorial(n - 1);
  }

  /**
   * Parse a float number considering different separators
   */
  static dotFormat(value: string, digits: number): number {
    const trimmed = value.replace(/\s/g, '');
    const separator = value[value.length - digits - 1];
    const usesDotSeparator = separator === '.';
    let formatted: string = trimmed;

    if (usesDotSeparator) {
      // Remove thousand separator
      formatted = trimmed.replace(',', '');
    } else {
      // Uses comma separator
      // @TODO: other locales not using comma separator
      formatted = trimmed.replace('.', '').replace(',', '.');
    }

    return Number(formatted);
  }

  static floor(value: number, exp: number): number {
    return this.decimalAdjust('floor', value, exp);
  }

  static round(value: number, exp: number): number {
    return this.decimalAdjust('round', value, exp);
  }

  static isNumeric(n): boolean {
    return !isNaN(parseFloat(n));
  }

  private static decimalAdjust(type: string, value: any, exp: number): number {
    exp = (-1) * exp;
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }
}
