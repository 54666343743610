import { Pipe, PipeTransform } from '@angular/core';

/**
 * this pipe return us a counted value of a certain sport league category
 */
@Pipe({name: 'eventsCount'})

export class EventsCountPipe implements PipeTransform {

  /**
   * counts sports league categories
   * @param items {any} the sport matches item
   * @param extras {string[]} string values which will represent the items keys, so it can count the events
   */
  transform(items: any, ...extras: string[]): number {

    if (extras.length > 0) {
      const read = extras[0];
      extras.splice(0, 1);

      return items[read].reduce((count, item) => {
        count += this.transform(item, ...extras); //recursive
        return count;
      }, 0);

    } else {
      return items.eventsCount;
    }
  }
}
