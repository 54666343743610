import { IAuthor } from '../common/author';
import { IAccount } from '../me/me';
import { IWalletMovement } from '../wallet/movement';
import { IOutputModel, IOutputStatus } from './enum-output';

// è IOutPut quando è stato già erogato, quindi ha uno stato ed un account che lo ha ricevuto
export interface IOutput extends IOutputBase {
  statusId: IOutputStatus
  account: IAccount;
  movements?: Array<IWalletMovement>;
}

export interface IOutputLog {
  id: number;
  message: string;
  date: string;
}

export interface IOutputBase {
  id: number;
  bookmakerId: number;
  amounts: Array<IOutputAmount>;
  modelId: IOutputModel;
  spendability: string;
  wagering?: string;
  expiration: IOutputExpiration;
}

export interface IOutputTemplate extends IOutputBase {
  name: string;
  creationDate: string;
  author: IAuthor;
}

export interface IOutputAmount {
  currencyId: number;
  amount: number;
}

export interface IOutputExpiration {
  typeId: IExpirationType;
  date?: string;
  afterX?: number;
}

export enum IExpirationType {
  fixedDate = 1,
  afterXDays = 2,
  afterXMonths = 3,
  never = 10
}

// Request per la search
export interface IOutputSearchRequest {
  outputModelId?: number;
  outputStatusId?: number;
  displayCurrencyId: number;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}

// request per l'assegnazione di un output ad uno specifico network
export interface IOutputCreateRequest {
  accountId: number;
  templateId: number;
  amount: number;
  note?: string;
}

// richiesta per ottener la lista di tutti i template
export interface IOutputTemplatesRequest {
  bookmakerId: number;
  currencyId: number;
  modelId: IOutputModel;
}

export interface IOutputChangeStatusRequest {
  statusId: number;
  note?: string;
}

// Request per la search
export interface IOutputTemplateSearchRequest {
  outputModelId?: number;
  displayCurrencyId: number;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}
