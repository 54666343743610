import { Component, OnDestroy, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { GameBaseClass } from '@homer/games';
import { map } from 'rxjs';

@Component({
  selector: 'section.home-games',
  templateUrl: 'home.page.html'
})
export class HomePage extends GameBaseClass implements OnInit, OnDestroy {

  device$ = this.windowService.device$;
  me = toSignal(this.authService.accountLogged$.pipe(map(me => me)));

  ngOnInit() {

  }

}
