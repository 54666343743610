import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '@bs/models';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdCashService {
  readonly url = this.config.trackers?.find(x => x.name === 'adCash').url;

  constructor(private config: EnvironmentConfig) {
  }

  trackRegistration(networkId: number) {
    const request = new Request(`${this.url}${networkId}`, {method: 'POST'});
    return fetch(request)
      .catch((error) => {
        return throwError(error);
      });
  }
}
