import { ReportData } from '../accounts/reports';

export class NetworkDrawersReport extends ReportData<NetworkDrawersReport> {
  ticketSalesCount: number;
  ticketSalesAmount: number;
  winningPaidCount: number;
  winningPaidAmount: number;
  refundedCount: number;
  refundedAmount: number;
  voidCount: number;
  voidAmount: number;
  virtualBetCount: number;
  virtualBetAmount: number;
  virtualWinCount: number;
  virtualWinAmount: number;
  shopTopUpCount: number;
  shopTopUpAmount: number;
  shopPayoutCount: number;
  shopPayoutAmount: number;
  manualCashInCount: number;
  manualCashInAmount: number;
  manualCashOutCount: number;
  manualCashOutAmount: number;

  initialize(x) {

    x.net = {
      ticketSalesCount: x.ticketSalesCount,
      ticketSalesAmount: x.ticketSalesAmount,
      winningPaidCount: x.winningPaidCount,
      winningPaidAmount: x.winningPaidAmount,
      refundedCount: x.refundedCount,
      refundedAmount: x.refundedAmount,
      voidCount: x.voidCount,
      voidAmount: x.voidAmount,
      virtualBetCount: x.virtualBetCount,
      virtualBetAmount: x.virtualBetAmount,
      virtualWinCount: x.virtualWinCount,
      virtualWinAmount: x.virtualWinAmount,
      shopTopUpCount: x.shopTopUpCount,
      shopTopUpAmount: x.shopTopUpAmount,
      shopPayoutCount: x.shopPayoutCount,
      shopPayoutAmount: x.shopPayoutAmount,
      manualCashInCount: x.manualCashInCount,
      manualCashInAmount: x.manualCashInAmount,
      manualCashOutCount: x.manualCashOutCount,
      manualCashOutAmount: x.manualCashOutAmount,
    }
    x.line = {
      ticketSalesCount: x.ticketSalesCount,
      ticketSalesAmount: x.ticketSalesAmount,
      winningPaidCount: x.winningPaidCount,
      winningPaidAmount: x.winningPaidAmount,
      refundedCount: x.refundedCount,
      refundedAmount: x.refundedAmount,
      voidCount: x.voidCount,
      voidAmount: x.voidAmount,
      virtualBetCount: x.virtualBetCount,
      virtualBetAmount: x.virtualBetAmount,
      virtualWinCount: x.virtualWinCount,
      virtualWinAmount: x.virtualWinAmount,
      shopTopUpCount: x.shopTopUpCount,
      shopTopUpAmount: x.shopTopUpAmount,
      shopPayoutCount: x.shopPayoutCount,
      shopPayoutAmount: x.shopPayoutAmount,
      manualCashInCount: x.manualCashInCount,
      manualCashInAmount: x.manualCashInAmount,
      manualCashOutCount: x.manualCashOutCount,
      manualCashOutAmount: x.manualCashOutAmount,
    }

  }

  // initialize(x) {
  //   const zeroedValues = {
  //     ticketCount: 0,
  //     ticketSold: 0,
  //     ticketWinningPaid: 0,
  //     ticketRefundedPaid: 0,
  //     ticketVoidPaid: 0,
  //     topUpCount: 0,
  //     topUp: 0,
  //     shopRequestCount: 0,
  //     shopRequestPaid: 0,
  //   };
  //
  //   x.net = zeroedValues
  //   x.line = zeroedValues
  // }
}
