import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDepositRequest, PaymentMethodsService } from '@bs/services';
import { finalize } from 'rxjs/operators';
import { CashierBaseClass } from '../../cashier-base.class';
import { DepositQrCode } from '../../dialogs/deposit-qrcode/deposit-qrcode.dialog';

@Component({
  selector: 'cashier-deposit',
  templateUrl: 'cashier-deposit.component.html',
  providers: [PaymentMethodsService]
})

export class CashierDepositComponent extends CashierBaseClass implements OnInit {

  ngOnInit(): void {
    this.all(true)

    this.form = new FormGroup({
      bonusCode: new FormControl(),
      amount: new FormControl(null, [
        Validators.required,
      ])
    });

    this.subs.add(this.route.queryParamMap.subscribe({
      next: query => {
        if (query.has('bonusCode')) {
          this.form.get('bonusCode').setValue(query.get('bonusCode'));
        }
      }
    }));
  }

  submit() {
    this.loading = true;

    const request: IDepositRequest = {
      amount: this.form.value.amount * 100,
      paymentMethodId: this.selected.id,
      bonusCode: this.form.value.bonusCode,
    }

    this.paymentMethodsService.deposit(request).pipe(finalize(() => this.loading = false)).subscribe({
      next: response => {
        switch (this.selected.type) {
          case 'push':
            this.showSnackbar('depositRequestSubmittedPleaseCheckYourPhone', 'success');
            break
          case 'qrcode':
            this.dialog.open(DepositQrCode, {
              width: '400px',
              data: {
                paymentMethod: this.selected,
                response,
                amount: request.amount,
              }
            });
            break
        }

        // console.log(response);
      },
      error: error => {
        this.showSnackbar(error.message, 'error');
      }
    })

  }

}
