import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@bs/forms';
import { IBetSlipSelection } from '@bs/models';

/**
 * validating all the betslip selections of matches
 *
 * @param minLength {number} the sportsBook matches array length
 */
export function jackpotSelectionsValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) {
      return null;
    }

    const values: Array<IBetSlipSelection> = control.value;

    const uniqueSelections = new Set(values.flatMap(v => v.sportEvent.id));

    const isValid = values.length && (uniqueSelections.size >= minLength) && values.every(v => v && v.marketSelection.oddValue && v.sportEvent);

    return isValid ? null : {'invalid-selection': {min: minLength, current: values.length}};
  }
}
