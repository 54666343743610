import { Component, Inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ISlider } from '@bs/models';
import { AuthService } from '@bs/services';
import { WINDOW, WindowService } from '@bs/universal';
import { map } from 'rxjs';

@Component({
  selector: 'section.vip',
  templateUrl: 'vip.page.html',
})

export class VipPage implements OnInit {
  sliderHero: ISlider;

  device = toSignal(this.windowService.device$);
  me = toSignal(this.authService.accountLogged$.pipe(map(me => me)));

  constructor(@Inject(WINDOW) public window: Window, protected windowService: WindowService, private authService: AuthService, route: ActivatedRoute, private router: Router, private dialog: MatDialog) {
    route.data.subscribe({
      next: data => {
        console.log(data)
        this.sliderHero = data.sliders?.find(x => x.name === data.name + '-hero')
        console.log(this.sliderHero)
      }
    });
  }

  ngOnInit(): void {

  }

}
