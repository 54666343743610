import { Component, OnDestroy } from '@angular/core';
import { AppSettingsService } from '@bs/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'theme-switcher',
  templateUrl: './theme-switcher.component.html'
})
export class ThemeSwitcherComponent implements OnDestroy {

  isDark: boolean;

  subs = new Subscription();

  constructor(private settingsService: AppSettingsService) {
    this.subs.add(this.settingsService.appSettings$.subscribe({
      next: data => this.isDark = data.settings.darkTheme
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  changeTheme(value: boolean) {
    this.settingsService.toggleDarkTheme(value);
  }
}
