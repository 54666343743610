interface ValidValue {
  label: string;
  value: string;
}

export class ReportingParameter {
  name: ParamNames;
  parameterTypeName: string;
  nullable: boolean;
  nullableSpecified: boolean;
  allowBlank: boolean;
  allowBlankSpecified: boolean;
  multiValue: boolean;
  multiValueSpecified: boolean;
  queryParameter: boolean;
  queryParameterSpecified: boolean;
  prompt: string;
  promptUser: boolean;
  promptUserSpecified: boolean;
  dependencies?: any;
  validValuesQueryBased: boolean;
  validValuesQueryBasedSpecified: boolean;
  validValues: ValidValue[];
  defaultValuesQueryBased: boolean;
  defaultValuesQueryBasedSpecified: boolean;
  defaultValues: string[];
  parameterStateName: string;
  errorMessage?: any;
  values?: any;
  validValuesCatalog: any; // TODO qui ci va ICatalogItem che non ho trovato nel progetto california
  type: 'catalog' | 'input';

  constructor(param: ReportingParameter) {
    Object.keys(param).forEach(key => this[key] = param[key]);
    if (this.validValues) {
      this.validValuesCatalog = this.validValues.map(
        it => {
          return {
            id: it.value as any,
            name: it.label
          };
        }
      );
    }
  }

}

/**
 * all operator's currencies
 * just bookmaker's currencies
 * GamesService=> catalogGameType
 * start from day X
 * year number
 */
export type ParamNames = 'BookmakerID' | 'NetworkType' | 'DisplayTimeZoneID' | 'IsTest' | 'DeliveryType' | 'DisplayCurrencyID' | 'CurrencyID' | 'GameTypeID' | 'TimeZone' | 'FromDate' | 'ToDate' | 'FromDay' | 'Year' | 'Month' | 'View' | 'SortingID' | 'TicketStatus' | 'GroupBy';

