import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { Pager } from '@bs/models';

@Injectable()
export abstract class CrudService<T> {

  /**
   * return all paginated items filtered by params
   */
  abstract search(params: Params): Observable<Pager<T>>;

  /**
   * return a single instance

   */
  abstract get(item: Partial<T>): Observable<T>;

  /**
   * return a new instance
   * @param item{T}
   */
  abstract post(item: Partial<T>): Observable<Partial<T>>;

  /**
   * return a patched instance
   * @param id
   * @param item{T}
   */
  abstract patch(id: number, item: Partial<T>): Observable<T>;

  /**
   * return a deleted instance
   * @param item{T}
   */
  abstract delete(item: Partial<T>): Observable<T>;
}
