import { inject } from '@angular/core';

import { DictionarySport } from '@bs/models';
import { map } from 'rxjs/operators';
import { DictionariesService } from '../services/dictionaries.service';
import { ResolveFn } from '@angular/router';

/**
 * Resolver returns us a list of DictionarySport items
 *
 * see {@link DictionarySport} for more details on the model
 */
export const SportDictionaryResolver: ResolveFn<Array<DictionarySport>> = () => inject(DictionariesService).dictionaries().pipe(map(d => d.sports));
