import { NgModule } from '@angular/core';

// Directives
import { InfiniteScrollDirective } from './infinite-scroll.directive';
import { InputCommaDirective } from './input-comma.directive';
import { OutsideClickDirective} from './outside-click.directive';

const components = [
  InfiniteScrollDirective,
  InputCommaDirective,
  OutsideClickDirective
];

@NgModule({
  imports: [
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class DirectivesModule { }
