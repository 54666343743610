<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <div class="d-flex align-items-center">
        <svg icon="lock-reset" lib="global" [size]="{w:14, h:14}" class="me-2"></svg>
        <span [translate]="'forgot-password'">Forgot Password</span>
      </div>
      <a (click)="dialogRef.close()" [routerLink]="[]" [fragment]="null" class="close-icon" mat-flat-button type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </a>
    </mat-card-title>
    @if (loading) {
      <div class="d-flex col-12 align-items-center justify-content-center">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
    }

    <mat-tab-group mat-stretch-tabs>
      @for (mode of modes; track mode) {
        <div>
          @if (mode === 'email') {
            <mat-tab [label]="'email' | translate">
              <div class="m-3">
                <div class="forgot-password-message alert alert-info mb-3">
                  <strong [translate]="'forgot-password-email-message'"></strong>
                </div>
                <form-builder #emailFormRef [config]="emailConfig" (submits)="submitEmail($event)">
                  <div class="button-container col-12">
                    <button [disabled]="!emailFormRef.form.valid || loading" color="primary" mat-flat-button type="submit">
                      <div class="d-flex align-items-center">
                        <span [translate]="'submit'">Submit</span>
                        <svg icon="send" lib="global" class="ms-1" [size]="{w:14, h:14}"></svg>
                      </div>
                    </button>
                  </div>
                </form-builder>
              </div>
            </mat-tab>
          }
          @if (mode === 'phone') {
            <mat-tab [label]="'phone' | translate">
              <div class="m-3">
                <div class="forgot-password-message alert alert-info mb-3">
                  <strong [translate]="'forgot-password-phone-message'"></strong>
                </div>
                <form (ngSubmit)="submitPhone()" [formGroup]="phoneForm" class="dialog-body">
                  <div class="row gx-0 align-items-center">
                    <mobile-prefix class="w-100 mat-form-field mat-primary mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-should-float mat-form-field-has-label"
                                   formControlName="phone"></mobile-prefix>
                  </div>
                  <div class="row gx-0 mb-3">
                    <mat-error [control]="phoneForm.get('phone')" class="col-12 p-0"></mat-error>
                  </div>
                  <div class="row gx-0">
                    <mat-radio-group class="col-8" formControlName="validationType">
                      <mat-radio-button class="me-2" value="sms">SMS</mat-radio-button>
                      <mat-radio-button value="ivr">Call</mat-radio-button>
                    </mat-radio-group>
                    <button [disabled]="!phoneForm.valid" class="col-4" color="primary" mat-flat-button type="submit">
                      <div class="d-flex align-items-center">
                        <span [translate]="'submit'">Submit</span>
                        <svg icon="send" lib="global" class="ms-1" [size]="{w:14, h:14}"></svg>
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </mat-tab>
          }
        </div>
      }
    </mat-tab-group>
    @if (modes.length === 0) {
      <div class="m-3">
        <div class="alert alert-info mb-3">
          <strong [translate]="'forgot-password-not-available'"></strong>
        </div>
      </div>
    }
  </mat-card>
</div>
