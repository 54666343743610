import { Component, Input, OnInit } from '@angular/core';
import { gameTypeEnum, IBalanceBonus, IMe, IOutputModel, IOutputStatus, IWalletBonus } from '@bs/models';

@Component({
  selector: 'bonuses',
  templateUrl: 'bonuses.component.html'
})

export class BonusesComponent implements OnInit {
  @Input()
  me: IMe;

  @Input()
  items: Array<IBalanceBonus>;
  @Input()
  bonuses: Array<IWalletBonus>;

  gameTypes = gameTypeEnum.values()

  ngOnInit(): void {
    //  this.items = this.items.filter(x => x.bonusModelId === IOutputModel.realBonusCrashGames || x.bonusModelId === IOutputModel.realBonusSportsBookWagering)
    this.gameTypes = this.gameTypes.filter(x => this.items.map(item => item.gameTypeId))

    // console.log(this.gameTypes)
    //
    // this.bonuses = [
    //   {
    //     'id': -2147441387,
    //     'modelId': 30,
    //     'statusId': 1,
    //     'template': null,
    //     'account': {
    //       'id': 402821,
    //       'username': 'umut',
    //       'typeId': 0
    //     },
    //     'bookmakerId': 13,
    //     'startDate': '2023-08-17T07:13:27.473Z',
    //     'endDate': '2023-08-24T07:13:27.473Z',
    //     'creationDate': '2023-08-19T00:50:45.761Z',
    //     'amount': {
    //       'amount': 1000,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'available': {
    //       'amount': 10,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'depositBalance': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'wagering': {
    //       'amountWagering': {
    //         'amount': 35000,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountPlayed': {
    //         'amount': 2740,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountCredited': null
    //     },
    //     'author': {
    //       'id': 0,
    //       'typeId': 0,
    //       'username': ''
    //     }
    //   },
    //   {
    //     'id': -2147443678,
    //     'modelId': 30,
    //     'statusId': 2,
    //     'template': null,
    //     'account': {
    //       'id': 402821,
    //       'username': 'umut',
    //       'typeId': 0
    //     },
    //     'bookmakerId': 13,
    //     'startDate': '2023-08-09T07:25:18.361Z',
    //     'endDate': '2023-08-12T21:04:19.806Z',
    //     'creationDate': '2023-08-12T21:04:19.806Z',
    //     'amount': {
    //       'amount': 1000,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'available': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'depositBalance': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'wagering': {
    //       'amountWagering': {
    //         'amount': 35000,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountPlayed': {
    //         'amount': 35130,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountCredited': {
    //         'amount': 16240,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'dateCredited': '2023-08-12T21:10:01.135Z',
    //       'movementCreditedId': -1996638859
    //     },
    //     'author': {
    //       'id': 0,
    //       'typeId': 0,
    //       'username': ''
    //     }
    //   },
    //   {
    //     'id': -2147446751,
    //     'modelId': 30,
    //     'statusId': 3,
    //     'template': null,
    //     'account': {
    //       'id': 402821,
    //       'username': 'umut',
    //       'typeId': 0
    //     },
    //     'bookmakerId': 13,
    //     'startDate': '2023-06-27T09:35:38.972Z',
    //     'endDate': '2023-07-04T09:35:38.972Z',
    //     'creationDate': '2023-06-27T09:50:32.394Z',
    //     'amount': {
    //       'amount': 1000,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'available': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'depositBalance': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'wagering': {
    //       'amountWagering': {
    //         'amount': 35000,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountPlayed': {
    //         'amount': 1270,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountCredited': null
    //     },
    //     'author': {
    //       'id': 0,
    //       'typeId': 0,
    //       'username': ''
    //     }
    //   },
    //   {
    //     'id': -2147447483,
    //     'modelId': 30,
    //     'statusId': 1,
    //     'template': null,
    //     'account': {
    //       'id': 402821,
    //       'username': 'umut',
    //       'typeId': 0
    //     },
    //     'bookmakerId': 13,
    //     'startDate': '2023-06-13T13:52:30.608Z',
    //     'endDate': '2023-06-20T13:52:30.608Z',
    //     'creationDate': '2023-06-13T14:20:34.759Z',
    //     'amount': {
    //       'amount': 1000,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'available': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'depositBalance': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'wagering': {
    //       'amountWagering': {
    //         'amount': 35000,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountPlayed': {
    //         'amount': 1490,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountCredited': null
    //     },
    //     'author': {
    //       'id': 0,
    //       'typeId': 0,
    //       'username': ''
    //     }
    //   },
    //   {
    //     'id': -2147447736,
    //     'modelId': 30,
    //     'statusId': 2,
    //     'template': null,
    //     'account': {
    //       'id': 402821,
    //       'username': 'umut',
    //       'typeId': 0
    //     },
    //     'bookmakerId': 13,
    //     'startDate': '2023-06-13T08:05:37.281Z',
    //     'endDate': '2023-06-20T08:05:37.281Z',
    //     'creationDate': '2023-06-13T13:20:34.003Z',
    //     'amount': {
    //       'amount': 6300,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'available': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'depositBalance': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'wagering': {
    //       'amountWagering': {
    //         'amount': 378000,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountPlayed': {
    //         'amount': 13620,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountCredited': null
    //     },
    //     'author': {
    //       'id': 0,
    //       'typeId': 0,
    //       'username': ''
    //     }
    //   },
    //   {
    //     'id': -2147447927,
    //     'modelId': 30,
    //     'statusId': 1,
    //     'template': null,
    //     'account': {
    //       'id': 402821,
    //       'username': 'umut',
    //       'typeId': 0
    //     },
    //     'bookmakerId': 13,
    //     'startDate': '2023-06-12T12:40:14.299Z',
    //     'endDate': '2023-06-19T12:40:14.299Z',
    //     'creationDate': '2023-06-12T12:50:30.262Z',
    //     'amount': {
    //       'amount': 500,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'available': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'depositBalance': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'wagering': {
    //       'amountWagering': {
    //         'amount': 17500,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountPlayed': {
    //         'amount': 3100,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountCredited': null
    //     },
    //     'author': {
    //       'id': 0,
    //       'typeId': 0,
    //       'username': ''
    //     }
    //   },
    //   {
    //     'id': -2147447974,
    //     'modelId': 1,
    //     'statusId': 1,
    //     'template': null,
    //     'account': {
    //       'id': 402821,
    //       'username': 'umut',
    //       'typeId': 0
    //     },
    //     'bookmakerId': 13,
    //     'startDate': '2023-06-12T12:08:27.901Z',
    //     'endDate': '2023-06-19T12:08:27.901Z',
    //     'creationDate': '2023-06-12T12:30:30.213Z',
    //     'amount': {
    //       'amount': 500,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'available': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'depositBalance': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'wagering': {
    //       'amountWagering': {
    //         'amount': 17500,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountPlayed': {
    //         'amount': 740,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountCredited': null
    //     },
    //     'author': {
    //       'id': 0,
    //       'typeId': 0,
    //       'username': ''
    //     }
    //   },
    //   {
    //     'id': -2147448541,
    //     'modelId': 4,
    //     'statusId': 4,
    //     'template': null,
    //     'account': {
    //       'id': 402821,
    //       'username': 'umut',
    //       'typeId': 0
    //     },
    //     'bookmakerId': 13,
    //     'startDate': '2023-06-07T09:48:27.252Z',
    //     'endDate': '2023-06-14T09:48:27.252Z',
    //     'creationDate': '2023-06-07T10:10:28.006Z',
    //     'amount': {
    //       'amount': 3750,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'available': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'depositBalance': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'wagering': {
    //       'amountWagering': {
    //         'amount': 168750,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountPlayed': {
    //         'amount': 13050,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountCredited': null
    //     },
    //     'author': {
    //       'id': 0,
    //       'typeId': 0,
    //       'username': ''
    //     }
    //   },
    //   {
    //     'id': -2147449076,
    //     'modelId': 8,
    //     'statusId': 4,
    //     'template': null,
    //     'account': {
    //       'id': 402821,
    //       'username': 'umut',
    //       'typeId': 0
    //     },
    //     'bookmakerId': 13,
    //     'startDate': '2023-05-26T20:25:19.59Z',
    //     'endDate': '2023-05-26T21:00:27.907Z',
    //     'creationDate': '2023-05-26T20:54:09.815Z',
    //     'amount': {
    //       'amount': 2000,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'available': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'depositBalance': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'wagering': {
    //       'amountWagering': {
    //         'amount': 105000,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountPlayed': {
    //         'amount': 59915,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountCredited': {
    //         'amount': -1,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       }
    //     },
    //     'promotionId': 622,
    //     'author': {
    //       'id': 0,
    //       'typeId': 0,
    //       'username': ''
    //     }
    //   },
    //   {
    //     'id': -2147449080,
    //     'modelId': 8,
    //     'statusId': 1,
    //     'template': null,
    //     'account': {
    //       'id': 402821,
    //       'username': 'umut',
    //       'typeId': 0
    //     },
    //     'bookmakerId': 13,
    //     'startDate': '2023-05-26T13:02:16.217Z',
    //     'endDate': '2023-05-26T13:40:23.526Z',
    //     'creationDate': '2023-05-26T13:34:07.74Z',
    //     'amount': {
    //       'amount': 22750,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'available': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'depositBalance': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'wagering': {
    //       'amountWagering': {
    //         'amount': 1755000,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountPlayed': {
    //         'amount': 118075,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountCredited': {
    //         'amount': -1,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       }
    //     },
    //     'promotionId': 620,
    //     'author': {
    //       'id': 0,
    //       'typeId': 0,
    //       'username': ''
    //     }
    //   },
    //   {
    //     'id': -2147449080,
    //     'modelId': 8,
    //     'statusId': 2,
    //     'template': null,
    //     'account': {
    //       'id': 402821,
    //       'username': 'umut',
    //       'typeId': 0
    //     },
    //     'bookmakerId': 13,
    //     'startDate': '2023-05-26T13:02:16.217Z',
    //     'endDate': '2023-05-26T13:40:23.526Z',
    //     'creationDate': '2023-05-26T13:34:07.74Z',
    //     'amount': {
    //       'amount': 22750,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'available': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'depositBalance': {
    //       'amount': 0,
    //       'currencyId': 1,
    //       'currencySymbol': '€',
    //       'currencyCode': 'EUR',
    //       'subunit': 100,
    //       'rate': 1
    //     },
    //     'wagering': {
    //       'amountWagering': {
    //         'amount': 1755000,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountPlayed': {
    //         'amount': 118075,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       },
    //       'amountCredited': {
    //         'amount': -1,
    //         'currencyId': 1,
    //         'currencySymbol': '€',
    //         'currencyCode': 'EUR',
    //         'subunit': 100,
    //         'rate': 1
    //       }
    //     },
    //     'promotionId': 620,
    //     'author': {
    //       'id': 0,
    //       'typeId': 0,
    //       'username': ''
    //     }
    //   }
    // ];
    // console.log(this.bonuses)
  }

  validModel(item: any) {
    const validModelId = [IOutputModel.realBonusCasinoWagering, IOutputModel.realBonusSportsBook, IOutputModel.realBonusSportsBookWagering, IOutputModel.realBonusCrashGamesWagering];
    return validModelId.includes(item.bonusModelId);
  }

  isRunning(item: any) {
    const validStatusIds = [IOutputStatus.running, IOutputStatus.created, IOutputStatus.emitted];
    return validStatusIds.includes(item.statusId);
  }

  isArchived(item: any) {
    const validStatusIds = [IOutputStatus.running, IOutputStatus.created, IOutputStatus.emitted];
    return !validStatusIds.includes(item.statusId);
  }

  hasWagering(item: any) {
    const validModelId = [IOutputModel.realBonusCasinoWagering,  IOutputModel.realBonusSportsBookWagering, IOutputModel.realBonusCrashGamesWagering];
    return validModelId.includes(item.modelId);
  }
}
