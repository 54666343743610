import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentConfig, FormInterface } from '@bs/models';
import { AuthService, PasswordService } from '@bs/services';
import { HomerFormsModule } from '@homer/forms';
import { Subscription } from 'rxjs';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MaterialComponentsModule } from '@homer/mat-components';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'section.password-recovery',
  templateUrl: 'recovery-password.page.html',
  standalone: true,
  imports: [
    MatCardModule,
    TranslateModule,
    MatProgressSpinnerModule,
    HomerFormsModule,
    MaterialComponentsModule,
    MatButtonModule
  ],
  styles: [`
    form-builder::ng-deep form {
      justify-content: center;
    }
  `]
})
export class RecoveryPasswordPage implements OnDestroy {
  config!: FormInterface;
  formGroupErrorMessage = '';
  formGroupMessage = '';
  token: string;
  loading = false;
  subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private passwordService: PasswordService,
    authService: AuthService,
    private env: EnvironmentConfig,
    private router: Router,
    private snackBar: MatSnackBar, private translate: TranslateService
  ) {

    this.subs.add(authService.accountLogged$.subscribe({
      next: me => {
        if (me) {
          setTimeout(() => void router.navigateByUrl(''));
        }
      }
    }));

    this.token = route.snapshot.queryParams.t;
    this.config = {
      inputs: [
        {
          autocomplete: 'new-password',
          label: 'newPassword',
          name: 'newPassword',
          rowspan: 2,
          type: 'password',
          validations: {
            minLength: 4,
            required: true
          }
        },
        {
          autocomplete: 'new-password',
          label: 'confirmNewPassword',
          name: 'password_match',
          rowspan: 2,
          type: 'password',
          validations: {
            matchPassword: true,
            required: true
          }
        }
      ]
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  submit(formValues) {
    this.loading = true;
    this.passwordService.recoveryPassword(formValues.newPassword, this.token).subscribe({
      next: me => {
        this.formGroupMessage = 'password-changed';
        void this.router.navigate(['']);
      },
      error: error => {
        if (!error.data) {
          this.snackBar.open(this.translate.instant('token-not-found'), this.translate.instant('close'));
        }
      }
    }).add(() => {
      this.loading = false;
    })
  }
}
