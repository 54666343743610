import { Component, EventEmitter, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { matchPassword, MobilePrefixValidator, phoneExists, ValidationService } from '@bs/forms';
import { EnvironmentConfig, IdName, RegistrationBaseRequest } from '@bs/models';
import { AccountsService, AuthService, CatalogService, CookieService, TagEventHandlerService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'registration-new',
  templateUrl: './registration-new.component.html',
  styleUrls: ['./registration-new.component.scss']
})
export class RegistrationNewComponent implements OnInit {

  // @Input()
  // countryCode: string;

  @Output()
  success = new EventEmitter<boolean>(false);

  loading: boolean;

  form: FormGroup;
  currencies$: Observable<IdName<string, number>[]>;
  prefix: string;
  mobileRegex: RegExp;
  constructor(fb: FormBuilder, private catalogService: CatalogService, validationService: ValidationService,
              private snackBar: MatSnackBar, private translate: TranslateService,
              private cookieService: CookieService, private config: EnvironmentConfig,
              private authService: AuthService, private accountsService: AccountsService,
              @Optional() private tagEventHandlerService: TagEventHandlerService) {

    this.mobileRegex = this.config.features.validators.phone // /^(6|7)[0-9]{8}$/)]
    //console.log(this.mobileRegex)

    this.form = fb.group({
      phone: ['', Validators.compose([Validators.required, MobilePrefixValidator.number(this.mobileRegex)]), phoneExists(validationService)],
      password: ['', Validators.required],
      repeatPassword: ['', Validators.compose([Validators.required, matchPassword])],
      referralCode: [this.getReferralCode()],
      currencyId: [''],
      confirmation: [true, Validators.requiredTrue],
    });
  }

  ngOnInit() {
    this.catalogService.prefixes().subscribe({
      next: data => {
        // this.prefix = '+' + data.find(x => x.code === this.countryCode).prefix
        this.prefix = data[0].prefix
      }
    })

    this.currencies$ = this.catalogService.currencies().pipe(map(currencies => {
      if (currencies.length === 1) {
        // this.form.get('currencyId').disable({onlySelf: true});
      }
      return currencies.map(c => ({id: c.id, name: c.name, selected: c.selected}))
    }));
    if (this.cookieService.check('referralCode')) {
      this.form.get('referralCode').disable()
    }
  }

  submit() {
    this.loading = true;
    const req: RegistrationBaseRequest = {
      typeId: 0,
      autoLogin: true,
      username: this.getUsername(),
      password: this.form.get('password').value,
      currencyId: this.form.get('currencyId').value,
      referralCode: this.form.get('referralCode').value,
      deliveries: [{typeId: 2, delivery: this.getUsername(), isConfirmed: false, isPrimary: true}],
    }

    if (this.cookieService.check('affiliateId')) {
      req.trackingCode = this.cookieService.get('affiliateId');
    }

    if (this.cookieService.check('referralCode')) {
      req.referralCode = this.cookieService.get('referralCode');
    }

    this.accountsService.register(req).subscribe({
      next: response => {
        // check user for autologin
        if (response.token) {
          this.authService.loginToken(response.token).then((res) => {
            // this.outcome.emit(true)
            this.success.emit(true)
          });
        }

        if (this.cookieService.check('affiliateId')) {
          this.cookieService.delete('affiliateId');
        }

        if (this.cookieService.check('referralCode')) {
          this.cookieService.delete('referralCode');
        }

        this.tagEventHandlerService?.registrationConfirm(true);

        console.log(response)

      },

      error: error => {
        this.snackBar.open(this.translate.instant(error.message), this.translate.instant('close'));
      }
    }).add(() =>
      this.loading = false
    )
  }

  private getUsername(): string {
    return `+${this.form.value.phone.prefix.prefix}${this.form.value.phone.phone}`
  }

  private getReferralCode(): string {
    if (this.cookieService.check('referralCode')) {
      return this.cookieService.get('referralCode')
    }
  }

}
