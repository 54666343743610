import { Pipe, PipeTransform } from '@angular/core';

/**
 * the value found in the values array
 */
@Pipe({name: 'find'})
export class FindPipe implements PipeTransform { // todo: remove from homer one is in bs/common

  /**
   * the transform pipe function
   * @param values {any[]} array with some items
   * @param predicate {(value: any, ...extras: any[])} callback function for comparing the arrays values
   * @param extras {any[]} the second array item
   *
   */
  transform(values: any[], predicate: (value: any, ...extras: any[]) => boolean, ...extras: any[]): any {
    if (!values) {
      return null;
    }
    if (typeof predicate === 'function') {
      return values.find(value => predicate(value, ...extras));
    }


    if (typeof predicate !== 'string') {

      const [key, val] = Object.entries<number>(predicate)[0];

      return values.find(x => x[key] === val);
    }

    // return values.find(value => predicate(value, ...extras));
  }
}
