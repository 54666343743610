import { Component, Input } from '@angular/core';

@Component({
  selector: 'read-more[content]',
  templateUrl: './read-more-content.component.html',
})

export class ReadMoreContentComponent {

  @Input()
  content: string;

  @Input()
  limitContent = 150;

  @Input()
  showBtn = true;

  readMore = false;
}
