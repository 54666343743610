<ng-template #loadingTpl>
  <div class="d-flex col-12 align-items-center justify-content-center">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</ng-template>


<div class="d-flex justify-content-between" mat-dialog-title>
  <div class="d-flex"><span>{{ data.method }}</span></div>
  <button mat-button (click)="dialogRef.close()" class="close-icon me-2" type="button">
    <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
  </button>
</div>

<div mat-dialog-content class="scrollbar">

  <!--<div class="dialog-body">-->
  @if (item) {
    <div class="row">
      <div class="col-12">
        <div class="row p-b-10">
          <div class="col-6">
            <span [translate]="'date'">date</span>
          </div>
          <div class="col-6 text-right">
            <span>{{ item.date | localizedDate:'dd/MM/yyyy HH:mm:ss' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row p-b-10">
          <div class="col-6">
            <span [translate]="'type'">Type</span>
          </div>
          <div class="col-6 text-right">
            <span [translate]="'type'">{{ item.causal }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row p-b-10">
          <div class="col-6">
            <span [translate]="'amount'">Amount</span>
          </div>
          <div class="col-6 text-right">
            <span>{{ item.amount.amount | displayCurrencyConversion: item.amount.rate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions align="end">
      <button (click)="print()" color="primary" mat-flat-button><span [translate]="'print'"></span></button>
    </div>
  } @else {
    <form [formGroup]="form">
      <div class=" row gx-0">
        <div class="col-12">
          <div class="alert alert-info m-b-30">
            <span [translate]="'your-current-balance'"
                  class="d-block">Your current Balance <b>&nbsp;{{ data.balance | displayCurrencyConversion }}</b></span>
          </div>
        </div>
      </div>
      <!--<div class="row gx-0">
      <div class="col">-->
      <mat-form-field class="w-100">
        <mat-label [translate]="'amount'">Amount</mat-label>
        <input autocapitalize="none" autocorrect="off" formControlName="amount" matInput placeholder="amount"
               type="number">
        <mat-error [control]="form.get('amount')"></mat-error>
      </mat-form-field>
      <!--</div>
    </div>
    <div class="row gx-0">
      <div class="col">-->
      <mat-form-field class="w-100">
        <mat-label>
          <span [translate]="'note'">note</span>
        </mat-label>
        <input formControlName="note" matInput type="text">
        <mat-error [control]="form.get('note')"></mat-error>
      </mat-form-field>
      <!--</div>
    </div>-->
      <div class="row p-t-15">
        <div class="col-12 text-right">
          <button (click)="submit()" [disabled]="!form.valid" [translate]="'confirm'" color="primary"
                  mat-flat-button type="button">Confirm
          </button>
        </div>
      </div>
    </form>
  }
  <!--</div>-->
</div>

