import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentConfig } from '@bs/models';

interface MyData {
  username: any;
}

@Component({
  selector: 'login-dialog',
  templateUrl: 'login-dialog.component.html',
  styleUrls: ['login-dialog.component.scss']
})
export class LoginDialog {
  @HostBinding('class')
  className = '';

  constructor(private config: EnvironmentConfig, public dialogRef: MatDialogRef<LoginDialog>, @Inject(MAT_DIALOG_DATA) public data: MyData) {
  }

}
