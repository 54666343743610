import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { IMe, NetworkBettingReport, NetworkCashFlowReport, NetworkCasinoReport, NetworkFinanceReport, Report } from '@bs/models';
import { ReportsService } from '../../services/accounts/reports.service';
import { DateFactoryService } from '../../services/core/date-factory.service';

/**
 * Resolver is returning us Report<NetworkCasinoReport> items
 *
 * see {@link NetworkCasinoReport} for more details about the model
 */
export const ReportPokerResolver: ResolveFn<Report<NetworkCasinoReport>> = (route: ActivatedRouteSnapshot) => {

  /**
   * The constructor
   * @param reportsService
   * @param dfs
   */
  const dfs = inject(DateFactoryService);

  const me: IMe = route.parent.parent.data.me;
  const base = {
    from: route.queryParams.from || dfs.dateFrom(7),
    to: route.queryParams.to || dfs.dateToday(),
  }
  const values = Object.assign({accountId: me.id}, route.queryParams, base);
  return inject(ReportsService).networkPoker(values);

}

/**
 * Resolver is returning us Report<NetworkCasinoReport> items
 *
 * see {@link NetworkCasinoReport} for more details about the model
 */
export const ReportLotteryResolver: ResolveFn<Report<NetworkCasinoReport>> = (route: ActivatedRouteSnapshot) => {

  /**
   * The constructor
   * @param reportsService
   * @param dfs
   */
  const dfs = inject(DateFactoryService);
  const me: IMe = route.parent.parent.data.me;
  const base = {
    from: route.queryParams.from || dfs.dateFrom(7),
    to: route.queryParams.to || dfs.dateToday(),
  }
  const values = Object.assign({accountId: me.id}, route.queryParams, base);
  return inject(ReportsService).networkLottery(values);
}


/**
 * Resolver is returning us Report<NetworkFinanceReport> items
 *
 * see {@link NetworkFinanceReport} for more details about the model
 */
export const ReportFinanceResolver: ResolveFn<Report<NetworkFinanceReport>> = (route: ActivatedRouteSnapshot) => {

  /**
   * The constructor
   * @param reportsService
   * @param dfs
   */
  const dfs = inject(DateFactoryService);

  const me: IMe = route.parent.parent.data.me;
  const base = {
    from: route.queryParams.from || dfs.dateFrom(7),
    to: route.queryParams.to || dfs.dateToday(),
  }
  const values = Object.assign({accountId: me.id, walletTypeId: 1}, route.queryParams, base);
  return inject(ReportsService).networkFinance(values);

}

/**
 * Resolver is returning us Report<NetworkCasinoReport> items
 *
 * see {@link NetworkCasinoReport} for more details about the model
 */
export const ReportCasinoLiveResolver: ResolveFn<Report<NetworkCasinoReport>> = (route: ActivatedRouteSnapshot) => {

  /**
   * The constructor
   * @param reportsService
   * @param dfs
   */
  const dfs = inject(DateFactoryService);

  const me: IMe = route.parent.parent.data.me;
  const base = {
    from: route.queryParams.from || dfs.dateFrom(7),
    to: route.queryParams.to || dfs.dateToday(),
  }
  const values = Object.assign({accountId: me.id}, route.queryParams, base);
  return inject(ReportsService).networkCasinoLive(values);

}

/**
 * Resolver is returning us Report<NetworkCasinoReport> items
 *
 * see {@link NetworkCasinoReport} for more details about the model
 */
export const ReportCasinoResolver: ResolveFn<Report<NetworkCasinoReport>> = (route: ActivatedRouteSnapshot) => {

  /**
   * The constructor
   * @param reportsService
   * @param dfs
   */
  const dfs = inject(DateFactoryService);

  const me: IMe = route.parent.parent.data.me;
  const base = {
    from: route.queryParams.from || dfs.dateFrom(7),
    to: route.queryParams.to || dfs.dateToday(),
  }
  const values = Object.assign({accountId: me.id}, route.queryParams, base);
  return inject(ReportsService).networkCasino(values);

}

/**
 * Resolver is returning us NetworkCashFlowReport items
 *
 * see {@link NetworkCashFlowReport} for more details about the model
 */
export const ReportCashflowResolver: ResolveFn<Report<NetworkCashFlowReport>> = (route: ActivatedRouteSnapshot) => {

  /**
   * The constructor
   * @param reportsService
   * @param dfs
   */
  const dfs = inject(DateFactoryService)

  const me: IMe = route.parent.parent.data.me;
  const base = {
    from: route.queryParams.from || dfs.dateFrom(7),
    to: route.queryParams.to || dfs.dateToday(),
  }
  const values = Object.assign({accountId: me.id}, route.queryParams, base);
  return inject(ReportsService).networkCashFlow(values);

}

/**
 * Resolver is returning us item of Report<NetworkBettingReport>
 */
export const ReportBettingResolver: ResolveFn<Report<NetworkBettingReport>> = (route: ActivatedRouteSnapshot) => {

  /**
   * The constructor
   * @param reportsService
   * @param dfs
   */
  const dfs = inject(DateFactoryService);
  const me: IMe = route.parent.parent.data.me;

  const base = {
    from: route.queryParams.from || dfs.dateFrom(7),
    to: route.queryParams.to || dfs.dateToday(),
    isOpen: false
  }
  const values = Object.assign({accountId: me.id}, route.queryParams, base);
  return inject(ReportsService).networkBetting(values);
}


/**
 * Resolver is returning us Report<NetworkCasinoReport> items
 *
 * see {@link NetworkCasinoReport} for more details about the model
 */
export const ReportVirtualResolver: ResolveFn<Report<NetworkCasinoReport>> = (route: ActivatedRouteSnapshot) => {

  /**
   * The constructor
   * @param reportsService
   * @param dfs
   */
  const dfs = inject(DateFactoryService);

  const me: IMe = route.parent.parent.data.me;
  const base = {
    from: route.queryParams.from || dfs.dateFrom(7),
    to: route.queryParams.to || dfs.dateToday(),
  }
  const values = Object.assign({accountId: me.id}, route.queryParams, base);
  return inject(ReportsService).networkVirtual(values);

}
