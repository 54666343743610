import { IFeedProvider } from '../feed';
import { IMapping } from '../mapping';

export interface IFeedMarket {
  id: number;
  name: string;
  feedProvider: IFeedProvider;
  externalKey: string;
  acquiringDate: string;
  isMapped: boolean;
  isHidden: boolean;
  mapping?: Array<IMapping>
}
