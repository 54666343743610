import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// move to sharable models
type IProgress = {
  name: string;
  level: number;
  points: number;
  bar: number;
  type: 'freeSpins' | 'money';
  wager: number;
  amount?: number;
}

@Component({
  selector: 'loyalty-levels-dialog',
  templateUrl: 'loyalty-levels.dialog.html'
})

export class LoyaltyLevelsDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Array<IProgress>, public dialogRef: MatDialogRef<LoyaltyLevelsDialog>,) {
    console.log(data);
  }
}
