<div class="container-fluid mt-4">
  <banner-slider [slider]="sliderHero"></banner-slider>
  <div class="my-4">
    <a class="btn-back" [routerLink]="['../']" mat-flat-button [matTooltip]="'back' | translate" matTooltipPosition="right" matTooltipClass="custom-tooltip">
      <svg icon="chevron-left" lib="global" [size]="{w:8, h:8}"></svg>
    </a>
  </div>
  <mat-card class="my-4">
    <iframe id="iframe-tournaments" style="width: 100%; height: 0; display: none;"></iframe>
  </mat-card>
</div>
