import { ISearchBaseSportsBookFilters } from './common';
import { IFixture } from './fixture';
import { ITournament } from './tournament';

export interface ICategory extends IFixture {
  offerTypeId: number;
  tournaments?: Array<ITournament>
}

export interface ICategoryGetAllFilters {
  betOfferId: number;
  sportId: number;
}

export interface ICategorySearchFilters extends ISearchBaseSportsBookFilters {
  sportId?: number;
}

// export interface ICategorySearchResponse {
//   list: Array<ICategory>;
//   count: number;
// }
