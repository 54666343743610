import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

const HOURS = 24;
const MINUTES = 60;
const SECONDS = 60;
const NOW = new Date()

@Component({
  selector: 'countdown',
  templateUrl: 'countdown.component.html',
})
export class Countdown implements OnDestroy, OnInit {

  days: number;
  hours: number;
  minutes: number;
  seconds: number;

  @Input()
  toDate: string | Date = new Date(new Date().setDate(NOW.getDate() + 7)); //init by a week
  @Input() isInline: boolean;

  private subs = new Subscription();

  ngOnInit() {
    this.subs.add(interval(1000).subscribe({
      next: () => {
        const diff = new Date(this.toDate).getTime() - new Date().getTime();
        this.setTime(diff);
      }
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  convertString(number: number): string {
    if (number) {
      const nums: string[] = `${number}`.split('');

      return nums.reduce((acc, n, index, original) => {
        let tpl = '';

        if (original.length == 1) {
          tpl += `<div class="cypher">0</div>`;
        }

        tpl += `<div class="cypher">${n}</div>`;

        acc += tpl;

        return acc;
      }, '');

    }
  }

  private setTime(diff: number) {
    this.seconds = Math.floor(diff / 1e3 % SECONDS);
    this.minutes = Math.floor(diff / (1e3 * MINUTES) % SECONDS);
    this.hours = Math.floor(diff / (1e3 * MINUTES * SECONDS) % HOURS);
    this.days = Math.floor(diff / (1e3 * MINUTES * SECONDS * HOURS));
  }

}
