export interface IBaseRequestFilters {
  accountId?: number;
  from: Date;
  to: Date;
}

export interface INetworkFinanceFilters extends IBaseRequestFilters {
  walletTypeId: number;
}

export interface INetworkBettingFilters extends IBaseRequestFilters {
  isOpen: boolean;
}

export interface INetworkGamesFilters extends IBaseRequestFilters {
  brandId?: number;
}

export interface INetworkGamesGenericFilters extends IBaseRequestFilters {
  gameTypeId: number;
}
