import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BaseBirthPlace } from '@bs/forms';
import { ICountry } from '@bs/models';

/**
 * The component contains us birthplace fields
 *
 * @description input field for country, region, province, city
 */
@Component({
  selector: 'birth-place',
  templateUrl: './birth-place.component.html',
  styleUrls: ['./birth-place.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BirthPlaceComponent),
      multi: true
    }
  ]
})
export class BirthPlaceComponent extends BaseBirthPlace {
  /**
   * the label for the field
   */
  @Input()
  label = 'birthplace';

  /**
   * function focuses search input when dropdown opens
   * @param opened
   * @param input
   */
  focusInput(opened: boolean, input: HTMLInputElement): void {
    if (opened) {
      input.focus();
    }
  }

  /**
   * function on key press focuses first coming element option
   * @param select
   */
  focusOptions(select: MatSelect) {
    select.options.first.select();
  }

  searchFn = (items: ICountry, search: string) => {
    if (!search) {
      return items;
    }
    return items.name.toLowerCase().includes(search);
  };
}
