import { Pipe, PipeTransform } from '@angular/core';

/**
 * if it matches market outcomeId will return boolean value, true or false
 */
@Pipe({name: 'winningOdd'})
export class WinningOddPipe implements PipeTransform {

  /**
   * return us boolean matching market outcomes
   * @param market {any} the market match item
   * @param outcomeId {number} the outcomeId used to compare with a given market match for a result
   */
  transform(market: any, outcomeId: number): boolean {
    // TODO check why market is not a compatible with ISportBookMarket
    return market.outcomes.some(el => el.outcomeId === outcomeId);
  }
}
