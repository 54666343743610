import * as networkAgents from './accounts/networkAgents.json';
import * as networkCashiers from './accounts/networkCashiers.json';
import * as networkReferrals from './accounts/networkReferrals.json';
import * as networkSearch from './accounts/networkSearch.json';
import * as networkTransfersSearchByCode from './accounts/networkTrasferSearchByCode.json';
import * as networkTransfersSearch from './accounts/networkTrasfersSearch.json';
import * as searchWalletsMovementsSimple from './accounts/search-wallet-movements-simple.json';
import * as searchWalletsMovements from './accounts/search-wallets-movements.json';
import * as emailRecovery from './core/email-recovery.json';

import * as passwordRecovery from './core/password-recovery.json';
import * as loginFormPhone from './me/login-phone.json';
import * as loginForm from './me/login.json';

import * as autoexclusion from './me/overview/autoexclusion.json';
import * as documents from './me/overview/documents.json';
import * as personDetails from './me/overview/person-details.json';
import * as security from './me/overview/security.json';
import * as searchMovementsGameSessions from './movements/searchGameSessions.json';
import * as searchMovements from './movements/searchMovements.json';

import * as referralLink from './referral-link/referral-link-dialog.json';
import * as agentFaster from './registration-forms/private/agent-faster.json';

import * as phonePrivateNoOTP from './registration-forms/private/phonePrivateNoOTP.json';
import * as playerFaster from './registration-forms/private/player-faster.json';
import * as playerFull from './registration-forms/private/player-full.json';
import * as shopFaster from './registration-forms/private/shop-faster.json';
import * as agentFast from './registration-forms/public/agent-fast.json';
import * as emailFast from './registration-forms/public/email-fast.json';
import * as emailFullDocuments from './registration-forms/public/email-full-documents.json';
import * as emailFullWithPhoneLisa from './registration-forms/public/email-full-with-phone-lisa.json';
import * as emailFullWithPhone from './registration-forms/public/email-full-with-phone.json';
import * as emailFull from './registration-forms/public/email-full.json';
import * as euRegistration from './registration-forms/public/eu-registration.json';
import * as phoneBlackBet from './registration-forms/public/phone-blackbet.json';
import * as phoneFastNoOTP from './registration-forms/public/phone-fast-no-otp.json';
import * as phoneFast from './registration-forms/public/phone-fast.json';
import * as phoneFullDocuments from './registration-forms/public/phone-full-documents.json';
import * as phoneFullNoAddress from './registration-forms/public/phone-full-no-address.json';
import * as phoneFull from './registration-forms/public/phone-full.json';
import * as playerBrazil from './registration-forms/public/player-brazil.json';
import * as playerFast from './registration-forms/public/player-fast.json';
import * as shopFast from './registration-forms/public/shop-fast.json';

import * as searchTickets from './tickets/searchTickets.json';
import * as searchTicketsRetail from './tickets/searchTicketsRetail.json';

import * as searchDrawerMovements from './wallets/searchDrawerMovements.json';
import * as searchWithdrawals from './wallets/searchWithdrawals.json';

export {
  agentFast,
  emailFast,
  playerFaster,
  phoneBlackBet,
  agentFaster,
  shopFaster,
  emailFull,
  emailFullDocuments,
  emailFullWithPhone,
  emailFullWithPhoneLisa,
  euRegistration,
  phoneFast,
  phoneFastNoOTP,
  phoneFull,
  phoneFullDocuments,
  playerFast,
  playerBrazil,
  phonePrivateNoOTP,
  playerFull,
  shopFast,
  phoneFullNoAddress,
  // CORE
  passwordRecovery,
  emailRecovery,
  // ME
  autoexclusion,
  documents,
  loginForm,
  loginFormPhone,
  personDetails,
  security,
  referralLink,
  // TICKETS
  searchTickets,
  searchTicketsRetail,
  // MOVEMENTS
  searchMovements,
  searchMovementsGameSessions,
  // ACCOUNTS
  networkAgents,
  networkCashiers,
  networkReferrals,
  networkSearch,
  networkTransfersSearch,
  networkTransfersSearchByCode,
  searchWalletsMovements,
  searchWalletsMovementsSimple,
  // WALLETS
  searchDrawerMovements,
  searchWithdrawals
};
