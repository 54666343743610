<form [formGroup]="form" class="dialog" (ngSubmit)="submit()">

  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex align-items-center">
          <svg icon="filter-variant" lib="global" [size]="{w:20, h:20}"></svg>
          <h2 class="title ms-2">{{ 'filters' | translate }}</h2>
        </div>
        <button mat-button type="button" (click)="resetForm()" color="warn">
          <svg icon="cancel" lib="global" [size]="{w:14, h:14}"></svg>
        </button>
      </div>
      <ng-content select=".close-icon"></ng-content>
    </mat-card-title>
    <mat-card-content class="px-4">
      <mat-accordion multi="false">
        @if (data.providers; as providers) {
          <mat-expansion-panel formArrayName="providers" class="mt-4">
            <mat-expansion-panel-header>
              <span>{{ 'providers' | translate }}</span>
              <span>({{ providers.length }})</span>
            </mat-expansion-panel-header>
            @for (control of providersArray.controls; track control; let i = $index) {
              <div class="d-flex justify-content-between providers-panel">
                <ng-container [formGroupName]="i">
                  <div class="d-flex p-1 align-items-center">
                    <mat-checkbox class="d-flex align-items-center" formControlName="selected" (change)="form.markAllAsTouched()"></mat-checkbox>
                    <span class="ms-2">{{ control.get('name').value }}</span>
                  </div>
                  <!--              <img src="assets/images/brands/{{(isDarkTheme$ | async)  ? 'dark' : 'light'}}/{{control.get('slug').value}}.png" [alt]="control.get('name').value">-->
                </ng-container>
              </div>
            }
          </mat-expansion-panel>
        }
        @if (featuresArray.controls.length) {
          <ng-template [ngTemplateOutlet]="groupTpl" [ngTemplateOutletContext]="{controls:featuresArray.controls, name:'features'}"></ng-template>
        }
        @if (lineArray.controls.length) {
          <ng-template [ngTemplateOutlet]="groupTpl" [ngTemplateOutletContext]="{controls:lineArray.controls, name:'lines'}"></ng-template>
        }
        @if (volatilityArray.controls.length) {
          <ng-template [ngTemplateOutlet]="groupTpl" [ngTemplateOutletContext]="{controls:volatilityArray.controls, name:'volatility'}"></ng-template>
        }
        @if (themeArray.controls.length) {
          <ng-template [ngTemplateOutlet]="groupTpl" [ngTemplateOutletContext]="{controls:themeArray.controls, name:'themes'}"></ng-template>
        }
        @if (stakeLevelsArray.controls.length) {
          <ng-template [ngTemplateOutlet]="groupTpl" [ngTemplateOutletContext]="{controls:stakeLevelsArray.controls, name:'stakeLevels'}"></ng-template>
        }
      </mat-accordion>

      <ng-template #groupTpl let-controls="controls" let-name="name">
        <mat-expansion-panel class="mt-2" [formArrayName]="name">
          <mat-expansion-panel-header>
            <span>{{ name | translate }}</span>
          </mat-expansion-panel-header>
          @for (control of controls; track control; let i = $index) {
            <div class="d-flex providers panel">
              <ng-container [formGroupName]="i">
                <div class="d-flex align-items-center pt-3">
                  <mat-checkbox class="d-flex align-items-center" formControlName="selected" (change)="form.markAllAsTouched()"></mat-checkbox>
                  <span class="ms-4">{{ control.get('name').value | translate }}</span>
                </div>
              </ng-container>
            </div>
          }
        </mat-expansion-panel>
      </ng-template>

      <div class="d-flex justify-content-end mt-4">
        <button type="submit" [disabled]="!form.valid || form.untouched" mat-flat-button color="primary">{{ 'apply' | translate }}</button>
      </div>
    </mat-card-content>

  </mat-card>
</form>
