import { CurrencyConversion } from '../common/currency';
import { IDrawerMovement } from '../wallet/drawer';
import { BettingGroups, BettingTypes, TicketStatus } from './ticket';

export interface ITicketRetail {
  id: number;
  code?: string;
  accountId: number;
  bookmakerId: number;
  username: string;

  issueDate: string;
  updateDate: string;
  closeDate: string;

  ticketTypeId: number;
  ticketStatusId: TicketStatus,

  bettingGroupId: BettingGroups;
  bettingTypeId: BettingTypes;
  retailPaymentStatusId: number;
  stake: ITicketStake;
  payment: ITicketPayment;
  drawerMovements: Array<IDrawerMovement>;
}

export interface ITicketStake extends CurrencyConversion {
  stake: number;
}

export interface ITicketPayment extends CurrencyConversion {
  id: number;
  totalAmountPayable: number;
  winAmount: number;
  winTaxAmount?: number;
}
