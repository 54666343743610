import { ISearchBaseSportsBookFilters } from './common';
import { ILanguageEntityLabel } from './language';
import { IMarketSport } from './market-sport';

export interface IMarketGroup {
  id: number;
  betOfferId: number;
  name: string;
  sportId: number;
  languages?: ILanguageEntityLabel[];
  orderId: number;
  m?: Array<IMarketSport>
}

export interface IMarketGroupSearchFilters extends ISearchBaseSportsBookFilters {
  betOfferId: number;
}
