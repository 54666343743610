import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppSettings } from '@bs/models';
import { AppSettingsService } from '../services/core/app-settings.service';
import { AuthService } from '../services/core/auth.service';

/**
 * guard checks if user status id is 7 it navigates to /auto-excluded route
 */
@Injectable({
  providedIn: 'root'
})
export class AutoExclusionGuard  {
  /**
   * settings variable
   */
  settings: AppSettings;

  /**
   * The constructor we set the settings variable
   * @param authService
   * @param router
   * @param appSettingsService
   */
  constructor(private authService: AuthService, private router: Router, appSettingsService: AppSettingsService) {
    appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    });
  }

  /**
   * interface guard deciding if a route can be activated
   */
  canActivate(): Promise<boolean> | boolean | UrlTree {
    if (this.authService.isValid()) {

      return new Promise((resolve, reject) => {
        this.authService.getAuthentication().subscribe({
          next: me => {
            if (me?.status.id === 7) {
              reject(false);
              return this.router.navigate([this.settings.languageCode, 'auto-excluded']);
            }
            return resolve(true);
          }
        })
      });
    }
    return true;
  }

  /**
   * interface guard deciding if a child route can be activated.
   */
  canActivateChild(): Promise<boolean> | boolean | UrlTree {
    return this.canActivate();
  }
}
