

<div class="play-game-grid min-vh" [ngClass]="{'expanded-grid' : expandRightSide, 'full-screen': isFullScreen}">
  <div></div>
  <div class="play-game-wrapper" #gameFrame [class.py-4]="!isFullScreen">
    <mat-card>
      <mat-card-header class="mb-3">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h1 class="title my-0">{{game.name}}</h1>
          <div class="d-flex align-items-center game-controls">
            @if (!isReal) {
              <a [routerLink]="['../', 'real']" class="real-link me-4" mat-stroked-button>
                <span>{{'play-for-real' | translate}}</span>
              </a>
            }
            @if (canFullscreen) {
              <button [title]="'fullScreen'|translate" class="bar-button" mat-icon-button type="button" (click)="fullscreen()">
                <svg [icon]="isFullScreen ? 'fullscreen-exit' : 'fullscreen'" lib="global" [size]="{w:16, h:16}"></svg>
              </button>
            }

            <button [title]="'favourites'|translate" class="bar-button favourite-btn" mat-icon-button type="button" (click)="addFavorite()">
              <svg [icon]="isFavouriteGame ? 'heart': 'heart-outline'" [class.fill]="isFavouriteGame" lib="global" [size]="{w:16, h:16}"></svg>
            </button>

            <button (click)="location.back()" [title]="'goBack'|translate" class="exit" mat-icon-button type="button">
              <svg icon="close" lib="global" [size]="{w:14, h:14}"></svg>
            </button>

          </div>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="play-game-iframe">
          <iframe [src]="game.url | trusted: 'RESOURCE_URL'" class="frame" frameborder="0" width="100%"></iframe>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="play-game-infos d-none d-lg-block">
    <mat-card class="h-100" [class.open-sidebar]="expandRightSide">
      <!-- TODO: we need to trigger switching template of casino tournament with some logic -->
      <mat-card-content>
        <mat-tab-group mat-stretch-tabs animationDuration="0ms">
          <mat-tab [label]="'mostPlayed' | translate">
            <div class="games-grid game-info">
              @for (game of mostPlayed; track game) {
                <casino-game-tile [game]="$any(game)"></casino-game-tile>
              }
            </div>
          </mat-tab>
          <mat-tab [label]="'favourites' | translate">
            @if (favourites.length) {
              <div class="games-grid game-info">
                @for (game of favourites; track game) {
                  <casino-game-tile [game]="$any(game)"></casino-game-tile>
                }
              </div>
            } @else {
              <div class="favourites-side-box text-center p-5 mt-3">
               <svg icon="heart" lib="global" [size]="{w:80, h:80}" color="lightgray"></svg>
                <h3 class="favourites-side-title mt-3">{{'games-favourites-title' | translate}}</h3>
                <span class="favourites-side-text">{{'games-favourites-description' | translate}}</span>
              </div>
            }
          </mat-tab>
          <mat-tab [label]="'tournaments' | translate">
            <casino-tournaments [gameId]="game.id" [tournaments]="tournaments" [me]="me"></casino-tournaments>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>

    <button mat-icon-button class="expand-menu" [class.open]="expandRightSide" (click)="expandRightSide = !expandRightSide">
      <svg [icon]="expandRightSide ? 'chevron-right' : 'chevron-left'" lib="global" [size]="{w:12, h:12}"></svg>
    </button>
  </div>
</div>
