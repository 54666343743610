import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'casino-calendar-dialog',
  templateUrl: 'casino-calendar.dialog.html'
})

export class CasinoCalendarDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<CasinoCalendarDialog>) {
  }

  claimPresent(){
    this.dialogRef.close({claim: true});
  }
}
