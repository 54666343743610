export class Confirmation {
  data: ConfirmationData;
  width: string;

  constructor() {
    this.width = '500px';
    this.data = {
      header: 'Confirm',
      message: '',
      accept: null,
      reject: null
    };
  }
}

export class ConfirmationData {
  message: string;
  icon?: string;
  header?: string;
  accept: () => void;
  reject?: () => void;
}
