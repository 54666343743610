import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Params } from '@angular/router';
import { IGameType } from '@bs/models';

@Component({
  selector: 'casino-filter-dialog',
  templateUrl: 'casino-filter.dialog.html'
})
export class CasinoFilterDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data: IGameType, public dialogRef: MatDialogRef<CasinoFilterDialog>) {

  }

  submit(dialogResult: Params) {
    this.dialogRef.close(dialogResult);
  }
}
