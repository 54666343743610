import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WindowService } from '@bs/universal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'registration-dialog',
  templateUrl: './registration.dialog.html',
  styleUrls: ['./registration.dialog.scss']
})

export class RegistrationDialogComponent {

  isMobile: any;

  subs = new Subscription();
  loading: boolean;

  constructor(public dialogRef: MatDialogRef<RegistrationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              windowService: WindowService) {


    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));

  }

}
