import { Component, OnDestroy, Optional } from '@angular/core';
import { EnvironmentConfig, IMe } from '@bs/models';
import { AuthService } from '@bs/services';
import { WidgetIsReadyService, WidgetStateService, WidgetStateSubject } from '@livechat/widget-angular'
import { Subscription } from 'rxjs';

@Component({
  selector: 'mobile-chat-btn',
  templateUrl: './mobile-chat-btn.component.html'
})
export class MobileChatBtnComponent implements OnDestroy {
  liveChatInc: number = this.config.liveChats?.liveChatInc;

  me: IMe;

  subs = new Subscription();
  widgetState$: WidgetStateSubject
  chat: 'livechatInc';

  constructor(private config: EnvironmentConfig,
              authService: AuthService,
              private widgetStateService: WidgetStateService,
              @Optional() private widgetIsReadyService: WidgetIsReadyService) {

    this.subs.add(authService.accountLogged$.subscribe({
      next: me => this.me = me
    }));
    this.widgetState$ = widgetStateService.subject
    this.widgetStateService.setWidgetState({availability: 'online', visibility: 'hidden'})


    if (this.config.liveChats?.liveChatInc) {
      this.chat = 'livechatInc';
      this.liveChatInc = this.config.liveChats.liveChatInc;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  trigger() {
    switch (this.chat) {
      case 'livechatInc':
        this.widgetStateService.setWidgetState({availability: 'online', visibility: 'maximized'});
        break;
      default:
        console.error('no chat defined');
        break
    }

  }
}
