import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EnvironmentConfig,
  ICheckOutResponse,
  ICurrency,
  IDepositCheckOutRequest,
  IDepositInitResponse,
  IDepositOtpCheckRequest,
  IDepositOtpCheckResponse,
  IDepositOtpSendResponse,
  IDepositRequest,
  InitDepositRequest,
  InitDepositResponse,
  IPaymentMethod, IWallet, IWithdrawalOtpCheckRequest, IWithdrawalOtpCheckResponse,
  IWithdrawalOtpSendRequest, IWithdrawalOtpSendResponse,
  IWithdrawalRequest,
  IWithdrawalRequestCheckOutRequest,
  IWithdrawalResponse
} from '@bs/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';

@Injectable({
  providedIn: 'root'
})

export class IdentityWalletService {
  readonly url = `${this.config.api.identity}/me/wallets`;

  displayCurrency$: BehaviorSubject<Array<ICurrency>> = new BehaviorSubject(null);

  constructor(private config: EnvironmentConfig, private http: HttpClient, private authService: AuthService) {
  }

  wallets(): Promise<Array<IWallet>> {
    return this.http.get<Array<IWallet>>(this.url).pipe(tap(wallets => this.authService.refreshMe({wallets}))).toPromise();
  }

  getPaymentProviders(isDeposit: boolean): Observable<IPaymentMethod[]> {
    const method = isDeposit ? 'deposit' : 'withdrawal';
    return this.http.get<IPaymentMethod[]>(`${this.url}/payment-methods/${method}`);
  }

  initDeposit(request: InitDepositRequest): Observable<InitDepositResponse> {
    return this.http.post<InitDepositResponse>(`${this.url}/deposit/init`, request);
  }

  deposit(request: IDepositRequest): Observable<ICheckOutResponse> {
    return this.http.post<ICheckOutResponse>(`${this.url}/deposit`, request);
  }

  depositCheckOut(request: IDepositCheckOutRequest): Observable<ICheckOutResponse> {
    return this.http.post<ICheckOutResponse>(`${this.url}/deposit/checkout`, request);
  }

  depositOtpSend(request: IDepositRequest): Observable<IDepositOtpSendResponse> {
    return this.http.post<IDepositOtpSendResponse>(`${this.url}/deposit/otp-send`, request);
  }

  depositOtpCheck(request: IDepositOtpCheckRequest): Observable<IDepositOtpCheckResponse> {
    return this.http.post<IDepositOtpCheckResponse>(`${this.url}/deposit/otp-check`, request);
  }

  depositPush(request: IDepositRequest): Observable<IDepositOtpSendResponse> {
    return this.http.post<IDepositOtpSendResponse>(`${this.url}/deposit/push`, request);
  }

  depositInit(request: IDepositRequest): Observable<IDepositInitResponse> {
    return this.http.post<IDepositInitResponse>(`${this.url}/deposit/init`, request);
  }

  withdrawal(request: IWithdrawalRequest): Observable<IWithdrawalResponse> {
    return this.http.post<IWithdrawalResponse>(`${this.url}/withdraw`, request);
  }

  withdrawalOtpSend(request: IWithdrawalOtpSendRequest): Observable<IWithdrawalOtpSendResponse> {
    return this.http.post<IWithdrawalOtpSendResponse>(`${this.url}/withdraw/otp-send`, request);
  }

  withdrawalOtpCheck(request: IWithdrawalOtpCheckRequest): Observable<IWithdrawalOtpCheckResponse> {
    return this.http.post<IWithdrawalOtpCheckResponse>(`${this.url}/withdraw/otp-check`, request);
  }

  withdrawCheckOut(request: IWithdrawalRequestCheckOutRequest): Observable<ICheckOutResponse> {
    return this.http.post<ICheckOutResponse>(`${this.url}/withdraw/checkout`, request);
  }

}
