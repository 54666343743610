import { Component, Optional } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormService } from '@bs/forms';
import { Ages, EnvironmentConfig, FormInterface, GenderTypes, ICountry, IInput, IWebapp, RegistrationBaseRequest } from '@bs/models';
import { AccountsService, AuthService, CookieService, TagEventHandlerService, WebappService } from '@bs/services';
import { WindowService } from '@bs/universal';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { CurrenciesByCountry } from '@bs/common';
import { Params } from '@angular/router';

@Component({
  selector: 'registration-geo',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent {

  isMobile: any;

  subs = new Subscription();

  loading: boolean;

  form: UntypedFormGroup;
  formConfig!: FormInterface;
  prefix: string;
  mobileRegex: RegExp;
  countriesCatalog: Array<ICountry>;
  webapp: IWebapp;

  constructor(private wa: WebappService,
              private formService: FormService,
              windowService: WindowService,
              private snackBar: MatSnackBar, private translate: TranslateService,
              private cookieService: CookieService, private config: EnvironmentConfig,
              private authService: AuthService, private accountsService: AccountsService,
              @Optional() private tagEventHandlerService: TagEventHandlerService) {

    this.webapp = this.wa.webappConfig();

    this.countriesCatalog = this.webapp.countries;

    this.formConfig = {
      inputs: [
        {
          placeholder: 'registration-place-holder-username',
          label: 'registration-label-username',
          name: 'username',
          type: 'text',
          validations: {required: true, japanese: true},
          asyncValidator: 'usernameExists'
        },
        {
          placeholder: 'registration-place-holder-email',
          label: 'registration-label-email',
          name: 'email',
          type: 'email',
          validations: {required: true, mailFormat: true},
          asyncValidator: 'emailExists'
        },
        {
          label: 'registration-label-password',
          name: 'password',
          type: 'password',
          validations: {required: true}
        },
        {
          placeholder: 'registration-place-holder-first-name',
          label: 'registration-label-first-name',
          name: 'firstName',
          type: 'text',
          validations: {required: true,japanese: true}
        },
        {
          placeholder: 'registration-place-holder-last-name',
          label: 'registration-label-last-name',
          name: 'lastName',
          type: 'text',
          validations: {required: true, japanese: true}
        },
        {
          label: 'registration-label-gender',
          name: 'genderTypeId',
          type: 'select',
          autoselect: true,
          values: GenderTypes.values(),
          validations: {required: true}
        },
        {
          label: 'registration-label-birthDate',
          name: 'birthDate',
          type: 'date-selector',
          validations: {required: true, adult: 18},
          options: {max: 'adult'}
        },
        {
          label: 'registration-label-currency',
          name: 'currencyId',
          type: 'select',
          values: this.webapp.currencies,
          options: {
            autoselect: true
          },
          validations: {required: true}
        },
        {
          label: 'registration-label-country',
          name: 'country',
          type: 'select',
          values: this.webapp.countries,
          options: {
            autoselect: true
          },
          validations: {required: true}
        },
        {
          placeholder: 'registration-place-holder-referral-code',
          label: 'registration-label-referral-code',
          name: 'referralCode',
          type: 'text'
        },
        {
          label: 'registration-label-marketing',
          name: 'marketing',
          type: 'checkbox'
        },
        {
          label: 'registration-label-confirmation',
          name: 'confirmation',
          type: 'checkbox',
          validations: {required: true}
        },
        {
          name: 'trackingCode',
          type: 'hidden'
        }
      ]
    }

    this.addGeoFields(this.webapp.visitor.countryCode);

    const currencyId = this.webapp.currencies.find(c => c.code === CurrenciesByCountry.get(this.webapp.visitor.countryCode))?.id || this.webapp.currencies[0].id

    const patch: Params = {
      birthDate: Ages.average,
      country: this.webapp.countries.find(c => c.code === this.webapp.visitor.countryCode).id,
      currencyId
    }

    if (this.cookieService.check('referralCode')) {
      patch.referralCode = this.cookieService.get('referralCode');
      this.formConfig.inputs.find(i => i.name === 'referralCode').readOnly = true;
    }

    if (this.cookieService.check('affiliateId')) {
      patch.trackingCode = this.cookieService.get('affiliateId');
    }

    this.form = this.formService.buildForm(this.formConfig, patch);


    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));

    this.mobileRegex = this.config.features.validators.phone // /^(6|7)[0-9]{8}$/)]
  }

  submit() {
    this.loading = true;
    const value = this.form.value;

    const req: RegistrationBaseRequest = {
      typeId: 0,
      autoLogin: false,
      country: this.countriesCatalog.find(x => x.id === value.country),
      username: value.username,
      password: value.password,
      currencyId: value.currencyId,
      person: {
        firstName: value.firstName,
        lastName: value.lastName,
        genderTypeId: value.genderTypeId,
        birthDate: value.birthDate,
      },
      deliveries: [{typeId: 3, delivery: value.email, isConfirmed: false, isPrimary: true}],
    };

    if (this.cookieService.check('affiliateId')) {
      req.trackingCode = this.cookieService.get('affiliateId');
    }

    switch (this.webapp.visitor.countryCode) {
      case 'BR':
        req.document = {
          typeId: 4,
          documentNumber: value.documentNumber
        }
        break;
    }

    this.accountsService.register(req).subscribe({
      next: response => {
        // check user for autologin
        if (response.token) {
          this.authService.loginToken(response.token).then((_res) => {
          });
        }

        if (this.cookieService.check('affiliateId')) {
          this.cookieService.delete('affiliateId');
        }

        if (this.cookieService.check('referralCode')) {
          this.cookieService.delete('referralCode');
        }

        this.tagEventHandlerService?.registrationConfirm(true);

        this.snackBar.open(this.translate.instant('registration-completed-please-check-your-email'), this.translate.instant('close'));

        //console.log(response)
      },
      error: error => {
        this.snackBar.open(this.translate.instant(error.message), this.translate.instant('close'));
      }
    }).add(() => {
        this.loading = false;
      }
    )
  }

  private addGeoFields(countryCode: string) {
    const fields: Array<IInput> = []

    switch (countryCode) {
      case 'BR': {
        fields.push({
          name: 'documentNumber',
          type: 'text',
          label: 'cpf',
          validations: {required: true, minLength: 10, maxLength: 25, pattern: '^([-\\.\\s]?(\\d{3})){3}[-\\.\\s]?(\\d{2})$'},
          // asyncValidator: 'cpfValidator' // uncomment
        })
        const idx = this.formConfig.inputs.findIndex(i => i.name === 'birthDate');
        if (idx > -1) {
          this.formConfig.inputs.splice(idx + 1, 0, ...fields)
        }
        //const documents
        break;
      }
    }
  }
}
