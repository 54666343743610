export class Log {

  static debug(msg: string, obj?: any) {
    this.print(msg, 'grey', obj);
  }

  static error(msg: string, obj?: any) {
    this.print(msg, 'red', obj);
  }

  static info(msg: string, obj?: any) {
    this.print(msg, 'DodgerBlue', obj);
  }

  static success(msg: string, obj?: any) {
    this.print(msg, 'LimeGreen', obj);
  }

  static warning(msg: string, obj?: any) {
    this.print(msg, 'Tomato', obj);
  }

  private static print(msg: any, bgc: string, obj?: any) {

    if (Object.hasOwnProperty.call(msg, 'error')) {
      msg = msg.error.message;
    }

    if (obj) {
      console.log('%c' + msg, 'padding:2px 4px;border-radius:6px;color:white;font-weight:bold; background-color:' + bgc + ';');
      console.log(obj);
      console.log('----');
    } else {
      console.log('%c' + msg, 'padding:2px 4px;border-radius:6px;color:white;font-weight:bold; background-color:' + bgc + ';');
    }
  }
}
