<div class="row m-b-15">
  <div class="col-12 text-center">
    @if (label) {
      <span>{{ label }}</span>
    } @else {
      <span [translate]="'insertPin'">Insert pin</span>
    }
  </div>
</div>
<form class="d-flex justify-content-center flex-nowrap position-relative">
  @for (control of form?.controls; track control; let i = $index) {
    <div class="mb-3">
      <input #digit (blur)="onBlur($event, i)" (focus)="onMouseDown(i)" (keydown)="block($event)" (keyup.backspace)="setFocus(i-1)" (mousedown)="onMouseDown(i)" (ngModelChange)="update($event, i)" [formControl]="control" autocomplete="off"
             class="pin-input"
             maxlength="1" type="number"/>
    </div>
  }
</form>
@if (isError && !form.valid) {
  <div class="alert alert-danger mb-3">{{ 'field-required' | translate }}</div>
}
