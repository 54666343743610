<div class="sort-by d-flex align-items-center">
  <!--  <div translate class="me-2 d-none d-md-block">sortBy</div>-->
  <mat-form-field>
    <mat-select [value]="currentOption" (valueChange)="onChange($event)" disableOptionCentering panelClass="sort-by-dropdown">
      <mat-select-trigger>
        <div class="d-flex align-items-center">
          <svg [icon]="currentOption.icon" lib="global" class="me-1" [size]="{w:12, h:12}"></svg>
          <span class="label">&nbsp;{{ currentOption.label | translate }}</span>
        </div>
      </mat-select-trigger>
      @for (option of sortOptions; track option) {
        <mat-option [value]="option">
          <div class="d-flex align-items-center">
            <svg [icon]="option.icon" lib="global" class="me-1" [size]="{w:12, h:12}"></svg>
            <span class="label">&nbsp;{{ option.label | translate }}</span>
          </div>
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>

