/**
 * @deprecated old schema
 */
export interface IPaymentProvider {
  id: number;
  name: string;
  logoPath: string;
  externalLink: string;
  groupId: number;
  groupName: string;
  minValue: number;
  maxValue: number;
  percentageFee: number;
  fixedFee: number;
  processingTime: number;
  isText: boolean;
  textContent?: string;
  isKycNeeded: boolean;
  integrationTypeId: number;
  hasInitTransaction?: boolean;
  openingTypeId: number;
}
