import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SwiperDirective } from '@bs/common';
import { BannerPositionHorizontal, BannerPositionVertical, ISlider } from '@bs/models';
import { AuthService } from '@bs/services';
import { SwiperProps } from 'swiper/swiper-react';
import { TimingPipe } from './timing.pipe';

@Component({
  selector: 'banner-slider',
  templateUrl: 'banner-slider.component.html',
  styleUrl: './banner-slider.component.scss',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    TimingPipe,
    SwiperDirective
  ]
})

export class BannerSliderComponent implements OnInit {

  @Input()
  slider: ISlider;

  swiperConfig: SwiperProps;

  vertical = BannerPositionVertical;
  horizontal = BannerPositionHorizontal;
  lang: string;

  constructor(activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService) {
    this.lang = activatedRoute.root.firstChild.snapshot.params.lang;
  }

  ngOnInit() {
    // console.log(this.slider)
    if (this.slider) {
      this.swiperConfig = {
        // modules: [Autoplay, Navigation, Pagination, Scrollbar, A11y],
        slidesPerView: 1,
        // pagination: this.slider.config.showNavigationIndicators,
        // navigation: this.slider.config.showNavigationArrows,
        //height: this.slider.config.sizes.desktop.height,
        //width: this.slider.config.sizes.desktop.width,
        autoplay: {delay: this.slider.config.interval, disableOnInteraction: false, pauseOnMouseEnter: this.slider.config.pauseOnHover}
      }
    }
  }


  bannerClick(banner: any) {
    if (this.authService.isLogged()) {
      this.router.navigateByUrl(this.lang + banner.cta.path[0].value);
    } else {
      this.router.navigate([], {fragment: 'register'})
    }
  }
}
