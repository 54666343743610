<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span>
        <svg icon="aggent-new" lib="global" [size]="{w:14, h:14}" class="me-2"></svg>
        <span [translate]="'registration'">Registration</span>
      </span>
      <button (click)="dialogRef.close()" [routerLink]="[]" [fragment]="null" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>
    <div class="dialog-wrapper" [ngClass]="{mobile: isMobile}">
      <div class="scrollbar" [class.disabled]="!isMobile">
        <div class="registration-wrapper">
          @switch (mode) {
            @case ('phoneFastNoOTP') {
              <registration-new (success)="onRegistration()" class="h-100"></registration-new>
            }
            @default {
              <registration class="h-100" [mode]="mode" (outcome)="onRegistration()"></registration>
            }
          }
        </div>
      </div>
    </div>

  </mat-card>
</div>
