import { AfterViewInit, Component, ElementRef, forwardRef, QueryList, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BasePinInput } from '@bs/forms';

/**
 * The component generates us the phone pin fields
 */
@Component({
  selector: 'pin-input',
  templateUrl: 'pin-input.component.html',
  styles: [`:host {
    display: block
  }

  .pin-error {
    bottom: inherit;
  }`],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PinInputComponent),
    multi: true,
  }],
})
export class PinInputComponent extends BasePinInput implements AfterViewInit {
  /**
   * when some input is touched, and left empty, displays an error message for it
   */
  isError = false;
  /**
   * gets us the QueryList of all the inputs generated in the view DOM
   */
  @ViewChildren('digit', {read: ElementRef})
  digitsComponents: QueryList<ElementRef>;

  /**
   * function handles our blur effect of the input
   * @param event
   * @param index
   */
  onBlur(event, index: number) {
    const elms = this.digitsComponents.toArray();
    this.indexSet.add(index);
    this.isError = ([...this.indexSet].length === elms.length);
  }

  /**
   * lifecycle hook, passes to the super class BasePinInput
   *
   * see {@link BasePinInput} for more information
   */
  ngAfterViewInit() {
    super.afterViewInit(this.digitsComponents);
  }
}
