<ng-template #textTpl let-col="col" let-item="item">
  <div class="ellipsis">{{ item[col.field] }}</div>
</ng-template>

<ng-template #nestedTpl let-col="col" let-item="item">
  <div class="ellipsis">{{ item | nested: col.field }}</div>
</ng-template>

<ng-template #walletsTpl let-col="col" let-item="item">
  <span>{{ item[col.field][0].balance.available | displayCurrencyConversion }}</span>
</ng-template>
<ng-template #formValueTpl let-col="col" let-item="item">
  <div class="ellipsis">
    <span>{{ item[col.field]|formValues:{values: col.values} | translate }}</span>
  </div>
</ng-template>

<ng-template #booleanTpl let-col="col" let-item="item">
  <mat-slide-toggle [(ngModel)]="item[col.field]" [disabled]="true"></mat-slide-toggle>
</ng-template>

<!-- TODO: @deprecated use FormValuePipe with values config -->
<ng-template #statusTpl let-col="col" let-item="item">
  <span>{{ item[col.field].status | translate }}</span>
</ng-template>

<ng-template #ticketStatusTpl let-col="col" let-item="item">
  <div class="dot {{ item[col.field]|formValues:{values: col.values} }}" matTooltipPosition="above" [matTooltip]="item[col.field]|formValues:{values: col.values} | translate "></div>
</ng-template>

<ng-template #imageTpl let-col="col" let-item="item">
  <img [src]="item[col.field]" height="40" [alt]="col.name" loading="lazy"/>
</ng-template>

<ng-template #iconTpl let-col="col" let-item="item"></ng-template>

<ng-template #accountParentTpl let-col="col" let-item="item">
  <div class="flex align-items-center justify-content-between">
    <span [matTooltip]="item[0][col.field].typeId | formValues: { values: 'accountType' }">{{ item[0][col.field].username }}</span>
    <button (click)="emitterValue($event, 'openItem', item[0][col.field])" [matTooltip]="'openAccount'| translate" class="m-1" mat-button type="button">
      <svg icon="search" lib="global" [size]="{w:14, h: 14}"></svg>
    </button>
  </div>
</ng-template>

<ng-template #accountPersonTpl let-col="col" let-item="item">
  <ng-container>
    <div class="flex align-items-center">
      <span>{{ item[col.field].firstName }} {{ item[col.field].lastName }}</span>
    </div>
  </ng-container>

</ng-template>

<ng-template #accountTpl let-col="col" let-item="item">
  <div class="flex align-items-center justify-content-between">
    <span [matTooltip]="item[col.field].id">{{ item[col.field].username }}
      <i [matTooltip]="item[col.field].bookmakerId | bookmakerName" class="pi pi-question-circle"></i>
    </span>
    <button (click)="emitterValue($event, 'openItem', item)" [matTooltip]="'openAccount'| translate" class="m-1" mat-button type="button"></button>
  </div>
</ng-template>

<ng-template #bookmakerTpl let-col="col" let-item="item">
  <span>{{ item[col.field] | bookmakerName }}</span>
</ng-template>

<ng-template #ipAddressTpl let-col="col" let-item="item">
  <div class="flex align-items-center">
    <span class="me-2">{{ item[col.field].ipAddress }}</span>
    @if (item[col.field].counter > 1) {
      <span [matBadge]="item[col.field].counter" matBadgeOverlap="false"></span>
    }
  </div>
</ng-template>

<ng-template #amountTpl let-col="col" let-item="item">
  @if (!col.values) {
    <span>{{ item[col.field] | displayCurrencyConversion }}</span>
  }

  @if (col.values) {
    <span>{{ item[col.field][col.values] |displayCurrencyConversion }}</span>
  }
</ng-template>

<ng-template #dateTpl let-col="col" let-item="item">
  {{ item[col.field] | localizedDate }}
</ng-template>

<ng-template #inputTpl let-col="col" let-item="item">
  <input (change)="emitter.emit({callback:'update', data:item})" [(ngModel)]="item[col.field]" class="p-inputtext" type="text">
</ng-template>

<ng-template #oddsFormatTpl let-col="col" let-item="item">
  {{ item[col.field] | oddFormat:oddFormat:true }}
</ng-template>

<ng-template #conditionButtonTpl let-button="button" let-item="item">
  @if (item | buttonConditions:button.visible:button.condition:(button.comparison || '=')) {
    <button mat-flat-button (click)="emitterValue($event, button.click, item)" [ngClass]="{'me-1 mb-1 p-1': isMobile, 'p-0 mx-1':!isMobile}"
            [matTooltip]="button.label | translate" matTooltipPosition="above"
    >
      <span class="d-block d-md-none me-1" style="font-size: 11px">{{ button.label | translate }}</span>
      <svg [icon]="button.icon" lib="global" [size]="{w: 14, h: 14}"></svg>
    </button>
  }
</ng-template>

<ng-template #buttonTpl let-button="button" let-item="item">
  @if ((!button.name || item[button.name]) && !(button.click === 'deleteItem' && item.isDeleted)) {
    <button mat-flat-button (click)="emitterValue($event, button.click, item)" [ngClass]="{'me-1 mb-1 p-1': isMobile, 'p-0 mx-1':!isMobile}"
            [matTooltip]="button.label | translate" matTooltipPosition="above"
    >
      <span class="d-block d-md-none me-1" style="font-size: 11px">{{ button.label | translate }}</span>
      <svg [icon]="button.icon" lib="global" [size]="{w: 14, h: 14}"></svg>
    </button>
  }
</ng-template>

<ng-template #buttonsTpl let-col="col" let-item="item">
  <div class="flex" [style.width.px]="isMobile ? 'auto' : col.width" [class.d-block]="isMobile">
    @for (button of (col.buttons | hasPermission); track button) {
      <ng-template [ngTemplateOutlet]="button.visible ? conditionButtonTpl: buttonTpl" [ngTemplateOutletContext]="{button:button, item:item}"></ng-template>
    }
  </div>
</ng-template>

<ng-content select="messages"></ng-content>

<mat-accordion>
  <mat-expansion-panel [expanded]="true" collapsedHeight="*" expandedHeight="*">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="d-flex align-items-center">
          <svg icon='filter' lib="global" class="me-2" [size]="{w:14, h:14}"></svg>
          <span>{{ headerTitle | translate }}</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    @if (config) {
      <form-builder #builderComponent (submits)="submit($event)" [config]="config" [values]="values">
        @if (isLoading) {
          <div class="overlay">
            <!--<p-progressSpinner strokeWidth="5"></p-progressSpinner>-->
          </div>
        }
        <div class="button-container">
          @if (!config.hideReset) {
            <button (click)="reset()" [disabled]="builderComponent.form.untouched" class="ms-auto" mat-raised-button type="button">
               <div class="d-flex align-items-center">
                  <svg icon="delete" lib="global" [size]="{w:10, h:10}" class="me-2"></svg>
                  <span>{{ 'clear' | translate }}</span>
               </div>
            </button>
          }
          <button [disabled]="!builderComponent.form.valid || isLoading" mat-raised-button class="mat-button-base search-btn ms-1" type="submit">
            <div class="d-flex align-items-center">
              <span>{{ 'search' | translate }}</span>
              <svg icon="search" lib="global" [size]="{w:12, h:12}" class="ms-2"></svg>
            </div>
          </button>
        </div>
      </form-builder>
    }
  </mat-expansion-panel>

</mat-accordion>

<div class="p-2"></div>

<ng-template #tplSwitcher let-col="col" let-item="item">
  @switch (col.type) {
    @case ('date') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="dateTpl"></ng-template>
    }
    @case ('input') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="inputTpl"></ng-template>
    }
    @case ('bookmaker') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="bookmakerTpl"></ng-template>
    }
    @case ('ipAddress') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="ipAddressTpl"></ng-template>
    }
    @case ('account') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="accountTpl"></ng-template>
    }
    @case ('accountParent') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="accountParentTpl"></ng-template>
    }
    @case ('accountPerson') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="accountPersonTpl"></ng-template>
    }
    @case ('amount') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="amountTpl"></ng-template>
    }
    @case ('status') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="statusTpl"></ng-template>
    }
    @case ('ticketStatus') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="ticketStatusTpl"></ng-template>
    }
    @case ('icon') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="iconTpl"></ng-template>
    }
    @case ('image') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="imageTpl"></ng-template>
    }
    @case ('odds') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="oddsFormatTpl"></ng-template>
    }
    @case ('button') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="buttonsTpl"></ng-template>
    }
    @case ('boolean') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="booleanTpl"></ng-template>
    }
    @case ('formValue') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="formValueTpl"></ng-template>
    }
    @case ('nested') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="nestedTpl"></ng-template>
    }
    @case ('wallets') {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="walletsTpl"></ng-template>
    }
    @default {
      <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="textTpl"></ng-template>
    }
  }
</ng-template>

@if (items?.count) {
  @switch (isMobile) {
    @default {
      <table class="w-100" mat-table [dataSource]="items.list">
        @for (col of cols; track col) {
          <ng-container [matColumnDef]="col.header">
            <th mat-header-cell *matHeaderCellDef>{{ col.header | translate }}</th>
            <td mat-cell *matCellDef="let item">
              <ng-template [ngTemplateOutlet]="tplSwitcher" [ngTemplateOutletContext]="{item:item, col:col}"></ng-template>
            </td>
          </ng-container>
        }
        <tr mat-header-row *matHeaderRowDef="headerRow"></tr>
        <tr mat-row *matRowDef="let row; columns: headerRow;"></tr>
      </table>
    }
    @case (true) {
      <div class="mobile-tickets-container">
        @for (item of items.list; track item) {
          <div (click)="checkAction(cols, item)" class="ticket-entry" [class.no-status]="!item?.status && !item?.ticketStatusId"
               [ngClass]="(item?.status?.status || ticketTypes.get(item?.ticketStatusId))|lowercase">
            @if (item?.status?.status || ticketTypes.get(item?.ticketStatusId); as ticketStatus) {
              <div class="side-status">
                <span class="text-center">{{ ticketStatus }}</span>
              </div>
            }
            <div class="ticket-body form-row ms-1 mr-0">
              @for (col of (cols | filter:isMobileHidden); track $index) {
                <div class="col-6 p-b-5">
                  <span [translate]="col.header"></span>
                  <b>
                    <ng-template [ngTemplateOutlet]="tplSwitcher" [ngTemplateOutletContext]="{item:item, col:col}"></ng-template>
                  </b>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
  }
  <mat-paginator (page)="onPage($event)" [length]="items.count" [pageSize]="+baseParams.pp" [pageSizeOptions]="[10,25,50]" [pageIndex]="(baseParams.p-1)"></mat-paginator>
} @else {
  <div class="alert alert-warning mb-3" role="alert">{{ 'thereAreNoResults' | translate }}</div>
}
