import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IMarketLanguage } from '@bs/models';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketLanguageService {
  readonly url = `${this.config.api.sportsbook}/markets`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  update(data: IMarketLanguage): Observable<IMarketLanguage> {
    return this.http.put<IMarketLanguage>(`${this.url}/${data.marketId}/languages`, data);
  }

}
