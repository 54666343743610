<html>
<head>
  <style type="text/css">

    .tg td {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      padding: 10px 5px;
      overflow: hidden;
      word-break: normal;
      border: 1px solid #000;
    }

    .tg th {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: normal;
      padding: 10px 5px;
      overflow: hidden;
      word-break: normal;
      border: 1px solid #000;
    }

    .tg .tg-0lax {
      text-align: left;
      vertical-align: top
    }
  </style>
  <meta charset="utf-8">
  <title>{{transfer.code}}</title>
</head>
<div id="printable" style="width:80mm">
  <table border="1" cellpadding="0" cellspacing="0" width="100%">
    <colgroup>
      <col style="width: 50%">
      <col style="width: 50%">
    </colgroup>
    <tr>
      <th class="tg-0lax" colspan="2">
        <img src="{{host}}/assets/images/logo-black.svg" alt="logo" style="width: 150px">
      </th>
    </tr>
    <tr>
      <th class="tg-0lax" colspan="2">{{transfer.code}}</th>
    </tr>
    <tr>
      <td [translate]="'date-of-creation'" class="tg-0lax">Created at</td>
      <td class="tg-0lax">{{transfer.requestedAt | localizedDate:'dd/MM/yyyy HH:mm:ss' }}</td>
    </tr>
    <tr>
      <td [translate]="'type'" class="tg-0lax">Type</td>
      <td class="tg-0lax">{{transfer.typeId | formValues:{values: 'networkTransferTypes'} }}</td>
    </tr>
    <tr>
      <td [translate]="'state'" class="tg-0lax">State</td>
      <td class="tg-0lax">{{transfer.stateId | formValues:{values: 'networkTransferStates'} }}</td>
    </tr>
    <tr>
      <td [translate]="'amount'" class="tg-0lax">Amount</td>
      <td class="tg-0lax">{{transfer.amount.amount / 100 | number:'1.2'}}</td>
    </tr>
    <tr>
      <td [translate]="'applicant'" class="tg-0lax">Applicant</td>
      <td class="tg-0lax">{{transfer.applicant.username}}</td>
    </tr>
    <tr>
      <td [translate]="'holder'" class="tg-0lax">Holder</td>
      <td class="tg-0lax">{{transfer.holder.username}}</td>
    </tr>
    <tr>
      <td [translate]="'beneficiary'" class="tg-0lax">Beneficiary</td>
      <td class="tg-0lax">{{transfer.beneficiary.username}}</td>
    </tr>
  </table>
</div>
</html>
