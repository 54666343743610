import { Component, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { SmarticoService } from '@bs/services';

@Component({
  selector: 'deep-link',
  templateUrl: './deep-links.component.html',
})
export class DeepLinksComponent implements OnInit {

  @Input()
  mode: 'popup' | 'widget' | 'inline';

  @Input({required: true})
  name: 'gf' | 'gf_activity' | 'inbox' | 'gf_missions' | 'gf_matchx' | 'gf_badges' | 'gf_board' | 'gf_tournaments'
    | 'gf_bonuses' | 'gf_levels' | 'gf_saw' | 'gf_section' | 'gf_store' | 'gf_change_nickname' | 'gf_change_avatar';

  @Input()
  params: Params;

  notifications = 0;

  constructor(private smartico: SmarticoService) {

  }

  ngOnInit() {

  }

  action() {
    this.smartico.dp(this.name, this.params);
  }
}
