export class Bonus {
  constructor(public min: number = 0,
              public max: number = 0,
              public percentage: number = 0) {
  }

  reset(): void {
    this.min = 0;
    this.max = 0;
    this.percentage = 0;
  }
}
