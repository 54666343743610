<div class="container-fluid my-3">
  <div class="row">
    <div class="col-12 col-lg-12">
      <div class="content">
        <mat-card class="new-card flat-card me-card">
          <mat-card-title class="box-header">
            <div class="title">
              <span>{{ 'emailConfirmation' | translate }}</span>
            </div>
          </mat-card-title>
          <mat-card-content class="p-3">
            @switch (result?.success) {
              @case (true) {
                <div class="alert alert-success mb-5">
                  <span>{{ 'emailConfirmationSuccess' | translate}}</span>
                </div>
                <div class="text-center">
                  <a [routerLink]="[]" fragment="login" mat-button mat-flat-button color="primary">{{'login' | translate}}</a>
                </div>
              }
              @default {
                <div class="alert alert-danger">
                  <span>{{ result?.message | translate}}</span>
                </div>
              }
            }
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
