import { IAuthor } from '../common/author';

export interface IGameVisibilityBase {
  bookmakerId: number;
  networkId?: number;
  isHidden: boolean;
}

export interface IGameVisibility extends IGameVisibilityBase {
  author: IAuthor;
  creationDate: string;
}
