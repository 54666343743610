import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { IBaseGame, IGame } from '@bs/models';
import { WINDOW, WindowService } from '@bs/universal';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GamesService } from '../../services/games/games.service';

/**
 * Resolver that decides the playing of casino games, if mobile opens the game direct link, otherwise loads in app page
 */
@Injectable({
  providedIn: 'root'
})
export class PlayGameResolver  {
  /**
   * viewport is mobile
   */
  isMobile: boolean;

  /**
   * The constructor
   */
  constructor(private gamesServices: GamesService,
              windowService: WindowService, @Inject(WINDOW) private window: any, private router: Router) {
    windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    });
  }

  /**
   * the resolve function retrieves us the data needed to activate for the requested route
   * @param route
   */
  resolve(route: ActivatedRouteSnapshot): Observable<IBaseGame> | Promise<IBaseGame> | IBaseGame {

    const lang = route.parent.paramMap.get('lang');

    const gameId = +route.paramMap.get('game_id');

    const sectionType = route.paramMap.get('section');

    const playMode = route.paramMap.get('mode') as 'real' | 'fun';

    return this.gamesServices.get(gameId).pipe(switchMap(value => {
      return this.gamesServices.play(gameId, playMode).pipe(map(url => {
        if (this.isMobile) {
          this.router.navigateByUrl(`/${lang}/games/${sectionType}`).then(() => {
            this.window.location.href = url;
          });
          return null;
        } else {
          value.url = url
          return value;
        }
      }))
    }), catchError(e => {
      this.router.navigate([route.paramMap.get('lang'), '404']);
      return throwError(e)
    }))
  }

}
