import { INetworkActivity } from '../accounts/activity';
import { IAccount } from '../me/me';
import { IOutputLog } from '../promo/output';
import { IWalletMovement } from './movement';

/**
 * @deprecated: will be replace with OutPut
 */
export interface IWalletBonus {
  id: number;
  modelId: number;
  statusId: number;
  account: IAccount;
  movements?: Array<IWalletMovement>;
  logs?: Array<IOutputLog>;
  networkLogs?: Array<INetworkActivity>;
  tpl?: string;
}

/**
 * @deprecated: will be replace with OutPut
 */
export interface IWalletBonusSearchRequest {
  outputModelId?: number;
  outputStatusId?: number;
  displayCurrencyId: number;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}

/**
 * @deprecated: will be replace with OutPut
 */
export interface IWalletBonusCreateRequest {
  accountId: number;
  templateId: number;
  amount: number;
  note?: string;
  startDate?: string;
  endDate?: string;
}

/**
 * @deprecated: will be replace with OutPut
 */
export interface IWalletBonusTemplatesRequest {
  bookmakerId: number;
  currencyId: number;
  outputModelId: number;
}

/**
 * @deprecated: will be replace with OutPut
 */
export interface IWalletBonusChangeStatusRequest {
  statusId: number;
  note?: string;
}
