<div class="container mt-5">
  @if (!registrationSuccess) {
    <registration-geo></registration-geo>
  } @else {
    <div class="max-vh">
      <div class="register-success d-flex flex-column align-items-center justify-content-center h-100  ">
        <img src="assets/images/success.png" class="img-fluid" alt="registration successful">
        <h2 class="title mb-2">{{ 'registration-message-successful-title' | translate }}</h2>
        <div class="description">{{ 'registration-message-successful-description' | translate }}</div>
        <a class="mt-4" routerLink="../games/casino" mat-flat-button color="primary">{{ 'back-to-casino' | translate }}</a>
      </div>
    </div>
  }
</div>
