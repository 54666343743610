import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/**
 * this directive displays title attribute with shorten text
 */
@Directive({
  selector: '[ellipsis]'
})
export class EllipsisDirective implements AfterViewInit {
  /**
   * some string to be ellipsis
   */
  @Input() ellipsis: string;

  /**
   * The "constructor"
   */
  constructor(private elementRef: ElementRef) {
  }

  /**
   * calculating text width content and displaying title attribute with shorten text in one line
   */
  ngAfterViewInit() {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;

      if (element.offsetWidth < element.scrollWidth) {
        element.setAttribute('title', this.ellipsis);
      }
    }, 200);
  }

}

/**
 * Example usage:
 *
 * @example
 * <span elipsis="Lorem Ipsum is simply dummy text of the printing and typesetting industry">some text</span>
 */
