<div class="dialog">
  <mat-card class="new-card flat-card">

    <mat-card-title class="box-header">
      <span [translate]="'pix-withdrawal-request'"></span>
      <button (click)="dialogRef.close()" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>

    <div class="dialog-wrapper">
      <div class="scrollbar">
        <div class="dialog-body">
          @if (!loading) {
            @if (response) {
              <div>
                <div class="row gx-0">
                  <div class="col-12">
                    <div class="alert alert-success">
                      <span class="d-block m-b-15">
                        <strong [translate]="'withdrawal-confirmed'" class="ms-1">Your withdrawal request is confirmed.</strong>
                      </span>
                      <span [translate]="'movementId'">Movement id</span>:<strong>{{ response.movementId }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            } @else {
              <form (ngSubmit)="submit()" [formGroup]="form">
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label [translate]="'pix-payment-method-title'"></mat-label>
                    <mat-select formControlName="selectedPixKey">
                      @for (pixKey of pixKeys; track pixKey) {
                        <mat-option [value]="pixKey">{{ pixKey.key }} [{{ pixKey.value }}]</mat-option>
                      }
                    </mat-select>
                    <mat-error [control]="form.get('pixKey')"></mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>
                      <span [translate]="'amount'">Amount</span>
                    </mat-label>
                    <input formControlName="amount" matInput type="number">
                    <mat-error [control]="form.get('amount')"></mat-error>
                  </mat-form-field>
                </div>
                <div class="row p-t-15">
                  <div class="col-12 text-right">
                    <button [disabled]="!form.valid" color="primary" mat-flat-button type="submit">{{ 'confirm' | translate }}</button>
                  </div>
                </div>
              </form>
            }
            @if (error) {
              <div>
                <div class="row gx-0">
                  <div class="col-12">
                    <div class="alert alert-danger">
                      <span class="d-block">
                        <strong [translate]="error.message" class="ms-1"></strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            }
          } @else {
            <div class="d-flex col-12 align-items-center justify-content-center">
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
          }
        </div>
      </div>
    </div>
  </mat-card>
</div>
