import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRouteSnapshot } from '@angular/router';
import { EnvironmentConfig } from '@bs/models';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GamesService } from '../../services/games/games.service';

/**
 * Resolver is returning us all the casino games for a current route
 */
@Injectable({
  providedIn: 'root'
})
export class GamesTitleResolver  {
  /**
   * The constructor
   */
  constructor(private gamesService: GamesService, private translateService: TranslateService, private readonly meta: Meta, private config: EnvironmentConfig) {
  }

  /**
   * the resolve function retrieves us the data needed to activate for the requested route
   */
  resolve(route: ActivatedRouteSnapshot): string | Observable<string> {

    let _section = route.data.section

    if (route.data.subsection === 'categories') {
      _section = route.parent.data.section;
    }

    const section = this.translateService.instant(_section);

    const cat = +route.queryParamMap.get('category') || +route.paramMap.get('id');

    if (cat) {
      return this.gamesService.listByType(cat, this.config.bookmakerId).pipe(switchMap(map => {
        // TODO understand the format es: seo-games-categories-scratchCards-description
        // this.meta.updateTag({name: 'description', description: ''});
        // this.meta.updateTag({name: 'og:description', description: ''});

        return this.translateService.get('seo-games-categories-page', {section, value: map.name});
      }));
    } else {

      return this.translateService.get(`seo-${_section}`); // OR  translate seo-games-section
    }


  }

}
