import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IdName } from '@bs/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'brand-header-selector',
  templateUrl: './brand-header-selector.component.html',
  styleUrls: ['./brand-header-selector.component.scss']
})
export class BrandHeaderSelectorComponent implements OnDestroy {

  @Input()
  brands: Array<IdName<string>>;

  selectedBand: number;

  subs = new Subscription();

  constructor(private route: ActivatedRoute) {

    this.subs.add(this.route.queryParamMap.subscribe({
      next: paramMap => {
        this.selectedBand = paramMap.has('brand') ? +paramMap.get('brand') : null;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
