import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { EnvironmentConfig, IMenuItem, Webapps } from '@bs/models';
import { CmsService } from '@bs/services';
import { WindowService } from '@bs/universal';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: '[menu]',
  templateUrl: './menu.component.html',
  styleUrls: ['menu.component.scss']
})
export class MenuComponent {

  @Input()
  menu: any;

  @Input()
  vertical: boolean;

  lang: string;

  headerLayout = this.config.features.headerLayout || 'header1';
  isDesktop = false;

  constructor(translate: TranslateService, private cmsService: CmsService, private router: Router, private config: EnvironmentConfig,
              windowService: WindowService) {
    this.lang = translate.currentLang;
    translate.onLangChange.subscribe({
      next: data => this.lang = data.lang
    });
    windowService.device$.subscribe({
      next: device => this.isDesktop = device.isDesktop
    });
    // console.log('menu', this.menu);
  }

  public isLinkActive(menuItem: IMenuItem): boolean {
    if (menuItem.routerLink) {
      return this.router.url.includes(menuItem.routerLink);
    }
    return false;
    /*else {
      if (menuItem.items && menuItem.items.length) { // if there are subs dig-in recursively
        const ml = menuItem.items as Array<IMenuItem>;

        return ml.some(mi => this.isLinkActive(mi));
      } else {
        return false;
      }
    }*/
  }

  getIconClass(icon: string): string {
    if(this.headerLayout === 'header3' && this.isDesktop || this.config.webAppId === Webapps.maggie) {
      return `icomoon-${icon}`
    }
    return '';
  }
}
