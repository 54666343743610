export interface TableInterface {
  $schema?: string;
  cols: Array<Col>
}

export type Comparison = '=' | '||=' | '!=' | '<' | '>' | '<=' | '>=';


export interface TableAction<T = any> {
  permission?: number;
  visible?: string[];
  condition?: Array<string | boolean | number>;
  comparison?: Comparison | string;
  icon: string;
  click: ((event: T) => void) |  string;
  name?: string;
  label?: string;
}

export interface Col<T = any> {
  field: keyof T |  string;
  header: string;
  type: 'date' | 'input' | 'account' | 'accountParent' | 'accountPerson' | 'amount' | 'status' | 'button' | 'text' | 'nested' | string;
  width?: number;
  rowspan?: number;
  buttons?: Array<TableAction<T>>
  hideMobile?: boolean;
  values?: any
}

export interface Sorting {
  field: string,
  order: 1 | -1 | 0
}
