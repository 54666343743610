<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span>{{ 'claim-bonus' | translate }}</span>
      <button (click)="close()" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>
    <div class="dialog-wrapper align-items-center flex-column row m-5">
      <strong>{{ 'claim-confirm' | translate }}</strong>
      <div class="mt-5">
        <a (click)="close('claim')" class="claim-btn mat-flat-button accept-btn mat-button-base m-1" color="primary" mat-stroked-button>{{ 'yes-sure' | translate }}</a>
        <a (click)="close()" class="stroke-primary mat-button-base m-1" mat-stroked-button>{{ 'maybe-later' | translate }}</a>
      </div>
    </div>

  </mat-card>
</div>
