// @ts-nocheck
import { EnvironmentConfig, Webapps } from '@bs/models';
import packageInfo from 'package.json';

export const environment: EnvironmentConfig = {
  api: {
    endpoint: 'https://api-frontend.betstarters.cloud',
    boe: 'https://api-boe.betstarters.cloud',
    accounts: 'https://api-accounts.betstarters.cloud',
    catalogs: 'https://api-catalogs.betstarters.cloud',
    cms: 'https://api-cms.betstarters.cloud/cms',
    games: 'https://api-games.betstarters.cloud',
    identity: 'https://api-identity.betstarters.cloud',
    promo: 'https://api-promo.betstarters.cloud',
    reports: 'https://api-reports.betstarters.cloud',
    //sportsbook: 'https://api-sportsbook.betstarters.cloud',
    //ws: 'wss://wss.betstarters.cloud:8009/connection/websocket'
  },
  appVersion: packageInfo.version,
  bookmakerId: 91,
  defaultLangCode: 'en',
  externalScripts: [
    {
      inRoot: true,
      link: 'https://www.googletagmanager.com/gtag/js?id=G-PVGRHNRCPR',
      name: 'gtag-integration',
      options: {
        callback: () => {
          window['dataLayer'] = window['dataLayer'] || [];
          window['gtag'] = function () {
            window['dataLayer'].push(arguments);
          }
          window['gtag']('js', new Date());
          window['gtag']('config', 'G-PVGRHNRCPR');
        }
      },
      type: 'text/javascript',
    },
    {
      inRoot: true,
      link: 'https://www.googletagmanager.com/gtm.js?id=GTM-N75SHCLN',
      name: 'gtm-integration',
      options: {
        callback: () => {
          window.dataLayer = window.dataLayer || [];
        }
      },
      type: 'text/javascript'
    },
    {
      //link: 'https://sb2wsdk-altenar2-stage.biahosted.com/altenarWSDK.js',
      link: 'https://sb2wsdk-altenar2.biahosted.com/altenarWSDK.js',
      name: 'altenar',
      type: 'text/javascript'
    }
  ],
  trackers: [],
  features: {
    allowThemeSwitch: true,
    betSlip: {
      autoPrint: true,
      fastBet: true,
      hideCountDown: false,
      selectionsByDate: true,
      bookBet: false,
      showBarCode: true
    },
    bottomSheet: false,
    creationModes: {
      player: 'phonePrivateNoOTP',
      agent: 'agentFast',
      shop: 'shopFast'
    },
    games: {},
    casinoMode: 'lite',
    liveStreaming: true,
    passwordRecovery: {
      email: false,
      phone: true
    },
    hideLiveWidget: true,
    registrationModes: 'phoneFastNoOTP',
    responsibleGaming: {
      selfExclusion: false,
      selfLimitation: false,
      realityChecker: false
    },
    showCouponCheck: true,
    showMarketSelector: true,
    showTopWinnings: true,
    userSportHomeTab: 1,
    validators: {
      phone: '^(7)\\d{7}$',
    },
    taxType: {
      id: 1,
      stakeTax: {
        percentage: 10
      }
    }
  },
  layout: {
    header: 'center',
    footer: 'center',
  },
  langs: [{
    code: 'en',
    name: 'English',
    code4: 'en-GB',
  }],
  live: {},
  liveChats: {
    tawkUrl: ''
  },
  paystackKey: '',
  production: false,
  project: '8bet.co.sz',
  depositPage: 'me/balances/deposit',
  homePage: 'sports',
  theme: {
    defaultDark: false
  },
  webAppId: Webapps.bart,
  timeZone: 'Africa/Johannesburg',
  keys: {
    altenar: '8bet',
  },
};
