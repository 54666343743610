import { ISearchBaseSportsBookFilters } from './common';

export interface IFeedProvider {
  id: number;
  name: string;
}

export interface IFeedTournamentSearchFilters extends ISearchBaseSportsBookFilters {
  tournamentId?: number;
}
