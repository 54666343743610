import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, makeStateKey, TransferState } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class BrowserStateInterceptor implements HttpInterceptor {

  constructor(private transferState: TransferState) {
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (req.method !== 'GET') {
      return next.handle(req);
    }

    const storedResponse: T | null = this.transferState.get(makeStateKey(req.url), null);

    if (storedResponse) {
      const response = new HttpResponse<T>({ body: storedResponse, status: 200 });
      return of(response);
    }

    return next.handle(req);
  }
}
