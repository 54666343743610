import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsCommonModule } from '@bs/common';
import { BsFormsModule } from '@bs/forms';
import { EnvironmentConfig } from '@bs/models';

/* SERVICES */
/* RESOLVERS */
/* GUARDS */
/* SHARED */
import { HomerCommonModule } from '@homer/common';
import { HomerFormsModule } from '@homer/forms';
/* VENDOR */
import { MaterialComponentsModule } from '@homer/mat-components';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { BonusesComponent } from './components/bonuses/bonuses.component';
import { CashierDepositComponent } from './components/cashier-deposit/cashier-deposit.component';
import { CashierWithdrawalComponent } from './components/cashier-withdrawal/cashier-withdrawal.component';

import { DocumentsManagerComponent } from './components/documents-manager/documents-manager.component';
import { PaymentsMethodsDepositComponent } from './components/payments-methods-deposit/payments-methods-deposit.component';
import { PaymentsMethodsWithdrawalComponent } from './components/payments-methods-withdrawal/payments-methods-withdrawal.component';
import { RealityCheckerComponent } from './components/reality-checker/reality-checker.component';
import { SelfExclusionComponent } from './components/self-exclusion/self-exclusion.component';
/* DIRECTIVES */
/* COMPONENTS */
import { SelfLimitationsComponent } from './components/self-limitations/self-limitations.component';
/* DIALOGS */
import { AutoExclusionDialog } from './dialogs/auto-exclusion/auto-exclusion.dialog';
import { BankTransferRequestDialog } from './dialogs/bank-transfer-request/bank-transfer-request.dialog';
import { ClaimPromotionDialog } from './dialogs/claim-promotion/claim-promotion.dialog';
import { CryptoWithdrawalRequestDialog } from './dialogs/crypto-withdrawal-request/crypto-withdrawal-request.dialog';
import { DepositCheckOut } from './dialogs/deposit-check-out/deposit-check-out-dialog.component';
import { DepositOtpCheckDialog } from './dialogs/deposit-otp-check/deposit-otp-check.component';
import { DepositQrCode } from './dialogs/deposit-qrcode/deposit-qrcode.dialog';
import { DepositRequestDialog } from './dialogs/deposit-request/deposit-request.dialog';
import { ManualDrawerMovementDialog } from './dialogs/manual-drawer-movement/manual-drawer-movement.dialog';
import { ManualWithdrawalDialog } from './dialogs/manual-withdrawal/manual-withdrawal.dialog';
import { PixWithdrawalRequestDialog } from './dialogs/pix-withdrawal-request/pix-withdrawal-request.dialog';
import { RealityCheckDialog } from './dialogs/reality-check/reality-check.dialog';
import { ShopWithdrawalRequestDialog } from './dialogs/shop-withdrawal-request/shop-withdrawal-request.dialog';
import { TextDialog } from './dialogs/text/text.dialog';
import { TfaSetupDialog } from './dialogs/tfa/tfa-setup.dialog';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

const components = [
  TextDialog,
  ClaimPromotionDialog,
  SelfLimitationsComponent,
  SelfExclusionComponent,
  RealityCheckerComponent,
  DocumentsManagerComponent,
  BonusesComponent,
  PaymentsMethodsDepositComponent,
  PaymentsMethodsWithdrawalComponent,
  CashierWithdrawalComponent,
  CashierDepositComponent
]

@NgModule({
  imports: [
    BsCommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    HomerCommonModule,
    TranslateModule,
    RouterModule,
    BsFormsModule,
    QRCodeModule,
    HomerFormsModule,
    MatCardModule,
    MatProgressBarModule,
    MatTabsModule,
    MatTableModule,
    MatButtonModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSelectModule,
    MatInputModule,
  ],
  declarations: [
    /* DIALOGS */
    AutoExclusionDialog,
    DepositOtpCheckDialog,
    DepositCheckOut,
    DepositQrCode,
    ShopWithdrawalRequestDialog,
    BankTransferRequestDialog,
    PixWithdrawalRequestDialog,
    ManualWithdrawalDialog,
    DepositRequestDialog,
    CryptoWithdrawalRequestDialog,
    ManualDrawerMovementDialog,
    RealityCheckDialog,
    TfaSetupDialog,
    ...components
  ],
  exports: components
})
export class HomerMeModule {
  static forRoot(config: EnvironmentConfig): ModuleWithProviders<HomerMeModule> {
    return {
      ngModule: HomerMeModule,
      providers: [
        {
          provide: EnvironmentConfig,
          useValue: config
        }
      ]
    };
  }
}
