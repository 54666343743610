import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMobilePrefix, ValidationService } from '@bs/forms';


interface MyData {
  phone: IMobilePrefix;
  validationType: string;
  transactionId: number;
  deliveryId?: number;
}

@Component({
  selector: 'otp-dialog',
  templateUrl: 'otp.dialog.html'
})
export class OtpDialog {

  @Input()
  withCheck = false;

  form: FormGroup;

  @Input()
  responseError = null;

  confirmed: boolean;

  constructor(fb: FormBuilder, private validationService: ValidationService, public dialogRef: MatDialogRef<OtpDialog>,
              @Inject(MAT_DIALOG_DATA) public data: MyData) {
    this.form = fb.group({
      otp: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)])]
    });
  }

  submit() {
    const values = Object.assign(this.form.value, this.data);

    if (this.withCheck) {
      this.validationService.checkMobileOtp(values).then(
        response => {
          if (response) {
            this.confirmed = response;
            setTimeout(() => this.dialogRef.close(response), 600);
          } else {
            this.responseError = 'wrong-otp';
          }
        },
        err => this.responseError = err.message);
    } else {
      this.dialogRef.close(values);
    }

  }
}
