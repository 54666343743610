<div class="games-grid mb-5">
  @for (game of games | slice: 0 : gamesLimit; track trackByFn($index, game)) {
    <casino-game-tile [game]="$any(game)"></casino-game-tile>
  }
</div>

@if (games.length >= gamesLimit) {
  <div class="load-more d-flex justify-content-center flex-column align-items-center mb-4">
    <button mat-flat-button color="primary" (click)="loadMoreGames()">{{'load-more' | translate}}</button>
    <div class="d-flex mt-3">
      <span>{{gamesLimit}}</span>
      <span class="mx-1">{{'of' | translate}}</span>
      <span>{{games.length}}</span>
    </div>
  </div>
}
