<ng-template #emptyTpl let-tab>
  <div class="alert alert-warning m-5" role="alert">
    <strong>{{ 'empty' | translate }}!</strong> {{ 'pleaseAdd' | translate }} {{ tab.value }} {{ 'contact' | translate }}
  </div>
</ng-template>

<ng-template #deliveriesTpl let-tab>
  @for (delivery of deliveries.controls; track delivery; let i = $index) {
    <ng-container [formGroup]="delivery">
      @if (delivery.get('typeId').value === tab.key) {
        <div class="row m-0 py2">
          @if (adding === i) {
            <div class="alert alert-warning" role="alert">
              <strong>{{ 'warning' | translate }}!</strong> {{ 'youMustConfirmYour' | translate }} {{ tab.value }} {{ 'beforeProceed' | translate }}
            </div>
          }
        </div>
        <div class="row m-0 py-3">
          <div class="col-md-5 col-12 p-1">
            @switch (tab.key) {
              @case (types.Mobile) {
                <mobile-prefix class="mat-form-field mat-primary  mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-should-float mat-form-field-has-label" [readonly]="!adding" formControlName="delivery">
                  <mat-error [control]="deliveries.at(i).get('delivery')"></mat-error>
                </mobile-prefix>
              }
              @default {
                <mat-form-field>
                  <mat-label [translate]="'contact'">Contact</mat-label>
                  <input [readonly]="delivery.get('isConfirmed').value || delivery.get('isPrimary').value" formControlName="delivery" matInput/>
                  <mat-error [control]="deliveries.at(i).get('delivery')"></mat-error>
                </mat-form-field>
              }
            }
          </div>
          <div class="col-md-5 col-12 p-1">
            <div class="row gx-0 p-1">
              @if (adding !== i && !isActivelyAdding) {
                <div class="col-6 email-col">
                  <div class="mat-form-field">
                    <mat-checkbox (ngModelChange)="markAsPrimary(delivery)" formControlName="isPrimary">{{ 'primary' | translate }}</mat-checkbox>
                  </div>
                  <div class="mat-form-field">
                    <mat-checkbox formControlName="isConfirmed">{{ 'confirmed' | translate }}</mat-checkbox>
                  </div>
                </div>
              }
              @if (tab.key === types.Mobile && !delivery.get('isConfirmed').value) {
                <div class="col-6">
                  <mat-label class="d-block pb-2">{{ 'validateBy' | translate }}</mat-label>
                  <mat-radio-group [(ngModel)]="validationType" [disabled]="isDisabled" [ngModelOptions]="{standalone:true}">
                    <mat-radio-button class="me-2" value="sms">{{ 'sms' | translate }}</mat-radio-button>
                    <mat-radio-button value="ivr">{{ 'call' | translate }}</mat-radio-button>
                  </mat-radio-group>
                </div>
              }
            </div>
          </div>
          <div class="col-md-2 col-12 p-1">
            @if (!delivery.get('isPrimary').value) {
              <button (click)="removeDelivery(delivery, i)" [disabled]="adding && adding!==i || isDisabled" class="remove-btn mb-1 me-1" color="warn" mat-raised-button type="button">
                <div class="d-flex align-items-center">
                  <svg icon="delete-circle" lib="global" class="pe-1" [size]="{w:18, h:18}"></svg>
                  <span>{{ 'remove' | translate }}</span>
                </div>
              </button>
            }
            @if (!delivery.get('isConfirmed').value && tab.key !== types.Mobile && !isActivelyAdding) {
              <button (click)="resend(delivery)" class="mb-1 send-activation-btn me-1" mat-raised-button type="button">
                <div class="d-flex align-items-center">
                  <svg icon="email-sync" lib="global" class="pe-1" [size]="{w:18, h:18}"></svg>
                  <span>{{ 'sendActivation' | translate }}</span>
                </div>
              </button>
            }
            @if (!delivery.get('isConfirmed').value) {
              @switch (tab.key) {
                @case (types.Email) {
                  @if (!delivery.get('isPrimary').value) {
                    <button class="save-btn mb-1" (click)="saveDelivery(delivery.value)" [disabled]="!delivery.valid || (adding && adding!==i) || isDisabled" color="basic" mat-raised-button type="button">
                      <div class="d-flex align-items-center">
                        <svg icon="email-sync" lib="global" class="pe-1" [size]="{w:18, h:18}"></svg>
                        <span>{{ 'save' | translate }}</span>
                      </div>
                    </button>
                  }
                }
                @case (types.Mobile) {
                  <button class="send-btn mb-1" (click)="requestMobileOtp(delivery, validationType)" [disabled]="!delivery.valid || (adding && adding!==i) || isDisabled" color="basic" mat-raised-button type="button">
                    <div class="d-flex align-items-center">
                      <svg icon="email-sync" lib="global" class="pe-1" [size]="{w:18, h:18}"></svg>
                      <span>{{ 'send' | translate }}</span>
                    </div>
                  </button>
                }
              }
            }
          </div>
          <hr>
        </div>
      }
    </ng-container>
  }
</ng-template>

<mat-tab-group animationDuration="0" mat-stretch-tabs="false">
  @for (tab of typeKeys; track tab) {
    <mat-tab [disabled]="!!adding" [label]="tab.value">
      <ng-container [ngTemplateOutletContext]="{$implicit:tab}" [ngTemplateOutlet]="deliveries?.controls.length ? deliveriesTpl: emptyTpl"></ng-container>
      <div class="row m-2">
        @if (hasNoEmail(tab, deliveries)) {
          <button (click)="addDelivery(tab.key)" [disabled]="!!adding || isDisabled" color="accent" mat-raised-button>
            <div class="d-flex align-items-center justify-content-center">
              <svg icon="plus-circle" lib="global" class="pe-1" [size]="{w:18, h:18}"></svg>
              <span>{{ 'add' | translate }}</span>
            </div>
          </button>
        }
      </div>
    </mat-tab>
  }
</mat-tab-group>


