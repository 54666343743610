import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ISport } from '@bs/models';

/**
 * Resolver is returning us the categories of sport
 *
 * see {@link ISport} for more details of the model
 */
export const SportCategoriesResolver: ResolveFn<ISport> = (route: ActivatedRouteSnapshot) => {

  /**
   * the resolve function retrieves us the data needed to activate for the requested route
   * @param route
   */
  return route.parent.data.betOffer.sports.find(s => s.slug === route.paramMap.get('sport_name'));
}
