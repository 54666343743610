export interface IMenuLink {
  id?: number;
  icon: string;
  isExternal: boolean;
  isRoot: boolean;
  isSystem: boolean;
  languages: IMenuLanguage[];
  name: string;
  orderId?: number;
  webAppId?: number;
  settingTypeId?: number;
  bookmakerId?: number;
}

export interface IMenuLanguage {
  id: number;
  code: string;
  name: string;
  text: string;
  //description: string;
  url?: string;
}

export interface ICmsMenu {
  id: number;
  isLogged: boolean;
  name?: string;
  isSystem?: boolean;
  webAppId?: number;
  bookmakerId?: number;
  languages?: Array<IMenuLanguage>;
  links?: Array<IMenuLink>;
}


export interface IMenuSearchFilters {
  bookmakerId?: number;
  webAppId: number;
  p: number;
  pp: number;
}

export interface IMenuLinkSearchResponse {
  records: IMenuLink[];
  total: number;
}


// export interface IMenuGetResponseItem {
//   isLogged: boolean;
//   isSystem: boolean;
//   bookmakerId: number;
//   languages: IMenuLanguage[];
//   links: IMenuLink[];
//   menuId: number;
// }
