<div class="dialog">
  <mat-card class="new-card flat-card">

    <mat-card-title class="box-header">
      <span>{{ 'bank-transfer-request' | translate }}</span>
      <button (click)="dialogRef.close()" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>

    <div class="dialog-wrapper">
      <div class="scrollbar">

        <div class="dialog-body">
          @if (!loading) {
            @if (response) {
              <div>
                <div class="row gx-0">
                  <div class="col-12">
                    <div class="bank-transfer-success">
                      <span class="confirm-wrapper m-b-15">
                        <img src="assets/images/success-checkmark.svg" alt="success withdrawal">
                        <strong class="ms-1">{{ 'withdrawal-confirmed' | translate }}</strong>
                      </span>
                      <span>{{ 'movementId' | translate }}</span>:<strong>{{ response.movementId }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            } @else {
              <form (ngSubmit)="submit()" [formGroup]="form">
                <div class="row">
                  <div class="col-12">
                    <div class="alert alert-info m-b-30">
                      <span class="d-block">{{ 'your-withdrawable-balance' | translate }}</span>
                      <b>{{ data.me?.wallets[0].balance.withdrawable | moneyFormat:data.me?.currencies[0]:true }}</b>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>
                      <span>{{ 'first-name' | translate }}</span>
                    </mat-label>
                    <input formControlName="firstName" matInput>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>
                      <span>{{ 'last-name' | translate }}</span>
                    </mat-label>
                    <input formControlName="lastName" matInput>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>
                      <span>{{ 'address' | translate }}</span>
                    </mat-label>
                    <input formControlName="address" matInput>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>
                      <span>{{ 'city' | translate }}</span>
                    </mat-label>
                    <input formControlName="city" matInput>
                  </mat-form-field>
                </div>
                <div class="col-12 p-t-10 p-b-10"></div>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>
                      <span>{{ 'account-number' | translate }}</span>
                    </mat-label>
                    <input formControlName="accountNumber" matInput>
                    <mat-error [control]="form.get('accountNumber')"></mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>
                      <span>{{ 'bank-name' | translate }}</span>
                    </mat-label>
                    <input formControlName="bank" matInput>
                    <mat-error [control]="form.get('bank')"></mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>
                      <span>{{ 'amount' | translate }}</span>
                    </mat-label>
                    <input formControlName="amount" matInput type="number">
                    <mat-error [control]="form.get('amount')"></mat-error>
                  </mat-form-field>
                </div>
                <div class="row p-t-15">
                  <div class="col-12 text-right">
                    <button [disabled]="!form.valid" color="primary" mat-flat-button type="submit">{{ 'confirm' | translate }}</button>
                  </div>
                </div>
              </form>
            }
            @if (error) {
              <div>
                <div class="row gx-0">
                  <div class="col-12">
                    <div class="alert alert-danger">
                      <span class="d-block">
                        <strong [translate]="error.message" class="ms-1"></strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            }
          } @else {
            <div class="d-flex col-12 align-items-center justify-content-center">
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
          }
        </div>
      </div>
    </div>
  </mat-card>
</div>
