<div class="default-stake-input d-flex">
  <button (click)="setAmount(-100)" [disabled]="Number(value) < 1" class="button-container" mat-mini-fab></button>
  <mat-form-field class="w-100" appearance="outline">
    <input [readonly]="readonly" type="text" matInput [(ngModel)]="value" (blur)="onBlur($event)" (ngModelChange)="onInput()" [placeholder]="placeholder"/>
  </mat-form-field>
  <button (click)="setAmount(+100)" class="button-container right-btn" mat-mini-fab></button>
</div>

@if (!hideStakeEdition) {
  <div class="mx-2 mt-3 d-flex flex-wrap stake-buttons mb-4">
    @for (btnStake of stakeButtons; track btnStake) {
      <button class="flex-grow-1" (click)="setStakeValue(btnStake)" mat-flat-button
              [class.active]="value === (btnStake / 100).toFixed(2) ? 'primary' : ''">{{ btnStake | displayCurrencyConversion }}
      </button>
    }
  </div>
}
