import { Pipe, PipeTransform } from '@angular/core';
import { gameTypeEnum } from '@bs/models';

/**
 * this pipe checks the controls if contains the tab value
 */
@Pipe({name: 'gameTypeSection'})
export class GameTypeSectionPipe implements PipeTransform {

  transform(gameTypeId: number): string {
    switch (gameTypeId) {
      case gameTypeEnum.Casino:
        return 'casino';
      case gameTypeEnum.LiveCasino:
        return 'casino-live';
      case gameTypeEnum.Virtual:
        return 'virtuals';
      case gameTypeEnum.Lottery:
        return 'lotteries';
      case gameTypeEnum.ScratchCard:
        return 'scratch-cards';
      case gameTypeEnum.CrashGames:
        return 'crash-games';
      default:
        return 'casino'
    }
  }
}
