import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentConfig, GeoInfoType, IMe, IPaymentMethod, IWithdrawalRequest } from '@bs/models';
import { AuthService, IdentityWalletService } from '@bs/services';
import { WithdrawalBaseDialog } from '../withdrawal-base.dialog';

interface MyData {
  me: IMe;
  amount: number;
  isMobile: boolean;
  provider: IPaymentMethod;
}

@Component({
  selector: 'bank-transfer-request-dialog',
  templateUrl: 'bank-transfer-request.dialog.html'
})
export class BankTransferRequestDialog extends WithdrawalBaseDialog {
  form: FormGroup;

  constructor(private config: EnvironmentConfig, public identityWalletService: IdentityWalletService, public authService: AuthService,
              public dialogRef: MatDialogRef<BankTransferRequestDialog>,
              fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: MyData) {
    super(identityWalletService, authService);

    const currGeo = data.me.geoInfos?.find(x => x.typeId === GeoInfoType.Residence);

    this.form = fb.group({
      amount: [{value: data.amount, disabled: true}],
      accountNumber: ['', this.accountNumberValidators()],
      bank: ['', Validators.required],
      firstName: [{value: data.me.person.firstName, disabled: true}],
      lastName: [{value: data.me.person.lastName, disabled: true}],
      address: [{value: currGeo?.address, disabled: false}],
      city: [{value: currGeo?.city.name, disabled: false}]
    });
  }

  accountNumberValidators() {
    if (this.config.bookmakerId === 50) {
      return Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.pattern(/^[0-9]{10}$/)
      ]);
    }

    return Validators.compose([Validators.required, Validators.minLength(10)]);
  }

  submit() {
    const request: IWithdrawalRequest = {
      amount: this.data.amount,
      paymentMethodId: this.data.provider.id,
      additionalData: JSON.stringify({
        accountNumber: this.form.value.accountNumber,
        bank: this.form.value.bank
      })
    };

    this.createMovement(request);
  }

}
