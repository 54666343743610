<!--<pre>{{gameType | json}}</pre>-->
<!--<pre>{{featured|json}}</pre>-->

<div class="container-fluid mt-lg-2">
  <banner-slider [slider]="sliderHero"></banner-slider>
  <nav class="casino-navbar mt-lg-2">
    <ul class="navbar">
      <li *ngIf="casinoRouteName==='casino'" class="nav-item">
        <a routerLink="/{{settings.languageCode}}/games" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <svg icon='home' lib="global" class="p-1" [size]="{w:30, h:30}"></svg>
          <span class="ellipsis" translate>home</span></a>
      </li>
      <li *ngIf="casinoRouteName==='casino'" class="nav-item">
        <a routerLink="/{{settings.languageCode}}/games/{{casinoRouteName}}/brands" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <svg icon='blur' lib="global" class="p-1" [size]="{w:30, h:30}"></svg>
          <span class="ellipsis" translate>brands</span></a>
      </li>
      <!--      <li *ngIf="me() && casinoRouteName==='casino'" class="nav-item">-->
      <!--        <a routerLink="/{{settings.languageCode}}/games/{{casinoRouteName}}/tournaments" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">-->
      <!--          <svg icon='trophy' lib="global" class="p-1" [size]="{w:30, h:30}"></svg>-->
      <!--          <span class="ellipsis" translate>tournaments</span></a>-->
      <!--      </li>-->
      <!--      <li *ngIf="casinoRouteName==='casino'" class="nav-item">-->
      <!--        <a routerLink="/{{settings.languageCode}}/games/{{casinoRouteName}}/tournaments" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">-->
      <!--          <span class="ellipsis" translate>tournaments</span></a>-->
      <!--      </li>-->
      <li class="nav-item">
        <a routerLink="/{{settings.languageCode}}/games/{{casinoRouteName}}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <svg icon='apps' lib="global" class="p-1" [size]="{w:30, h:30}"></svg>
          <span class="ellipsis" translate>all-games</span></a>
      </li>
      <li class="nav-item" *ngFor="let category of navMenu?.items">
        <a routerLink="/{{settings.languageCode}}/games/{{casinoRouteName}}" [queryParams]="{category: (category.routerLink)}" queryParamsHandling="merge" routerLinkActive="active" class="nav-link">
          <svg [icon]='category.icon | sluggify | lowercase' lib="games-categories" class="p-1" [size]="{w:30, h:30}"></svg>
          <span class="ellipsis">{{ category.label }}</span>
        </a>
      </li>
    </ul>
  </nav>

  <section class="m-2">
    <ng-container *ngIf="featured?.length">
      <app-swiper class="no-btn"
                  [config]="{
          grid: {rows:1},
          slidesPerView: (device$ | async).isDesktop ? 6 : (device$ | async).isTablet ? 5 : 3,
          spaceBetween: 10,
          autoplay: true,
          navigation: true,
        }" [sliderItems]="featured">
        <ng-template #headerTpl>
          <div *ngIf="featured.length" class="d-flex p-2">
            <h2 class="title">{{ 'featured' | translate }}</h2>
          </div>
        </ng-template>
        <ng-template #slidesTpl let-game>
          <casino-game-tile [game]="game"></casino-game-tile>
        </ng-template>
      </app-swiper>
    </ng-container>
  </section>

  <section class="m-2">
    <ng-container *ngIf="new?.length">
      <app-swiper class="no-btn"
                  [config]="{
              grid: {rows:1},
              slidesPerView: (device$ | async).isDesktop ? 6 : (device$ | async).isTablet ? 3 : 2,
              spaceBetween: 10,
              navigation: true
            }" [sliderItems]="new">
        <ng-template #headerTpl>
          <div *ngIf="new.length" class="d-flex p-2">
            <h2 class="title">{{ 'new' | translate }}</h2>
          </div>
        </ng-template>
        <ng-template #slidesTpl let-game>
          <casino-game-tile [game]="game"></casino-game-tile>
        </ng-template>
      </app-swiper>
    </ng-container>
  </section>

  <section class="m-2">
    <ng-container *ngIf="jackpot?.length">
      <app-swiper class="no-btn" [config]="{
                grid: {rows:1},
                slidesPerView: (device$ | async).isDesktop ? 6 : (device$ | async).isTablet ? 3 : 2,
                spaceBetween: 10,
                navigation: true,
              }" [sliderItems]="jackpot">
        <ng-template #headerTpl>
          <div *ngIf="jackpot.length" class="d-flex p-2">
            <h2 class="title">{{ 'jackpot' | translate }}</h2>
          </div>
        </ng-template>
        <ng-template #slidesTpl let-game>
          <casino-game-tile [game]="game"></casino-game-tile>
        </ng-template>
      </app-swiper>
    </ng-container>
  </section>

  <section class="m-2">
    <ng-container *ngIf="misteryItems?.length">
      <app-swiper class="no-btn" [config]="{
                grid: {rows:1},
                slidesPerView: (device$ | async).isDesktop ? 6 : (device$ | async).isTablet ? 3 : 2,
                spaceBetween: 10,
                navigation: true,
              }" [sliderItems]="misteryItems">
        <ng-template #headerTpl>
          <div *ngIf="misteryItems.length" class="d-flex p-2">
            <h2 class="title">{{ 'misteryDrops' | translate }}</h2>
          </div>
        </ng-template>
        <ng-template #slidesTpl let-game>
          <casino-game-tile [game]="game"></casino-game-tile>
        </ng-template>
      </app-swiper>
    </ng-container>
  </section>

  <section class="">
    <app-swiper class="no-btn"
                [config]="{grid: {rows:1}, slidesPerView: (device$ | async).isDesktop ? 6 : 2,
                spaceBetween: 10,
                navigation: true}"
                [sliderItems]="themes | slice: 0 : themes.length">
      <ng-template #headerTpl>
        <div class="d-flex p-2">
          <h2 class="title">{{ 'themes' | translate }}</h2>
        </div>
      </ng-template>
      <ng-template #slidesTpl let-theme>
        <a [routerLink]="['/', lang, 'games', casinoRouteName]" [queryParams]="{ attributes: theme.id }">
          <div class="themes-card d-flex align-items-center justify-content-center">
            <svg [icon]="theme.name | lowercase" lib="games-themes" [size]="{w:60, h:60}"></svg>
          </div>
        </a>
        <span class="themes-card-title d-flex justify-content-center mt-2 mt-lg-4">
          {{ (theme.name | lowercase) }}
        </span>
      </ng-template>
    </app-swiper>
  </section>

  <section class="smartico-promo mt-5 mx-auto mb-4 mb-lg-5">
    <img src="assets/icons/casino-smartico/fortune.webp" class="smartico-promo-img d-none d-xl-block">
    <mat-card class="p-3 p-md-5">
      <mat-card-content class="d-flex flex-column">
        <div class="smartico-header mb-5 d-flex flex-column align-items-start">
          <h2 class="mb-3 w-100">{{ 'casino-home-gamification-title' | translate }}</h2>
          <p class="mb-4 w-100">{{ 'casino-home-gamification-description' | translate }}</p>
          <div class="d-none d-xl-block">
            <button mat-flat-button color="primary" class="mb-5 d-none d-xl-block">
              <span><a [href]="lang+'/loyalty'">{{ 'casino-home-gamification-button' | translate }}</a> </span>
            </button>
          </div>
          <img src="assets/icons/casino-smartico/fortune.webp" class="smartico-promo-img d-xl-none">
        </div>
        <div class="smartico-cards d-flex flex-column flex-lg-row">
          <div class="smartico-card p-3 m-1">
            <div class="d-flex align-items-center">
              <img src="/assets/icons/casino-smartico/crown.svg" class="me-2">
              <h4>{{ 'casino-home-missions-title' | translate }}</h4>
            </div>
            <p>{{ 'casino-home-missions-description' | translate }}</p>
          </div>
          <div class="smartico-card p-3 m-1">
            <div class="d-flex align-items-center">
              <img src="/assets/icons/casino-smartico/wheel.svg" class="me-2">
              <h4>{{ 'casino-home-wheel-title' | translate }}</h4>
            </div>
            <p>{{ 'casino-home-wheel-description' | translate }}</p>
          </div>
          <div class="smartico-card p-3 m-1">
            <div class="d-flex align-items-center">
              <img src="/assets/icons/casino-smartico/cup.svg" class="me-2">
              <h4>{{ 'casino-home-tournaments-title' | translate }}</h4>
            </div>
            <p>{{ 'casino-home-tournaments-description' | translate }}</p>
          </div>
        </div>
        <div class="d-xl-none">
          <button mat-flat-button color="primary" class="mt-4 w-100">
            <span><a [href]="lang+'/loyalty'">{{ 'casino-home-gamification-button' | translate }}</a> </span>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </section>

  <section class="m-2">
    <app-swiper class="no-btn" [config]="{slidesPerView: isMobile ? 1 : 3, spaceBetween: 10, navigation: true}" [sliderItems]="promotions">
      <ng-template #headerTpl>
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="title">{{ 'all-promotions' | translate }}</h3>
        </div>
      </ng-template>
      <ng-template #slidesTpl let-item>
        <mat-card class="promo-card">
          <img mat-card-image [src]="item.images[0].desktop" [alt]="item.name">
          <mat-card-content class="d-flex flex-column">
            <h2 class="mb-0 mt-4 promo-title">{{ item.name }}</h2>
            <p class="my-4 promo-description">{{ item.description }}</p>
          </mat-card-content>
          <div class="d-flex justify-content-end">
            <a class="promo-link " [routerLink]="['../promotions/', item.id]" color="primary" mat-flat-button>{{ 'view-promo' | translate }}</a>
          </div>
        </mat-card>
      </ng-template>
    </app-swiper>
  </section>

  <section class="brands m-2">
    <app-swiper [config]="{grid: {rows: 1, fill:'row'},
      slidesPerView: isMobile ? 2 : 6, spaceBetween: 5, navigation: false}"
                [sliderItems]="gameType.providers | slice: 0 :gameType.providers.length">
      <ng-template #headerTpl>
        <div class="d-flex p-2">
          <h3 class="title ms-auto" style="transform: translatex(50%);">{{ 'brands' | translate }}</h3>
          <a [routerLink]="[casinoRouteName + '/brands']" mat-raised-button class="rounded-btn ms-auto">{{ 'view-all' | translate }}</a>
        </div>
      </ng-template>
      <ng-template #slidesTpl let-item>
        <a [routerLink]="[casinoRouteName]" [queryParams]="{providers: item.id}" queryParamsHandling="merge">
          <mat-card class="brand-card" routerLinkActive="active">
            <span class="brand-name m-3">{{ item.name }}</span>
            <!--                <svg [icon]="item.icon" lib="games-providers-color" class="img-fluid p-3 grayscale"></svg>-->
          </mat-card>
        </a>
      </ng-template>
    </app-swiper>
  </section>

</div>

@if (isMobile) {
  <bottom-bar [menuId]="maggieMenu.Main" [menuLoggedId]="maggieMenu.Main" class="row"></bottom-bar>
}
