import { Inject, Injectable } from '@angular/core';
import { IMe } from '@bs/models';
import { WINDOW } from '@bs/universal';
import { AuthService } from './auth.service';

@Injectable()
export class CrispService {

  constructor(authService: AuthService, @Inject(WINDOW) private window: Window) {
    authService.accountLogged$.subscribe({
      next: (me: IMe) => {
        if (me?.person) {
          this.window['$crisp'].push(['set', 'session:data', [[
            ['Firstname', me.person.firstName],
            ['Lastname', me.person.lastName],
            ['Username', me.username],
            ['UserId', me.person.id],
            ['Email', me.person.lastName],
            ['KYC', me.kyc],
            ['Currency', me.wallets[0].balance.currencySymbol],
            ['Balance', me.wallets[0].balance.available],
            ['Bonus', me.wallets[0].balance.bonus || ''],
            ['Deposit', me.wallets[0].balance.deposit],
            ['Withdrawable', me.wallets[0].balance.withdrawable],
            // TODO: FIX IStatus in Library!!!
            // ['Status',  me.status.status],
          ]]]);
        } else {
          this.window['$crisp'].push(['do', 'session:reset', [false]]);
        }
      }
    });
  }

  show() {
    this.window['$crisp'].push(['do', 'chat:open']);
  }
}
