import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ICmsFaqSection } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CmsFaqSectionsService {

  readonly url = `${this.config.api.endpoint}/cms/faq`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  all(): Observable<ICmsFaqSection[]> {
    return this.http.get<Array<ICmsFaqSection>>(`${this.url}`);
  }
}
