import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IReferralLink, IReferralLinkCreateRequest, IReferralLinkSearchRequest, Pager } from '@bs/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ReferralLinkService {

  readonly url = `${this.config.api.accounts}/referral-links`;

  referralLinks$ = new BehaviorSubject<Pager<IReferralLink>>(null);
  createdLink$ = new BehaviorSubject<IReferralLink>(null);

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  create(req: IReferralLinkCreateRequest): Observable<IReferralLink> {
    return this.http.post<IReferralLink>(`${this.url}`, req).pipe(tap(value => this.createdLink$.next(value)));
  }

  // delete(id: number): Promise<any> {
  //   return this.http.delete<any>(`${this.url}/${id}`).toPromise()
  // }

  patch(id: number, item: Partial<IReferralLink>): Promise<IReferralLink> {
    return this.http.patch<IReferralLink>(`${this.url}/${id}`, item).toPromise();
  }

  search(filter: IReferralLinkSearchRequest): Promise<Pager<IReferralLink>> {
    return this.http.post<Pager<IReferralLink>>(`${this.url}/search`, filter).pipe(tap(value => this.referralLinks$.next(value))).toPromise();
  }

}
