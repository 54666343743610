import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettings, EnvironmentConfig, IBaseGame } from '@bs/models';
import { AppSettingsService, GamesFavouritesService } from '@bs/services';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'casino-game-tile[game]',
  templateUrl: 'casino-game-tile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CasinoGameTileComponent implements OnInit {
  @Input()
  game: IBaseGame;

  @Input()
  isFeaturedGames: boolean;

  baseUrl: string;
  settings: AppSettings;
  subs = new Subscription();
  section: string;

  forFun: boolean;

  constructor(private gamesFavouritesService: GamesFavouritesService, appSettingsService: AppSettingsService,
              config: EnvironmentConfig,
              route: ActivatedRoute) {
    this.subs.add(combineLatest([
      route.data,
      appSettingsService.appSettings$
    ]).subscribe({
      next: ([data, {settings}]) => {
        this.section = data.section;
        this.settings = settings;
      }
    }))
  }

  ngOnInit() {
    this.baseUrl = `/${this.settings.languageCode}/games/${this.section}/play/${this.game.id}/mode`;
  }

  isFavorite(game: IBaseGame) {
    const fav = this.gamesFavouritesService.favourites$.getValue();
    return !!fav.find(g => g.id === game.id);
  }

  toggleFavorite(game: IBaseGame) {
    this.gamesFavouritesService.toggle(game);
  }
}
