import { Component, Inject, OnDestroy, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrinterService } from '@bs/common';
import { DrawerMovementCreateRequest, IDrawerMovement } from '@bs/models';
import { DrawerMovementsService, DrawerService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

class MyData {
  method: string
  balance: number;
}

@Component({
  selector: 'manual-drawer-movement-dialog',
  templateUrl: 'manual-drawer-movement.dialog.html',
})

export class ManualDrawerMovementDialog implements OnDestroy {
  loading: boolean;
  form: FormGroup;
  item: IDrawerMovement;
  subs = new Subscription();

  constructor(public dialogRef: MatDialogRef<ManualDrawerMovementDialog>,
              @Inject(MAT_DIALOG_DATA) public data: MyData,
              private drawerMovementsService: DrawerMovementsService,
              fb: FormBuilder,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private printerService: PrinterService,
              private viewRef: ViewContainerRef,
  ) {
    this.form = fb.group({
      amount: ['',Validators.required],
      note: []
    });

    if (this.data.method === 'withdraw') {
      this.form.get('amount').setValidators([
        Validators.max(this.data.balance / 100)
      ]);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  submit() {
    const request: DrawerMovementCreateRequest = {
      amount: this.form.value.amount * 100,
      note: this.form.value.note,
      causalId: this.setCausalId(this.data.method)
    }
    this.subs.add(this.drawerMovementsService.create(request).subscribe({
      next: item => {
        this.item = item;
      },

      error: error => {
        // snack
        this.snackBar.open(error.message, this.translate.instant('close'),
          {panelClass: 'error', horizontalPosition: 'end', duration: 3500});
      }
    }))
  }

  private setCausalId(method: string): number {
    if (method === 'deposit') {
      return 706;

    } else if (method === 'withdraw') {
      return 707;
    }
  }

  print() {
    this.printerService.printDrawerMovement(this.viewRef, this.item)
  }
}
