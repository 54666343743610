import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentConfig } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dropdown-language',
  templateUrl: 'dropdown-language.component.html'
})
export class DropdownLanguageComponent {
  languages = this.config.langs;
  headerLayout = this.config.features?.headerLayout || 'header1';
  currentLang: { name: string, code: string } = {name: '', code: this.config.defaultLangCode};

  constructor(private config: EnvironmentConfig, private appSettingsService: AppSettingsService, private router: Router, private translate: TranslateService) {

    this.currentLang = this.languages.find(x => x.code === translate.currentLang);
    this.appSettingsService.saveSetting('languageCode', translate.currentLang);
  }

  changeLanguage(lang: string) {
    const asAray = this.router.url.split('/');
    const last = asAray.splice(2, asAray.length - 2).join('/');
    //void this.router.navigateByUrl(`/${lang}/${last}`);
    this.translate.use(lang);
    this.currentLang = this.languages.find(x => x.code === lang)
    this.appSettingsService.saveSetting('languageCode', lang);
    window.location.href = `/${lang}/${last}`;
  }
}
