<swiper-container fmSwiper [config]="config" init="false" [class]="class">
  <div slot="container-start">
    <ng-container [ngTemplateOutlet]="headerTpl"></ng-container>
  </div>
  @for (item of sliderItems; track item.id) {
    <swiper-slide>
      <ng-container [ngTemplateOutlet]="slidesTpl" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
    </swiper-slide>
  }
</swiper-container>
