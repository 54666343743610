import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { ValidationService } from '@bs/forms';
import { Ages, DeliveryType, EnvironmentConfig, IdName, IFormStep, IGeoInfo, IRegistrationFormStructure, RegistrationBaseRequest, registrationMode } from '@bs/models';

import { AccountsService, AuthService, CatalogService, CookieService, GeoIpService, TagEventHandlerService } from '@bs/services';
import { LocalStorage, WindowService } from '@bs/universal';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { agentFast, agentFaster, emailFast, emailFull, emailFullDocuments, emailFullWithPhone, emailFullWithPhoneLisa, euRegistration, phoneBlackBet, phoneFast, phoneFastNoOTP, phoneFull, phoneFullDocuments, phoneFullNoAddress, phonePrivateNoOTP, playerBrazil, playerFast, playerFaster, shopFast, shopFaster } from '../../jsons';

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnDestroy, OnInit {

  @Input()
  mode: registrationMode;
  @Output()
  outcome: EventEmitter<boolean>;
  @Output()
  registerSuccess = new EventEmitter<boolean>(false);
  @Input()
  account: any;
  @Input()
  gridLayout: boolean;

  @ViewChild('stepper')
  stepperRef: MatStepper;

  struct: IRegistrationFormStructure;
  form: FormGroup;
  ages = Ages;
  transactionId: number;
  complete: { message: string, outcome: boolean };
  isLoading: boolean;
  isMobile = false;
  isEuRegistration = false;
  subs = new Subscription();
  private modes: unknown = {agentFast, emailFast, phoneBlackBet, playerFaster, agentFaster, shopFaster, emailFull, emailFullDocuments, emailFullWithPhoneLisa, emailFullWithPhone, euRegistration, phoneFast, phonePrivateNoOTP, phoneFastNoOTP, phoneFull, phoneFullDocuments, phoneFullNoAddress, playerFast, playerBrazil, shopFast};

  constructor(private dialog: MatDialog, private localStorage: LocalStorage, private config: EnvironmentConfig, private fb: FormBuilder, private accountsService: AccountsService, private authService: AuthService, private cookieService: CookieService,
              private validationService: ValidationService, private catalogService: CatalogService, private translate: TranslateService, private route: ActivatedRoute, windowService: WindowService,
              @Optional() private geoIpService: GeoIpService, @Optional() private tagEventHandlerService: TagEventHandlerService) {
    this.outcome = new EventEmitter<boolean>();
    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));
  }

  ngOnInit() {
    console.log(this.mode)
    if (this.geoIpService) {
      this.subs.add(this.geoIpService.get().subscribe({
        next: geoInfo => {
          const exception = this.config.features.geolocation.registrations?.find(e => e.country === geoInfo.country_code);

          if (exception) {
            this.selectForm(exception.mode);
          } else {
            this.selectForm(this.mode);
          }

        }
      }));
    } else {
      this.selectForm(this.mode);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  submit() {
    this.isLoading = true;

    let account = Object.assign(this.form.value.account, this.account);

    const value: Partial<RegistrationBaseRequest> = {
      typeId: this.struct.typeId,
      username: account.username ? account.username : this.form.value.phone.phone,
      autoLogin: this.struct.autoLogin,
      ...account
    };

    if (this.cookieService.check('affiliateId')) {
      value.trackingCode = this.cookieService.get('affiliateId');
    }

    if (this.cookieService.check('referralCode') && !this.struct.steps.find(s => s.inputs.find(i => i.name === 'referralCode'))) {
      value.referralCode = this.cookieService.get('referralCode');
    }

    switch (this.struct.mode) {
      case 'agentFast':
      case 'playerFaster':
      case 'agentFaster':
      case 'shopFaster':
      case 'emailFull':
      case 'emailFast':
      case 'emailFullWithPhone':
      case 'emailFullWithPhoneLisa':
      case 'playerBrazil':
      case 'playerFast':
      case 'shopFast':
        Object.assign(value, {
          deliveries: [
            {
              typeId: DeliveryType.Email,
              delivery: account.email,
              isPrimary: true
            }
          ]
        });

        if (account.phone?.phone) {
          const mobileDeliveries = {
            typeId: DeliveryType.Mobile,
            delivery: `+${account.phone.prefix.prefix}${account.phone.phone}`,
            isPrimary: true
          }
          value.deliveries = [...value.deliveries, mobileDeliveries];
        }
        break;
      case 'phoneFull':
      case 'phoneFullDocuments':
      case 'phoneFast':
      case 'phonePrivateNoOTP':
      case 'phoneFullNoAddress':
      case 'phoneFastNoOTP': {
        Object.assign(value, {
          deliveries: [
            {
              typeId: DeliveryType.Mobile,
              delivery: `+${this.form.value.phone.phone.prefix.prefix}${this.form.value.phone.phone.phone}`,
              isPrimary: true,
              isConfirmed: true,
            }
          ],
          username: `+${this.form.value.phone.phone.prefix.prefix}${this.form.value.phone.phone.phone}`
        });

        if (account.email) {
          value.deliveries.push({
            typeId: DeliveryType.Email,
            delivery: account.email,
            isPrimary: false
          });
        }
        break;
      }
    }

    if (this.form.value.person) {
      const person = this.form.value.person;

      if (person.birthPlace) {
        Object.assign(person.birthPlace, {
          province: this.asIdName(person.birthPlace.province),
          city: this.asIdName(person.birthPlace.city)
        });
      }

      Object.assign(value, {person});
    }

    if (this.form.value.documents) {
      const document = this.form.value.documents;
      //Object.assign(document, {typeId: document.typeId.id}, {...document});
      //console.log(document)
      Object.assign(value, {document: {typeId: document.typeId, documentNumber: document.documentNumber}})
      //console.log(value)
    }

    if (this.form.value.geoInfos) {

      const geoInfos: IGeoInfo[] = this.form.value.geoInfos.country;

      delete geoInfos[0].id;

      Object.assign(geoInfos[0], {
        province: this.asIdName(geoInfos[0].province),
        city: this.asIdName(geoInfos[0].city)
      });

      Object.assign(value, {geoInfos});
    }

    if (this.form.value.account.currencyId) {
      Object.assign(value, {currencyId: this.form.value.account.currencyId});
    }

    if (this.account?.currencyId) {
      Object.assign(value, {currencyId: this.account.currencyId});
    }

    this.accountsService.register(this.removeEmptyStrings(value)).subscribe({
      next: response => {
        if (response.hasEmailRegistration) {
          this.complete = {message: `${this.struct.mode}SuccessWithActivationLink`, outcome: true};
        } else {
          this.complete = {message: `${this.struct.mode}Success`, outcome: true};
        }

        this.tagEventHandlerService?.registrationConfirm(true);

        // void this.adCashService?.trackRegistration(response.id);

        if (window['esk']) { // script eskimi - it tracks when successful signup
          window['esk']('track', 'Conversion');
        }

        this.registerSuccess.emit(true);

        // check user for autologin
        if (response.token) {
          this.authService.loginToken(response.token).then((res) => {
            this.outcome.emit(true)
          });
        }

        if (this.cookieService.check('affiliateId')) {
          this.cookieService.delete('affiliateId');
        }

        if (this.cookieService.check('referralCode')) {
          this.cookieService.delete('referralCode');
        }
      },

      error: error => {
        this.complete = {message: this.translate.instant(error.message), outcome: true}
        this.tagEventHandlerService?.registrationConfirm(false);
      },

      complete: () => this.isLoading = false
    });
  }

  validateAndNext(step: IFormStep) {
    const values = this.form.value[step.name];

    this.isLoading = true;

    switch (step.validationType) {
      case 'requestMobileOtp':
        if (!values.validationType) {
          values.validationType = 'sms';
        }
        this.validationService.requestMobileOtp(values.phone, values.validationType).then(response => {
          this.transactionId = response;
          this.goToNextStep();
        }, () => {
          // throw err
          this.stepperRef.selected.completed = false;
          this.form.setErrors({'otp-request-failed': true});
        }).finally(() => this.isLoading = false);
        break;
      case 'checkMobileOtp':
        this.validationService.checkMobileOtp({
          transactionId: `${this.transactionId}`,
          otp: values.otp,
          phone: this.form.value.phone.phone
        }).then(response => {
          if (response) {
            this.goToNextStep();
          } else {
            this.stepperRef.selected.completed = false;
            this.form.setErrors({'wrong-otp': true});
          }
        }, () => {
          // throw err
          this.stepperRef.selected.completed = false;
          this.form.setErrors({'otp-check-failed': true});
        }).finally(() => this.isLoading = false);
        break;
      default:
        if (this.isEuRegistration) {
          this.isEuRegistration = false;
          this.isLoading = false;
          this.struct.steps.shift();
          return;
        }
        this.goToNextStep();
        this.isLoading = false;

    }
  }

  /**
   * @description it navigates to the next step
   */
  goToNextStep() {
    this.stepperRef.selected.completed = true;
    this.stepperRef.next();
  }

  dateChange(event: MatDatepickerInputEvent<Date, Date | null>, name: string) {
    const month = this.pad(event.value.getMonth() + 1);
    const day = this.pad(event.value.getDate());

    this.form.get(name).setValue(`${event.value.getFullYear()}-${month}-${day}`);
  }

  private selectForm(mode: registrationMode) {
    this.mode = mode || this.config.features.registrationModes; // default to fast
    this.isEuRegistration = this.mode === 'euRegistration';
    this.struct = this.modes[this.mode];
    this.form = this.buildForm(this.struct.steps);

    this.subs.add(this.form.valueChanges.subscribe({
      next: () => {
        this.stepperRef?.steps?.forEach((s, index) => {
          if (this.stepperRef.selectedIndex <= index) {
            // Next steps are no longer available by clicking step header but they need to be validated (NEXT button).
            s.completed = false;
          }
        });
      }
    }));
  }

  private pad(n) {
    return n < 10 ? '0' + n : n;
  }

  private asIdName(val: string | IdName<string>): IdName<string> {
    if (typeof val === 'string') {
      return {
        id: null,
        name: val
      };
    } else {
      return val;
    }
  }

  private buildForm(steps: Array<IFormStep>): FormGroup {

    const form: FormGroup = this.fb.group({});

    steps.forEach(s => {

      const group: FormGroup = this.fb.group({});

      s.inputs.forEach(i => {

        let value: any = '';

        if (i.name === 'birthDate') {
          value = `${this.ages.average}`;
        } else if (i.type === 'mobile-prefix' && this.config.features?.defaultPrefix) {
          value = this.config.features.defaultPrefix;
        } else if (i.name === 'referralCode') {
          value = this.cookieService.get('referralCode');
        } else if (i.name == 'promoCode') {
          value = this.localStorage.getItem('promoCode');
        } else if (i.name === 'typeId' && typeof i.values !== 'string') {

          const found = i.values.find(v => v.selected);

          if (found) {
            value = found.id;
          }

        } else if (i.type === 'radio' && typeof i.values !== 'string') {

          const found = i.values.find(v => v.selected);

          if (found) {
            value = found.id;
          }
        } else if (i?.options?.selected && (i.options.selected in this.ages)) {
          value = this.ages[i.options.selected]
        } /*else if (i.type === 'phone') {
          value = this.config.phonePrefix;
        }*/

        group.setControl(i.name, this.fb.control({value, disabled: i.disabled}, this.validationService.buildValidator(i), this.validationService.buildAsyncValidator(i)));

        if (i.name === 'currencyId') {
          this.subs.add(this.catalogService.currencies().subscribe({
            next: c => {

              const currentCurrency = this.route.snapshot.queryParams.currencyCode;
              const currencyCtrl = group.get('currencyId');
              const referralCurrency = c.find(x => x.code === currentCurrency);

              if (currentCurrency && referralCurrency) {
                i.values = [referralCurrency];
                currencyCtrl.setValue(referralCurrency);
              } else {
                i.values = c;
                currencyCtrl.setValue(c[0].id);
              }
            }
          }));
        }

      });

      form.setControl(s.name, group);
    });

    return form;
  }


  private removeEmptyStrings(obj) {
    const clone = {...obj};
    Object.entries(clone).forEach(([key, val]) => val === '' && delete clone[key]);
    return clone;
  }
}
