import { IAuthor } from '../common/author';
import { CurrencyConversion } from '../common/currency';
import { ISearchBaseSportsBookFilters } from './common';
import { LimitTypes } from './sportEventLimit';


export interface ISettingSelection {
  id: number;
  betOfferId: number;
  sportId: number;
  name: string;
  note: string;
  author: IAuthor;
  lastDate: string;
}

export interface ISettingFixtureLimit extends CurrencyConversion {
  id: number;
  versionId: number;
  parentId: number;
  selectionEntityId: number;
  limitTypeId: LimitTypes;
  offerTypeId: number;
  maxBet: number;
  maxWin: number;
  fromDate: string;
  endDate: string;
  author: IAuthor;
  date: string;
}

export interface ISettingMarketLimit extends CurrencyConversion {
  id: number;
  versionId: number;
  parentId: number;
  selectionEntityId: number;
  marketSportId: number;
  limitTypeId: LimitTypes;
  offerTypeId: number;
  maxBet: number;
  maxWin: number;
  fromDate: string;
  endDate: string;
  author: IAuthor;
  date: string;
}

export interface ISettingSelectionSearchFilters extends ISearchBaseSportsBookFilters {
  sportId: number;
}
