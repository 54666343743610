import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseDateSelector } from '@bs/forms';

@Component({
  selector: 'date-selector',
  templateUrl: 'date-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateSelectorComponent),
      multi: true
    }
  ]
})
export class DateSelectorComponent extends BaseDateSelector implements OnInit, ControlValueAccessor {

  writeValue(model: Date) {
    if (model) {

      if (model && model < this.min || model > this.max) {
        throw new Error('Provided date is not in range');
      }

      this.modelDate = new Date(model);

      if (this.modelDate) {
        this.date = this.modelDate.getDate();
        this.month = this.months.find(month => month.value === this.modelDate.getMonth() + 1);
        this.year = this.modelDate.getFullYear();
      } else {
        this.date = this.month = this.year = undefined;
      }

      this.months = this.updateMonths(this.modelDate.getFullYear());
    } else {
      this.date = this.month = this.year = undefined;
    }
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  ngOnInit() {
    this.allMonths = this.getMonthsName('en-US', 'long');
    super.onInit();
  }

}
