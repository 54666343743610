import { ModuleWithProviders, NgModule } from '@angular/core';
import { SmarticoService } from '@bs/services';
import { DeepLinksComponent } from './components/deep-links/deep-links.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { EnvironmentConfig } from '@bs/models';
import { SmarticoBarComponent } from './components/smartico-bar/smartico-bar.component';
import { MaterialComponentsModule } from '@homer/mat-components';
import { BsCommonModule } from '@bs/common';
import { SmarticoSidebarComponent } from './components/smartico-sidebar/smartico-sidebar.component';

const components = [
  SmarticoBarComponent,
  SmarticoSidebarComponent,
  DeepLinksComponent
]


@NgModule({
  imports: [
    TranslateModule,
    MatButtonModule,
    MatBadgeModule,
    MaterialComponentsModule,
    BsCommonModule
  ],
  exports: components,
  declarations: components,
  providers: [
    SmarticoService
  ]
})
export class HomerSmarticoModule {
  static forRoot(config: any): ModuleWithProviders<HomerSmarticoModule> {
    return {
      ngModule: HomerSmarticoModule,
      providers: [
        {
          provide: EnvironmentConfig,
          useValue: config
        }
      ]
    };
  }
}
