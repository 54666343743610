import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@bs/forms';
import { IBetSlipSelection } from '@bs/models';

/**
 * validating all the selections playability
 */
export const selectionPlayabilityValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (isPresent(Validators.required(control))) {
    return null;
  }

  const values: Array<IBetSlipSelection> = control.value;
  const minSelections = values.reduce((max, sel) => (sel.marketSelection.playability > max ? sel.marketSelection.playability : max), -Infinity);
  const event = values.find(i => i.marketSelection.playability === minSelections);

  return values.length >= minSelections ? null : {'playability': {value: event.sportEvent.name, min: minSelections}};
};
