import { ChangeDetectionStrategy, Component, ContentChild, CUSTOM_ELEMENTS_SCHEMA, Input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISlider } from '@bs/models';
import { SwiperDirective } from '@bs/common';
import { SwiperProps } from 'swiper/swiper-react';

@Component({
  selector: 'app-swiper[sliderItems]',
  templateUrl: './swiper-main.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    SwiperDirective
  ]
})
export class SwiperMainComponent {

  @Input()
  config: SwiperProps = {
    //modules: [Navigation, Pagination, A11y, Autoplay, Scrollbar],
    autoHeight: true,
    navigation: true,
    slidesPerView: 5,
  }

  @Input() class: string;

  @Input() sliderItems: Array<ISlider>;

  @ContentChild('slidesTpl', {static: true, read: TemplateRef})
  slidesTpl: TemplateRef<any>;
  @ContentChild('headerTpl', {static: true, read: TemplateRef})
  headerTpl: TemplateRef<any>;

}
