import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IMarketsCollection, ISettingSelectionSearchFilters, Pager } from '@bs/models';

import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class BetOfferMarketsCollectionService {
  private marketsCollections: IMarketsCollection[] = [
    {id: 1, name: 'Basic', isDefault: true, sport: {id: 1, name: 'Soccer'}, author: {id: 1, username: 'marco', typeId: 10}, lastUpdated: '2023-03-01'},
    {id: 1, name: 'Major Leagues', isDefault: false, sport: {id: 1, name: 'Soccer'}, author: {id: 1, username: 'marco', typeId: 10}, lastUpdated: '2023-03-01'},
    {id: 1, name: 'Amateur Leagues', isDefault: false, sport: {id: 1, name: 'Soccer'}, author: {id: 1, username: 'marco', typeId: 10}, lastUpdated: '2023-03-01'},
    {id: 1, name: 'Seconds Leagues', isDefault: false, sport: {id: 1, name: 'Soccer'}, author: {id: 1, username: 'marco', typeId: 10}, lastUpdated: '2023-03-01'},
    {id: 1, name: 'High Risk Leagues', isDefault: false, sport: {id: 1, name: 'Soccer'}, author: {id: 1, username: 'marco', typeId: 10}, lastUpdated: '2023-03-01'},
  ]
  readonly url = `${this.config.api.sportsbook}/markets-collection`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  getAll(betOfferId: number, sportId?: number): Observable<Array<IMarketsCollection>> {
    return this.http.get<Array<IMarketsCollection>>(`${this.url}`, {params: {bId: betOfferId.toString(), sId: sportId.toString()}});
  }

  createOrUpdate(data: IMarketsCollection): Observable<IMarketsCollection> {
    return data.id ? this.http.put<IMarketsCollection>(`${this.url}/${data.id}`, data) : this.http.post<IMarketsCollection>(this.url, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }

  search(filters: ISettingSelectionSearchFilters): Observable<Pager<IMarketsCollection>> {
    const pager: Pager<IMarketsCollection> = {list: this.marketsCollections, count: this.marketsCollections.length}
    console.log(pager)
    return of(pager);
    //return this.http.post<Pager<IMarketsCollection>>(`${this.url}/search`, filters);
  }

}
