import { IMe } from './me';
import { Tfa } from './tfa';

export interface ILoginCredentials {
  username: string;
  password: string;
}


export interface ILoginResponse {
  token?: string;
  me?: IMe;
  tfa?: Tfa
}
