<div class="container-fluid mt-3 p-3">
  <banner-slider [slider]="sliderHero"></banner-slider>

  <!--  <div class="cashback-header text-start">-->
  <!--    <div class="d-flex justify-content-between align-items-center">-->
  <!--      <h1 class="mb-3">{{'cash-back-page-title' | translate}}</h1>-->
<!--    </div>-->
<!--    <p class="mb-4">{{'cash-back-page-title-description' | translate}}</p>-->
<!--    <div class="d-flex">-->
<!--      <a mat-flat-button [routerLink]="[]" fragment="register" color="primary">{{'sign-up' | translate}}</a>-->
<!--    </div>-->
<!--  </div>-->

<app-swiper class="my-5"
  [class]="'no-btn'"
              [config]="{grid: {rows: 1, fill:'row'},
              slidesPerView: isMobile() ? 2 : 7,
              spaceBetween: 10,
              navigation: true}"
  [sliderItems]="items">
  <ng-template #headerTpl>
    <div class="mb-4">
      <h2>{{'cash-back' | translate}}</h2>
    </div>
  </ng-template>
  <ng-template #slidesTpl let-item>
    <div class="levels-card text-center px-4">
      <div class="card-header mb-2 pt-4">
        <img [src]="'assets/icons/smartico-cashback/' + (item.name | lowercase) + '.svg'" [alt]=item.name>
      </div>
      <div class="card-body d-flex flex-column">
        @switch (item.name) {
          @case ('vip') {
            <strong class="py-3 strong-2 percentage">{{'up-to' | translate}} {{item.percentage | percent}}</strong>
            <strong class="py-3 amount">{{'contact-your-account-manager' | translate}}</strong>
            <strong class="deposit d-flex align-items-center justify-content-center">{{'vip'|translate}}</strong>
          }
          @case ('tier') {
            <strong class="py-3 strong-2 percentage">{{'up-to' | translate}}</strong>
            <strong class="py-3 amount">{{'max-amount' | translate}}</strong>
            <strong class="deposit d-flex align-items-center justify-content-center">{{'deposit-to-reach'|translate}}</strong>
          }
          @default {
            @if (!item.depositRange.max) {
              <strong class="py-3 strong-2 percentage">{{item.percentage | percent}}</strong>
              <strong class="py-3 amount">{{(item.depositRange.min * item.percentage) | displayCurrencyConversion}}</strong>
              <strong class="deposit d-flex align-items-center justify-content-center">
                {{item.depositRange.min | displayCurrencyConversion}}+
              </strong>
            }
            @if (item.depositRange.max) {
              <strong class="py-3 strong-2 percentage">{{item.percentage | percent}}</strong>
              <strong class="py-3 amount">{{(item.depositRange.max * item.percentage) | displayCurrencyConversion}}</strong>
              <strong class="deposit d-flex align-items-center justify-content-center">
                {{item.depositRange.min | displayCurrencyConversion}} - {{item.depositRange.max | displayCurrencyConversion}}
              </strong>
            }
          }
        }

      </div>
    </div>
  </ng-template>
</app-swiper>

<section>
  <faq [faqSectionId]="8"></faq>
</section>
</div>
