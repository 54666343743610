@for (item of items; track item; let i = $index) {
  <ng-template [ngTemplateOutletContext]="{$implicit: item}" [ngTemplateOutlet]="(!!children && half===i) ? contentTemplate:linkTemplate"></ng-template>
}

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>

<ng-template #linkTemplate let-item>
  <div class="col justify-content-center d-flex align-items-center" [ngClass]="{logged: !!me, 'ignore-link': item.fragment}" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
    @if (item.routerLink === 'chat()') {
      <mobile-chat-btn>
        <span class="chat-text flex-grow-1 item-label font-size-sm">{{ item.label }}</span>
      </mobile-chat-btn>
    }
    @if (item.url) {
      <a [href]="item.url"
         [class.p-0]="column" mat-button [matBadge]="item.badge"
         matBadgeColor="warn" matBadgeSize="small"
         target="_blank">
         <div class="d-flex flex-column align-items-center">
            <svg [icon]="item.icon" lib="global" class="mb-1" [class.me-1]="!column" [size]="{w:18, h:18}"></svg>
            <span class="flex-grow-1 item-label font-size-sm">{{ item.label }}</span>
         </div>
      </a>
    }
    @if (item.fragment) {
      <a [fragment]="item.fragment"
         [routerLink]="item.routerLink ? ['/' + settings.languageCode + item.routerLink] : []"
         [class.p-0]="column" mat-button [matBadge]="item.badge"
         matBadgeColor="warn" matBadgeSize="small">
        <div class="d-flex flex-column align-items-center">
          <svg [icon]="item.icon" lib="global" class="mb-1" [class.me-1]="!column" [size]="{w:18, h:18}"></svg>
          <span class="flex-grow-1 item-label font-size-sm">{{ item.label }}</span>
        </div>
      </a>
    }
    @if (item.routerLink) {
      <a
        [routerLink]="item.routerLink ? ['/' + settings.languageCode + item.routerLink] : []"
        [class.p-0]="column" mat-button [matBadge]="item.badge"
        matBadgeColor="warn" matBadgeSize="small">
        <div class="d-flex flex-column align-items-center">
          <svg [icon]="item.icon" lib="global" class="mb-1" [class.me-1]="!column" [size]="{w:15, h:15}"></svg>
          <span class="flex-grow-1 item-label">{{ item.label }}</span>
        </div>
      </a>
    }
  </div>
</ng-template>
