import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPaymentProvider } from '@bs/models';

interface MyData {
  paymentMethod: IPaymentProvider;
  response: any;
}

@Component({
  selector: 'deposit-request-dialog',
  templateUrl: 'deposit-request.dialog.html',
})

export class DepositRequestDialog {

  constructor(public dialogRef: MatDialogRef<DepositRequestDialog>,
              @Inject(MAT_DIALOG_DATA) public data: MyData) {
  }
}
