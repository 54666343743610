import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMe, IPaymentProvider, IWithdrawalRequest } from '@bs/models';
import { AuthService, IdentityWalletService } from '@bs/services';
import { WithdrawalBaseDialog } from '../withdrawal-base.dialog';

interface MyData {
  me: IMe;
  amount: number;
  provider: IPaymentProvider;
}


@Component({
  selector: 'crypto-withdrawal',
  templateUrl: 'crypto-withdrawal-request.dialog.html'
})

export class CryptoWithdrawalRequestDialog extends WithdrawalBaseDialog {
  form: FormGroup;

  constructor(public identityWalletService: IdentityWalletService, public authService: AuthService, fb: FormBuilder, public dialogRef: MatDialogRef<CryptoWithdrawalRequestDialog>,
              @Inject(MAT_DIALOG_DATA) public data: MyData) {
    super(identityWalletService, authService);

    this.form = fb.group({
      amount: [{value: this.data.amount, disabled: true}],
      cryptoWalletAddress: ['', Validators.required],
    });

  }

  handleSubmit() {
    const request: IWithdrawalRequest = {
      amount: this.data.amount,
      paymentMethodId: this.data.provider.id,
      additionalData: JSON.stringify({
        cryptoWalletAddress: this.form.value.cryptoWalletAddress,
      })
    };
    this.createMovement(request);
  }

}

