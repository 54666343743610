import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IdName, IOutputSearchRequest, IOutputTemplate, IOutputTemplateSearchRequest, IOutputTemplatesRequest, Pager } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable()
export class PromoOutputTemplatesService {

  readonly url = `${this.config.api.promo}/outputs/templates`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  get(id: number): Observable<IOutputTemplate> {
    return this.http.get<IOutputTemplate>(`${this.url}/${id}`);
  }

  search(filters: IOutputTemplateSearchRequest): Observable<Pager<IOutputTemplate>> {
    return this.http.post<Pager<IOutputTemplate>>(`${this.url}/search`, filters);
  }

  create(req: IOutputTemplate): Observable<IOutputTemplate> {
    return this.http.post<IOutputTemplate>(`${this.url}`, req);
  }

  all(req: IOutputTemplatesRequest): Observable<Array<IdName<string>>> {
    return this.http.get<Array<IdName<string>>>(`${this.url}`, {params: {cId: req.currencyId.toString(), bId: req.bookmakerId.toString(), omId: req.modelId.toString()}});
  }
}
