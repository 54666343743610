export interface Smartico {
  requestPushPermissions: () => void;
  dp: any;

  on(identify: string, change: (paramOne: any, paramsTwo?: any) => void): void;

  init: any;
  api: any;
  setNickname: (nick: string) => void;
  miniGame: (id: number, params: Record<string, string | number | boolean>) => void;
  showWidget: (container: string, params: Record<string, string | number | boolean>) => void;

  getPublicProps(): SmarticoProps;
}

export interface SmarticoProps {
  ach_points_ever?: number;
  core_public_tags?: Array<string>;
  ach_level_current_id?: number;
  user_country?: string;
  ach_points_balance?: number;
  user_last_session_push_state?: string;// BROWSER_NOT_SUPPORTED;
  core_registration_date?: number;
  ach_gamification_in_control_group?: boolean;
  ach_points_board_period_type_1?: number;
  ach_points_board_period_type_2?: number;
  core_user_language?: string;
  ach_level_current?: string;
  core_is_test_account?: boolean;
  avatar_id?: string;
  public_username?: string;
}
