import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@bs/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { TfaResponse, TfaType } from '@bs/models';
import { FormBuilderComponent } from '@homer/forms';

@Component({
  selector: 'tfa-dialog',
  templateUrl: './tfa-setup.dialog.html'
})

export class TfaSetupDialog implements OnInit {

  @ViewChild(FormBuilderComponent, {static: false})
  fb: FormBuilderComponent;

  data: TfaResponse;
  protected readonly TfaType = TfaType;

  constructor(public dialogRef: MatDialogRef<TfaSetupDialog>, @Inject(MAT_DIALOG_DATA) public setup: { type: TfaType },
              private snackBar: MatSnackBar, private translate: TranslateService, private authService: AuthService) {

    authService.generateTfaCode(setup.type).subscribe(data => {
      this.data = data;
    });

  }

  ngOnInit() {
  }

  submit(value: any) {
    const req = Object.assign(value, {id: this.data.id});
    this.authService.activateTfaCode(req).subscribe({
      next: data => {
        this.snackBar.open(this.translate.instant('validated'), this.translate.instant('close'), {panelClass: 'success', horizontalPosition: 'end', duration: 3500})
        this.dialogRef.close(data);
      },
      error: response => this.fb.form.get('otp')?.setErrors({[response.message]: true})
    })
  }
}
