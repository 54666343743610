export class Selection {
  constructor(public type: number = 1,
              public sport: string,
              public category: string,
              public tournament: string,
              public eventId: number,
              public competitors: string[],
              public date: string,
              public oddId: number,
              public oddClass: string,
              public oddOutcome: string,
              public oddOutcomeId: number,
              public oddSpecialBetId: number,
              public oddSpecialBet: any,
              public oddValue: number,
              public oddValueBeforeNegotiation: number,
              public oddState: number = 1,
              public oddPlayability: number = null,
              public blocked: boolean = false,
              public oddArrow: number = 0,
              public scores: any[] = null) {
  }
}


