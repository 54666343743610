interface Total {
  key: string;
  count: number;
  sum: number;
  currencyCode: string;
  currencySymbol: string;
  rate: number;
  subunit: number;
}


export interface Pager<T = any> {
  list: Array<T>;
  count: number;
  totals?: Array<Total> | any; // TODO: any because its incompatible with ITicketHistorySearch models needs to be merged
}
