import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ICmsFaqBase } from '@bs/models';
import { CmsFaqSectionsService } from '@bs/services';

@Component({
  selector: 'faq',
  templateUrl: 'faq.component.html',
  styles: [`:host {
    display: block
  }`]
})

export class FaqComponent implements OnInit, OnChanges {
  @Input() faqSectionId: number;
  faq: Array<ICmsFaqBase> = [];

  constructor(private cmsFaqSectionsService: CmsFaqSectionsService) {
  }

  ngOnInit() {
    this.loadFaq();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.faqSectionId && !changes.faqSectionId.firstChange) {
      this.loadFaq();
    }
  }

  loadFaq() {
    this.cmsFaqSectionsService.all().subscribe(data => {
      const faqSection = data.find(x => x.id === this.faqSectionId);
      this.faq = faqSection ? faqSection.faqs : [];
    });
  }
}
