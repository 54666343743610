import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DrawerMovementCreateRequest, DrawerMovementSearchRequest, EnvironmentConfig, IDrawerMovement, Pager } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerMovementsService { // in krusty accounts
  readonly url = `${this.config.api.identity}/me/drawers/movements`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  get(id: number): Observable<IDrawerMovement> {
    return this.http.get<IDrawerMovement>(`${this.url}/${id}`);
  }

  create(req: DrawerMovementCreateRequest): Observable<IDrawerMovement> {
    return this.http.post<IDrawerMovement>(`${this.url}`, req);
  }

  search(filters: DrawerMovementSearchRequest): Observable<Pager<IDrawerMovement>> {
    return this.http.post<Pager<IDrawerMovement>>(`${this.url}/search`, filters);
  }
}
