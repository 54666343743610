<div class="games-grid-container">
  @switch (layoutRender) {
    @case ('grid') {
      <div class="grid-games">
        @for (game of games; track trackByFn($index, game)) {
          <div>
            <game-tile (click)="selected = game.id" [class.selected]="selected === game.id" [game]="game" [isMobile]="isMobile"></game-tile>
          </div>
        }
      </div>
    }
    @default {
      <div class="row games-row mx-0 mb-3">
        @for (game of games; track trackByFn($index, game)) {
          <div class="col-game">
            <game-tile (click)="selected = game.id" [class.selected]="selected === game.id" [game]="game" [isMobile]="isMobile"></game-tile>
          </div>
        }
      </div>
    }
  }
</div>
