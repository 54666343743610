import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IOutput, IOutputChangeStatusRequest, IOutputCreateRequest, IOutputSearchRequest, Pager } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable()
export class PromoOutputService {

  readonly url = `${this.config.api.promo}/outputs`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  get(id: number): Observable<IOutput> {
    return this.http.get<IOutput>(`${this.url}/${id}`);
  }

  search(filters: IOutputSearchRequest): Observable<Pager<IOutput>> {
    return this.http.post<Pager<IOutput>>(`${this.url}/search`, filters);
  }

  create(req: IOutputCreateRequest): Observable<IOutput> {
    return this.http.post<IOutput>(`${this.url}`, req);
  }

  changeStatus(id: number, req: IOutputChangeStatusRequest) {
    return this.http.patch(`${this.url}/${id}/status`, req);
  }
}
