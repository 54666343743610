import { IWalletMovement } from './movement';

export interface DrawerMovementCreateRequest {
  amount: number
  note?: string;
  causalId: number;
}

export interface DrawerMovementSearchRequest {
  accountId?: number;
  causalGroupId?: number;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}

export interface IDrawer {
  id: number;
  networkId: number;
  balance: IDrawerMovementAmounts;
  toPay: IDrawerMovementAmounts;
  lastUpdate: string;
}

export interface IDrawerMovement {
  id: number;
  date: string;
  causal: string;
  causalGroup: string;
  amount: IDrawerMovementAmounts;
  balance: IDrawerMovementAmounts;
  movement?: IWalletMovement;
  note?: IDrawerMovementNote;
  tpl?: string;
}

export interface IDrawerMovementNote {
  id: number;
  note: string;
}

export interface IDrawerMovementAmounts {
  amount: number;
  currencySymbol: string;
  currencyCode: string;
  subunit: number;
  rate: number;
}
