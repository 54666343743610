import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentConfig, ITicketBooking } from '@bs/models';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BetslipService } from './betslip.service';
import { TicketService } from './ticket.service';

@Injectable({
  providedIn: 'root'
})
export class TicketBookingService {

  readonly url = this.config.api.sportsbook;

  constructor(private config: EnvironmentConfig,
              private betslipService: BetslipService,
              private ticketService: TicketService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private snackBar: MatSnackBar,
              private translate: TranslateService) {
  }

  bookingBet() {
    //
    // const currentSlip = this.betslipService.betslip$.getValue();
    //
    // const betTypeId = IBetslipType.values().find(v => v.name.toLowerCase() === currentSlip.type).id;
    //
    // const betGroupId = IBetslipGroup.values().find(v => v.name.toLowerCase() === currentSlip.context).id;
    //
    // const selections = currentSlip.selections.flatMap(v => ({oddId: v.oddId, blocked: v.blocked}));
    //
    // const reqSlip: BookBetRequest = {
    //   betTypeId,
    //   betGroupId,
    //   stake: currentSlip.betType.amount * 100,
    //   selections
    // }

    //    return this.http.post(`${this.url}/tickets/booking`, reqSlip);
  }

  getByCode(code: string): Observable<ITicketBooking> {
    //
    // const bs = new Betslip({betType: new Single()});
    //
    // const one$ = this.ticketService.getBonusAndRisk().pipe(take(1));
    // const two$ = this.http.get<ITicketBooking>(`${this.url}/tickets/booking/${code}`).pipe(
    //   take(1),
    //   switchMap(value2 => one$.pipe(map(() => value2)))
    // );
    // return two$.pipe(
    //   tap((booking) => {
    //     bs.betType.amount = booking.stake / 100
    //     bs.selections = booking.selections;
    //     bs.type = bs.guessBetType();
    //
    //     this.betslipService.bettingSlip = Object.assign(bs, booking);
    //   }),
    //   tap(() => this.betslipService.updateAndBroadcast())
    // );

    return this.http.get<ITicketBooking>(`${this.url}/tickets/booking/${code}`);
  }

  loadSharedBet() {
    this.route.queryParamMap.subscribe({
      next: paramMap => {
        if (paramMap.has('bookingCode')) {
          this.getByCode(paramMap.get('bookingCode')).pipe(finalize(() => {
            this.router.navigate([], {queryParams: null, replaceUrl: true});
          })).subscribe({
            next: () => this.snackBar.open(this.translate.instant('booking-loaded'), this.translate.instant('close'), {panelClass: 'success', horizontalPosition: 'end', duration: 2500}),
            error: error => this.snackBar.open(this.translate.instant(error.message), this.translate.instant('close'), {panelClass: 'error', horizontalPosition: 'end', duration: 3500})
          });
        }
      }
    });
  }
}
