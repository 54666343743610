import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, CatalogService, MeService } from '@bs/services';
import { Col, IDocument, IDocumentType } from '@bs/models';
import { Subscription, switchMap } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'documents-manager',
  templateUrl: 'documents-manager.component.html'
})

export class DocumentsManagerComponent implements OnInit {
  form: FormGroup;
  documentTypes: Array<IDocumentType>;
  subs = new Subscription();

  loading: boolean;
  myDocuments: Array<IDocument>;
  cols: Col[];
  headerRow: string[];

  get attachments(): FormArray {
    return this.form.get('attachments') as FormArray;
  }

  constructor(private fb: FormBuilder, private authService: AuthService, private meService: MeService, private translate: TranslateService, private catalogService: CatalogService, private snackBar: MatSnackBar) {

    this.subs.add(
      this.catalogService.documentTypes()
        .pipe(switchMap(dt => {
          this.documentTypes = dt;
          return meService.getDocuments()
        }))
        .subscribe({
        next: md => {
            this.myDocuments = this.documentTypes.map(d => {
              const myDoc = md.find(md => md.typeId === d.id)
              if (myDoc) {
                return myDoc;
              }
              return {documentNumber: '', typeId: d.id, expiringDate: '', issueDate: '', issueAuth: '', issuePlace: '', isConfirmed: null};
            })
          }
      })
    )

    this.cols = [
      {field: 'typeId', header: 'documentName', type: 'typeId'},
      {field: 'isConfirmed', header: 'isConfirmed', type: 'boolean'}
    ];

    this.headerRow = this.cols.flatMap(x => x.header);

  }

  submit() {
    this.subs.add(this.meService.createDocument(this.form.value).pipe(finalize(() => this.loading = false)).subscribe({
      next: document => {
        this.snackBar.open(this.translate.instant('document-uploaded'), this.translate.instant('close'), {duration: 3500});
        this.form = null;
        this.authService.refreshMe({documents: [document]});
      },

      error: error => this.snackBar.open(this.translate.instant(error.message), this.translate.instant('close'), {panelClass: 'error'})
    }));
  }

  ngOnInit() {
  }

  loadForm(typeId: number) {

    this.form = this.fb.group({
      typeId: [typeId],
      documentNumber: [],
      issueDate: [],
      expiringDate: [],
      issueAuth: [],
      issuePlace: [],
      attachments: this.fb.array([])
    });

    const attachments = this.documentTypes.find(d => d.id === typeId).attachments;
    this.attachments.clear();
    attachments.forEach(a => {
      this.attachments.push(this.fb.group({
        id: [a.id],
        name: [a.name],
        url: ['', Validators.required]
      }))
    })

  }
}
