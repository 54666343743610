<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span>{{ data.title | translate }}</span>
      <button (click)="dialogRef.close()" class="close-icon" type="button"></button>
    </mat-card-title>
    <div class="dialog-wrapper">
      <p [innerHTML]="data.content" class="m-5"></p>
    </div>
  </mat-card>
</div>
