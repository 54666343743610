import { IAuthor } from '../common/author';
import { IMarketSport } from './market-sport';
import { ISport } from './sport';

/**
 * @deprecated
 */
export interface IMarketsCollectionFixture extends IMarketsCollection {
  entityTypeId: number;
  entityId: number;
  author: IAuthor;
  lastUpdated: string;
}

/**
 * @deprecated
 */
export interface IMarketsCollection {
  id: number;
  author: IAuthor;
  name: string;
  lastUpdated: string;
  sport: ISport;
  isDefault: boolean;
  marketSports?: Array<IMarketSport>;
}
