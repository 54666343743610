import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '@bs/models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RealityCheckerService {
  readonly url = `${this.config.api.identity}/me/reality-checker`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }
}
