import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, OnDestroy, Output } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ControlValueAccessor, FormArray, FormBuilder, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { emailExists, mailFormat, phoneExists, phoneValidator, ValidationService } from '@bs/forms';
import { DeliveryType, IDelivery } from '@bs/models';

import { AuthService, MeService } from '@bs/services';
import { OtpDialog } from '../../dialogs/otp/otp.dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

/**
 * The deliveries cva component is used in user contacts section as information of that user
 */
@Component({
  selector: 'deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeliveriesComponent),
      multi: true
    }
  ]
})
export class DeliveriesComponent implements ControlValueAccessor, OnDestroy {
  /**
   * if outputs is true, we are able to add new form
   */
  @Output()
  isAdding = new EventEmitter<boolean>();
  /**
   * responsible for disabling the field
   */
  isDisabled: boolean;
  /**
   * it acts as condition for the isAdding output decorator condition
   */
  adding: number;
  /**
   * keeps track of added delivery form, so we don't append multiple forms
   */
  isActivelyAdding: boolean;
  /**
   * Tracks the value and validity state of the deliveries array of FormControl, FormGroup or FormArray instances.
   */
  deliveries: FormArray;
  /**
   * local reference to Delivery types
   *
   * see {@link DeliveryType} for all the type
   */
  types = DeliveryType;
  /**
   * array of DeliveryType values
   *
   * see {@link DeliveryType} for all the type
   */
  typeKeys: Array<{ key: number, value: string }>;
  /**
   * the value of the validation type
   */
  validationType: 'sms' | 'ivr';
  /**
   * local reference to Subscription
   */
  subs = new Subscription();

  /**
   * The constructor setting a default typeKeys and validationType value
   * @param fb
   * @param validationService
   * @param meService
   * @param authService
   * @param snackBar
   * @param dialog
   * @param translate
   */
  constructor(private fb: FormBuilder, private validationService: ValidationService, private meService: MeService, private authService: AuthService,
              private snackBar: MatSnackBar, private dialog: MatDialog, private translate: TranslateService) {
    this.typeKeys = this.types.values();
    this.validationType = 'sms';
  }

  /**
   * unsubscribe from the subscription
   */
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param _fn
   */
  registerOnChange(_fn: any): void {
    this.propagateChange = _fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param _fn
   */
  registerOnTouched(_fn: any): void {
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;

    if (isDisabled) {
      this.deliveries.controls.forEach(c => c.disable());
    }
  }

  /**
   * when programmatic changes from model to view are requested, creates the form of deliveries, and on form value change it registers the change
   * @param birthPlace
   */
  writeValue(deliveries: Array<IDelivery>): void {
    if (deliveries) {
      this.deliveries = this.buildDeliveries(deliveries);
    }

    this.subs.add(this.deliveries.valueChanges.subscribe({
      next: res => {
        this.propagateChange(res);
      }
    }));
  }

  /**
   * function allow us to add a new delivery form
   * @param typeId
   */
  addDelivery(typeId: number): void {
    this.isActivelyAdding = true;
    this.deliveries.push(this.createDeliveries({typeId}, this.deliveries.length));
  }

  /**
   * function removes a delivery form from the page if the backend api call is successful
   * @param delivery
   * @param idx
   */
  removeDelivery(delivery: AbstractControl, idx: number) {
    if (this.adding === idx) {
      this.adding = null;
      this.isAdding.emit(false);
    }

    if (delivery.value.id) {
      this.meService.deleteInfo(delivery.value.id, 'delivery').then(
        () => this.deliveries.removeAt(idx),
        error => this.handleError(error));
    } else {
      this.deliveries.removeAt(idx);
    }
  }

  /**
   * function makes a http request to backend for the otp, opens us the OtpDialog, and checks the otp value user enters if it's a correct it saves the delivery
   * @param delivery
   * @param validationType
   */
  requestMobileOtp(delivery, validationType: 'sms' | 'ivr') {
    const current: any = delivery.getRawValue();

    this.validationService.requestMobileOtp(current.delivery, validationType).then(transactionId => {

        const dialogRef = this.dialog.open(OtpDialog, {
          data: {transactionId, phone: current.delivery, deliveryId: current.id}
        });

        dialogRef.componentInstance.withCheck = true;

        this.subs.add(dialogRef.afterClosed().subscribe({
          next: result => {
            if (result) {
              const deliveryRequest: IDelivery = Object.assign(current, {delivery: `+${current.delivery.prefix.prefix}${current.delivery.phone}`});
              this.saveDelivery(deliveryRequest); // transactionId
            }

          },

          error: error => this.handleError(error)
        }));
      },
      error => this.handleError(error));
  }

  /**
   * function on form submit sends information to backend and saves the delivery if we have successful callback, otherwise if we show the error message
   * @param current
   */
  saveDelivery(current: IDelivery) {

    const value: Partial<IDelivery> = {
      delivery: current.delivery,
      isPrimary: current.isPrimary,
      typeId: current.typeId
    };

    if (current.id) {
      this.meService.patchDelivery(value, current.id).then(
        () => {
          // toast ok
          this.snackBar.open(this.translate.instant('operation-confirmed'), this.translate.instant('close'), {duration: 3500});
          this.adding = null;
        },
        error => this.handleError(error));
    } else {
      this.meService.saveDelivery(value).then(
        me => {
          // toast ok
          this.snackBar.open(this.translate.instant('operation-confirmed'), this.translate.instant('close'), {duration: 3500});
          this.adding = null;
          this.deliveries = this.buildDeliveries(me.deliveries); // todo: fix model return
          this.isActivelyAdding = false;
          this.isAdding.emit(false);
          this.authService.refreshMe(me);
        },
        error => this.handleError(error));
    }
  }

  /**
   * function request an api call with form value for email activation
   * @param delivery
   */
  resend(delivery: AbstractControl) {
    this.validationService.requestEmailActivation(delivery.value.id).then(() => {
      this.snackBar.open(this.translate.instant('checkYourEmail'), this.translate.instant('close'), {duration: 3500});
    }, error => this.handleError(error));
  }

  /**
   * function marks the users deliveries as primary, and saves it to backend if we have successful callback, otherwise we show the error message
   * @param delivery
   */
  markAsPrimary(delivery: AbstractControl) {
    this.meService.markDeliveryAsPrimary(delivery.value.id).then(
      () => ({}),
      error => this.handleError(error));
  }

  /**
   * checks if we have already an email delivery added, if we do we can't add anymore, otherwise we are able to add a new one
   * @param tab
   * @param deliveries
   */
  hasNoEmail(tab: { key: number; value: string }, deliveries: FormArray) {
    if (tab.key === DeliveryType.Email) {
      const count = deliveries.value.filter(i => i.typeId === tab.key).length;
      return count < 1;
    }
    return true;
  }

  /**
   * function prints us the error message of the http response
   * @param error
   * @private
   */
  private handleError(error: HttpErrorResponse) {
    this.snackBar.open(this.translate.instant(error.message), this.translate.instant('close'), {panelClass: 'error'});
  }

  /**
   * function builds us the deliveries form
   * @param deliveries
   * @private
   */
  private buildDeliveries(deliveries: Array<IDelivery>) {
    const arr = deliveries ? deliveries.map(delivery => this.createDeliveries(delivery)) : [];
    return this.fb.array(arr);
  }

  /**
   * function created the form with the fields and validation for the fields
   * @param delivery
   * @param adding
   * @private
   */
  private createDeliveries(delivery?: Partial<IDelivery>, adding?: number) {

    this.adding = adding || null;

    this.isAdding.emit(!!this.adding);

    const group = this.fb.group({
      id: [],
      delivery: ['', this.buildValidator(delivery.typeId), this.buildAsyncValidator(delivery)],
      typeId: [''],
      isConfirmed: [{value: false, disabled: true}],
      isPrimary: [{value: false, disabled: true}] // todo was: !delivery.isConfirmed
    });

    if (delivery) {
      // group.patchValue(Object.assign(delivery, {isConfirmed: true}));
      group.patchValue(delivery as any);
    }

    return group;
  }

  /**
   * function adds a build in validations by the typeId for the delivery field
   * @param typeId
   * @private
   */
  private buildValidator(typeId: DeliveryType): ValidatorFn {
    const validators: Array<ValidatorFn> = [];

    validators.push(Validators.required); // default

    switch (typeId) {
      case DeliveryType.Email:
        validators.push(mailFormat);
        break;
      case DeliveryType.Mobile:
        validators.push(phoneValidator);
        break;
    }

    return Validators.compose(validators);

  }

  /**
   * function adds an async validators by typeId for the delivery field
   * @param delivery
   * @private
   */
  private buildAsyncValidator(delivery: Partial<IDelivery>): AsyncValidatorFn {
    if (!delivery.id) {
      switch (delivery.typeId) {
        case DeliveryType.Email:
          return emailExists(this.validationService);
        case DeliveryType.Mobile:
          return phoneExists(this.validationService);
      }
    }
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: Array<IDelivery>) {
  }
}
