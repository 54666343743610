import { Directive, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings, FaqSection, gameTypeEnum, IBaseGame, IGame, IGameAttribute, IGameSubType, IGameType, IMe, IMenuItem, IPromotion, ISlider, MaggieMenu } from '@bs/models';
import { AppSettingsService, AuthService, GamesFavouritesService } from '@bs/services';
import { WindowService } from '@bs/universal';
import { Subscription } from 'rxjs';

interface ISortByDropdown {
  name: string;
  label: string;
  icon: string;
  direction: 'asc' | 'desc';
}

@Directive()
export class GameBaseClass implements OnInit, OnDestroy {
  maggieMenu = MaggieMenu;

  misteryItems: Array<IBaseGame> = [];
  featured: Array<IBaseGame> = [];
  new: Array<IBaseGame> = [];
  jackpot: Array<IBaseGame> = [];

  gameType: IGameType;
  themes: Array<IGameAttribute> = [];
  promotions: Array<IPromotion>;
  navMenu: IMenuItem;

  casinoRouteName = '';
  readonly defaultGamesLimit = 16;
  gameTypeId: gameTypeEnum;
  favourites: IBaseGame[] = [];
  settings: AppSettings;
  lang: string;

  subs = new Subscription();

  gamesLimit: Array<number> = [];
  isDarkTheme = false;
  sliderHero: ISlider;
  selected: number;
  casinoSection = '';
  provider: string;

  isMobile = false;
  hasGames = false;

  filters: {
    // tags?:Array<string>
    attributes?: Array<number>;
    providers?: Array<number>;
    category?: string;
    search?: string;
  } = {}

  sortOptions: Array<ISortByDropdown> = [
    {icon: 'crown', label: 'popular', name: 'position', direction: 'asc'},
    {icon: 'sort-alphabetical-ascending', label: 'A-Z', name: 'name', direction: 'asc'},
    {icon: 'sort-alphabetical-descending', label: 'Z-A', name: 'name', direction: 'desc'},
  ];

  sortSelected: ISortByDropdown;
  faqSectionId: number;

  constructor(protected route: ActivatedRoute, protected router: Router, protected windowService: WindowService,
              protected gamesFavouritesService: GamesFavouritesService, protected authService: AuthService, private appSettingsService: AppSettingsService) {

    this.sortSelected = this.sortOptions[0];

    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({settings}) => {
        this.settings = settings;
        this.isDarkTheme = settings.darkTheme;
      }
    }));

    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));

    this.subs.add(route.paramMap.subscribe({
      next: map => {
        this.casinoSection = map.get('section')
      }
    }));

    this.subs.add(route.queryParamMap.subscribe({
      next: param => {
        this.gamesLimit = [];
        this.filters.providers = param.getAll('providers').map(x => +x);
        this.filters.attributes = param.getAll('attributes').map(x => +x);
        this.filters.search = param.get('search');
        this.filters.category = param.get('category');

        if (param.has('search')) {
          this.casinoSection = 'search';
        } else {
          this.filters.search = null;
        }
        this.faqSectionId = this.getFaqSectionId(this.casinoRouteName, this.filters.category)
      }

    }));

    this.subs.add(route.data.subscribe({
      next: data => {
        [this.gameType, this.casinoRouteName, this.promotions, this.navMenu] = [data.gameType, data.name, data.promotions, data.menu];
        this.sliderHero = data.sliders?.find(x => x.name === data.name + '-hero')
        this.hasGames = this.gameType?.gameSubTypes.some(x => x.games?.length);
        this.themes = this.gameType?.attributes?.filter(x => x.typeId === 17);
        //console.log(this.themes)
      }
    }));

    this.subs.add(this.route.root.firstChild.params.subscribe({
      next: p => {
        this.lang = p.lang;
      }
    }))
  }

  ngOnInit() {
    const gameSubType = this.gameType.gameSubTypes[0];
    const tagsMap = {
      'featured': this.featured,
      'new': this.new,
      'jackpot': this.jackpot,
      'mistery': this.misteryItems
    };

    gameSubType.games.forEach(game => {
      Object.keys(tagsMap).forEach(tagName => {
        if (this.hasTag(game, tagName)) {
          tagsMap[tagName].push(game);
        }
      });
    });

    this.faqSectionId = this.getFaqSectionId(this.casinoRouteName, this.filters.category)

  }

  getFaqSectionId(gameType: string, category?: string): number {
    let key = gameType.toLowerCase();
    if (category) {
      key += `_${category}`;
    }

    switch (key) {
      case 'casino':
        return FaqSection.casinoLobby;
      case 'casino-live':
        return FaqSection.casinoLiveLobby;
      case 'casino-live_lobby':
        return FaqSection.casinoLiveLobby;
      case 'crash-games':
        return FaqSection.crashGamesLobby;
      case 'virtual_lobby':
        return FaqSection.virtualLobby;
      case 'virtual_horses':
        return FaqSection.virtualHorses;
      case 'virtual_soccer':
        return FaqSection.virtualSoccer;
      case 'virtual_tennis':
        return FaqSection.virtualTennis;
      case 'virtual_virtualSports':
        return FaqSection.virtualVirtualSports;
      case 'casino_numberGames':
        return FaqSection.casinoInstantGames;
      case 'casino_scratchCards':
        return FaqSection.casinoScratchCards;
      case 'casino_tableGames':
        return FaqSection.casinoTableGames;
      case 'casino_videoPokers':
        return FaqSection.casinoVideoPokers;
      case 'casino-live_baccarat':
        return FaqSection.casinoLiveBaccarat;
      case 'casino-live_poker':
        return FaqSection.casinoLivePoker;
      case 'casino-live_roulette':
        return FaqSection.casinoLiveRoulette;
      case 'casino-live_tableGames':
        return FaqSection.casinoLiveTableGames;
      case 'casino_videoSlots':
        return FaqSection.casinoVideoSlots;
      case 'casino-live_blackjack':
        return FaqSection.casinoLiveBlackjack;
      default:
        return null
    }
  }

  gamesFilter = (item: IBaseGame) => {

    if (this.filters.providers.length) {
      return this.filters.providers.includes(item.provider.id);
    }

    if (this.filters.attributes.length) {
      return this.filters.attributes.some(x => item.attributes.includes(x));
    }

    if (this.filters.search) {
      return item.name.toLowerCase().includes(this.filters.search.toLowerCase());
    }
    return true;
  }

  categoryFilter = (item: IGameSubType) => {
    if (this.filters.category) {
      return item.name === this.filters.category;
    }
    return true;
  };

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  search(search: string) {
    setTimeout(() => this.appSettingsService.scrollTo$.next('top'), 400);

    if (!search) {
      this.casinoSection = '';
      search = null;
    } else if (search.length < 3) {
      return;
    }

    void this.router.navigate(['../'], {queryParams: {search}, relativeTo: this.route});
  }

  addGames(idx: number) {
    if (this.gamesLimit[idx]) {
      this.gamesLimit[idx] += this.defaultGamesLimit
    } else {
      // must be created
      this.gamesLimit[idx] = this.defaultGamesLimit * 2;
    }
  }

  private hasTag(game: IBaseGame, tagName: string): boolean {
    return game.tags.some(tag => tag.name === tagName);
  }


}
