import { Params } from '@angular/router';


export class DateTimeFormat {
  time: string;
  day: string;
  date: string;
  dateTime: string;
  dayMonth: string;
  dayMonthTime: string;
}

export enum AlternarOddsFormat {
  Decimal,
  Fractional,
  American
}

export interface AltenarBaseConfig {
  integration: string;
  culture: string;
  themeName: 'dark' | 'light';
  token: string | null;
  dateFormat: DateTimeFormat;
  numFormat?: any;
  oddsFormat?: AlternarOddsFormat;
}

export interface AltenarSportbookConfig {
  props: Partial<AltenarConfig>;
  container: HTMLElement;
}

export interface AltenarBanner {
  url: string;
  id?: string;
  link?: string;
  width?: string | number | undefined;
  aspectRatio?: string | undefined;
  target?: string | undefined;
  position?: number | undefined;
}

export interface AltenarConfig extends AltenarBaseConfig {
  banners: Array<AltenarBanner>;
  betId: string;
  page: string;
  routerType: 'hash' | 'memory',
  categoryIds: Array<number>;
  onRouterLoad: () => void;
  onRouteChange: (data: Params) => void;
  onRouteChangeDetailed: (data: Params) => void;
  onSignInButtonClick?: () => void;
  onGoBack?: () => void;
  token: string;
}
