import { Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppSettings, EnvironmentConfig } from '@bs/models';
import { AppSettingsService } from '../services/core/app-settings.service';
import { GeoIpService } from '../services/core/geoIp.service';

/**
 * this guard is to check if set of pages can be visited from a certain country
 */
@Injectable({
  providedIn: 'root'
})
export class GeoSectionsGuard  {

  /**
   * settings variable
   */
  settings: AppSettings;
  /**
   * allowed array
   * @private
   */
  private allowed: Array<any>;

  /**
   * The constructor sets the allowed array values and settings
   * @param config
   * @param geoIpService
   * @param router
   * @param appSettingsService
   */
  constructor(private config: EnvironmentConfig, @Optional() private geoIpService: GeoIpService, private router: Router, appSettingsService: AppSettingsService) {
    this.allowed = this.config.features.geolocation?.allowed;
    appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    });
  }

  /**
   * interface guard deciding if a route can be activated by a certain country
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> | UrlTree {

    if (this.geoIpService && this.allowed) {
      return new Promise((resolve, reject) => {
        this.geoIpService.get().subscribe({
          next: geoItem => {

            const found = this.allowed.find(c => c.country === geoItem.country_code);

            if (found) {

              if (route.paramMap.has('mode') && route.paramMap.get('mode') === 'fun') {
                return resolve(true);
              }

              // if country has a limitation in states
              if (found.states) {
                if (found.states.includes(geoItem.region_code)) {
                  return resolve(true);
                }

                reject(false);
                return this.router.navigate([this.settings.languageCode, 'country-restricted']);
              }
              return resolve(true);
            }

            reject(false);
            return this.router.navigate([this.settings.languageCode, 'country-restricted']);
          }
        });
      });
    } else {
      return true;
    }
  }

  /**
   * interface guard deciding if a child route can be activated.
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot): boolean | Promise<boolean> | UrlTree {
    return this.canActivate(childRoute);
  }
}
