import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@bs/forms';
import { IBetSlipSelection } from '@bs/models';

/**
 * validating bets by the bettingStatusId of the model IBetSlipSelection, and deciding if match is valid or not
 *
 * see {@link IBetSlipSelection} more info about the model
 *
 * @param control
 * @constructor
 */
export const BettingStatusValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (isPresent(Validators.required(control))) {
    return null;
  }

  const values: Array<IBetSlipSelection> = control.value;

  const invalid = values.some(s => s.marketSelection.bettingStatusId !== 1);

  return invalid ? {'bet-not-available': true} : null;
};
