import { BetType } from './bet-type';
import { Bonus } from './bonus';
import { Combination } from './combination';
import { Multiplier } from './multiplier';
import { MyMath } from './mymath';
import { Selection } from '../selection';
import { Winning } from './winning';

class TotalStake {
  constructor(public value: number = 0, public forced: boolean = false) {
  }
}

export class System extends BetType {
  constructor(public amount: number = 1,
              public winning: Winning = new Winning(),
              public multiplier: Multiplier = new Multiplier(),
              public combinations: Array<Combination> = [],
              public totalStake: TotalStake = new TotalStake()) {
    super();
  }

  static fromJSON(plainObject) {
    return Object.assign(new System(), plainObject);
  }

  calculateBonus(_percentages: any, _minimumRequiredMultiplier: number, _selections: Array<Selection>, bonus: Bonus): Bonus {
    const foundMin = this.combinations.find(c => c.enabled && c.bonus && c.bonus.min !== undefined);

    bonus.min = foundMin ? MyMath.round(foundMin.bonus.min, 4) : 0;

    bonus.max = MyMath.round(this.combinations.reduce((sum, c) => {
      return c.enabled ? sum + c.bonus.max : sum;
    }, 0), 4);

    return bonus;
  }

  calculateCombinations(selections: Array<Selection>, percentages: any, minimumRequiredMultiplier: number): void {

    const oldCombos = this.combinations.map((c) => {
      return {
        type: c.type,
        amount: c.amount,
        enabled: c.enabled
      };
    });

    this.combinations = [];

    for (let i = 1, l = selections.length; i <= l; i++) {

      const cs = MyMath.combinations(selections, i, minimumRequiredMultiplier, percentages);

      const newCombo = new Combination();
      newCombo.type = i;

      const oldAmount = oldCombos.find((c) => c.type === newCombo.type);
      newCombo.amount = oldAmount ? oldAmount.amount : this.amount;

      let done = false;
      const values = [];

      while (!done) {
        const currentCombo = cs.next();
        done = currentCombo.done;
        if (!done) {
          values.push(currentCombo.value);
          newCombo.combinations++;
        }
      }

      if (values.length) {
        values.sort((a, b) => a - b);

        newCombo.bonus.percentage = null;
        newCombo.values = values;
        newCombo.calculateBonus();
        newCombo.calculateWinning();

        this.combinations.push(newCombo);
      }
    }

    const oldCombosEnabled = oldCombos.reduce((a, c) => c.enabled ? a.concat(c.type) : a, []);

    if (this.hydrated || oldCombosEnabled) {
      this.combinations.forEach((c) => {
        if (oldCombosEnabled.indexOf(c.type) > -1) {
          c.enabled = true;
        }
      });
    }
    if (!oldCombosEnabled.length || (oldCombosEnabled.length === 1 && oldCombosEnabled.reduce((s, v) => s + v) > selections.length)) {
      this.combinations[this.combinations.length - 1].enabled = true;
    }
  }

  calculateTotal(): number {
    return this.amount * this.combinations.reduce((tot, c) => tot + c.combinations, 0);
  }

  getTotalForBetRequest(): number {
    return this.totalStake.value;
  }

  calculateWinning(_bonus: Bonus): Winning {
    if (!this.totalStake.forced) {
      this.totalStake.value = MyMath.round(this.combinations.reduce((sum, c) => {
        return c.enabled ? sum + (c.combinations * c.amount) : sum;
      }, 0), 6);
    }

    const foundMin = this.combinations.reduce((acc, c) => {
      if (c.enabled && c.winning && c.winning.min !== undefined && (acc === null || c.winning.min < acc.winning.min)) {
        return c;
      } else {
        return acc;
      }
    }, null);
    this.winning.min = foundMin ? MyMath.round(foundMin.winning.min, 4) : 0;

    this.winning.max = MyMath.round(this.combinations.reduce((sum, c) => {
      return c.enabled ? sum + c.winning.max : sum;
    }, 0), 4);

    return this.winning;
  }

  setTotalStake(amount: number, forced = false): void {
    this.totalStake.forced = forced;
    this.totalStake.value = amount;
  }
}
