import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';

/**
 * this pipe checks the controls if contains the tab value
 */
@Pipe({name: 'deliveryTabValues'})
export class DeliveryTabsValuesPipe implements PipeTransform {
  /**
   *
   * @param deliveries
   * @param tab
   */
  transform(deliveries: FormArray, tab: {key: number, value: string}): boolean {
    return !!deliveries.controls.find(d => tab.key === d.value.typeId);
  }
}
