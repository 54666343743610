@if (me) {
  <div>
    <div class="row m-1 gx-0">
      <div class="col-6">
        <a (click)="action('gf_tournaments')" class="btn-sidebar-smartico">
          <img src="/assets/images/smartico-sidebar/tournaments.png">
          <span>{{ 'tournaments' | translate }}</span>
        </a>
      </div>
      <!--    <div class="col-6">-->
      <!--      <a [href]="translate.currentLang+'/loyalty'" class="btn-sidebar-smartico">-->
      <!--        <img src="/assets/images/smartico-sidebar/loyalty.png">-->
      <!--        <span>{{ 'loyalty' | translate }}</span>-->
      <!--      </a>-->
      <!--    </div>-->
      <!--    <div class="col-6">-->
      <!--      <a [href]="translate.currentLang+'/cashback'" class="btn-sidebar-smartico">-->
      <!--        <img src="/assets/images/smartico-sidebar/cashback.png">-->
      <!--        <span>{{ 'cash-back' | translate }}</span>-->
      <!--      </a>-->
      <!--    </div>-->
      <!-- <div class="col-6">
        <a (click)="action('gf_saw')" class="btn-sidebar-smartico">
          <img src="/assets/images/smartico-sidebar/wheel.gif">
          <span>{{ 'wheels' | translate }}</span>
        </a>
      </div> -->
      <div class="col-6">
        <a (click)="action('gf_store')" class="btn-sidebar-smartico">
          <img src="/assets/images/smartico-sidebar/cashback.png">
          <span>{{ 'store' | translate }}</span>
        </a>
      </div>
      <!--    <div class="col-6">-->
      <!--      <a [href]="translate.currentLang+'/vip'" class="btn-sidebar-smartico">-->
      <!--        <img src="/assets/images/smartico-sidebar/vipclub.png">-->
      <!--        <span>{{ 'vip-club' | translate }}</span>-->
      <!--      </a>-->
      <!--    </div>-->
      <!--    <div class="col-12">-->
      <!--      <a [href]="translate.currentLang+'/promotions'" class="btn-sidebar-smartico">-->
      <!--        <img src="/assets/images/smartico-sidebar/promotions.png">-->
      <!--        <span>{{ 'promotions' | translate }}</span>-->
      <!--      </a>-->
      <!--    </div>-->
    </div>
  </div>
}
