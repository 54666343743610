import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { EnvironmentConfig } from '@bs/models';

/**
 * this guard is to redirect to the deposit url according to the route in the environment file
 */
@Injectable({
  providedIn: 'root'
})
export class DepositGuard  {

  /**
   * The constructor
   * @param config
   * @param router
   */
  constructor(
    private config: EnvironmentConfig,
    private router: Router,
  ) {
  }

  /**
   * interface guard deciding if a route can be activated according to the route set in the environment file.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const lang = route.params.lang || route.root.firstChild.params.lang;

    if (state.url.includes(this.config.depositPage)) {
      return true;
    } else {
      return this.router.createUrlTree([`${lang}/${this.config.depositPage}`]);
    }
  }

  /**
   * interface guard deciding if a child route can be activated
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
