import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '@bs/models';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class B3c1trkService {
  readonly urlRegistration = this.config.pixelTracking?.registration;

  constructor(private config: EnvironmentConfig) {
  }

  trackRegistration(networkId: number) {
    const request = new Request(`${this.urlRegistration}${networkId}`, {method: 'POST'});
    return fetch(request)
      .catch((error) => {
        return throwError(error);
      });
  }
}
