<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span>{{ 'zero-balance-title' | translate }}</span>
      <button (click)="dialogRef.close()" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>
    <div class="dialog-wrapper text-center m-4 p-1">
      <div class="title mb-3">
        <span>{{ 'zero-balance-desc' | translate }}</span>
      </div>
      <div class="my-4">
        <a mat-flat-button color="primary" (click)="toDepositPage()">{{ 'deposit' | translate }}</a>
      </div>
    </div>
  </mat-card>
</div>
