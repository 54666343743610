export function updateObject<T>(obj: T, partial: T): T {
  if (!partial) {
    return obj;
  }
  return Object.keys(partial).reduce((acc, key) => {
    const value = partial[key];
    if (Array.isArray(value)) {
      // @ts-ignore
      acc[key] = updateArray(obj[key], value);
    } else if (typeof value === 'object' && value !== null) {
      acc[key] = updateObject(obj[key], value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {...obj});
}

export function updateArray<T extends { id: number }>(arr: T[], partial: T): T[] {
  if (!Array.isArray(arr)) {
    return arr;
  }
  let updatedArray = [...arr];
  if (Array.isArray(partial)) {
    partial.forEach(p => updatedArray = updateArray(updatedArray, p));
  } else {
    const index = updatedArray.findIndex((item) => item.id === partial.id);
    if (index !== -1) {
      updatedArray[index] = updateObject(updatedArray[index], partial);
    }
  }
  return updatedArray;
}
