import { Injectable } from '@angular/core';
import { LocalStorage } from '@bs/universal';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class TournamentFavoritesService {
  private STORAGE_KEY = 'SportsbookFavorites';
  private _favourites = new BehaviorSubject<string[]>([]);

  favourites$ = this._favourites.asObservable().pipe(filter(x => !!x));

  constructor(private localStorage: LocalStorage) {
    this.restoreFavourites();
  }

  toggle(newFav) {
    const favourites = this._favourites.getValue();
    const isFavourite = favourites.find(fav => fav.includes(newFav));

    if (isFavourite) {
      this.remove(newFav);
    } else {
      this.setFavourites(favourites.concat(newFav));
    }
  }

  remove(favourite) {
    const favourites = this._favourites.getValue();
    this.setFavourites(favourites.filter(fav => !fav.includes(favourite)));
  }

  private restoreFavourites() {
    const stored: any = JSON.parse(this.localStorage.getItem(this.STORAGE_KEY)) || [];
    this.setFavourites(stored);
  }

  private setFavourites(favourites) {
    this._favourites.next(favourites);
    this.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(favourites));
  }
}
