export interface ICountry {
  id?: number;
  code: string;
  name: string;
}

export interface IRegion {
  id: number;
  countryId: number;
  name: string;
}

export interface IProvince {
  id: number;
  regionId: number;
  name: string;
}

export interface ICity {
  id: number;
  provinceId: number;
  name: string;
}
