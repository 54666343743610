import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { restrictType } from '@bs/models';

@Component({
  selector: 'common-search',
  templateUrl: './common-search.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommonSearchComponent),
      multi: true
    }
  ]
})
export class CommonSearchComponent implements ControlValueAccessor {

  @Input()
  restrict: Array<restrictType>;

  @Input()
  placeholder = 'search';

  isDisabled: boolean;
  model: string;

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(model: string): void {
    if (model !== null) {
      this.model = model;
    }
  }

  update(_model: string) {
    this.onTouched();
    this.propagateChange(_model);
  }

  reset() {
    this.model = null;
    this.propagateChange(this.model);
  }

  propagateChange(_model: any) {
  }

  private onTouched() {
  }
}
