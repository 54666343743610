import { Component, Input } from '@angular/core';

import { IBaseGame } from '@bs/models';

@Component({
  selector: 'games-list',
  templateUrl: 'games-list.component.html',
  styleUrls: ['games-list.component.scss']
})
export class GamesListComponent {
  @Input()
  activeGameId: number;

  @Input()
  games: IBaseGame[];

  @Input()
  isMobile: boolean;

}
