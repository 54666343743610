import * as homerAgentsTable from './accounts/agents.json';
import * as homerCashiersTable from './accounts/cashiers.json';
import * as homerMovementsTable from './accounts/movements.json';
import * as networkTransfersTable from './accounts/networkTrasfers.json';
import * as homerPlayersTable from './accounts/players.json';
import * as homerPlayersNoSensitiveDataTable from './accounts/playersNoSensitiveData.json';
import * as referralLinksTable from './accounts/referralLinks.json';
import * as shopPayoutRequest from './accounts/shopPayoutRequest.json';
import * as homerShopsTable from './accounts/shops.json';
import * as referralLinksSearchTable from './accounts/shops.json';
import * as homerWithdrawalsTable from './accounts/withdrawals.json';
import * as homerTicketsTable from './sportsbook/tickets.json';
import * as homerTicketsRetailTable from './sportsbook/ticketsRetail.json';
import * as homerTicketsShopTable from './sportsbook/ticketsShop.json';
import * as homerDrawerMovemetnsTable from './wallets/drawer-movements.json';

export {
  referralLinksSearchTable,
  referralLinksTable,
  homerShopsTable,
  homerCashiersTable,
  homerPlayersTable,
  homerPlayersNoSensitiveDataTable,
  homerTicketsTable,
  homerTicketsShopTable,
  homerTicketsRetailTable,
  homerAgentsTable,
  homerMovementsTable,
  networkTransfersTable,
  shopPayoutRequest,
  homerDrawerMovemetnsTable,
  homerWithdrawalsTable
}
