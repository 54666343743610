import { AfterViewInit, Component, ContentChild, HostBinding, Input, OnDestroy } from '@angular/core';
import { AppSettings, IMe, IMenuItem } from '@bs/models';
import { AppSettingsService, AuthService, CmsService } from '@bs/services';
import { Subscription } from 'rxjs';


@Component({
  selector: 'bottom-bar',
  templateUrl: 'bottom-bar.component.html',
  styles: [`
    mobile-chat-btn::ng-deep button {
      padding: 0;
    }
  `]
})
export class BottomBarComponent implements AfterViewInit, OnDestroy {

  @HostBinding('class.column')
  @HostBinding('class.gx-0')
  column = false;

  @Input()
  menuId: number;

  @Input()
  menuLoggedId: number;

  @ContentChild('betslipToggle')
  children: any;

  me: IMe;

  settings: AppSettings;

  menu: IMenuItem;
  items: Array<IMenuItem | any>;
  half = 0;

  subs = new Subscription();

  constructor(appSettingsService: AppSettingsService, private authService: AuthService, private cmsMenuService: CmsService) {
    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    }));
  }

  ngAfterViewInit(): void {
    this.subs.add(this.authService.accountLogged$.subscribe({
      next: me => {
        this.me = me;
        if (this.me && this.menuLoggedId) {
          this.subs.add(this.cmsMenuService.getMenu(this.menuLoggedId, 'bottom').subscribe({
            next: menu => this.setItems(menu)
          }));
        } else {
          this.subs.add(this.cmsMenuService.getMenu(this.menuId, 'bottom').subscribe({
            next: menu => this.setItems(menu)
          }));
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private setItems(menu: IMenuItem) {
    this.items = menu.items || [];
    this.half = Math.floor(this.items.length / 2);
    if (this.children && this.items.length) {
      this.items.splice(this.half, 0, this.children);
    }

    this.column = this.items.length > 3;
  }
}
