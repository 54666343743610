import { AfterViewInit, Component, EventEmitter, Inject } from '@angular/core';
import { INetworkTransfer } from '@bs/models';
import { WINDOW } from '@bs/universal';

@Component({
  selector: 'transfer-receipt',
  templateUrl: 'transfer-receipt.component.html',
  styleUrls: ['transfer-receipt.component.scss']
})
export class TransferReceiptComponent implements AfterViewInit {

  loaded: EventEmitter<void> = new EventEmitter<void>();
  transfer: INetworkTransfer;
  host: string;

  constructor(@Inject(WINDOW) window: Window) {
    this.host = window.location.origin;
  }

  ngAfterViewInit(): void {
    this.loaded.emit();
  }
}
