import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AppSettings, EnvironmentConfig, IMe } from '@bs/models';
import { WINDOW } from '@bs/universal';
import { BehaviorSubject } from 'rxjs';

import { AppSettingsService } from './app-settings.service';
import { AuthService } from './auth.service';

@Injectable()
export class ZendeskService {
  anonymous = 'anonymous';
  loaded = false;
  settings: AppSettings;
  private _livechat = new BehaviorSubject<any>(null);
  livechat$ = this._livechat.asObservable();

  constructor(private config: EnvironmentConfig, @Inject(WINDOW) private window: any, @Inject(DOCUMENT) private document: any, private authService: AuthService, appSettingsService: AppSettingsService) {

    appSettingsService.appSettings$.subscribe({
      next: ({settings}) => {
        this.settings = settings;
        if (settings.languageCode && this.loaded && this.window.zE && this.window.zE.setLocale) {
          // this.window.zE.setLocale(settings.languageCode);
          this.window.zE('messenger:set', 'locale', settings.languageCode)
        }
      }
    });

    this.authService.accountLogged$.subscribe(
      {
        next: player => {
          if (this.loaded) {
            this.identify(player);
          }
        }
      }
    );
  }

  triggerClick() {
    this.window.zE('messenger', 'open', function () {
      console.log(`You have opened the Web Widget`);
    })
  }

  identify(account: IMe | null) {
    // this.window.zE('messenger', 'identify', {
    //   name: account ? account.username : null,
    //   email: account ? account.deliveries[0].delivery : null,
    //   organization: this.config.project
    // })
  }
}
