import { Component, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslationModule } from '@bs/common';
import { ValidationService } from '@bs/forms';
import { AuthService } from '@bs/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'section.email-confirmation',
  templateUrl: 'email-confirmation.page.html',
  standalone: true,
  imports: [MatButtonModule, TranslationModule, MatCardModule, RouterModule]
})
export class EmailConfirmationPage implements OnDestroy {

  result: { success: boolean, message: string };

  subs = new Subscription();

  constructor(route: ActivatedRoute, validationService: ValidationService, private router: Router, authService: AuthService) {
    this.subs.add(authService.accountLogged$.subscribe({
      next: me => {
        if (me) {
          setTimeout(() => void router.navigateByUrl(''));
        }
      }
    }));

    this.subs.add(route.queryParamMap.subscribe({
      next: paramsMap => {
        if (paramsMap.has('t')) {
          validationService.checkEmailActivation(paramsMap.get('t')).then(
            () => this.result = {success: true, message: 'emailConfirmationSuccess'},
            error => this.result = {success: false, message: error.message}
          ); // .finally(() => router.navigate([], {queryParams: null}));
        } else {
          this.result = {success: false, message: 'missingToken'};
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
