import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'claim-promotion',
  styleUrls: ['claim-promotion.dialog.scss'],
  templateUrl: 'claim-promotion.dialog.html'
})
export class ClaimPromotionDialog {

  constructor(public dialogRef: MatDialogRef<ClaimPromotionDialog>, @Inject(MAT_DIALOG_DATA) public data) {
  }

  /**
   * @description it closes the dialog sending the action
   * @param {string} action
   */
  close(action?: string) {
    this.dialogRef.close({action});
  }
}
