import { Component, Input, OnInit } from '@angular/core';
import { IMe } from '@bs/models';
import { WindowService } from '@bs/universal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wallets-viewer',
  templateUrl: './wallets-viewer.component.html',
})
export class WalletsViewerComponent implements OnInit {
  @Input() me: IMe;

  isMobile: boolean;
  subs = new Subscription();
  totalBalance = 0;

  constructor(windowService: WindowService) {
    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));
  }

  ngOnInit(): void {
    this.calculateTotalBalance()
  }

  private calculateTotalBalance() {
    this.totalBalance = this.me.wallets[0].balance.available;
    this.me.wallets[0].balance.bonuses?.forEach(x => this.totalBalance = x.available + this.totalBalance)
  }
}
