import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ILanguageEntityLabel } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityLabelLanguageService {
  readonly url = `${this.config.api.sportsbook}/entity-labels`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  update(data: ILanguageEntityLabel): Observable<ILanguageEntityLabel> {
    return this.http.put<ILanguageEntityLabel>(`${this.url}/${data.entityLabelTypeId}/${data.entityLabelId}/languages`, data);
  }

}
