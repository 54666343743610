import { ReportData } from '../accounts/reports';

export class NetworkSportsBookReport extends ReportData<NetworkSportsBookReport> {
  count: number;
  stake: number;
  stakeTax: number;
  void: number;
  refunded: number;
  win: number;
  winTax: number;

  initialize(x) {
    // const zeroedValues = {
    //   count: 0,
    //   stake: 0,
    //   stakeTax: 0,
    //   void: 0,
    //   refunded: 0,
    //   win: 0,
    //   winTax: 0,
    // };

    x.net = {
      count: x.count,
      stake: x.stake,
      stakeTax: x.stakeTax,
      void: x.void,
      refunded: x.refunded,
      win: x.win,
      winTax: x.winTax,
    }

    x.line = {
      count: x.count,
      stake: x.stake,
      stakeTax: x.stakeTax,
      void: x.void,
      refunded: x.refunded,
      win: x.win,
      winTax: x.winTax,
    }
  }
}
