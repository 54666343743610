import { Component } from '@angular/core';

@Component({
  selector: 'categories-menu',
  templateUrl: 'categories-menu.component.html'
})
export class CategoriesMenuComponent {
  // @Input()
  // categories: IGameCategory[];
  // @Input()
  // showBadges: number[];
  // @Input()
  // selectedId: number;
  // @Output()
  // categorySelected: EventEmitter<IGameCategory> = new EventEmitter<IGameCategory>();
  // @Input()
  // isMobile: boolean;
  //
  // selectCategory(category: IGameCategory) {
  //   this.categorySelected.emit(category);
  // }
}
