import { ReportingParameter } from './reporting-parameters';

interface IGroup {
  id: number;
  name: string;
}

export class IReport {
  id: number;
  name: string;
  description: string;
  link: string;
  group: IGroup;
  parameters: ReportingParameter[];
}
