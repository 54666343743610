<!--<pre>{{items | json}}</pre>-->
<!--<pre>{{bonuses | json}}</pre>-->

<ng-template #noBonusesTpl>
  <div class="d-flex flex-column py-5 align-items-center">
    <svg icon="bonuses" lib="global" [size]="{w:60, h:60}" class="no-bonus-icon mb-4"></svg>
    <div class="no-bonus-text">{{ 'noBonusText' | translate }}</div>
  </div>
</ng-template>

<ng-template #itemTpl let-bonusItems>
  @for (v of bonusItems | slice: 0; track v) {
    <div class="row mat-card-row py-4 align-items-center mx-0">
      <div class="col-12 col-md-3 d-flex flex-column mb-2 mb-md-0">
        <div class="mb-1"><span class="card-item-title ">{{ 'startDate' | translate }}:</span> {{ v.startDate | localizedDate }}</div>
        <div class="mb-1"><span class="card-item-title ">{{ 'date-of-ended' | translate }}:</span> {{ v.endDate | localizedDate }}</div>
        <div><span class="card-item-title">{{ 'status' | translate }}:</span> {{ v.statusId | formValues: {values: 'bonusStatus'} }}</div>
      </div>
      <div class="col-11 col-md-4 mx-auto mr-md-5 align-items-center mb-2 mb-md-0 ">
        <div class="row ">
          <div class="col p-2 card-item-box d-flex flex-column align-items-center  me-1 ">
            <span class="card-item-title mb-1">{{ 'bonus' | translate }}</span>
            <span>{{ v.amount.amount | displayCurrencyConversion: v.amount.amount.rate }}</span>
          </div>
          <div class="col p-2 card-item-box d-flex flex-column align-items-center me-1">
            <span class="card-item-title mb-1">{{ 'deposit' | translate }}</span>
            <span>{{ v.depositBalance.amount | displayCurrencyConversion: v.depositBalance.amount.rate }}</span>
          </div>
          <div class="col p-2 card-item-box d-flex flex-column align-items-center ">
            <span class="card-item-title mb-1">{{ 'available' | translate }}</span>
            <span>{{ v.available.amount | displayCurrencyConversion: v.available.amount.rate }}</span>
          </div>
        </div>
      </div>
      @if (hasWagering(v)) {
        <div class="col-12 col-md-4">
          <div class="card-item-title text-uppercase px-md-2">
            <span class="progress-title">{{ 'wagering-in-progress' | translate }}</span>
          </div>
          <div class="progress-bar-container py-2 p-md-2">
            <div class="d-flex justify-content-between mb-2">
              <span>{{ v.wagering?.amountPlayed?.amount | displayCurrencyConversion: v.wagering?.amountWagering?.rate }}</span><span>{{ v.wagering?.amountWagering?.amount | displayCurrencyConversion: v.wagering?.amountWagering?.rate }}</span></div>
            <mat-progress-bar mode="determinate" [value]="(v.wagering?.amountPlayed?.amount / v.wagering?.amountWagering?.amount) * 100"></mat-progress-bar>
          </div>
        </div>
      }
    </div>
  }
</ng-template>

<!--<mat-toolbar>-->
<!--  <mat-button-toggle-group [value]="gameTypes" (change)="setType($event)">-->
<!--    &lt;!&ndash;      <mat-button-toggle checked [value]=null>all</mat-button-toggle>&ndash;&gt;-->
<!--    <mat-button-toggle *ngFor="let gt of gameTypes" [value]=gt.id>{{gt.name}}</mat-button-toggle>-->
<!--  </mat-button-toggle-group>-->
<!--</mat-toolbar>-->

@for (walletBonus of items | filter: validModel; track walletBonus) {
  @if (bonuses | filter:{modelId: walletBonus.bonusModelId}; as bonusesFiltered) {
    <div class="mb-2">
      <mat-card class="mat-card mat-focus-indicator new-card flat-card mx-0">
        <mat-card-title class="mat-card-title box-header sport-card d-block">
          <div matbadgeoverlap="false" class="box-title">
            <svg icon="gift-outline" lib="global" [size]="{w:14, h:14}" class="me-2"></svg>
            <span translate>{{ walletBonus.bonusModelId | formValues: {values: 'bonusModels'} }}</span>
            <span class="available ms-auto "><span class="me-2">{{ 'available' | translate }}</span>{{ walletBonus.available | moneyFormat:me?.currencies[0]: true }}</span>
          </div>
        </mat-card-title>
        <div class="mat-card-content">
          <mat-tab-group class="mat-tab-group" animationDuration="0ms">
            <mat-tab [label]="'running' | translate" class="px-1">
              @if (bonusesFiltered | filter:isRunning; as bonusRunning) {
                <ng-template [ngTemplateOutlet]="bonusRunning.length? itemTpl : noBonusesTpl" [ngTemplateOutletContext]="{$implicit: bonusRunning}"></ng-template>
              }
            </mat-tab>
            <mat-tab [label]="'archived' | translate">
              @if (bonusesFiltered | filter:isArchived; as bonusArchived) {
                <ng-template [ngTemplateOutlet]="bonusArchived.length? itemTpl : noBonusesTpl" [ngTemplateOutletContext]="{$implicit: bonusArchived}"></ng-template>
              }
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-card>
    </div>
  }
}
