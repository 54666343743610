import { Component, ElementRef, Input, QueryList } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, Validators } from '@angular/forms';

/**
 *  \    / /\  |_) |\ |  |  |\ | /__
 *   \/\/ /--\ | \ | \| _|_ | \| \_|
 *
 *  DUE TO THE IMPACT THIS FILE HAS ON THE PLATFORM DO NOT CHANGE ANYTHING AND REFER TO YOUR TEAM LEADER
 * */
@Component({template: ``})
export class BasePinInput implements ControlValueAccessor {
  /**
   * how many input digit fields do we generate. By default, we create 4 input fields
   */
  @Input()
  digits = 4;
  /**
   * label of the digit cva
   */
  @Input()
  label: string;
  /**
   * responsible for disabling a field
   */
  isDisabled: boolean;
  /**
   * local reference of FormArray
   */
  form: FormArray;
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: Array<number>;
  /**
   * local reference of Set object
   */
  indexSet = new Set();
  /**
   * generated digit inputs
   */
  digitsComponents: QueryList<ElementRef>;

  /**
   * The constructor
   * @param fb
   */
  constructor(private fb: FormBuilder) {
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * sets model value, and creates the form group, when programmatic changes from model to view are requested
   * @param obj
   */
  writeValue(obj: string): void {
    if (obj && obj !== '') {
      this.model = obj.toString().split('').map(x => +x);
    } else {
      this.model = new Array(this.digits);
    }
    this.form = this.buildForm(this.model);
  }

  /**
   * when we change input value, we update the model value, and propagate the changes
   * @param val
   * @param idx
   */
  update(val: number, idx: number) {
    this.model[idx] = val;
    const modelValue = this.model.join('');
    if (val != null) {
      this.setFocus(idx + 1);
    }
    // this.form.setErrors(modelValue.length < this.digits ? {'pin-error': true} : null);
    this.propagateChange(modelValue);
  }

  /**
   * sets the focus to the next input field not containing value
   * @param nextIndex
   */
  setFocus(nextIndex: number) {
    const elms = this.digitsComponents.toArray();
    if (nextIndex < elms.length && nextIndex >= 0) {
      elms[nextIndex].nativeElement.focus();
    }
  }

  /**
   * when we click one of the fields, we empty that field value
   * @param idx
   */
  onMouseDown(idx: number) {
    this.form.at(idx).setValue(null);
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   */
  touched() {
  }

  /**
   * @ignore
   * @param $event
   */
  block($event: KeyboardEvent) {
    return $event.key === 'Backspace' || /\d/.test($event.key) && this.model.join('').length < this.digits;
  }

  /**
   * gets reference of all the digit input fields, when view created, called in ngAfterViewInit lifecycle
   * @param digitsComponents
   */
  afterViewInit(digitsComponents: QueryList<ElementRef>) {
    this.digitsComponents = digitsComponents;
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: string) {
  }

  /**
   * builds the form with the form array inputs
   * @param model
   * @private
   */
  private buildForm(model: Array<number>): FormArray {
    const arr = [];
    for (const i of model) {
      arr.unshift([model[i], Validators.required]);
    }
    return this.fb.array(arr);
  }
}
