import { IdName } from './id-name';

export interface Values {
  [key: string]: Array<IdName<any>>;
}

export type InputType = 'email' | 'number' | 'text' | 'textarea' | 'select' | 'password' | 'radio' | 'checkbox' | 'date' | 'calendar' | 'phone' | 'pin' | 'geo-infos' | 'date-range' | 'date-time' | 'mobile-prefix' | 'hidden' | 'birth-place' | 'file' | 'bookmakers' |
  'displaycurrency' | 'fixture-selector' | 'promotion-bonus' | 'language-editor' | 'multi-select' | 'bookmakers-brands-categories' | 'causal-picklist' | 'currencies-amount' | 'webapps' | 'weekly-slots' | 'p-editor' | 'select-button' | 'date-selector';

export type restrictType = 'integer' | 'float' | 'kenyaPhone' | 'words' | 'letters' | 'lowerLetters' | 'upperLetters' | 'lettersAndDigits' | 'alphaNum' | 'phone' | 'leadingZero' | 'noSpaces' | 'username' | string;

export type formValueEnum =
  'networkStatus'
  | 'bettingTypes'
  | 'bettingGroups'
  | 'ticketStatus'
  | 'ticketTypes'
  | 'ticketChannelTypes'
  | 'walletTypes'
  | 'webapps'
  | 'deviceTypes'
  | 'genderTypes'
  | 'networkTypes'
  | 'gameTypes'
  | 'gameDeviceTypes'
  | 'documentTypes'
  | 'referralLinks'
  | 'resultStatus'
  | 'mappingStatus'
  | 'settlementTypes'
  | 'noteTypes'
  | 'settlementTimeTypes'
  | 'settingTemplatesTypes'
  | 'participationRequirements'
  | 'participationRequirementsGroups'
  | 'promoStatus'
  | 'promoEntryTypes'
  | 'accountType'
  | 'withdrawalStates'
  | 'withdrawalChangeStates';


export interface IInput {
  name?: string;
  type?: InputType | string;
  label?: string;
  validations?: Partial<IValidationTypes>;
  asyncValidator?: 'phoneExists' | 'usernameExists' | 'emailExists' | 'cpfValidator';

  icon?: string;
  // externalLinkText?: string;
  readOnly?: boolean;
  disabled?: boolean;
  disableOnComplete?: boolean;
  //values?: Array<IdName<any>> | formValueEnum | string;
  values?: any;
  onChange?: (input: IInput) => void;
  prefixName?: string;
  // value?: any;
  // exclude?: boolean;
  template?;
  // externalLink?: string;
  translateValues?: boolean;
  placeholder?: string;
  autoselect?: boolean | any; // for the select first value
  hasStrength?: boolean;
  autocomplete?: boolean | string;
  rowspan?: 1 | 2 | 3 | 4 | 6 | number;
  noReset?: boolean;
  exclusive?: boolean;
  url?: string;
  mode?: string;
  filter?: string;
  restrict?: Array<restrictType>;
  empty?: boolean;
  options?: any;
  permission?: number;
  floating?: boolean;
  appendTo?: string;
}

/*interface IOptions {
  [key: string]: any;
}*/

export interface FormInterface {
  $schema?: string;
  mode?: string;
  hideReset?: boolean;
  inputs?: Array<IInput>;
  fieldset?: Array<FieldSet>;
}

interface BaseFieldSet {
  inputs: Array<IInput>;
  name?: string;
  label?: string;
  icon?: string;
  optional?: boolean;
}

interface FieldSet extends BaseFieldSet {
  legend?: string;
  toggleable?: boolean;
  collapsed?: boolean;
}

export interface IValidationTypes {
  requiredTrue: boolean;
  required: boolean;
  minLength: number;
  maxLength: number;
  min: number;
  max: number;
  pattern: string; // regex
  matchPassword: string | boolean;
  password: string; // regex
  adult: number;
  japanese: boolean;
  mailFormat: boolean;
  urlFormat: boolean;
  mobilePrefixValidator: string;
  geoInfos: boolean;
  birthPlace: boolean;
  delivery: string;
  currenciesAmount: boolean;
}

export interface IFormStep extends BaseFieldSet {
  validationType?: 'checkMobileOtp' | 'requestMobileOtp';
}
