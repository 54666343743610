import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AccountTypeId, IMe } from '@bs/models';
import { Observable } from 'rxjs';
import { AuthService } from '../services/core/auth.service';

/**
 * this guard checks if user is authorized to enter the games routes
 */
@Injectable({
  providedIn: 'root'
})
export class GamesGuard  {
  /**
   * me variable
   */
  me: IMe;

  /**
   * The constructor sets the logged user information
   * @param authService
   * @param router
   */
  constructor(authService: AuthService, private router: Router) {
    authService.accountLogged$.subscribe({
      next: account => this.me = account
    });
  }

  /**
   * interface guard deciding if a child route can be activated.
   */
  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._validate(route);
  }

  /**
   * interface guard deciding if a route can be activated
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._validate(route);
  }

  /**
   * function that checks if user has authorization for entering games page
   */
  _validate(route: ActivatedRouteSnapshot) {
    if (!this.me) {
      return true;
    }
    // shops can access virtuals as per request
    if(this.me.typeId === AccountTypeId.Shop && route.params.section === 'virtuals'){
      return true;
    }

    if(route.data.prohibitions.includes(this.me.typeId)){
      return this.router.navigate([route.root.firstChild.params.lang + '/not-authorized']);
    }
    return true;
  }
}
