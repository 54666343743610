import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { BaseGeoInfos } from '@bs/forms';
import { GeoInfoType, IAccountMePermissions, ICountry } from '@bs/models';

/**
 * Component renders 2 templates, one with the saved geographic information of user, other with the input fields to fill in the user information
 *
 * @description contains fields: city, country, region, province, address, zip code
 */
@Component({
  selector: 'geo-infos',
  templateUrl: './geo-infos.component.html',
  styleUrls: ['./geo-infos.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeoInfosComponent),
      multi: true
    }
  ]
})
export class GeoInfosComponent extends BaseGeoInfos implements OnDestroy, OnInit {
  /**
   * if true, we create a geo info form
   */
  @Input()
  isRegistration = false;
  /**
   * users permission reference
   */
  permission = IAccountMePermissions;

  /**
   * when dropdown is opened we set the focus on the search input field
   * @param opened
   * @param input
   */
  dropdownOpened(opened: boolean, input: HTMLInputElement): void {
    if (opened) {
      input.focus();
    }
  }

  /**
   * sets the geoinfos types tabs, if is registration we generate the geoinfos form in runtime
   *
   * see {@link GeoInfoType} for more info of typekeys values
   */
  ngOnInit() {
    this.typeKeys = this.typeKeys || this.types.values().filter(g => g.key !== GeoInfoType.Headquarters);

    if (this.isRegistration) {
      setTimeout(() => this.addGeoInfo(GeoInfoType.Residence), 0);
    }
  }

  searchFn = (items: ICountry, search: string) => {
    if (!search) {
      return items;
    }
    return items.name.toLowerCase().includes(search);
  };

}
