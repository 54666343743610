import { Component, Input, OnInit } from '@angular/core';
import { SmarticoService } from '@bs/services';
import { SmarticoProps } from '@bs/universal';

@Component({
  selector: 'smartico-bar',
  templateUrl: './smartico-bar.component.html',
  styleUrls: ['./smartico-bar.component.scss']
})

export class SmarticoBarComponent implements OnInit {

  currentProps: SmarticoProps = {};

  @Input()
  iconSize = 22;

  constructor(private smartico: SmarticoService) {
    this.smartico.isReady$.subscribe((smartico) => {
      smartico.on('props_change', (change: SmarticoProps) => {
        Object.assign(this.currentProps, change);
      });
    })
  }

  ngOnInit() {
  }
}
