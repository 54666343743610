import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'unique'})
export class UniquePipe implements PipeTransform {
  transform<T>(values: Array<T>, key: string): Array<T> {
    if (!values) {
      return;
    }

    if (!key) {
      return values;
    }

    return values.reduce((acc, i) => {
      if (!acc.some(p => (p[key] === i[key]))) {
        acc.push(i);
      }
      return acc;
    }, []);

  }

}
