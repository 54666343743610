import { ICategory } from './category';
import { ISearchBaseSportsBookFilters } from './common';
import { DictionarySuperGroup } from './dictionary';
import { IFixture } from './fixture';
import { IMarketSport } from './market-sport';

export interface ISport extends IFixture {
  categories?: Array<ICategory>;
  marketSports?: Array<IMarketSport>;
  sgs?: Array<DictionarySuperGroup>;
}

export interface ISportSearchFilters extends ISearchBaseSportsBookFilters {
  betOfferId: number;
}
