import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

/**
 * guard checks if :lang is provided in the route, if not navigates to 404 page
 */
@Injectable({
  providedIn: 'root'
})
export class LangGuard  {

  /**
   * The constructor
   */
  constructor(private router: Router, private translate: TranslateService) {
  }

  /**
   * interface guard deciding if a route can be activated, this is main guard if lang doesn't exist won't load any route ex: /en/home
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    const ican = route.data.langs.some(l => l.code === route.params.lang);

    const lang = this.translate.currentLang || 'en';

    return ican || this.router.navigate([lang, '404']);
  }

  /**
   * interface guard deciding if a child route can be activated
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    return this.canActivate(childRoute);
  }
}
