import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IWalletBonus } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeBonusService {
  readonly url = `${this.config.api.identity}/me/bonuses`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  getAll(): Observable<Array<IWalletBonus>> {
    return this.http.get<Array<IWalletBonus>>(`${this.url}`)
  }

}
