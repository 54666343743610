@if (faq.length >= 1) {
  <h3 class="mb-3">{{'faq' | translate}}</h3>
  @for (item of faq; track item) {
    <mat-expansion-panel class="mb-2">
      <mat-expansion-panel-header>
        <h4>{{ item.question }}</h4>
      </mat-expansion-panel-header>
      <div [innerHtml]="item.answer"></div>
    </mat-expansion-panel>
  }
}
