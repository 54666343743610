import { IWithdrawalRequest, IWithdrawalResponse } from '@bs/models';

import { AuthService, IdentityWalletService } from '@bs/services';
import { finalize } from 'rxjs/operators';

export class WithdrawalBaseDialog {
  loading: boolean;
  response: IWithdrawalResponse;
  public error: { message: string; details: string };

  constructor(public identityWalletService: IdentityWalletService, public authService: AuthService) {
  }

  createMovement(request: IWithdrawalRequest) {
    this.loading = true;
    this.identityWalletService.withdrawal(request)
      .pipe(finalize(() => {
        this.identityWalletService.wallets();//.then(wallets => this.authService.refreshMe({wallets}));
        this.loading = false;
      }))
      .subscribe({
      next: response => this.response = response,
      error: error => this.error = error
    })
    ;
  }

}
