import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseField } from '@bs/forms';

/**
 * component act as a parent cva for all the cva, so we can switch the template field by input type passed
 */
@Component({
  selector: 'field[input], field[formControlName][type], field[ngModel]',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldComponent),
      multi: true
    }
  ]
})
export class FieldComponent extends BaseField implements OnInit {
  /**
   * the years range of birthDate
   */
  yearRange: string;
  /**
   * the birthDate field has range
   */
  hasRange: boolean;
  /**
   * toggles the type and icon of password field
   */
  toggle: boolean;


  /**
   * execute the constructor of the base class, and sets years range on birthDate field
   */
  ngOnInit(): void {
    super._ngOnInit();

    if (this.input.name === 'birthDate') {
      this.hasRange = true;
      const year = new Date().getFullYear();
      this.yearRange = `${year - 99}:${year}`;
    }

  }

}
