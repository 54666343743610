import { Pipe, PipeTransform } from '@angular/core';


interface TimeRange {
  from: string;
  to: string;
}

interface TimingSlot {
  day: number;
  timeSlots: Array<TimeRange>
}

interface Timing {
  range: TimeRange;
  slots?: Array<TimingSlot>;
}

@Pipe({
  name: 'timing',
  pure: false,
  standalone: true
})

export class TimingPipe<T extends { timing: Timing }> implements PipeTransform {

  private static normalize(day: number): number {
    const days = [7, 1, 2, 3, 4, 5, 6];
    return days[day];
  }

  transform(values: Array<T>): Array<T> {
    const now = new Date();
    if (!values) {
      return [];
    }
    return values.filter(v => {
      if (v.timing) {
        // check timing
        const fromDate = new Date(v.timing.range.from);
        const toDate = new Date(v.timing.range.to);

        if (now > fromDate && now < toDate) {
          // check day of the week
          if (v.timing.slots?.length) {
            const slotStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const slotEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate());

            v.timing.slots.forEach(s => {
              const theDay = now.getDate() + (s.day + 7 - now.getDay()) % 7;

              if (now.getDay() === TimingPipe.normalize(s.day)) {

                // check all timing slot in that day
                if (s.timeSlots?.length) {
                  s.timeSlots.forEach(ts => {

                    const [hours, minutes] = ts.from.split(':');

                    slotStart.setHours(+hours);
                    slotStart.setMinutes(+minutes);
                    slotStart.setDate(theDay);

                    const [hoursEnd, minutesEnd] = ts.to.split(':');

                    slotEnd.setHours(+hoursEnd);
                    slotEnd.setMinutes(+minutesEnd);
                    slotEnd.setDate(theDay);
                    slotEnd.setSeconds(59);

                    // check time of each slot
                    return (now >= slotStart && now <= slotEnd);
                  })
                } else {
                  // day correspond and there are no time slot
                  return true;
                }

              } else {
                // day in time slot do not correspond
                return false;
              }
            })
          } else {
            // banner is in bound and there are no day slots
            return true;
          }
        } else {
          // banner is out of bound dates
          return false;

        }

      } else {
        // banner has no timing
        return true;
      }
    })
  }
}
