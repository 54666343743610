import { ResolveFn } from '@angular/router';
import { IBetOffer, IBetOfferInProgressRequest } from '@bs/models';
import { inject } from '@angular/core';
import { SportsbookService } from '../services/sportsbook.service';

/**
 * Resolver gets the sports daily matches
 */
export const LiveMatchesResolver: ResolveFn<IBetOffer> = () => {
  /**
   * the resolve function retrieves us the data needed to activate for the requested route
   * @param route
   */
  const filters: IBetOfferInProgressRequest = {
    offerTypeId: '2',
  };

  return inject(SportsbookService).get(filters);

}
