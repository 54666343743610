import { Inject, Injectable } from '@angular/core';
import { EnvironmentConfig, IMe } from '@bs/models';
import { Smartico, WINDOW } from '@bs/universal';
import { AuthService } from '@bs/services';
import { Params } from '@angular/router';
import { EnvConfig } from '@swc/core';
import { Subject } from 'rxjs';

@Injectable()
export class SmarticoService {

  isReady$: Subject<Smartico> = new Subject<Smartico>();

  constructor(private authService: AuthService, @Inject(WINDOW) private window: Window) {

  }

  showWidget(name: string, iframe: string) {
    this.window._smartico.showWidget(name, { iframe:iframe, height: 'auto'})
  }

  dp(name: string, params?: Params) {
    const paramsString = this.objectToQueryParams(params);
    if (paramsString) {
      this.window._smartico.dp(`dp:${name}&${paramsString}`);
    } else {
      this.window._smartico.dp(`dp:${name}`);
    }
  }

  get reference(): Smartico {
    return this.window._smartico;
  }

  private objectToQueryParams = (obj = {}) => {
    return Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
  };

  init() {
    console.log('smartico-start')

    this.reference.on('init', (errorCode) => {
      this.authService.accountLogged$.subscribe({
        next: (me: IMe) => {
          if (me) {
            this.window._smartico_user_id = `${me.id}`;
            this.window._smartico.setNickname(me.username);
          } else {
            //console.log('ciao')
            this.window._smartico_user_id = null;
            this.window._smartico.setNickname(null);
          }
        }
      });

      if (!errorCode) {
        this.isReady$.next(this.reference);
      }
    })
  }
}
