<mat-form-field>
  <mat-label>
    <span [translate]="'oddsFormat'">Odds format</span>
  </mat-label>
  <mat-select disableOptionCentering [value]="oddFormat" (valueChange)="changeFormat($event)">
    @if (!isMobile) {
      <div class="scrollbar max-dropdown-h">
        <ng-container *ngTemplateOutlet="options"></ng-container>
      </div>
    }
    @if (isMobile) {
      <ng-container *ngTemplateOutlet="options"></ng-container>
    }
    <ng-template #options>
      @for (format of formats; track format) {
        <mat-option [value]="format.name">
          {{ format.name }}
        </mat-option>
      }
    </ng-template>
  </mat-select>
</mat-form-field>
