<div class="wallets-wrapper">
  <div class="row gx-0 pt-md-0 pt-2">
    <div class="col-4">
      <mat-card class="fancy-card">
        <mat-card-header class="p-0">
          <mat-card-title>{{ totalBalance| displayCurrencyConversion }}</mat-card-title>
          <mat-card-subtitle class="d-flex align-items-center">
            <svg icon="transfer-up" lib="global" [size]="{w:14, h:14}" class="me-2"></svg>
            <span [translate]="'total-balance'"></span>
          </mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    </div>
    <div class="col-4">
      <mat-card class="fancy-card">
        <mat-card-header class="p-0">
          <mat-card-title>{{ me?.wallets[0]?.balance.available | displayCurrencyConversion }}</mat-card-title>
          <mat-card-subtitle class="d-flex align-items-center">
            <svg icon="transfer-up" lib="global" [size]="{w:14, h:14}" class="me-2"></svg>
            <span [translate]="'available-balance'"></span>
          </mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    </div>
    <div class="col-4">
      <mat-card class="fancy-card">
        <mat-card-header class="p-0">
          <mat-card-title>{{ me?.wallets[0]?.balance.withdrawable | displayCurrencyConversion }}</mat-card-title>
          <mat-card-subtitle class="d-flex align-items-center">
            <svg icon="transfer-down" lib="global" [size]="{w:14, h:14}" class="me-2"></svg>
            <span [translate]="'total-real-balance'"></span>
          </mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    </div>
  </div>
</div>
