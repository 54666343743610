import { IPromotion  } from '@bs/models';
import { PromoService } from '../services/promo.service';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';

/**
 * Resolver returning us all the promotions
 *
 * see {@link IPromotion} for more information of the return type model
 */
export const PromotionsResolver: ResolveFn<Array<IPromotion>> = () => {
  return inject(PromoService).all();
}

/**
 * Resolver returning us a single promotion for a certain param id
 *
 * see {@link IPromotion} for more information of the return type model
 */
export const PromotionResolver: ResolveFn<IPromotion> = (route: ActivatedRouteSnapshot) => {

  return inject(PromoService).get(+route.paramMap.get('id'));

}
