import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { integerValidator } from '@bs/forms';
import { AppSettings, IDepositCheckOutRequest, IDepositRequest, IMe, IPaymentMethod } from '@bs/models';
import { AppSettingsService, IdentityWalletService } from '@bs/services';
import { WINDOW, WindowService } from '@bs/universal';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DepositCheckOut } from '../../dialogs/deposit-check-out/deposit-check-out-dialog.component';
import { DepositQrCode } from '../../dialogs/deposit-qrcode/deposit-qrcode.dialog';

@Component({
  selector: 'payments-methods-deposit',
  templateUrl: 'payments-methods-deposit.component.html'
})

export class PaymentsMethodsDepositComponent implements OnInit {
  @Input()
  me: IMe;
  loading: boolean;
  isMobile: boolean;
  hostname: string;
  settings: AppSettings;

  paymentMethods: Array<IPaymentMethod> = [];
  paymentMethodsFiltered: Array<IPaymentMethod> = [];
  selected: IPaymentMethod;
  form: FormGroup;

  subs = new Subscription();
  selectedProviderGroupId: number;

  constructor(appSettingsService: AppSettingsService, windowService: WindowService, @Inject(WINDOW) private window: any, private sanitizer: DomSanitizer,
              private route: ActivatedRoute, private dialog: MatDialog,
              private identityWalletService: IdentityWalletService, private translate: TranslateService, private snackBar: MatSnackBar) {
    this.hostname = `${this.window.location.protocol}//${this.window.location.hostname}`;
    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    }));
    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));
    this.form = new FormGroup({
      promoCode: new FormControl(),
      amount: new FormControl(null, [
        Validators.required,
      ])
    });
    this.subs.add(this.route.queryParamMap.subscribe({
      next: query => {
        if (query.has('promoCode')) {
          this.form.get('promoCode').setValue(query.get('promoCode'));
        }
      }
    }));
  }

  showSnackbar(key: string, panelClass: string): void {
    this.snackBar.open(this.translate.instant(key), this.translate.instant('close'), {panelClass});
  }

  ngOnInit(): void {
    this.identityWalletService.getPaymentProviders(true).subscribe({
      next: data => {
        this.paymentMethods = data
        this.selectedProviderGroupId = this.paymentMethods[0].groupId
        this.selectMethodForSelect(this.selectedProviderGroupId)
      }
    }).add(() => {
      this.selectMethod(this.paymentMethods[0].id)
      //this.selected = this.paymentMethods[0];
      //this.setAmount(this.selected.minValue)
      //this.loading = true;
    })
  }

  selectMethod(pmId: number) {
    //console.log($event)
    this.selected = this.paymentMethods.find(x => x.id === pmId)
    //this.setAmount(this.selected.minValue)
    this.form.get('amount').setValidators([
      Validators.required,
      Validators.min(this.selected.minValue / 100),
      Validators.max(this.selected.maxValue / 100)
    ]);

    // only for mobile money
    if (this.selected.groupId === 5) {
      console.log('ok')
      this.form.get('amount').addValidators(integerValidator);
    }
  }

  generateRange(base: number, increment: number, count: number) {
    const range = [];
    for (let i = 0; i < count; i++) {
      range.push(base * Math.max(1, (increment * i)));
    }
    return range;
  }

  setAmount(amount: number) {
    this.form.get('amount').setValue(amount / 100);
    this.form.get('amount').markAsTouched();
  }

  submit() {
    this.loading = true;
    switch (this.selected.integrationTypeId) {
      case 1:
        // CheckOutPageRequest
        this.getCheckOutPage()
        break
      case 2:
        // OtpSendRequest
        this.otpSendRequest()
        break
      case 3:
        // DepositSmsPush (sms will be sent to customers through the integrated service)
        this.depositPush()
        break
      case 4:
        // DepositInit for PayStack
        break
      default:
    }
  }

  private getCheckOutPage() {
    const request: IDepositCheckOutRequest = {
      amount: this.form.value.amount * 100,
      paymentMethodId: this.selected.id,
      promoCode: this.form.value.promoCode,
      redirectSuccessUrl: `${this.hostname}/${this.settings.languageCode}/payment/success`,
      redirectFailingUrl: `${this.hostname}/${this.settings.languageCode}/payment/declined`,
    };
    this.subs.add(this.identityWalletService.depositCheckOut(request)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
      next: response => {
            switch (response.openingTypeId) {
              case 1:
                this.dialog.open(DepositCheckOut, {
                  data: {
                    url: this.sanitizer.bypassSecurityTrustResourceUrl(response.url),
                    isMobile: this.isMobile
                  }
                });
                break
              case 7:
                this.openInNewTab(response.url);
                break
              case 10:
                this.dialog.open(DepositQrCode, {
                  width: '400px',
                  data: {
                    paymentMethod: this.selected,
                    response,
                    amount: request.amount,
                  }
                });
                break
              default:
                console.error('response type not mapped ', response.openingTypeId);
            }
          },

      error: error => {
        this.showSnackbar(error.message, 'error');
      }
    }));
  }

  private otpSendRequest() {
    // not implemented
  }

  private depositPush() {
    const request: IDepositRequest = {
      amount: this.form.value.amount * 100,
      paymentMethodId: this.selected.id,
      promoCode: this.form.value.promoCode,
    };
    this.identityWalletService.depositPush(request).pipe(finalize(() => this.loading = false)).subscribe({
      next: response => {
        this.showSnackbar('depositRequestSubmittedPleaseCheckYourPhone', 'success');
        // console.log(response);
      },

      error: error => {
        this.showSnackbar(error.message, 'error');
      }
    });
  }

  private openInNewTab(url: string) {
    const win = this.window.open(url, '_blank');
    win.focus();
  }

  selectMethodForSelect($event: any) {
    this.paymentMethodsFiltered = this.paymentMethods.filter(x => x.groupId === $event)
    if (this.paymentMethodsFiltered.length === 1) {
      this.selected = this.paymentMethodsFiltered[0]
    } else {
      this.selected = null
    }
  }

}
