import { Pipe, PipeTransform } from '@angular/core';

/**
 * return us only the keys of the object
 */
@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {

  /**
   * the transform pipe function
   * @param value {any} some object value
   * @param split {string} transforms the list of key value pairs into an object
   *
   */
  transform(value: any, split?: string): string[] | { [k: string]: any } {

    if (split) {
      if (!value) return;
      const entries = new Map<string, string>([value.split(split)]);
      return Object.fromEntries<any>(entries);
    }

    return Object.keys(value);
  }
}
