import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { autoexclusion, FormBuilderComponent } from '@homer/forms';
import { FormInterface } from '@bs/models';
import { AuthService, SelfExclusionService } from '@bs/services';
import { finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialog } from '@homer/common';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'self-exclusion',
  templateUrl: './self-exclusion.component.html'
})

export class SelfExclusionComponent implements OnInit, AfterViewInit {


  @ViewChild(FormBuilderComponent, {static: false})
  fb: FormBuilderComponent;

  autoexclusionConfig: FormInterface = autoexclusion;
  loading = false;

  constructor(private dialog: MatDialog, private selfExclusionService: SelfExclusionService, private snackBar: MatSnackBar, private translate: TranslateService, private authService: AuthService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.fb.form.get('exclusionTypeId').valueChanges.subscribe({
      next: v => {
        if (v === 1) {
          this.fb.form.get('exclusionLength').disable();
        } else {
          this.fb.form.get('exclusionLength').enable();
        }
      }
    });
  }


  submit(formValues: any) {

    this.dialog.open(ConfirmationDialog, {
      panelClass: ['dialog-auto-exclusion', 'dialog-full'],
      data: {
        message: 'selfautoexlusion',
        icon: 'account-off',
        reject: () => {
          return;
        },
        accept: () => {
          this.loading = true;

          this.selfExclusionService.create(formValues).pipe(
            finalize(() => this.loading = false)).subscribe({
            next: () => {
              this.snackBar.open(this.translate.instant('operation-confirmed'), this.translate.instant('close'), {duration: 3500});
              this.authService.logout();
            },

            error: error => this.snackBar.open(this.translate.instant(error.message), this.translate.instant('close'), {panelClass: 'error'})
          });
        }
      }
    });

  }
}
