import { DOCUMENT, Location } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeService } from '@bs/common';
import { AppSettings, getTypeIdBySection, IBaseGame, ICasinoTournament, ICurrency, IGame, IGameType, ILeaderBoards, IMe } from '@bs/models';
import { AppSettingsService, AuthService, CatalogService, GamesFavouritesService, GamesService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'section.play-game',
  templateUrl: './play-game.page.html'
})
export class PlayGamePage extends UnsubscribeService implements OnInit {

  @ViewChild('gameFrame', {static: true})
  gameFrame: ElementRef;

  gameType: IGameType;
  typeId: number;
  game: IGame;
  isLoading = false;
  errorMessage: string;
  me: IMe;
  favourites: IBaseGame[];
  isReal = false;
  isFavouriteGame = false;
  isFullScreen = false;
  canFullscreen = false;
  expandRightSide = false;
  defaultCurrency: ICurrency;
  settings: AppSettings;
  tournaments: Array<ICasinoTournament>;
  leaderboards: Array<ILeaderBoards> = [
    {position: '#1 Jas****', score: 3783453, price: 250000},
    {position: '#1 Jas****', score: 3783453, price: 250000},
    {position: '#1 Jas****', score: 3783453, price: 250000},
    {position: '#1 Jas****', score: 3783453, price: 250000},
    {position: '#1 Jas****', score: 3783453, price: 250000},
    {position: '#1 Jas****', score: 3783453, price: 250000},
    {position: '#1 Jas****', score: 3783453, price: 250000},
    {position: '#1 Jas****', score: 3783453, price: 250000}
  ];
  mostPlayed: Array<IBaseGame>;

  constructor(@Inject(DOCUMENT) private document: Document, public location: Location, private route: ActivatedRoute,
              private gamesService: GamesService,
              private gamesFavouritesService: GamesFavouritesService,
              catalog: CatalogService, appSettingsService: AppSettingsService,
              private authService: AuthService, private snackbar: MatSnackBar, private translate: TranslateService) {
    super();
    this.defaultCurrency = catalog.currentCurrency;
    appSettingsService.appSettings$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: ({settings}) => this.settings = settings
    });


    route.data.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: data => {
        //console.log(data)
        this.gameType = data.gameType
        if (data && data.game && data.game.url !== '') {
          this.game = data.game;
        } else {
          const ref = this.snackbar.open(this.translate.instant('gameNotAvailable'), translate.instant('close'), {panelClass: 'error'});
          ref.afterDismissed().pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: () => this.location.back()
          });
        }
      }
    });

    route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: paramMap => {
        const sectionType = paramMap.get('section');
        this.typeId = getTypeIdBySection(sectionType);
        this.isReal = paramMap.get('mode') === 'real';
      }
    });

    authService.accountLogged$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (account: IMe) => {
        this.me = account;
      }
    });

    this.canFullscreen = document.fullscreenEnabled;
  }

  ngOnInit() {
    this.mostPlayed = this.gameType.gameSubTypes[0].games.slice(30, 40);

    this.gamesFavouritesService.favourites$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: favourites => {
        this.favourites = favourites.filter(f => f.gameTypeId === this.typeId);
        this.isFavouriteGame = this.favourites.some(x => x.id === this.game.id);
      }
    });
  }

  addFavorite() {
    this.gamesFavouritesService.toggle(this.game);
  }

  removeFavourite(favourite) {
    this.gamesFavouritesService.toggle(favourite);
  }

  @HostListener('fullscreenchange')
  onFullScreenChange() {
    this.isFullScreen = !!this.document.fullscreenElement;
  }

  fullscreen() {
    if (this.isFullScreen) {
      this.document.exitFullscreen();
    } else {
      const elem = this.gameFrame.nativeElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    }
  }
}
