import { IGameProvider } from './game';

/**
 * @deprecated remove it
 */
export interface IGameBrand {
  id: number;
  name: string;
  gameTypeId: number;
  orderId?: number;
  icon: string;
  providers?: Array<IGameProvider>
}

/**
 * @deprecated remove it
 */
export interface IGameBrandSearchRequest {
  p: number;
  pp: number;
  bookmakerId: number;
  accountId?: number;
  isHidden?: boolean;
}

