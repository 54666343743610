import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { BaseTableBuilder, BookmakerNamePipe } from '@bs/forms';
import { Col, TicketStatus } from '@bs/models';
import { FormBuilderComponent } from '../form/form-builder.component';

/**
 * the component creates a form builder, and shows us corresponding data fields with pagination
 */
@Component({
    selector: 'data-table-builder[config][cols]',
    templateUrl: './data-table-builder.component.html',
    styles: [`
      :host {
        display: block
      }

      .ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 600px;
      }

      tr.deleted:hover td,
      tr.deleted td {
        background-color: rgba(200, 0, 0, .3);
      }
    `],
    providers: [BookmakerNamePipe]
  }
)
export class DataTableBuilderComponent extends BaseTableBuilder implements OnInit, AfterViewInit {
  /**
   * local reference to FormBuilderComponent
   */
  @ViewChild(FormBuilderComponent, {static: false})
  fb: FormBuilderComponent;
  /**
   * local reference of ticketTypes
   */
  ticketTypes = new Map<number, string>([[TicketStatus.open, 'open'], [TicketStatus.lost, 'lost'], [TicketStatus.won, 'won'], [TicketStatus.void, 'void'], [TicketStatus.refunded, 'refunded']]);
  /**
   * header rows array items
   */
  headerRow: string[];
  /**
   * function variable which returns us column hideMobile option
   * @param col
   */
  isMobileHidden = (col: Col) => {
    return !col.hideMobile;
  };

  /**
   * lifecycle hook, initialize the header items to the headerRow
   */
  ngOnInit() {
    this.headerRow = this.cols.flatMap(x => x.header);
  }

  /**
   * lifecycle hook, and calls inherited method from the super class BaseTableBuilder
   *
   * see {@link BaseTableBuilder} for more information
   */
  ngAfterViewInit() {
    super.afterViewInit(this.fb);
  }

  /**
   * functions resets form values, and clears the query params
   *
   * see {@link FormBuilderComponent} for more details
   */
  reset() {
    super.reset(this.fb)
  }

  /**
   * function initializes the baseParams, and inherits method _onPage() from the base class
   * @param $event
   */
  onPage($event: PageEvent) {
    this.baseParams = Object.assign(this.baseParams, {
      p: $event.pageIndex + 1,
      pp: $event.pageSize
    });

    super._onPage();
  }

  /**
   * this method is for mobile generic click, the method ALWAYS take the first action
   * @param cols
   * @param item
   */
  checkAction(cols: Array<Col>, item: any) {
    const actions = cols.find(c => c.field === 'actions');
    const callback = actions.buttons[0].click;
    if (actions && typeof callback === 'string') {
      this.emitter.emit({callback, data: item})
    }
  }
}
