<ng-template #picTpl let-banner let-config="config">
  <div class="banner"
       [ngClass]="{'left': banner.position.horizontal === horizontal.left, 'center': banner.position.horizontal === horizontal.center, 'right': banner.position.horizontal === horizontal.right}">
    <div class="info" [ngClass]="{'center': banner.position.vertical===vertical.center, 'bottom': banner.position.vertical === vertical.bottom}">
      <div class="headline" [innerHTML]="banner.headline.text"></div>
      <div class="tagline" [innerHTML]="banner.tagline.text"></div>
      @if (banner.cta; as cta) {
        <a class="btn btn-primary d-inline-flex" [routerLink]="cta.path[0].value">{{ cta.languages[0].value }}</a>
      }
    </div>
    <picture>
      @if (banner.images?.length >= 1 && banner.images[0]; as image) {
        @if (image.desktop) {
          <source media="(min-width: 1024px)" [attr.srcset]="image.desktop">
        }
        @if (image.tablet) {
          <source media="(min-width: 620px)" [attr.srcset]="image.tablet">
        }
        @if (image.mobile) {
          <img fetchpriority="high" [alt]="banner.headline?.value" [src]="image.mobile" class="img-fluid slide-container"/>
        }
      }
    </picture>
  </div>
</ng-template>

<ng-template #linkTpl let-banner>
  @if (banner.cta.url[0]; as url) {
    <a class="d-flex justify-content-center" [href]="url.value" [target]="banner.target">
      <ng-template [ngTemplateOutlet]="picTpl" [ngTemplateOutletContext]="{$implicit:banner}"></ng-template>
    </a>
  }
</ng-template>

<ng-template #pageTpl let-banner>
  @if (banner.cta.path[0]; as path) {
    <a class="d-flex justify-content-center" (click)="bannerClick(banner)">
      <ng-template [ngTemplateOutlet]="picTpl" [ngTemplateOutletContext]="{$implicit:banner}"></ng-template>
    </a>
  }
</ng-template>

@if (slider?.config; as config) {
  <div class="slider-container">
    @if (slider.banners?.length) {
      <swiper-container fmSwiper [config]="swiperConfig">
        @for (banner of (slider.banners|timing); track banner) {
          <!--          <pre>{{banner | json}}</pre>-->
          <swiper-slide class="m-auto" [style.background-color]="banner.backgroundColor">
            <ng-template [ngTemplateOutletContext]="{$implicit:banner, config: config.sizes.desktop}" [ngTemplateOutlet]="!banner?.ctaVisible ? picTpl : (banner.cta.url ? linkTpl : pageTpl)"></ng-template>
          </swiper-slide>
        }
      </swiper-container>
    }
  </div>
}
