import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, makeStateKey, TransferState } from '@angular/core';
import { Observable, tap } from 'rxjs';

@Injectable()
export class ServerStateInterceptor implements HttpInterceptor {

  constructor(private transferState: TransferState) {
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<void>> {

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // console.log(`make stateKey for ${req.urlWithParams}`);
          this.transferState.set(makeStateKey(req.urlWithParams), event.body);
        }
      })
    );

  }

}
