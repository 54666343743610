import { IAuthor } from '../common/author';
import { ISearchBaseSportsBookFilters } from './common';
import { ISport } from './sport';

export interface ITournamentTemplate {
  id?: number;
  betOfferId?: number;
  sport?: ISport;
  name?: string;
  creationDate?: string;
  lastUpdated?: string;
  author?: IAuthor;
  activeVersionId?: number;
  versions?: Array<ITournamentTemplateVersion>;
}

export interface ITournamentTemplateVersion {
  author: IAuthor;
  versionId: number;
  creationDate: string;
  limits: Array<ITournamentTemplateLimit>;
}

export interface ITournamentTemplateLimit {
  currencyId: number;
  eventTypeId: number;
  limitTypeId: number;
  maxBet: number;
  maxWin: number;
  offerTypeId: number;
}

export interface ITournamentTemplateMarketSport extends ITournamentTemplate {
  marketSportId: number;
}

export interface ITournamentTemplateSearchFilters extends ISearchBaseSportsBookFilters {
  sportId: number;
}
