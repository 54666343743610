
<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span class="my-md-3">
        <strong>{{'loyalty-levels' | translate}}</strong>
      </span>
      <a (click)="dialogRef.close()" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </a>
    </mat-card-title>
    <div class="dialog-wrapper">
      @for (item of data; track item) {
        <mat-card class="new-card flat-card sport-card mb-3">
          <mat-card-title class="box-header">
            <div class="d-flex justify-content-between flex-grow-1">
              <div class="d-flex align-items-center">
                <img class="rank-img me-2" [src]="'assets/icons/smartico-loyalty/level-' + (item.name | lowercase) + '.png'">
                <span class="me-1 title">{{item.name}}</span>
                <span>{{item.level}}</span>
              </div>
              <div class="d-flex align-items-center points">
                <span class="me-1">{{item.points | number}}</span>
                <span>{{'points' | translate}}</span>
              </div>
            </div>
          </mat-card-title>
          <mat-card-content>
            <div class="row no-gutters">
              <div class="col-6 text-center p-3 border">
                @if (item.type === 'freeSpins') {
                  <div translate class="mb-1">freeSpins</div>
                  <strong>{{item.amount}} FP</strong>
                }
                @if (item.type === 'money') {
                  <div translate class="mb-1">money</div>
                  <strong>{{item.amount | currency}}</strong>
                }
              </div>
              <div class="col-6 text-center p-3">
                <div translate class="mb-1">wager</div>
                <strong>x{{item.wager}}</strong>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      }
    </div>
  </mat-card>
</div>

