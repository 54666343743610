import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { map, takeWhile, timer } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@bs/services';
import { Col } from '@bs/models';

/*interface IRealityCheck {
  deposits: number;
  wins: number;
  losses: number;
  fromTime: string;
}*/

interface MyData {
  lastLogin: number;
  /*  realityChecks!: Array<IRealityCheck>;*/
}

@Component({
  selector: 'modal.reality-check',
  templateUrl: './reality-check.dialog.html'
})
export class RealityCheckDialog implements OnInit, OnDestroy {

  checked!: boolean;

  playTime!: string;

  columns: Array<Col> = [
    {header: 'Winning', field: 'Wins', type: 'amount'},
    {header: 'Loss', field: 'Losses', type: 'amount'},
    {header: 'Deposit', field: 'Deposits', type: 'amount'}
  ];

  private sub = true;

  constructor(public dialogRef: MatDialogRef<RealityCheckDialog>, @Inject(MAT_DIALOG_DATA) public data: MyData, private authService: AuthService) {
  }

  ngOnInit() {
    timer(1e3, 1e3).pipe(
      takeWhile(() => this.sub),
      map(() => {
        let difference = new Date().getTime() - new Date(this.data.lastLogin).getTime();

        const hour = Math.floor(difference / 1e3 / 60 / 60);
        difference -= hour * 1e3 * 60 * 60;

        const min = Math.floor(difference / 1e3 / 60);
        difference -= min * 1e3 * 60;

        const sec = Math.floor(difference / 1e3);

        return `${this.pad(hour)} : ${this.pad(min)} : ${this.pad(sec)}`;
      })
    ).subscribe({
      next: res => this.playTime = res
    });
  }

  ngOnDestroy() {
    this.sub = false;
  }

  logout() {
    this.authService.logout();
    this.dialogRef.close();
  }

  close() {
    this.authService.realityCheck = new Date().valueOf();
    this.dialogRef.close();
  }

  private pad(value: number): string {
    return `${value >= 10 ? value : '0' + value}`;
  }
}
