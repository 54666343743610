export interface ICurrency {
  id: number;
  code: string;
  name: string;
  symbol?: string;
  selected?: boolean;
}

export class CurrencyConversion {
  currencyId: number;
  currencyCode: string;
  currencySymbol: string;
  subunit: number;
  rate: number;
}

export interface Amount extends CurrencyConversion {
  amount: number;
  amountDeposit: number;
  amountWithdrawable: number;
  amountBonus: number;
  amountOverdraft: number;
}
