import { Component, OnDestroy, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { GameBaseClass } from '@homer/games';
import { map } from 'rxjs';


@Component({
  selector: 'section.list-games',
  templateUrl: 'list-games.page.html'
})
export class ListGamesPage extends GameBaseClass implements OnInit, OnDestroy {

  device = toSignal(this.windowService.device$);
  me = toSignal(this.authService.accountLogged$.pipe(map(me => me)));

}
