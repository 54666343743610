<div class="dialog">
  <mat-card class="new-card flat-card">

    <mat-card-title class="box-header">
      <span [translate]="'forgot-password'">Forgot Password</span>
      <button (click)="dialogRef.close()" class="close-icon me-2" mat-flat-button type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>
    <div class="dialog-wrapper">
      <div class="scrollbar">
        <div class="p-3">
          @if (formGroupErrorMessage) {
            <div class="m-b-10">
              <div class="alert alert-danger">
                <span>{{ formGroupErrorMessage | translate }}</span>
              </div>
            </div>
          }
          @if (formGroupSuccess) {
            <div class="p-b-30">
              <div class="alert alert-success">
                @switch (data.mode) {
                  @case ('password') {
                    <span [translate]="'pin-changed'">Pin changed</span>
                  }
                  @case ('pin') {
                    <span [translate]="'password-changed'">Password changed</span>
                  }
                }
              </div>
            </div>
          } @else {
            @if (!isLoading) {
              <form [formGroup]="form">
                @switch (data.mode) {
                  @case ('password') {
                    <ng-template #emailFormTpl>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100" matInput>
                            <input [placeholder]="'newPassword' | translate" autocapitalize="none" autocorrect="off" formControlName="newPassword" matInput type="password">
                            <mat-error [control]="form.get('newPassword')"></mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <input [placeholder]="'password-repeat' | translate" autocapitalize="none" autocorrect="off" formControlName="password_match" matInput type="password">
                            <mat-error [control]="form.get('password_match')"></mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </ng-template>
                  }
                  @case ('pin') {
                    <div class="row mb-4 gx-0">
                      <mat-form-field class="col-12">
                        <mat-label [translate]="'newPassword'"></mat-label>
                        <input [type]="hide[0] ? 'password' : 'text' " autocomplete="current-password" formControlName="newPassword" matInput>
                        <button (click)="hide[0]=!hide[0]" [color]="hide[0] ? null : 'primary'" mat-icon-button matSuffix tabindex="-1" type="button"></button>
                        <mat-error [control]="form.get('newPassword')"></mat-error>
                      </mat-form-field>
                    </div>
                    <div class="row mb-4 gx-0">
                      <mat-form-field class="col-12">
                        <mat-label [translate]="'password-repeat'"></mat-label>
                        <input [type]="hide[1] ? 'password' : 'text' " autocomplete="new-password" formControlName="password_match" matInput>
                        <button (click)="hide[1]=!hide[1]" [color]="hide[1] ? null : 'primary'" mat-icon-button matSuffix tabindex="-1" type="button"></button>
                        <mat-error [control]="form.get('password_match')"></mat-error>
                      </mat-form-field>
                    </div>
                  }
                }
                <div class="row m-t-10">
                  <div class="col-12 text-right">
                    <button (click)="handleSubmit()" [disabled]="!form.valid" color="primary" mat-flat-button type="button">
                      <span [translate]="'submit'">Submit</span>
                    </button>
                  </div>
                </div>
              </form>
            } @else {
              <div class="d-flex col-12 align-items-center justify-content-center">
                <mat-spinner [diameter]="30"></mat-spinner>
              </div>
            }
          }
        </div>
      </div>
    </div>
  </mat-card>
</div>
