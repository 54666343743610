import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mailFormat, MobilePrefixValidator } from '@bs/forms';
import { Sharer } from '@bs/models';

import { WINDOW } from '@bs/universal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sharer',
  templateUrl: './sharer.component.html',
  styles: [`
    .icon-telegram::ng-deep span::before {
      transform: rotate(-35deg);
    }
  `]
})
export class SharerComponent implements OnInit {

  @Input()
  config: Array<Sharer>;

  @Input()
  link: string;

  emailGroup: FormGroup;
  phoneGroup: FormGroup;
  tabs = [];
  socialButtons = [];

  constructor(fb: FormBuilder, @Inject(WINDOW) private window: Window, private translate: TranslateService) {
    this.phoneGroup = fb.group({
      phone: [null, Validators.compose([Validators.required, MobilePrefixValidator.number()])]
    });

    this.emailGroup = fb.group({
      email: ['', Validators.compose([Validators.required, mailFormat])]
    });
  }

  ngOnInit(): void {
    this.config.forEach(c => {
      if (['facebook', 'whatsapp', 'telegram', 'twitter'].includes(c)) {
        if (!this.tabs.includes('socials')) {
          this.tabs.push('socials');
        }
        this.socialButtons.push(c);
      } else {
        this.tabs.push(c);
      }
    });
  }

  submit(data) {
    // shareService??.share(tabselected[group].value, resource)
    console.log(data);
  }

  share(what: Sharer) {
    const text = `${what}ShareText`;
    const shareLink = encodeURIComponent(this.link);
    let baseUrl;

    switch (what) {
      case 'telegram':
        baseUrl = `https://t.me/share/url?url=${shareLink}&text=${this.translate.instant(text)}`;
        break;
      case 'whatsapp':
        baseUrl = `https://api.whatsapp.com/send?text=${this.translate.instant(text)}+${shareLink}`;
        break;
    }

    this.window.open(baseUrl, '_blank');
  }
}
