import { Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Params, Router } from '@angular/router';
import { IMe } from '@bs/models';
import { AuthService, SmarticoService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'smartico-sidebar',
  templateUrl: 'smartico-sidebar.component.html',
  styleUrls: ['smartico-sidebar.component.scss']
})

export class SmarticoSidebarComponent {
  ready = false;
  subs = new Subscription();
  me: IMe;

  constructor(public translate: TranslateService,
              private router: Router,
              private auth: AuthService, private smarticoService: SmarticoService) {
    this.translate.currentLang;

    this.smarticoService.isReady$.subscribe(() => {
      this.ready = true
    });
    this.subs.add(auth.accountLogged$.subscribe(
      {
        next: me => {
          if (me) {
            this.me = me;

          } else {
            this.me = null
          }
        }
      }));

  }

  action(dp: string, params?: Params) {
    if (this.auth.isLogged()) {
      this.smarticoService.dp(dp, params);
    } else {
      void this.router.navigate([], {fragment: 'login'});
    }
  }

}
