<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span>{{ 'crypto-withdrawal-request-title' | translate }}</span>
      <button (click)="dialogRef.close()" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>

    <div class="dialog-wrapper">
      <div class="scrollbar">
        <div class="dialog-body">
          @if (!loading) {
            @if (response) {
              <div>
                <div class="row gx-0">
                  <div class="col-12">
                    <div class="alert alert-success">
                      <span class="d-block m-b-15">
                        <strong class="ms-1">{{ 'withdrawal-confirmed' | translate }}</strong>
                      </span>
                      <span>{{ 'movementId' | translate }}</span>:<strong>{{ response.movementId }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            } @else {
              <form [formGroup]="form">
                <div class=" row gx-0">
                  <div class="col-12">
                    <div class="alert alert-info m-b-30">
                      <span class="d-block">{{ 'your-withdrawable-balance' | translate }}
                        <b>&nbsp;{{ data.me?.wallets[0].balance.withdrawable | moneyFormat:data.me?.currencies[0]:true }}</b></span>
                    </div>
                  </div>
                </div>
                <div class="row gx-0">
                  <div class="col">
                    <mat-form-field class="w-100">
                      <mat-label>
                        <span>{{ 'crypto-wallet-address' | translate }}</span>
                      </mat-label>
                      <input formControlName="cryptoWalletAddress" matInput>
                      @if (form.get('cryptoWalletAddress').errors && !form.get('cryptoWalletAddress').pristine) {
                        <div
                          class="padded">
                          @if (form.get('cryptoWalletAddress').errors.required) {
                            <div
                              class="alert alert-danger">{{ 'field-required' | translate }}
                            </div>
                          }
                        </div>
                      }
                    </mat-form-field>
                  </div>
                </div>
                <div class="row gx-0">
                  <div class="col">
                    <mat-form-field class="w-100">
                      <mat-label>
                        <span>{{ 'amount' | translate }}</span>
                      </mat-label>
                      <input formControlName="amount" matInput type="number">
                      @if (form.get('amount').errors && !form.get('amount').pristine) {
                        <div>
                          @if (form.get('amount').errors.required) {
                            <div class="alert alert-danger">
                              {{ 'field-required' | translate }}
                            </div>
                          }
                          @if (form.get('amount').errors?.pattern) {
                            <div
                              class="alert alert-danger">{{ 'amount-invalid' | translate }}
                            </div>
                          }
                        </div>
                      }
                    </mat-form-field>
                  </div>
                </div>
                <div class="row p-t-15">
                  <div class="col-12 text-right">
                    <button (click)="handleSubmit()" [disabled]="!form.valid" color="primary"
                            mat-flat-button type="button">{{ 'confirm' | translate }}
                    </button>
                  </div>
                </div>
              </form>
            }
            @if (error) {
              <div>
                <div class="row gx-0">
                  <div class="col-12">
                    <div class="alert alert-danger">
                        <span class="d-block">
                          <strong [translate]="error.message" class="ms-1"></strong>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            }
          } @else {
            <div class="d-flex col-12 align-items-center justify-content-center">
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
          }
        </div>
      </div>
    </div>
  </mat-card>
</div>
