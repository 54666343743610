export interface DictionarySport {
  id: number;
  name: string;
  defaultGroup: number;
  icon?: string;
  slug?: string;
  sgs?: Array<DictionarySuperGroup>;
}

export interface DictionarySuperGroup {
  id: number;
  eventTypeId: number;
  name: string;
  orderId: number;
  g?: Array<DictionaryGroup>;
}

export interface DictionaryGroup {
  id: number;
  name: string;
  orderId: number;
  ms?: Array<DictionaryMarketSport>;
}

export interface DictionarySpecifier {
  id: number;
  specifier: string;
  isDefault: boolean;
}

export interface DictionaryMarketSport {
  id: number;
  name: string;
  description?: string;
  // orderId: number;
  templateWebId: number;
  typeId: number;
  offerTypeId: number;
  marketSelections: Array<DictionaryMarketSelection>;
  specifiers?: Array<DictionarySpecifier>;
}

export interface DictionaryMarketSelection {
  id: number;
  marketSportId: number;
  outcomeId: number;
  outcome: string;
  fastCode?: string;
  description?: string;
  specifier: string;
  specifierId: number;
}
