import { Component } from '@angular/core';
import { UnsubscribeService } from '@bs/common';
import { AppSettings } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'balance-navigation',
  templateUrl: 'balance-navigation.component.html'
})

export class BalanceNavigationComponent extends UnsubscribeService {

  settings: AppSettings;

  constructor(appSettingsService: AppSettingsService) {
    super();
    appSettingsService.appSettings$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: ({settings}) => this.settings = settings
    });
  }
}
