import { Component, HostBinding, Input } from '@angular/core';

import { EnvironmentConfig, IGame } from '@bs/models';

@Component({
  selector: 'games-grid',
  templateUrl: 'games-grid.component.html',
  styleUrls: ['games-grid.component.scss']
})
export class GamesGridComponent {
  @HostBinding('class')
  layoutRender = this.env.layout?.lobby;
  @Input()
  isMobile: boolean;
  @Input()
  games: IGame[];
  selected: number;

  constructor(private env: EnvironmentConfig) {
  }

  trackByFn(i: number, game: IGame) {
    return game.id;
  }
}
