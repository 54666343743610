import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from '../services/core/auth.service';

// TODO: i think this can be merged with private-area.guard
/**
 * guards that checks for logout queryParams if it finds it logs out the user
 */
@Injectable({
  providedIn: 'root'
})
export class QsLogoutGuard  {

  /**
   * The constructor
   */
  constructor(private authService: AuthService) {
  }

  /**
   * interface guard deciding if a route can be activated
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {

    const request = route.queryParams;

    if (request.logout) {
      this.authService.logout();
    }

    return true;
  }
}
