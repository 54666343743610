<div class="dialog max-vh">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <div><span [translate]="'self-auto-exclusion-title'"></span></div>
      <button (click)="dialogRef.close()" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>
    <div class="auto-exclusion-wrapper dialog-wrapper">
      <div class="dialog-body text-center">
        <div class="mb-3">{{ 'auto-exclusion-desc' | translate }}</div>
        <button (click)="dialogRef.close(true)" mat-flat-button color="primary">{{ 'yes-sure' | translate }}</button>
      </div>
    </div>
  </mat-card>
</div>
