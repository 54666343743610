<ng-template #itemsTpl>
  <div class="col-12 px-2 py-2">
    @if (!selected) {
      <strong>{{ 'selectPaymentMethod' | translate }}</strong>
    }
    <div class="d-flex">
      @for (pm of itemsFiltered; track pm) {
        <div class="row">
          <div class="col-md-12">
            <div class="p-2">
              <button mat-icon-button (click)="selectItem(pm.id)" class="me-2 p-0">
                <svg [icon]="pm.icon" lib="payments" [size]="{w: 40, h: 40}"></svg>
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #noItems>
  <div class="alert alert-secondary m-5" role="danger">
    <span [translate]="'payment-method-not-available'" class="text-center"></span>
  </div>
</ng-template>

@if (items?.length) {
  <div class="col-12 d-flex flex-wrap p-2">
    <div class="d-sm-block col-12">
      <mat-button-toggle-group (ngModelChange)="selectGroup($event)" [(ngModel)]="groupId">
        @for (paymentGroups of items | groupBy:'group.name'; track paymentGroups) {
          <mat-button-toggle [value]="paymentGroups.values[0].group.name">{{ paymentGroups.index | translate }}
          </mat-button-toggle>
        }
      </mat-button-toggle-group>
    </div>
    <ng-template [ngTemplateOutlet]="items.length? itemsTpl : noItems"
                 [ngTemplateOutletContext]="{$implicit: noItems}"></ng-template>
  </div>
  @if (selected) {
    <div class="row p-2">
      <div class="col-12">
        <mat-divider></mat-divider>
        <div class="align-items-center d-flex py-2">
          <strong class="me-2">{{ selected.name }}</strong>
          <div class="d-flex align-items-center me-2">
            <span>{{ 'min' | translate }}</span>
            <span class="ms-1 me-2">{{ selected.validators.min | moneyFormat:me()?.currencies[0]:true }}</span>
            <span> | </span>
          </div>
          <div class="d-flex align-itens-center me-2">
            <span>{{ 'max' | translate }}</span>
            <span class="ms-1 me-2">{{ selected.validators.max | moneyFormat:me()?.currencies[0]:true }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>

        <form [formGroup]="form">
          <div class="row mt-2">
            <div class="col-lg-3 col-12">
              <mat-form-field>
                <mat-label [translate]="'bonus-code'"></mat-label>
                <input autocomplete="off" formControlName="bonusCode" matInput type="text"/>
              </mat-form-field>
            </div>
          </div>
          <div class="row align-items-center gx-1 mt-3">
            <div class="col-lg-2 col-4">
              <button class="w-100 amount-btn" (click)="setAmount(selected.validators.min * 10)" color="primary"
                      [class.amount-active]="form.get('amount').value === (selected.validators.min / 100)"
                      mat-flat-button>{{ (selected.validators.min * 10 / 100) | moneyFormat:me()?.currencies[0]:false }}
              </button>
            </div>
            <div class="col-lg-2 col-4">
              <button class="w-100 amount-btn" (click)="setAmount(selected.validators.min * 50)" color="primary"
                      [class.amount-active]="form.get('amount').value === (selected.validators.min  * 2) / 100"
                      mat-flat-button>{{ ((selected.validators.min * 50) / 100) | moneyFormat:me()?.currencies[0]:false }}
              </button>
            </div>
            <div class="col-lg-2 col-4">
              <button class="w-100 amount-btn" (click)="setAmount(selected.validators.min * 200)" color="primary"
                      [class.amount-active]="form.get('amount').value === (selected.validators.min * 10) / 100"
                      mat-flat-button>{{ ((selected.validators.min * 200) / 100) | moneyFormat:me()?.currencies[0]:false }}
              </button>
            </div>
          </div>
          <div class="row align-items-center gx-1 mt-1">
            <div class="col-lg-2 col-4">
              <button class="w-100 amount-btn" (click)="setAmount(selected.validators.min * 1000)" color="primary"
                      [class.amount-active]="form.get('amount').value === (selected.validators.min) * 50 / 100"
                      mat-flat-button>{{ ((selected.validators.min * 1000) / 100) | moneyFormat:me()?.currencies[0]:false }}
              </button>
            </div>
            <div class="col-lg-2 col-4">
              <button class="w-100 amount-btn" (click)="setAmount(selected.validators.min * 2000)" color="primary"
                      [class.amount-active]="form.get('amount').value === (selected.validators.min) * 400 / 100"
                      mat-flat-button>{{ ((selected.validators.min * 2000) / 100) | moneyFormat:me()?.currencies[0]:false }}
              </button>
            </div>
            <div class="col-lg-2 col-4">
              <button class="w-100 amount-btn" (click)="setAmount(selected.validators.min * 5000)" color="primary"
                      [class.amount-active]="form.get('amount').value === (selected.validators.min * 2000) / 100"
                      mat-flat-button>{{ ((selected.validators.min * 5000) / 100) | moneyFormat:me()?.currencies[0]:false }}
              </button>
            </div>
          </div>
          <div class="row align-items-center mt-2">
            <div class="col-lg-3 col-12">
              <mat-form-field>
                <mat-label>
                  <span [translate]="'amount'"></span>
                  <span matPrefix>({{ me()?.currencies[0].code }})</span>
                </mat-label>
                <input autocomplete="off" formControlName="amount" matInput type="number"/>
                <mat-error [control]="form.get('amount')"></mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 d-flex justify-content-end my-3">
              <button (click)="submit()" [disabled]="!form.valid || loading"
                      class="mat-primary" mat-flat-button type="button">{{ 'deposit' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--    <pre>{{ selected | json }}</pre>-->
  }
} @else {
  <div class="d-flex col-12 align-items-center justify-content-center">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
}
