import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppSettings } from '@bs/models';
import { AppSettingsService, AuthService } from '@bs/services';
import { Observable } from 'rxjs';

/**
 * guard checks user has permission ClassicPoker if it doesn't have permission it navigates to /games/eb-poker route
 */
@Injectable({
  providedIn: 'root'
})
export class PokerRoomGuard  {
  /**
   * appSettings variable
   * @private
   */
  private appSettings: AppSettings;

  /**
   * The constructor
   * @param appSettingsService
   * @param authService
   * @param router
   */
  constructor(appSettingsService: AppSettingsService, private authService: AuthService, private router: Router) {
    appSettingsService.appSettings$.subscribe({
      next: data => this.appSettings = data.settings
    });
  }

  /**
   * interface guard deciding if a route can be activated, by user permission
   */
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authService.accountLogged$.subscribe(
      {
        next: account => {
          if (account) {
            this.router.navigate([this.appSettings.languageCode + '/games/eb-poker']);
          }
        }
      }
    );

    return true;
  }

}
