<div class="dialog">
  <mat-card class="flat-card">
    <mat-card-title class="box-header">
      <h2 class="title">{{ 'casino-calendar-title' | translate }}</h2>
      <span translate></span>
      <a (click)="dialogRef.close()" [routerLink]="[]" [fragment]="null" class="close-icon" type="button"></a>
    </mat-card-title>
    <div class="d-flex flex-column align-items-center">
      <img class="mt-3" [src]="data.logo" [alt]="data.description">
      <p class="text-center p-3">{{ data.description }}</p>
      <span class="mb-5 text-muted">{{ 'casino-calendar-terms-and-conditions' | translate }}</span>
      <div class="button-container d-flex align-items-center w-100">
        <button class="w-100 text-uppercase" (click)="claimPresent()" color="primary" mat-flat-button>{{ 'claim' | translate }}</button>
      </div>
    </div>
  </mat-card>
</div>
