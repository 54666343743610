import { ISearchBaseSportsBookFilters } from './common';
import { ILanguageEntityLabel } from './language';
import { IMarket, IMarketSelection } from './market';
import { IMarketGroup } from './market-group';

export interface IMarketSport extends IMarket {
  id: number;
  offerTypeId: number; // prematch o live o entrambi
  selections?: Array<IMarketSelection>;
  groups?: IMarketGroup;
  languages?: Array<ILanguageEntityLabel>;
}


export interface IMarketsSportSearchFilters extends ISearchBaseSportsBookFilters {
  betOfferId: number;
}
