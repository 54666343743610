import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { matchPassword } from '@bs/forms';
import { PasswordService } from '@bs/services';

interface MyData {
  token: string;
  mode: string;
}

@Component({
  selector: 'password-recovery',
  templateUrl: 'password-recovery-dialog.component.html'
})
export class PasswordRecoveryDialog {

  form: FormGroup;
  formGroupSuccess: boolean;
  formGroupErrorMessage: string;
  formGroupMessage = '';
  isLoading: boolean;
  hide = [true, true];

  constructor(fb: FormBuilder, private passwordService: PasswordService, public dialogRef: MatDialogRef<PasswordRecoveryDialog>,
              @Inject(MAT_DIALOG_DATA) public data: MyData) {

    this.form = fb.group({
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      password_match: ['', Validators.compose([Validators.required, matchPassword])]
    });
  }


  handleSubmit() {
    this.isLoading = true;
    this.formGroupMessage = null;
    this.formGroupErrorMessage = null;

    if (this.form.valid) {
      this.passwordService.recoveryPassword(this.form.value.newPassword, this.data.token)
        .subscribe(
          () => this.formGroupSuccess = true,
          () => {
            this.form.reset();
            this.formGroupErrorMessage = 'passwordUnchanged';
          }
        ).add(() => this.isLoading = false);
    }
  }

}
