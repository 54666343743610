import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'reality-checker',
  templateUrl: './reality-checker.component.html'
})

export class RealityCheckerComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
