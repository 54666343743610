import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IBetOfferInProgressRequest, ISportEvent } from '@bs/models';
import { BaseResolver, DateFactoryService } from '@bs/services';
import { Observable } from 'rxjs';
import { SportsbookService } from '../services/sportsbook.service';

/**
 * Resolver is returning us the sports events items when using data-table-builder component
 */
@Injectable()
export class SportEventResolver extends BaseResolver  {

  /**
   * The constructor
   * @param sportsBookService
   * @param {DateFactoryService} dfs public variable
   */
  constructor(private sportsBookService: SportsbookService, public dfs: DateFactoryService) {
    super(dfs);
  }

  /**
   * the resolve function retrieves us the data needed to activate for the requested route
   * @param route
   */
  resolve(route: ActivatedRouteSnapshot): Observable<ISportEvent> {
    const filters: IBetOfferInProgressRequest = {};

    const sportEventId = route.paramMap.get('event_id')

    return this.sportsBookService.getSportEvent(+sportEventId, filters)
  }
}
