<div class="dialog">
  <mat-card class="new-card flat-card">

    <mat-card-title class="box-header">
      <div><span>{{ data.provider.name }}</span></div>
      <button (click)="dialogRef.close()" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>

    <div class="dialog-wrapper">
      <div class="scrollbar">

        <div class="dialog-body">
          @if (!loading) {
            @if (response) {
              <div>
                <div class="row gx-0">
                  <div class="col-12">
                    <div class="alert alert-success">
                      <span class="d-block m-b-15">
                        <strong [translate]="'withdrawal-confirmed'" class="ms-1">Your withdrawal request is confirmed.</strong>
                      </span>
                      <span [translate]="'movementId'">Movement id</span>:<strong>{{ response.movementId }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            }
            @if (error) {
              <div>
                <div class="row gx-0">
                  <div class="col-12">
                    <div class="alert alert-danger">
                      <span class="d-block">
                        <strong [translate]="error.message" class="ms-1"></strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            }
          } @else {
            <div class="d-flex col-12 align-items-center justify-content-center">
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
          }
        </div>
      </div>
    </div>
  </mat-card>
</div>
