import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IAccount, IMe } from '@bs/models';

/**
 * Funds class
 */
export class FundsValidator {
  /**
   * validating funds amount of deposit or withdrawal depends selected control value direction
   *
   * @param data
   */
  static validate(data: {
    me: IMe,
    account: IAccount,
    isTopUp: boolean,
    isMobile: boolean
  }): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

      if (control.value.direction === 4 || data.isTopUp) {
        return null;
      }


      // const amount = control.value.amount;

      let isValid = false;

      if (control.value.direction === 1) {
        // deposit
        // my funds are >= amount
        // isValid = Number(mf.transform(data.me.wallets[0]?.balance.available, data.me.currencies[0], true, false)) >= amount;
        isValid = true;
      }

      if (control.value.direction === 2) {
        // withdraw
        // user funds are > amount;
        // isValid = Number(mf.transform(data.account.wallets[0]?.balance.available, data.me.currencies[0], true, false)) >= amount;
        isValid = true;
      }

      return isValid ? null : {'insufficient-funds': true};
    };
  }
}
