<a class="download-back-btn" routerLink="/">
</a>
<div class="download-bg"></div>
<div class="col-12 download-wrapper text-center">
  <h1 class="title mb-2">{{'download-page-title' | translate}}</h1>
  <p class="subtitle">{{'download-page-description' | translate}}</p>
  <div class="row mt-5 mb-5">
    <div class="col-4">
      <img class="download-img img-fluid" src="assets/images/save-data.svg" alt="save data">
      <span class="download-label" translate>save-data</span>
    </div>
    <div class="col-4">
      <img class="download-img img-fluid" src="assets/images/first-news.svg" alt="fist-news">
      <span class="download-label" translate>first-hands-news</span>
    </div>
    <div class="col-4">
      <img class="download-img img-fluid" src="assets/images/better-experience.svg" alt="better experience">
      <span class="download-label" translate>better-experience</span>
    </div>
  </div>

  <a class="download-btn" mat-flat-button color="primary" (click)="addToHomeScreen()">{{'download-now' | translate}}</a>
  <div class="mt-4">{{'available-for-android' | translate}}</div>
</div>

