import { ISearchBaseSportsBookFilters } from './common';
import { ILanguageEntityLabel } from './language';
import { IMarketGroup } from './market-group';

export interface IMarketSuperGroup {
  id: number;
  betOfferId: number;
  name: string;
  sportId: number;
  languages?: ILanguageEntityLabel[];
  g?: Array<IMarketGroup>;
  orderId: number;
  isPrimary: boolean;
}

export interface IMarketSuperGroupSearchFilters extends ISearchBaseSportsBookFilters {
  sportId: number;
}
