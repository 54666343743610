import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentConfig, IBetOffer, ISport, ISportEvent, ITournament } from '@bs/models';

import { Observable, of } from 'rxjs';

@Injectable()
export class SportsbookService {

  readonly url = `${this.config.api.sportsbook}/sports-books`;
  private betoffer = 'assets/mocks/betoffer.json'; // Sostituisci con il percorso corretto del tuo file JSON locale

  constructor(private config: EnvironmentConfig, private http: HttpClient) {

  }

  get(params: Params): Observable<IBetOffer> {
    // return this.http.get<IBetOffer>(`${this.url}`, {params})
    return this.http.get<IBetOffer>(this.betoffer, { params });

  }

  /**
   * get all the available sports
   */
  getSports(): Observable<ISport> {
    return this.http.get<ISport>(`${this.url}/sports`)
  }

  /**
   * get all the available tournaments
   */
  getTournaments(params: Params): Observable<Array<ITournament>> {
    return this.http.get<Array<ITournament>>(`${this.url}/tournaments`, {params})
  }

  /**
   * get all sport-events in eventStatus Not Started
   */
  getSportEventsByTournament(tId: number, params: Params): Observable<ITournament> {
    //return this.http.get<ITournament>(`${this.url}/tournaments/${tId}/sport-events`, {params});
    return this.http.get<ITournament>('assets/mocks/tournament100.json', {params});
  }

  getSportEvent(id: number, params: Params): Observable<ISportEvent> {
    //return this.http.get<ISportEvent>(`${this.url}/sport-events/${id}`, {params});
    return this.http.get<ISportEvent>(`assets/mocks/event.json`, {params});
  }

  getSportEvents(params: Params): Observable<Array<ISportEvent>> {
    return this.http.get<Array<ISportEvent>>(`${this.url}/sport-events`, {params});
  }

}
