export const CurrenciesByCountry = new Map([
  //['RU', 'RUB'],
  ['CA', 'CAD'],
  //['CH', 'CHF'],
  //['AR', 'ARS'],
  //['CL', 'CLP'],
  //['CO', 'COP'],
  //['MX', 'MXN'],
  //['PE', 'PEN'],
  //['ind', 'INR'],
  //['aus', 'AUD'],
  //['niu', 'NZD'],
  //['cok', 'NZD'],
  //['tkl', 'NZD'],
  ['NZ', 'NZD'],
  ['BR', 'BRL'],
  //['idn', 'IDR'],
  //['mys', 'MYR'],
  //['nor', 'NOK'],
  //['phl', 'PHP'],
  //['tha', 'THB'],
  //['vnm', 'VND'],
  //['cmr', 'XAF'],
  //['caf', 'XAF'],
  //['tcd', 'XAF'],
  //['cog', 'XAF'],
  //['gnq', 'XAF'],
  //['gab', 'XAF'],
  //['ben', 'XOF'],
  //['bfa', 'XOF'],
  //['gnb', 'XOF'],
  //['mli', 'XOF'],
  //['ner', 'XOF'],
  //['sen', 'XOF'],
  //['tgo', 'XOF'],
  //['civ', 'XOF']
]);
