import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UnsubscribeService } from '@bs/common';
import { getSectionByTypeId, IBaseGame, IGameProvider, IGameType } from '@bs/models';
import { GamesFavouritesService } from '@bs/services';
import { CasinoFilterDialog } from '@homer/games';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'casino-filter',
  templateUrl: 'casino-filter.component.html',
  styles: [`:host {
    display: block
  }`]
})

export class CasinoFilterComponent extends UnsubscribeService {

  @Input()
  showSort: boolean;

  @Input()
  showProvidersFilter: boolean;

  @Output()
  sortBy = new EventEmitter(null);

  favourites: Array<IBaseGame>;
  providers: Array<IGameProvider>;
  gameType: IGameType;

  searchGame = '';

  section = '';
  currLang = '';

  constructor(translate: TranslateService, route: ActivatedRoute, private router: Router,
              gamesFavouritesService: GamesFavouritesService, cdr: ChangeDetectorRef, private dialog: MatDialog) {
    super();
    this.currLang = translate.currentLang;

    route.parent.data.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: data => {
        this.section = data.section;
        this.providers = data.gameType.providers;
        this.gameType = data.gameType;
      }
    });

    gamesFavouritesService.favourites$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: favourites => {
        this.favourites = favourites.filter(f => getSectionByTypeId(f.gameTypeId) === this.section);
        cdr.markForCheck();
      }
    });
  }

  openProvidersFilter() {

    const dialogRef = this.dialog.open(CasinoFilterDialog, {
      data: this.gameType,
      panelClass: ['dialog-full', 'casino-filter-dialog']
    });

    dialogRef.afterClosed().subscribe({
      next: (res: { [key: string]: Array<number> }) => {
        this.router.navigate([], {queryParams: res});
      }
    });
  }

  searchForGame(search: string) {
    this.router.navigate([`${this.currLang}/games/${this.section}`], {queryParams: {search}});
  }
}
