import { Component, Input, OnInit } from '@angular/core';
import { EnvironmentConfig, ICurrency, ISelfLimitation, LimitationLength } from '@bs/models';
import { SelfLimitationService } from '@bs/services';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { limitationsValidator } from '@bs/forms';


@Component({
  selector: 'self-limitations',
  templateUrl: 'self-limitations.component.html',
  styleUrls: ['./self-limitations.component.scss']
})

export class SelfLimitationsComponent implements OnInit {

  @Input()
  currency: ICurrency

  loading = false;
  limitations: Array<ISelfLimitation>;
  limitationValues = LimitationLength.values();
  selfLimitationForms: Array<{ form: UntypedFormGroup, name: string }>;

  periods(name: string) {
    const currentForm = this.selfLimitationForms.find(f => f.name === name);

    if (currentForm) {
      return currentForm.form.get('periods') as FormArray;
    }
    return this.fb.array([]);
  }

  constructor(private fb: UntypedFormBuilder, private config: EnvironmentConfig, private selfLimitationService: SelfLimitationService) {
    const selfLimitations = config.features.responsibleGaming.selfLimitation;
    if (selfLimitations) {
      this.selfLimitationForms = selfLimitations.map(name => {

        const form = fb.group({
          periods: fb.array([
            ...this.limitationValues.map(x => (fb.group({
              name: [x.name],
              amount: [{value: '', disabled: true}, Validators.compose([Validators.required, Validators.min(1)])],
              active: [false]
            })))
          ], {validators: limitationsValidator})
        });

        const periodsCtrl = form.get('periods') as FormArray;

        periodsCtrl.controls.forEach(c => {
          c.get('active').valueChanges.subscribe({
            next: v => {
              const amountCtrl = c.get('amount');
              if (v) {
                amountCtrl.enable({emitEvent: false});
              } else {
                amountCtrl.setValue('');
                amountCtrl.disable({emitEvent: false});
              }
            }
          })
        })

        return {form, name}
      })
    }
  }

  ngOnInit() {
    this.selfLimitationService.all().subscribe({
      next: limitations => {
        this.limitations = limitations;
        // const now = new Date();
        // this.limitations.push({
        //   type: 'deposit', lenghtId: 1,
        //   value: 100,
        //   // currency: {id: 1, name: 'eur', code: ''},
        //   creationDate: now, closingDate: now
        // })
      }
    })
  }


  submit(data: { form: UntypedFormGroup, name: string }) {
    this.selfLimitationService.update(data.name, data.form.value).subscribe({
      next: res => {

      },

      error: err => {

      }
    })

  }


}
