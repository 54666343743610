@if (!complete) {
  @if (form) {
    <form (ngSubmit)="submit()" [formGroup]="form" autocomplete="off">
      @if (isLoading) {
        <div class="loading-overlay">
          <mat-spinner [diameter]="22" color="accent"></mat-spinner>
        </div>
      }
      <!--<pre class="floating">{{form.value | json}}</pre>-->
      <ng-template #gridFormTpl>
        <div class="row my-3">
          @for (step of struct.steps; track step; let first = $first; let last = $last) {
            <div class="col-md-{{12 / struct.steps.length}}">
              <div class="register-column h-100">
                @if (first) {
                  <h2 class="register-title">{{ 'register' | translate }}</h2>
                }
                <div class="register-label mb-4">{{ step.label }}</div>
                <ng-container [ngTemplateOutletContext]="{$implicit:step}" [ngTemplateOutlet]="fieldsTpl"></ng-container>
                @if (last) {
                  <button [color]="!form.valid ? null : 'accent'" [disabled]="!form.get(step.name).valid" class="w-100 mt-4" mat-raised-button type="submit">{{ 'register' | translate }}</button>
                }
              </div>
            </div>
          }
        </div>
      </ng-template>
      <ng-template #steppedHorizontalTpl>
        @if (isEuRegistration) {
          <div class="px-3 eu-wrapper position-relative">
            <h1 class="step-title my-4 text-center">{{ 'registration-title' | translate }}</h1>
            <dic class="scrollbar fields-content" [class.disabled]="isMobile">
              <ng-container [ngTemplateOutletContext]="{$implicit:struct.steps[0]}" [ngTemplateOutlet]="fieldsTpl"></ng-container>
            </dic>
            <div class="reg-buttons-wrapper d-flex flex-column first-step">
              <button (click)="validateAndNext(struct.steps[0])" [color]="!form.get(struct.steps[0].name).valid ? null:'primary'" [disabled]="!form.get(struct.steps[0].name).valid" class="w-100 next-btn " mat-flat-button
                      type="button">{{ 'next' | translate }}
              </button>
              <div class="text-center mt-3 pb-2">
                <span translate>registration-do-you-have-account-question</span>?
                <a [routerLink]="[]" fragment="login">{{ 'signIn' | translate }}</a>
              </div>
            </div>
          </div>
        }
        @if (!isEuRegistration) {
          <mat-horizontal-stepper #stepper [linear]="true" labelPosition="bottom">
            <ng-template matStepperIcon="edit">
            </ng-template>
            <ng-template matStepperIcon="done">
            </ng-template>
            @for (step of struct.steps; track step; let first = $first; let last = $last) {
              <mat-step [state]="step.name" [completed]="false">
                <ng-template matStepLabel>{{ step.label | translate }}</ng-template>
                <ng-container [ngTemplateOutletContext]="{$implicit:step}" [ngTemplateOutlet]="fieldsTpl"></ng-container>
                <div class="mb-5 mb-md-0 reg-buttons-wrapper">
                  @if (!first) {
                    <button class="float-left me-2 " color="primary" mat-flat-button matStepperPrevious type="button">{{ 'prev' | translate }}</button>
                  }
                  @if (!last) {
                    <button (click)="validateAndNext(step)" [color]="!form.get(step.name).valid ? null:'primary'" [disabled]="!form.get(step.name).valid" class="float-right " [class.second-step-next]="!first" mat-flat-button
                            type="button">{{ 'next' | translate }}
                    </button>
                  }
                  @if (last) {
                    <button [color]="!form.valid ? null : 'accent'" [disabled]="!form.get(step.name).valid" class="float-right " mat-raised-button type="submit">{{ 'register' | translate }}</button>
                  }
                </div>
                <mat-error [control]="form" class="position-relative mt-md-5 w-100"></mat-error>
              </mat-step>
            }
            @for (step of struct.steps; track step) {
              <ng-template [matStepperIcon]="step.name">
                <svg [icon]="step.icon" lib="global" [size]="{w:14, h:14}"></svg>
              </ng-template>
            }
          </mat-horizontal-stepper>
        }
      </ng-template>
      <ng-template #steppedVerticalTpl>
        <mat-vertical-stepper #stepper [linear]="true" labelPosition="bottom">
          <ng-template matStepperIcon="edit"></ng-template>
          <ng-template matStepperIcon="done"></ng-template>
          @for (step of struct.steps; track step; let first = $first; let last = $last) {
            <mat-step [state]="step.name" [completed]="false">
              <ng-template matStepLabel>{{ step.label | translate }}</ng-template>
              <ng-container [ngTemplateOutletContext]="{$implicit:step}" [ngTemplateOutlet]="fieldsTpl"></ng-container>
              <div class="mb-5 mb-md-0">
                @if (!first) {
                  <button class="float-left" color="primary" mat-flat-button matStepperPrevious type="button">{{ 'prev' | translate }}</button>
                }
                @if (!last) {
                  <button (click)="validateAndNext(step)" [color]="!form.get(step.name).valid ? null:'primary'" [disabled]="!form.get(step.name).valid" class="float-right" mat-flat-button type="button">{{ 'next' | translate }}</button>
                }
                @if (last) {
                  <button [color]="!form.valid ? null : 'accent'" [disabled]="!form.get(step.name).valid" class="float-right" mat-raised-button type="submit">{{ 'register' | translate }}</button>
                }
              </div>
              <mat-error [control]="form" class="position-relative mt-md-5 w-100"></mat-error>
            </mat-step>
          }
          @for (step of struct.steps; track step) {
            <ng-template [matStepperIcon]="step.name">
              <svg [icon]="step.icon" lib="global" [size]="{w:14, h:14}"></svg>
            </ng-template>
          }
        </mat-vertical-stepper>
      </ng-template>
      <ng-template #fieldsTpl let-formStep>
        @for (input of formStep.inputs; track input) {
          <div [formGroupName]="formStep.name" class="{{formStep.name}}">
            @switch (input.type) {
              @case ('pin') {
                <pin-input class="mb-2" [formControlName]="input.name" [label]="input.label | translate"></pin-input>
              }
              @case ('mobile-prefix') {
                <div class="position-relative mb-5">
                  <mobile-prefix [formControlName]="input.name" [placeholder]="input.name | translate" class="outline bottom-borders mb-1"></mobile-prefix>
                  <mat-error [control]="form.get(formStep.name+'.'+input.name)" class="position-relative"></mat-error>
                </div>
              }
              @case ('checkbox') {
                <mat-checkbox [formControlName]="input.name" class="mb-3">
                  <span [innerHTML]="input.label | translate"></span>
                </mat-checkbox>
              }
              @case ('radio') {
                <mat-radio-group [formControlName]="input.name" class="d-flex justify-content-center mb-5" [class.flex-column]="fieldAppearance === 'outline'">
                  @if (input?.icon) {
                    <svg [icon]="input.icon" lib="global" [size]="{w:14, h:14}" matPrefix></svg>
                  }
                  <label [translate]="input.label" class="me-2"></label>
                  <div class="d-flex align-items-center">
                    @for (item of input.values; track item) {
                      <mat-radio-button [checked]="item.selected" [value]="item.id" class="me-2">{{ item.name | translate }}</mat-radio-button>
                    }
                  </div>
                </mat-radio-group>
              }
              @case ('date') {
                <mat-form-field class="mb-3">
                  <mat-label [translate]="input.label"></mat-label>
                  <input (dateChange)="dateChange($event, (formStep.name+'.'+input.name))" [formControlName]="input.name" [matDatepicker]="picker"
                         [min]="input.options?.min ? ages[input.options.min] : null"
                         [max]="input.validations?.adult ? ages.adult: ( input.options?.max ? ages[input.options.max] :null)" matInput>
                  <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error [control]="formStep.name ? form.get(formStep.name+'.'+input.name) : form.get(input.name)"></mat-error>
                </mat-form-field>
              }
              @case ('promotion-bonus') {
                <promotion-bonus [formControlName]="input.name"></promotion-bonus>
              }
              @case ('geo-infos') {
                <geo-infos [formControlName]="input.name" [isRegistration]="true"></geo-infos>
              }
              @case ('select') {
                <mat-form-field class="mb-3">
                  <!--          <pre>{{input | json}}</pre>-->
                  @if (input?.icon) {
                    <svg [icon]="input.icon" lib="global" [size]="{w:14, h:14}" matPrefix></svg>
                  }
                  <mat-label><span [class.required]="input.validations?.required">{{ input.label | translate }}</span></mat-label>
                  <mat-select [formControlName]="input.name" [panelClass]="input.label === 'currency' ? 'currency-registration' : ''" [value]="input.value" disableOptionCentering>
                    @for (value of input.values; track value) {
                      <mat-option [value]="value.id">{{ value.name }}</mat-option>
                    }
                    <!--                        <ng-container [ngSwitch]="input.translateValues">&lt;!&ndash;better before, less calc&ndash;&gt;-->
                    <!--                          <ng-container *ngSwitchDefault>-->
                    <!--                            <mat-option *ngFor="let item of (input.values | formValues:input)" [value]="item">{{item.name | translate}}</mat-option>-->
                    <!--                          </ng-container>-->
                    <!--                          <ng-container *ngSwitchCase="false">-->
                    <!--                            <mat-option *ngFor="let item of (input.values | formValues:input)" [value]="item">{{item.name}}</mat-option>-->
                    <!--                          </ng-container>-->
                    <!--                        </ng-container>-->
                  </mat-select>
                  <mat-error [control]="formStep.name ? form.get(formStep.name+'.'+input.name) : form.get(input.name)"></mat-error>
                </mat-form-field>
              }
              @case ('birth-place') {
                <birth-place [formControlName]="input.name"></birth-place>
              }
              @default {
                <mat-form-field class="mb-3">
                  <mat-label [translate]="input.label"></mat-label>
                  <input [formControlName]="input.name" [placeholder]="input.label | translate" [type]="input.type" [restrict]="input?.restrict" autocomplete="new-password" matInput>
                  @if (input.name === 'password' || input.name === 'repeat') {
                    <button (click)="input.type==='password' ? (input.type='text'):(input.type='password')"
                            [color]="input.type==='password' ? null : 'primary'" mat-icon-button matSuffix tabindex="-1" type="button">
                            <svg [icon]="input.type=== 'password' ? 'eye-off' : 'eye'" lib="global" [size]="{w:14, h:14}" matPrefix></svg>
                    </button>
                  }
                  <mat-error [control]="formStep.name ? form.get(formStep.name+'.'+input.name) : form.get(input.name)"></mat-error>
                </mat-form-field>
              }
            }
          </div>
        }
        @if (struct.steps.length === 1) {
          <button [color]="!form.valid ? null : 'primary'" [disabled]="!form.valid" class="float-right register-btn" mat-raised-button type="submit">{{ 'register' | translate }}</button>
        }
      </ng-template>
      <div class="scrollbar scrollbar-register">
        @if (gridLayout && struct.steps.length > 1) {
          <ng-container [ngTemplateOutletContext]="{$implicit:struct.steps[0]}" [ngTemplateOutlet]="gridFormTpl"></ng-container>
        } @else {
          <ng-container [ngTemplateOutletContext]="{$implicit:struct.steps[0]}" [ngTemplateOutlet]="struct.steps.length>1 ? (struct.direction === 'vertical' ? steppedVerticalTpl : steppedHorizontalTpl) : fieldsTpl"></ng-container>
        }
      </div>
    </form>
  }
} @else {
  <mat-card class="new-card">
    @switch (complete.outcome) {
      @case (true) {
        <div class="success col-12">
          <mat-card-content class="d-flex flex-column align-items-center justify-content-center h-100">
            <div class="text-center text-wrapper">{{ complete.message | translate }}</div>
          </mat-card-content>
          <mat-card-actions align="center">
            <button (click)="outcome.emit(true)" color="accent" class="w-100 mb-3" mat-flat-button type="button">{{ 'done' | translate }}</button>
          </mat-card-actions>
        </div>
      }
      @default {
        <div class="fail col-12">
          <mat-card-content class="d-flex flex-column align-items-center">
            <div class="p-3">
              <span>{{ complete.message | translate }}</span>
            </div>
          </mat-card-content>
          <mat-card-actions class="d-flex gx-0 justify-content-between">
            <button (click)="complete = null" color="accent" mat-flat-button type="button">{{ 'back' | translate }}</button>
            <button (click)="outcome.emit(true)" color="warn" mat-flat-button type="button">{{ 'done' | translate }}</button>
          </mat-card-actions>
        </div>
      }
    }
  </mat-card>
}

<!--<pre style="font-size: 10px; line-height: .9">{{form.value | json}}</pre>-->
