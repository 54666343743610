import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IMarketSelectionOdd } from '@bs/models';


@Injectable({
  providedIn: 'root'
})
export class OddService {
  readonly url = `${this.config.api.sportsbook}/odds`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  patchValue(item: IMarketSelectionOdd): Promise<IMarketSelectionOdd> {
    return this.http.patch<IMarketSelectionOdd>(`${this.url}/${item.id}/value`, item).toPromise();
  }

  patchBettingStatus(item: IMarketSelectionOdd): Promise<IMarketSelectionOdd> {
    return this.http.patch<IMarketSelectionOdd>(`${this.url}/${item.id}/betting-status`, item).toPromise();
  }

  patchPlayability(item: IMarketSelectionOdd): Promise<IMarketSelectionOdd> {
    return this.http.patch<IMarketSelectionOdd>(`${this.url}/${item.id}/playability`, item).toPromise();
  }
}
