<div class="read-more-content">
  <span [innerHTML]="readMore ? content : content | slice: 0 : limitContent | translate"></span>
  @if (!readMore && showBtn) {
    <span>&nbsp;...</span>
  }
  @if (!readMore && showBtn) {
    <div class="overlay-text"></div>
  }
</div>
@if (showBtn) {
  <div class="d-flex justify-content-center read-more-btn">
    @if (!readMore) {
      <button (click)="readMore=true" mat-button>{{'read-more' | translate}}</button>
    }
    @if (readMore) {
      <button mat-button (click)="readMore=false">{{'read-less' | translate}}</button>
    }
  </div>
}

