import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Route, RouterModule, Routes } from '@angular/router';
import { AccountTypeId, gameTypeEnum, MaggieMenu } from '@bs/models';
import { PromoService, PromotionsResolver } from '@bs/promotions';
import { CasinoTournamentsResolver, GamesGuard, GameTypeResolver, MenuResolver, PageResolver, PlayGameResolver, PrivateAreaGuard, SlidersResolver } from '@bs/services';
import { BannerSliderComponent, HomerCommonModule } from '@homer/common';
import { HomerGamesModule } from '@homer/games';
import { AppComponentsModule } from '@maggie/app/components/app.components.module';
import { DirectivesModule } from '@maggie/app/directives/directives.module';
import { CasinoTournamentsPage } from '@maggie/app/pages/games/casino-tournaments/casino-tournaments.page';
import { HomePage } from '@maggie/app/pages/games/home/home.page';
import { environment } from '@maggie/environments/environment';
// Pages
import { FavouritesPage } from './favourites/favourites.page';
import { ListGamesPage } from './list-games/list-games.page';
import { PlayGamePage } from './play-games/play-game.page';
import { MatTabsModule } from '@angular/material/tabs';

const components = [
  CasinoTournamentsPage,
  FavouritesPage,
  ListGamesPage,
  PlayGamePage,
  HomePage,
];

const base: Partial<Route> = {
  canActivate: [GamesGuard],
  resolve: {
    gameType: GameTypeResolver,
    menu: MenuResolver,
    sliders: SlidersResolver,
    promotions: PromotionsResolver
  },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
}

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ''
  },
  {
    path: '',
    component: HomePage,
    data: {
      name: 'casino',
      section: 'home',
      gameTypeId: gameTypeEnum.Casino,
      menuId: MaggieMenu.Casino,
      prohibitions: [AccountTypeId.Agent, AccountTypeId.Shop],
    },
    ...base,
  },
  {
    path: ':section/play/:game_id/mode/:mode',
    component: PlayGamePage,
    resolve: {
      game: PlayGameResolver,
      gameType: GameTypeResolver,
    },
    canActivate: [PrivateAreaGuard, GamesGuard],
    data: {
      prohibitions: [AccountTypeId.Agent, AccountTypeId.Shop],
      hideMobileHeader: true,
      gameTypeId: gameTypeEnum.Casino,
    },
  },
  {
    path: 'casino',
    data: {
      prohibitions: [AccountTypeId.Shop, AccountTypeId.Agent],
      name: 'casino',
      section: 'casino',
      menuId: MaggieMenu.Casino,
      gameTypeId: gameTypeEnum.Casino,
    },
    ...base,
    children: [
      {
        path: '',
        component: ListGamesPage,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'favourites',
        component: FavouritesPage,
        data: {
          name: 'casino',
          section: 'casino'
        },
      },
      {
        path: 'tournaments',
        component: CasinoTournamentsPage,
        resolve: {
          tournaments: CasinoTournamentsResolver,
        },
        data: {
          name: 'tournaments',
          section: 'casino',
          gameTypeId: gameTypeEnum.Casino
        },
        title: 'casino-tournaments'
      },
      {
        path: 'tournaments/:id',
        component: CasinoTournamentsPage,
        resolve: {
          tournaments: CasinoTournamentsResolver,
        },
        data: {
          name: 'tournaments',
          section: 'casino',
          gameTypeId: gameTypeEnum.Casino
        },
        title: 'casino-tournaments'
      },
      {
        path: ':section',
        component: ListGamesPage,
      }
    ]
  },
  {
    path: 'casino-live',
    data: {
      prohibitions: [AccountTypeId.Agent, AccountTypeId.Shop],
      name: 'casino-live',
      section: 'casino-live',
      menuId: MaggieMenu.CasinoLive,
      gameTypeId: gameTypeEnum.LiveCasino,
    },
    ...base,
    children: [
      {
        path: '',
        component: ListGamesPage,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'favourites',
        component: FavouritesPage,
        data: {
          name: 'casino-live',
          section: 'casino-live'
        },
      },
      {
        path: ':section',
        component: ListGamesPage
      }
    ]
  },
  {
    path: 'virtuals',
    data: {
      prohibitions: [AccountTypeId.Shop, AccountTypeId.Agent, AccountTypeId.Cashier],
      name: 'virtuals',
      section: 'virtuals',
      menuId: MaggieMenu.Virtual,
      gameTypeId: gameTypeEnum.Virtual
    },
    ...base,
    children: [
      {
        path: '',
        component: ListGamesPage,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',

      },
      {
        path: 'favourites',
        component: FavouritesPage,
        data: {
          name: 'virtuals',
          section: 'virtuals'
        },
      },
      {
        path: ':section',
        component: ListGamesPage
      }
    ]
  },
  {
    path: 'crash-games',
    data: {
      prohibitions: [AccountTypeId.Shop, AccountTypeId.Agent],
      name: 'crash-games',
      section: 'crash-games',
      menuId: MaggieMenu.CrashGames,
      gameTypeId: gameTypeEnum.CrashGames
    },
    ...base,
    children: [
      {
        path: '',
        component: ListGamesPage,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'favourites',
        component: FavouritesPage,
        data: {
          name: 'crash-games',
          section: 'crash-games'
        },
      },
      {
        path: ':section',
        component: ListGamesPage
      }
    ]
  },
  {
    path: ':section/play/:game_id/mode/:mode',
    component: PlayGamePage,
    resolve: {
      game: PlayGameResolver
    },
    canActivate: [PrivateAreaGuard, GamesGuard], // AuthenticateBsGuard,
    data: {
      prohibitions: [AccountTypeId.Agent, AccountTypeId.Shop],
      hideMobileHeader: true
    }
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    HomerCommonModule,
    AppComponentsModule,
    DirectivesModule,
    HomerGamesModule.forRoot(environment),
    BannerSliderComponent,
    MatCardModule,
    MatButtonModule,
    MatTabsModule,
    MatTooltipModule
  ],
  providers: [PromoService],
  declarations: components,
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class GamesModule {

}
