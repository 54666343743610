import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'text-dialog',
  styleUrls: ['text.dialog.scss'],
  templateUrl: 'text.dialog.html'
})
export class TextDialog {

  constructor(public dialogRef: MatDialogRef<TextDialog>, @Inject(MAT_DIALOG_DATA) public data) {

  }
}
