import { IAuthor } from '../common/author';

export interface IpWhitelist {
  id?: number;
  name: string;
  ip: string;
  creationDate?: string;
  author?: IAuthor;
  isDeleted?: boolean;
}

export interface IpWhiteListCreateRequest {
  name: string;
  ip: string;
}
