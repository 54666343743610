import { Component, HostBinding, Input } from '@angular/core';
import { BaseFormBuilder } from '@bs/forms';

/**
 * component that dynamically creates the form, with the fields from json file
 */
@Component({
  styleUrls: ['./form-builder.component.scss'],
  selector: 'form-builder[config]',
  templateUrl: 'form-builder.component.html'
})
export class FormBuilderComponent extends BaseFormBuilder {
  /**
   * when true 1 input field will take a full row
   */
  @Input()
  @HostBinding('class.vertical')
  vertical = false;

}
