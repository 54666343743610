import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IMe, IReferralLink, IReferralLinkSearchRequest, Pager } from '@bs/models';
import { Observable } from 'rxjs';
import { DateFactoryService } from '../../services/core/date-factory.service';

import { ReferralLinkService } from '../../services/accounts/referral-link.service';
import { BaseResolver } from '../core/base.resolver';

/**
 * Resolver is returning us referral links as a pagination items
 *
 * see {@link BaseResolver} for more info
 */
@Injectable({
  providedIn: 'root'
})
export class ReferralsResolver extends BaseResolver  {

  /**
   * The constructor that passes to the super class BaseResolver
   *
   * see {@link DateFactoryService} for more info
   * @param referralLinkService
   * @param dfs
   */
  constructor(private referralLinkService: ReferralLinkService, dfs: DateFactoryService) {
    super(dfs);
  }

  /**
   * the resolve function retrieves us the data needed to activate for the requested route
   *
   * see {@link IReferralLink} for more details about model
   * @param route
   */
  resolve(route: ActivatedRouteSnapshot): Observable<Pager<IReferralLink>> | Promise<Pager<IReferralLink>> | Pager<IReferralLink> {

    if (route.data.stopResolve && !route.queryParamMap.keys.length) {
      return {
        list: [],
        count: 0
      };
    }

    const me: IMe = route.parent.parent.data.me;

    const values: IReferralLinkSearchRequest = {
      networkId: me.id,
      displayCurrencyId: me.currencies[0].id,
      p: route.queryParams.p || 1,
      pp: route.queryParams.pp || 10,
      ...this.remap(route.queryParamMap)
    }

    return this.referralLinkService.search(values);
  }

}
