import { Injectable, signal, Signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentConfig, IWebapp } from '@bs/models';
import { Observable, tap } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class WebappService {
  readonly url = `${this.config.api.endpoint}/core/webapp`;
  public webappConfig!: Signal<IWebapp>;

  wa$: Observable<IWebapp>;

  constructor(private http: HttpClient, private config: EnvironmentConfig) {
    this.wa$ = this.http.get<IWebapp>(this.url).pipe(shareReplay(1));
  }

  webapp(): Observable<IWebapp> {
    return this.wa$.pipe(tap(webapp => {
      this.webappConfig = signal(webapp);
    }))
  }
}
