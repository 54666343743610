import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ISelfLimitation } from '@bs/models';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SelfLimitationService {
  readonly url = `${this.config.api.identity}/me/self-limitations`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  all(): Observable<Array<ISelfLimitation>> {
    return this.http.get<Array<ISelfLimitation>>(this.url);
  }


  update(name: string, value: Record<string, string>) {
    return this.http.put(`${this.url}`, {value, name});
  }
}
