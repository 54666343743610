import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentConfig, ICountry, IWallet } from '@bs/models';

export interface IPaymentMethod {
  id: number;
  name: string;
  description?: string;
  icon?: string;
  group: { id: number; name: string };
  isDeposit: boolean;
  type: 'checkout' | 'push' | 'qrcode';
  fees?: { percentage?: number; fixed?: number; }
  validators: { min: number; max: number };
  countries?: Array<ICountry>
}

export interface IDepositRequest {
  paymentMethodId: number;
  amount: number;
  bonusCode?: string;
}

export interface IDepositResponse {
  transactionId: number;
  url?: string;
}

export interface IWithdrawalRequest {
  paymentMethodId: number;
  amount: number;
}

export interface IWithdrawalResponse {
  movementId: number;
  wallets: Array<IWallet>;
}


@Injectable()
export class PaymentMethodsService {
  readonly url = `${this.config.api.endpoint}/me/payment-methods`;

  // todo EnvironmentConfig to be replace with EnvConfig
  constructor(private http: HttpClient, private config: EnvironmentConfig) {
  }

  deposit(request: IDepositRequest) {
    return this.http.post<IDepositResponse>(`${this.url}/deposits`, request);
  }

  withdrawal(request: IWithdrawalRequest) {
    return this.http.post<IWithdrawalResponse>(`${this.url}/withdrawals`, request);
  }

  getAll(isDeposit: boolean) {
    const params: Params = {isDeposit: isDeposit};
    return this.http.get<Array<IPaymentMethod>>(`${this.url}`, {params});
  }
}
