import { AbstractControl, ValidatorFn } from '@angular/forms';

export function amountWithdrawableValidator(balance: number, min: number, max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const amount = control.value ? parseFloat(control.value) : 0;

    if (amount > balance / 100) {
      return {'insufficient-balance': true};
    }

    return null
  };
}
