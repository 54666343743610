import { DecimalPipe } from '@angular/common';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { map, Subscription } from 'rxjs';

@Directive({
  selector: '[inputWithComma]',
  providers: [DecimalPipe]
})
export class InputCommaDirective implements OnInit, OnDestroy {

  private subscription: Subscription;
  private previousString = '';
  private regex = new RegExp(/^[0-9]*$/);

  constructor(private ngControl: NgControl, private decimal: DecimalPipe) {
  }

  ngOnInit() {
    const control = this.ngControl.control;
    if(control.value) {
      this.previousString = control.value;
      const firstValue = this.convertDecimal(`${control.value}`);
      control.setValue(firstValue);
    }
    this.subscription = control.valueChanges.pipe(
      map(value => {
        if(!value) {
          return this.convertDecimal(this.previousString.toString());
        }
        value = value.toString().replaceAll(',', '');
        if(value.match(this.regex)) {
          this.previousString = value.toString();
          return this.convertDecimal(value.toString());
        } else {
          return this.convertDecimal(this.previousString.toString());
        }
      })
    ).subscribe({
      next: value => control.setValue(value, {emitEvent: false})
    });
  }

  private convertDecimal(letters: string): string {
    if(!letters) {
      return null;
    }
    const parts = letters.split('.');
    parts[0] = this.decimal.transform(parts[0].replace(/,/g, ''));
    return parts.join('.');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
