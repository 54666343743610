import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IBaseGame, IGame } from '@bs/models';

@Component({
  selector: 'casino-games-grid',
  templateUrl: 'casino-games-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CasinoGamesGridComponent {

  @Input()
  gamesLimit = 20;

  @Input()
  games: Array<IGame> | Array<IBaseGame>;

  @Output()
  gamesLimitChange: EventEmitter<number> = new EventEmitter();

  addPlusGames = 20;

  loadMoreGames() {
    this.gamesLimit += this.addPlusGames;
    this.gamesLimitChange.emit(this.gamesLimit);
  }

  trackByFn(i: number, game: IGame): number {
    return game.id;
  }
}
