import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EnvironmentConfig } from '@bs/models';

@Component({
  selector: 'settings-dialog',
  templateUrl: './settings-dialog.component.html'
})
export class SettingsDialog implements OnInit {

  allowThemeSwitch = this.config.features.allowThemeSwitch;

  constructor(private config: EnvironmentConfig, private dialogRef: MatDialogRef<SettingsDialog>) {
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }


}
