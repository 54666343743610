interface OddinEvent {
  type: 'LOADED' | 'ERROR' | 'REQUEST_SIGN_IN' | 'REQUEST_REFRESH_BALANCE' | 'ROUTE_CHANGE'
  error: number;
  route: string;
}

export interface Oddin {
  destroy: () => void;
  iframe: HTMLIFrameElement;
  refreshToken: (token: string) => void
  updateConfig: (c: Partial<OddinConfig>) => void
}

export interface OddinConfig {
  token?: string;
  brandToken: string;
  baseUrl: string
  referer?: string
  language?: string
  currency?: string
  height?: string | number | (() => number);
  contentElement: string
  eventHandler?: (e: OddinEvent) => void,
  banners?: BannersConfig
}


enum BannerType {
  Iframe = 'IFRAME',
  Image = 'IMAGE'
}

type BaseBanner<T extends BannerType> = {
  height?: number
  type: T
}

type ImageBanner = {
  src: string
  link?: string
} & BaseBanner<BannerType.Image>

type IframeBanner = {
  src: string
} & BaseBanner<BannerType.Iframe>

type Banner = ImageBanner | IframeBanner

type BannersConfig = {
  timeline?: {
    items: Banner[]
  }
}
