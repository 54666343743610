<div class="timer">
  <h4 class="text-center mt-0 mb-3">{{ 'time-remaining' | translate }}</h4>
  <div class="d-flex">
    <div class="cell days">
      @if (days > 0) {
        <div class="value">
          <div [innerHTML]="convertString(days)"></div>
        </div>
      } @else {
        <div class="value">
          <div>
            <span class="cypher">0</span>
            <span class="cypher">0</span>
          </div>
        </div>
      }
      <span class="key">{{ isInline ? ('days' | translate)[0] : days > 1 ? ('days' | translate) : ('day' | translate) }}</span>
    </div>
    <div class="cell hours">
      @if (hours > 0) {
        <div class="value">
          <div [innerHTML]="convertString(hours)"></div>
        </div>
      } @else {
        <div class="value">
          <div>
            <span class="cypher">0</span>
            <span class="cypher">0</span>
          </div>
        </div>
      }
      <span class="key">{{ isInline ? ('hours' | translate)[0] : hours > 1 ? ('hours' | translate) : ('hour' | translate) }}</span>
    </div>

    <div class="cell minutes">
      @if (minutes > 0) {
        <div class="value">
          <div [innerHTML]="convertString(minutes)"></div>
        </div>
      } @else {
        <div class="value">
          <div>
            <span class="cypher">0</span>
            <span class="cypher">0</span>
          </div>
        </div>
      }
      <span class="key">{{ isInline ? ('minutes' | translate)[0] : minutes > 1 ? ('minutes' | translate) : ('minute' | translate) }}</span>
    </div>

    <div class="cell seconds">
      @if (seconds > 0) {
        <div class="value">
          <div [innerHTML]="convertString(seconds)"></div>
        </div>
      } @else {
        <div class="value">
          <div>
            <span class="cypher">0</span>
            <span class="cypher">0</span>
          </div>
        </div>
      }
      <span class="key">{{ isInline ? ('seconds' | translate)[0] : seconds > 1 ? ('seconds' | translate) : ('second' | translate) }}</span>
    </div>
  </div>
</div>

