interface ScriptTagInstruction {
  keys: Record<string,string>;
  instruction: 'create' | 'inline';
  appendTo?: string;
}

export interface ScriptTag {
  inRoot?: boolean;
  name: string;
  type: 'text/javascript' | string;
  link: string;
  async?: boolean;
  defer?: boolean;
  options?: TagOptions;
  loaded?: boolean;
  status?: 'Loaded' | 'Already Loaded' | 'Errored' | null;
}

interface TagOptions {
  callback?: (settings:any) => void;
  preload?: () => void;
  beforeLoad?: ScriptTagInstruction;
}
