<ng-template #requirementTpl let-req>
  @switch (req.key) {
    @case ('minMultiplierOdd') {
      <span>{{ 'requiredMinOdds'| translate:{value: (req.value | oddFormat: (oddFormat$ | async))} }}</span>
    }
    @case ('minEventsCount') {
      <span>{{ 'requiredMinEvents'| translate:{value: req.value} }}</span>
    }
    @case ('bettingTypeId') {
      <span>{{ 'requiredBettingType'| translate:{value: (req.value|formValues:{values: 'bettingTypes'})|translate} }}</span>
    }
    @case ('bettingGroupTypeId') {
      <span>{{ 'requiredBettingGroupType'| translate:{value: (req.value|formValues:{values: 'bettingGroups'})|translate} }}</span>
    }
    @case ('minSelectionOdd') {
      <span>{{ 'requiredMinSelectionOdd'| translate:{value: req.value|oddFormat: (oddFormat$ | async):true} }}</span>
    }
    @default {
      non-existent
    }
  }
</ng-template>

<mat-menu #promoDetails="matMenu" yPosition="above" backdropClass="promotion-dropdown-menu" xPosition="before">
  <ng-template let-promo="promo" matMenuContent>
    <div class="promotion-description">
      <mat-card>
        <div class="promotion-description-icon status-{{promo.status}} d-flex align-items-center">
          <span class="ms-2">{{ promo.name }}</span>
        </div>
        <img [alt]="promo.name" [src]="promo.imageUrl" class="promotion-description-banner my-2">
        <div class="d-flex-flex-column promotion-description-content">
          <!--          <h2 class="promotion-description-title mt-0 mb-2" translate>{{promo.name}}</h2>-->
          <div>
            <span translate>endingOn</span>&nbsp;
            <span class="promotion-description-date-end">{{ promo.endDate | localizedDate: 'HH:mm:ss' }}</span>
          </div>
        </div>
        <div class="promotion-description-bonus my-2">
          @for (step of promo.steps; track step) {
            <div class="text">{{ step.infos[0].name }}</div>
            <ul class="steps">
              <li>
                @for (info of step.infos; track info) {
                  <div>
                    <div>{{ info.description }}</div>
                  </div>
                }
                <hr>
                <ul class="requirements">
                  @for (req of step.requirements; track req) {
                    <li [class.success]="req.isCompleted">
                      <ng-template [ngTemplateOutlet]="requirementTpl" [ngTemplateOutletContext]="{$implicit:req}"></ng-template>
                    </li>
                  }
                </ul>
              </li>
            </ul>
          }

        </div>
        <div class="promotion-description-terms">
          <p [innerHTML]="promo.terms" class="description my-1"></p>
        </div>
      </mat-card>
    </div>
  </ng-template>
</mat-menu>

@if (promotions$ | async; as promotions) {
  <mat-slide-toggle class="mb-4" (blur)="onTouched()" [(ngModel)]="model.choosePromotion" (ngModelChange)="update({choosePromotion: model.choosePromotion, selectedPromotion: promotions[0]?.id})">
    <span translate>choosePromotions</span>
  </mat-slide-toggle>
  @for (promotion of promotions; track promotion) {
    <mat-card class="promotion-box mb-3 py-2 px-3" [ngClass]="{'disabled': !model.choosePromotion, 'active': promotion.id === model.selectedPromotion}"
              (click)="update({choosePromotion: model.choosePromotion, selectedPromotion: promotion.id})">
      <div class="d-flex align-items-center">
        <img src="assets/images/gift-register.svg" height="32" width="32" class="me-2" alt="{{'registerIcon' | translate}}">
        <div class="d-flex flex-column flex-grow-1">
          <strong class="ellipsis promotion-name mb-1">{{ promotion.name }}</strong>
          <span class="ellipsis promotion-description">{{ promotion.description }}</span>
        </div>
      </div>
    </mat-card>
  }
}



