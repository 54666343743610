<ng-template #itemsTpl>
  <!--  <pre>{{paymentMethods | json}}</pre>-->
  <div class="payments-method-container col-12 p-0">
    @if (!selected) {
      <strong>{{ 'selectPaymentMethod' | translate }}</strong>
    }
    <div class="payments-method-info d-flex">
      @for (pm of paymentMethodsFiltered; track pm) {
        <div class="row">
          <div class="col-md-12 logo-container">
            <div class="p-2">
              <div class="card-cnt">
                <a (click)="selectMethod(pm.id)">
                  <img [src]="pm.logoPath" [alt]="'payment-method-logo-alt' | translate"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #noItems>
  <div class="alert alert-secondary m-5" role="danger">
    <span [translate]="'payment-method-not-available'" class="text-center"></span>
  </div>
</ng-template>

@if (paymentMethods?.length) {
  <div class="payments-methods-container">
    <div class="col-12 payments-methods-list d-flex flex-wrap justify-content-center px-0">
      <div class="d-sm-block col-12 m-b-30">
        <mat-button-toggle-group (ngModelChange)="selectMethodForSelect($event)" [(ngModel)]="selectedProviderGroupId"
                                 class="row gx-0 w-100" color="primary">
          @for (paymentGroups of paymentMethods | groupBy:'groupName'; track paymentGroups) {
            <mat-button-toggle
              [value]="paymentGroups.values[0].groupId" class="col-4">
              {{ paymentGroups.index | translate }}
            </mat-button-toggle>
          }
        </mat-button-toggle-group>
      </div>
      <!--      <div class="row mb-2">-->
      <!--        <span>Available balance</span>-->
      <!--        {{me.wallets[0].balance.available | moneyFormat:me?.currencies[0]:true}}-->
      <!--      </div>-->
      <ng-template [ngTemplateOutlet]="paymentMethods.length? itemsTpl : noItems"
                   [ngTemplateOutletContext]="{$implicit: noItems}"></ng-template>
    </div>
    @if (selected) {
      <div class="row">
        <div class="col-12 payments-method-container">
          <div class="payments-method-info">
            <ng-container>
              <div class="details-container">
                <div class=" align-items-center d-flex">
                  <strong class="me-2">{{ selected.name }}</strong>
                  <div class="d-flex align-items-center me-2">
                    <span>{{ 'min' | translate }}</span>
                    <span class="ms-1 me-2">{{ selected.minValue | moneyFormat:me?.currencies[0]:true }}</span>
                    <span> | </span>
                  </div>
                  <span class="d-flex align-itens-center me-2">
                  <span>{{ 'max' | translate }}</span>
                  <span class="ms-1 me-2">{{ selected.maxValue | moneyFormat:me?.currencies[0]:true }}</span>
                </span>
                </div>
              </div>
            </ng-container>
            @if (!selected.isText) {
              <form [formGroup]="form" class="payments-method-form">
                <div class="flex-column flex-md-row align-items-center">
                  <div class="col-auto col-input px-0 d-flex justify-content-start">
                    <mat-form-field>
                      <mat-label [translate]="'promo-code'"></mat-label>
                      <input autocomplete="off" formControlName="promoCode" matInput type="text"/>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-row align-items-center justify-content-md-start">
                  <div class="col-auto">
                    <button class="w-100 amount-btn" (click)="setAmount(selected.minValue * 10)" color="primary"
                            [class.amount-active]="form.get('amount').value === (selected.minValue / 100)"
                            mat-flat-button>{{ selected.minValue * 10 / 100 }}
                    </button>
                  </div>
                  <div class="col-auto">
                    <button class="w-100 amount-btn" (click)="setAmount(selected.minValue * 50)" color="primary"
                            [class.amount-active]="form.get('amount').value === (selected.minValue  * 2) / 100"
                            mat-flat-button>{{ (selected.minValue * 50) / 100 }}
                    </button>
                  </div>
                  <div class="col-auto">
                    <button class="w-100 amount-btn" (click)="setAmount(selected.minValue * 200)" color="primary"
                            [class.amount-active]="form.get('amount').value === (selected.minValue * 10) / 100"
                            mat-flat-button>{{ (selected.minValue * 200) / 100 }}
                    </button>
                  </div>
                </div>
                <div class="form-row align-items-center justify-content-md-start">
                  <div class="col-auto">
                    <button class="w-100 amount-btn" (click)="setAmount(selected.minValue * 1000)" color="primary"
                            [class.amount-active]="form.get('amount').value === (selected.minValue) * 50 / 100"
                            mat-flat-button>{{ (selected.minValue * 1000) / 100 }}
                    </button>
                  </div>
                  <div class="col-auto">
                    <button class="w-100 amount-btn" (click)="setAmount(selected.minValue * 2000)" color="primary"
                            [class.amount-active]="form.get('amount').value === (selected.minValue) * 400 / 100"
                            mat-flat-button>{{ (selected.minValue * 2000) / 100 }}
                    </button>
                  </div>
                  <div class="col-auto">
                    <button class="w-100 amount-btn" (click)="setAmount(selected.minValue * 5000)" color="primary"
                            [class.amount-active]="form.get('amount').value === (selected.minValue * 2000) / 100"
                            mat-flat-button>{{ (selected.minValue * 5000) / 100 }}
                    </button>
                  </div>
                </div>
                <!--              <div class="form-row align-items-center mb-3 px-0 px-md-2 justify-content-md-start">-->
                <!--                <div *ngFor="let amount of generateRange(selected.minValue*400, 5, 3)" class="col-auto">-->
                <!--                  <button class="w-100 amount-btn" (click)="setAmount(amount)" color="primary" [class.amount-active]="form.get('amount').value === (amount / 100)"-->
                <!--                          mat-flat-button>{{amount / 100}}</button>-->
                <!--                </div>-->
                <!--              </div>-->
                <div class="flex-column flex-md-row align-items-center">
                  <div class="col-auto col-input px-0 d-flex justify-content-start">
                    <mat-form-field>
                      <mat-label>
                        <span [translate]="'amount'"></span>
                        <span matPrefix>({{ me?.currencies[0].code }})</span>
                      </mat-label>
                      <input autocomplete="off" formControlName="amount" matInput type="number"/>
                      <mat-error [control]="form.get('amount')"></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-auto px-0 px-md-2 d-flex justify-content-end">
                    <button (click)="submit()" [disabled]="!form.valid || loading"
                            class="mat-primary w-100" mat-flat-button type="button">{{ 'deposit' | translate }}
                    </button>
                  </div>
                </div>
              </form>
            } @else {
              <div [innerHTML]="selected.textContent" class="payments-method-text"></div>
            }
          </div>
        </div>
      </div>
      <!-- <pre>{{selected | json}}</pre> -->
    }
  </div>
} @else {
  <div class="d-flex col-12 align-items-center justify-content-center">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
}
