@if (!form) {
  <table class="mb-3" mat-table [dataSource]="myDocuments">
    @for (col of cols; track col) {
      <ng-container [matColumnDef]="col.header">
        <th mat-header-cell *matHeaderCellDef>{{col.header | translate}}</th>
        <td mat-cell *matCellDef="let item">
          @switch (col.type) {
            @case ('typeId') {
              <span>{{ (documentTypes|find:{id: item[col.field]}).name | translate}}</span>
            }
            @case ('boolean') {
              @if (item[col.field] === false) {
                <span></span>
              }
              @if (item[col.field]) {
                <span></span>
              }
              @if (item[col.field] === null) {
                <span>
                  <button mat-flat-button color="primary" class="btn-document-load" (click)="loadForm(item.typeId)">{{'load' | translate}}</button>
                </span>
              }
            }
            @default {
              <span>{{ item[col.field] }}</span>
            }
          }
        </td>
      </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="headerRow"></tr>
    <tr mat-row *matRowDef="let row; columns: headerRow;"></tr>
  </table>
}

@if (form) {
  <form class="d-flex flex-column" [formGroup]="form" (ngSubmit)="submit()">
    <button mat-flat-button color="accent" class="align-self-start ms-md-2 mb-4" (click)="form=null">{{'back' | translate}}</button>
    <!--<pre>{{form.value | json}}</pre>-->
    <field formControlName="documentNumber"
    [input]="{label:'documentNumber',type:'text',validations:{maxLength:25,minLength:3,required:true}}"></field>
    <div class="d-flex w-100 flex-md-row flex-column">
      <field formControlName="issueDate" class="flex-grow-1 me-md-2"
      [input]="{label:'issueDate',type:'date',options:{max:'yesterday',min:'last10Years'},validations:{required:true}}"></field>
      <field formControlName="expiringDate" class="flex-grow-1 ms-md-2"
      [input]="{label:'expiringDate',type:'date',options:{max:'next10Years',min:'tomorrow'},validations:{required:true}}"></field>
    </div>
    <div class="d-flex w-100 flex-md-row flex-column">
      <field formControlName="issueAuth" class="flex-grow-1 me-md-2"
      [input]="{label:'issueAuth',type:'text',validations:{maxLength:25,minLength:3,required:true}}"></field>
      <field formControlName="issuePlace" class="flex-grow-1 ms-md-2"
      [input]="{label:'issuePlace',type:'text',validations:{maxLength:25,minLength:3,required:true}}"></field>
    </div>
    <ng-container formArrayName="attachments">
      @for (image of attachments.controls; track image; let i = $index) {
        <ng-container [formGroupName]="i">
          <div class="row gx-0 align-items-center">
            <div class="col-12 col-md-6 mt-3">{{'attachment' | translate}}-{{image.get('name').value}}</div>
            <field formControlName="url" class="col-12 col-md-6"
              [input]="{label:image.get('name').value,type:'file',options:{accept:'image/jpeg, image/png, application/pdf',maxFileSize: 1e5},validations:{required:true}}">
            </field>
          </div>
        </ng-container>
      }
    </ng-container>
    <mat-divider></mat-divider>
    <div class="button-container col-12 justify-content-end mt-3">
      <button [disabled]="!form.valid || loading" color="primary" mat-flat-button type="submit">{{'save' | translate}}</button>
    </div>
  </form>
}
