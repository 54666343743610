import { Component, ElementRef, Input, SimpleChanges } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { MatError } from '@angular/material/form-field';
import { Warning } from '@bs/models';
import { TranslateService } from '@ngx-translate/core';

/**
 * component outputs us error message of a certain field that contains error
 */
@Component({
  selector: 'mat-error[control], mat-error[warning], mat-error[control][warning]',
  templateUrl: './field-error.component.html'
})
export class FieldErrorComponent extends MatError {
  /**
   * the control field
   */
  @Input()
  control: AbstractControl | NgControl;
  /**
   * the warning notification message template
   */
  @Input()
  warning: Warning;
  /**
   * the class name of the alert
   */
  @Input()
  className: 'success' | 'danger' | 'warning' | 'info' = 'danger';
  /**
   * clears the setTimeout javascript function on warning variable change
   */
  timeout: number;

  /**
   * The constructor calls the base class of MatError
   * @param translate
   * @param elementRef
   */
  constructor(private translate: TranslateService, elementRef: ElementRef) {
    super('', elementRef);
  }

  /**
   * a getter for a control, that loops thought the error message, translate it, and it outputs them in the template
   */
  get errorMessage() {
    for (const propertyName in this.control?.errors) {
      if (propertyName in this.control.errors && this.control.touched) {
        return this.translate.get(`error-${propertyName}`, this.control.errors[propertyName]);
      }
    }

    return null;
  }

  /**
   * lifecycle hook that checks changes of warning input decorator and sets timeout for clearing the warning template
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.warning?.currentValue) {
      if (changes.warning.currentValue.delay) {

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.warning = null, changes.warning.currentValue.delay) as any;
      }
    }
  }

}
