import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppSettings, EnvironmentConfig, IMe } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { Subscription } from 'rxjs';

interface MyData {
  me: IMe;
}

@Component({
  selector: 'zero-balance-dialog',
  templateUrl: 'zero-balance.dialog.html',
  styles: [`
    .title {
      font-size: 16px;
    }
  `],
})
export class ZeroBalanceDialog implements OnDestroy {
  settings: AppSettings;
  subs = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: MyData,
              public dialogRef: MatDialogRef<ZeroBalanceDialog>,
              public config: EnvironmentConfig,
              private router: Router,
              appSettingsService: AppSettingsService) {
    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  toDepositPage() {
    this.dialogRef.close();
    void this.router.navigate([`${this.settings.languageCode}/${this.config.depositPage}`]);
  }
}
