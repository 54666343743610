import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * pipe disables Angular built-in sanitization and renders some url to the page, mostly used for rendering the casino games as trustful resource
 */
@Pipe({name: 'trusted'})
export class TrustedPipe implements PipeTransform {
  /**
   * The constructor
   */
  constructor(private sanitizer: DomSanitizer) {
  }

  /**
   * returns us safe resource url load
   * @param value {any} the url of a given casino game
   * @param context {'RESOURCE_URL'} sanitizes a value for use in the given SecurityContext.
   *
   */
  transform(value, context?: 'RESOURCE_URL') {

    switch (context) {
      case 'RESOURCE_URL':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
