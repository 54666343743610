export interface ISearchBaseSportsBookFilters {
  betOfferId?: number;
  bookmakerId?: number;
  displayCurrencyId?: number;
  from?: string;
  to?: string;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}
