import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AutoExclusionResponse, EnvironmentConfig, ICreateAutoExclusionRequest, IMe } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelfExclusionService {
  readonly url = `${this.config.api.identity}/me/autoexclusion`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  create(request: ICreateAutoExclusionRequest): Observable<IMe> {
    return this.http.post<IMe>(`${this.url}`, request);
  }

  get(): Observable<AutoExclusionResponse> {
    return this.http.get<AutoExclusionResponse>(`${this.url}`);
  }

  all() {
    return this.http.get(`${this.url}`);
  }

  update(id: number, autoexclusion) {
    return this.http.put(`${this.url}/${id}`, {autoexclusion});
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }


}
