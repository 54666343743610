import { ICountry } from '../common/country';
import { IFormStep } from '../common/forms';
import { IDelivery, IGeoInfo } from './account';
import { IDocument } from './documents';
import { IPerson } from './me';

export type registrationMode =
    'playerEmail'
    | 'playerPhone'
    | 'phoneBlackBet'
    | 'agent'
    | 'shop'
    | 'cashier'
    | 'emailFull'
    | 'emailFast'
    | 'emailMedium'
    | 'emailFullDocuments'
    | 'emailFullWithPhone'
    | 'emailFullWithPhoneLisa'
    | 'euRegistration'
    | 'betBallGaming'
    | 'phoneFull'
    | 'phoneFullNoAddress'
    | 'phoneFast'
    | 'phonePrivateNoOTP'
    | 'phoneFastNoOTP'
    | 'agentFast'
    | 'playerFast'
    | 'operatorFast'
    | 'playerFull'
    | 'phoneFullDocuments'
    | 'playerFaster'
    | 'playerBrazil'
    | 'agentFaster'
    | 'shopFaster'
    | 'shopFast';

export interface IRegistrationFormStructure {
    steps: IFormStep[];
    mode: registrationMode;
    autoLogin: boolean;
    typeId: number;
    direction?: string | 'horizontal' | 'vertical';
}

export interface RegistrationBaseRequest {
    typeId: number;
    /** @link AccountTypeId */
    username: string;
    password: string;
    currencyId: number;
    parentId?: number;
    referralCode?: string;
    trackingCode?: string;
    person?: IPerson;
    geoInfos?: Array<IGeoInfo>;
    deliveries: Array<IDelivery>;
    autoLogin?: boolean;
    document?: Partial<IDocument>;
    bookmakerId?: number;
    country?: ICountry;
}

export interface ICreateOperator {
    username: string;
    password: string;
    person: IPerson;
    deliveries: Array<IDelivery>;
    bookmakers: Array<number>;
}
