import { IAccountBase } from '../wallet/movement';

export interface SettingThresholdValue {
  settingTypeId: number;
  value: number;
  thresholdTypeId: number;
}

export interface SettingValue {
  settingTypeId: number;
  value?: boolean;
  // templateVersionId: number;
  settingThresholdValue?: Array<SettingThresholdValue>;
}

export interface INetworkSettingValueRequest {
  settingTypeId: number;
  value: boolean;
}

export interface NetworkSettingValue {
  settingTypeId: number;
  value: boolean;
  creationDate: string;
  endDate?: string;
  author: IAccountBase;
  oldValue?: boolean;
}

export interface NetworkSettingThresholdValue {
  settingTypeId: number;
  thresholdTypeId: number;
  value: number;
  oldValue?:any;
  creationDate: string;
  endDate?: string;
  operatorId: number;
}

export interface TemplateVersionNetwork {
  creationDate: string;
  author?: IAccountBase;
  settingValues?: Array<NetworkSettingValue>;
  settingThresholdValue?: Array<NetworkSettingThresholdValue>;
  bettingThresholds?: Array<NetworkBettingThreshold>;
}

export interface BettingThreshold extends NetworkBettingThresholdBase {
  id: number;
}

export interface NetworkBettingThresholdBase {
  bettingGroupId: number;
  bettingTypeId: number;
  bettingLimitId: number;
  thresholdTypeId: number;
  value: number;
}

export interface NetworkBettingThreshold extends NetworkBettingThresholdBase {
  creationDate: string;
  endDate?: string;
  author: IAccountBase;
}

