import { IBaseGame } from './game';

type CasinoTournamentStatus = 'open' | 'closed' | 'progress';

export class CasinoTournament {
  id: number;
  title: string;
  description: string;
  status: CasinoTournamentStatus;
  jackpot: number;
  registrationStart: Date;
  registrationEnd: Date;
  start: Date;
  end: Date;
  fee: number;
  rules?: string;
  prizes?: Array<{ place: string, size: string }>;
  players?: Array<string>;
}

export interface ILeaderBoards {
  position: string;
  score: number;
  price: number;
}

export interface ICasinoTournament {
  id: number;
  title: string;
  status: string;
  description: string;
  logo: string;
  minimumStake: number;
  minimumRound: number;
  minimumConsecutiveRounds: number;
  pricePool: number;
  startDate: string;
  endDate: string;
  leaderboards: Array<ILeaderBoards>;
  games: Array<IBaseGame>;
}
