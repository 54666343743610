import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ICmsPage } from '@bs/models';
import { CmsService } from '../../services/core/cms.service';


export const PageResolver: ResolveFn<ICmsPage> = (route: ActivatedRouteSnapshot) => {
  const path = route.params.path || route.data.name;
  return inject(CmsService).getPage(path);
};
