import { BetType } from './bet-type';
import { Bonus } from './bonus';
import { Multiplier } from './multiplier';
import { MyMath } from './mymath';
import { Selection } from '../selection';
import { Winning } from './winning';

export class Integral extends BetType {
  calculateBonus(percentages: any, minimumRequiredMultiplier: number, selections: Array<Selection>, bonus: Bonus): Bonus {

    let validOdds = 0;
    const uniqueSelections = [];

    selections.forEach((s) => {
      if (uniqueSelections.indexOf(s.eventId) > -1) {
        return;
      }

      if (s.oddValue >= minimumRequiredMultiplier) {
        uniqueSelections.push(s.eventId);
        validOdds++;
      }
    });

    if (validOdds) {
      bonus.percentage = percentages[validOdds];
      bonus.min = MyMath.round(this.amount * this.multiplier.min * (bonus.percentage / 100), 4);
      bonus.max = MyMath.round(this.amount * this.multiplier.max * (bonus.percentage / 100), 4);
    }

    return bonus;
  }

  calculateMultiplier(selections: Array<Selection>): Multiplier {
    const uniqueIds = {};
    const comb = [];
    const min = [];
    const max = [];
    let idx = 0;

    selections.forEach((el) => {

      if (uniqueIds[el.eventId] !== undefined) {

        const eventIndex = uniqueIds[el.eventId];
        comb[eventIndex.index]++;

        if (el.oddValue < min[eventIndex.index]) {
          min[eventIndex.index] = el.oddValue;
        }

        if (el.oddValue > max[eventIndex.index]) {
          max[eventIndex.index] = el.oddValue;
        }
      } else {
        uniqueIds[el.eventId] = {index: idx};
        max[idx] = el.oddValue;
        min[idx] = el.oddValue;
        comb[idx] = 1;
        idx++;
      }
    });

    this.multiplier = new Multiplier(
      MyMath.round(min.reduce((a, b) => a * b), 4),
      MyMath.round(max.reduce((a, b) => a * b), 4),
      MyMath.round(comb.reduce((a, b) => a * b), 4)
    );

    return this.multiplier;
  }

  calculateTotal(): number {
    return this.amount * this.multiplier.totalCombinations;
  }

  calculateWinning(bonus: Bonus): Winning {
    this.winning.min = MyMath.round((this.multiplier.min * this.amount) + bonus.min, 4);
    this.winning.max = MyMath.round((this.multiplier.max * this.amount) + bonus.max, 4);

    return this.winning;
  }
}
