import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseMobilePrefix } from '@bs/forms';

/**
 * The component contains the prefix of country, and the input field that let us enter a phone number
 */
@Component({
  selector: 'mobile-prefix',
  templateUrl: './mobile-prefix.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MobilePrefixComponent),
      multi: true
    }
  ]
})
export class MobilePrefixComponent extends BaseMobilePrefix {
}

