import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'section.download',
  templateUrl: 'download.page.html',
})

export class DownloadPage {

  deferredPrompt: any;
  showButton = false;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    e.preventDefault();
    this.deferredPrompt = e;
    this.showButton = true;
  }

  addToHomeScreen() {
    this.showButton = false;
    this.deferredPrompt?.prompt();
    this.deferredPrompt?.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // TODO load game url download apk
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }
}
