import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IMe } from '@bs/models';
import { IWithdrawalRequest, MeService, PaymentMethodsService } from '@bs/services';
import { finalize } from 'rxjs/operators';
import { validators } from 'sitemap';
import { CashierBaseClass } from '../../cashier-base.class';

@Component({
  selector: 'cashier-withdrawal',
  templateUrl: 'cashier-withdrawal.component.html',
  providers: [PaymentMethodsService, MeService]
})

export class CashierWithdrawalComponent extends CashierBaseClass implements OnInit {

  ngOnInit(): void {

    this.all(false)

    this.form = new FormGroup({
      amount: new FormControl(null, [
        Validators.required,
      ])
    });
  }

  submit() {
    this.loading = true;
    const request: IWithdrawalRequest = {
      paymentMethodId: this.selected.id,
      amount: this.form.value.amount * 100,
    }

    this.paymentMethodsService.withdrawal(request).pipe(finalize(() => this.loading = false)).subscribe({
      next: response => {
        this.showSnackbar('withdrawalRequest', 'success');
        const me: Partial<IMe> = {wallets: response.wallets}
        this.authService.refreshMe(me)
      },
      error: error => {
        this.showSnackbar(error.message, 'error');
      }
    })

  }
}
