import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@bs/universal';
import { AuthService } from './auth.service';

@Injectable()
export class TagEventHandlerService {

  constructor(@Inject(WINDOW) private window: Window, authService: AuthService) {
    authService.accountLogged$.subscribe({
      next: me => {
        if (me) {
          this.window.dataLayer?.push({event: 'login', user_id: me?.id?.toString()})
        }
      }
    });
  }

  /**
   * @description initilizes the existing analytics providers
   */
  init() {
    //this.window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
  }

  /**
   * @description triggers registration events
   * @param isSuccessful
   */
  registrationConfirm(isSuccessful: boolean) {
    this.window.dataLayer?.push({
      event: 'sign_up'
    })
  }

  /**
   * @description triggers bet-placement events
   * @param value - amount
   * @param transaction_id - couponCode
   * @param currency - currencyCode, e.g. USD
   */
  betConfirm(value: number, transaction_id: string, currency: string) {
    this.window.dataLayer?.push({
      event: 'purchase', transaction_id, currency, value
    })
  }
}
