import { Component, HostBinding, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { debounce } from '@bs/common';
import { AppSettings, IMenuStatus } from '@bs/models';
import { AppSettingsService, AuthService, CookieService, DynamicScriptLoaderService, MenuResolver, SmarticoService, TagEventHandlerService } from '@bs/services';
import { WINDOW, WindowService } from '@bs/universal';
import { LoginDialog, SettingsDialog } from '@homer/common';
import { RealityCheckDialog } from '@homer/me';
import { RegistrationDialogComponent } from '@maggie/app/components/dialogs/registration/registration.dialog';
import { environment } from '@maggie/environments/environment';
import { EMPTY, switchMap, takeWhile, timer } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'body',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  @HostBinding('class')
  deviceClass = '';

  settings: AppSettings;
  menuStatus: IMenuStatus;
  isMobile = false;
  //isMobile = toSignal(this.windowService.device$.pipe(map(({isMobile}) => isMobile)));
  //me = toSignal(this.authService.accountLogged$.pipe(map(me => me)));


  constructor(swUpdate: SwUpdate, private authService: AuthService, private route: ActivatedRoute, private router: Router,
              private dialog: MatDialog, appSettingsService: AppSettingsService, @Inject(WINDOW) public window: Window,
              dsl: DynamicScriptLoaderService, meta: Meta, private menuService: MenuResolver,
              private cookieService: CookieService, @Optional() private smarticoService: SmarticoService,
              @Optional() private tagEventHandlerService: TagEventHandlerService, private windowService: WindowService) {
    meta.updateTag({name: 'copyright', content: `${environment.project.split('.')[0]} - ${environment.appVersion}`});

    swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe({
        next: () => {
          console.log('new-version')
          if (confirm('A new version is available. Would you like to load it?')) {
            window.location.reload(); // Ricarica la pagina
          }
          console.log(this.authService.isLogged)
          if (!this.authService.isLogged) {
            window.location.reload();
          }
        }
      });


    // if (environment.production && !environment?.mobileApp) {
    //   void swUpdate.checkForUpdate();
    // }

    this.windowService.device$.subscribe(({isMobile}) => this.isMobile = isMobile);

    route.fragment.pipe(filter(fragment => !!fragment)).subscribe({
      next: (fragment: string) => {
        switch (fragment) {
          case 'login': {
            if (this.authService.isLogged()) {
              void router.navigate([], {fragment: null});
            } else {
              const currentNavigation = router.getCurrentNavigation();
              dialog.open(LoginDialog, {panelClass: ['dialog-login', 'dialog-full']}).afterClosed().subscribe({
                next: res => {
                  if (res) {
                    const commands = [];
                    if (router.url.includes('register') || router.url.includes('logout') || router.url.includes('email-confirmation')) {
                      commands.push('/');
                    } else if (currentNavigation.extras.state) {
                      commands.push(currentNavigation.extras.state.redir);
                    }
                    void router.navigate(commands, {fragment: null});
                  } else {
                    void router.navigate([], {fragment: null});
                  }
                }
              });
            }
            break;
          }
          case 'register':
            if (this.authService.isLogged()) {
              void router.navigate([], {fragment: null});
            } else {
              dialog.open(RegistrationDialogComponent, {
                panelClass: ['dialog-login', 'dialog-full']
              }).afterClosed().subscribe({
                next: res => {
                  if (res) {

                  } else {
                    void router.navigate([], {fragment: null});
                  }
                }
              });
            }
            break;
          case 'settings':
            dialog.open(SettingsDialog).afterClosed().subscribe({
              next: res => {
                if (res) {

                } else {
                  void router.navigate([], {fragment: null});
                }
              }
            });
            break;
        }
      }
    });

    appSettingsService.appSettings$.subscribe({
      next: ({settings, valueChanged}) => {
        this.settings = settings;
        if ([null, 'timeZone', 'languageCode'].includes(valueChanged)) {
          const scripts = environment.externalScripts as any;
          const rooted = scripts.flatMap(link => link?.inRoot ? [link.name] : []);
          dsl.load(...rooted).forEach(re => {
            re.subscribe(tag => {
              if (tag.options?.callback) {
                tag.options.callback({settings});
              }
              if (tag.name === 'smartico-integration') {
                this.smarticoService.init()
              }
            })
          })
        }
      }
    });

    menuService.menuStatus$.subscribe({
      next: status => this.menuStatus = status
    });

    windowService.device$.pipe(
      takeUntilDestroyed(),
      switchMap(({isMobile}) => {
        if (isMobile) {
          return this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          )
        }
        return EMPTY;
      })
    ).subscribe({
      next: () => this.menuService.menuStatus$.next({side: false, main: false, section: false})
    });

    this.realityChecker();
  }

  @HostListener('window:resize')
  @debounce(50)
  onResize() {
    this.deviceClass = this.windowService.detectDevice();
  }

  ngOnInit() {
    this.authService.isLogged();

    this.route.queryParamMap.subscribe({
      next: params => {
        if (params.has('i')) {
          this.authService.loginToken(params.get('i')).finally(() => this.router.navigate([], {queryParams: {t: null}}));
        }

        ['affiliateId', 'referralCode'].forEach(key => {
          if (params.has(key)) {
            if (environment.production) {
              this.cookieService.set(key, params.get(key), 365, '/', `.${environment.project}`, true); // set tracking cookies expires 1 year
            } else {
              this.cookieService.set(key, params.get(key), 1, '/');
            }
          }
        });
      }
    });

  }

  realityChecker() {
    if (environment.features?.responsibleGaming.realityChecker) {

      this.authService.accountLogged$.pipe(
        switchMap(() => {
          return timer(1e3, 60e3).pipe(
            takeWhile(() => !!this.authService.accountLogged$.getValue()),
            switchMap(() => this.authService.accountLogged$),
          );
        })
      ).subscribe({
        next: userProfile => {
          const userSelectedCheck = userProfile?.realityCheckTime ?? 0;
          const lastLogin = this.authService.realityCheck ? new Date(this.authService.realityCheck).getTime() : new Date().getTime();
          const scheduledCheck = new Date(lastLogin + userSelectedCheck * 60e3).getTime();
          const currentTime = new Date().getTime();

          /*console.log(
            'user selected schedule: ', userSelectedCheck, '\n',
            'user last login: ', lastLogin, '\n',
            'next scheduled check: ', scheduledCheck, '\n',
            'current time: ', currentTime, '\n',
            'is scheduled: ', currentTime >= scheduledCheck
          );*/


          if (!this.dialog.openDialogs.length && userSelectedCheck && currentTime >= scheduledCheck) {
            /*return userService.realityCheck().pipe(
              tap(realityChecks => {*/
            this.dialog.open(RealityCheckDialog, {data: {lastLogin}}); // pass realityChecks in data
            /*})
          );*/
          }
        }
      });


    }
  }

}
