import { Directive, ElementRef, Host, Input, OnDestroy, Optional } from '@angular/core';
import { AppSettingsService } from '@bs/services';
import { delay, Subscription } from 'rxjs';

/**
 * directive for scrolling to a given location
 */
@Directive({
  selector: '[center-scroll]'
})
export class CenterScrollDirective implements OnDestroy {
  /**
   * center-scroll directive requires html-tag to be passed to smooth scroll to that elementRef
   */
  @Input('center-scroll') centerScroll: string;
  /**
   * variable for subscription
   */
  subs = new Subscription();

  /**
   * The "constructor" calculates where to scroll to on the site, replaced by appSettingsService.scrollTo$
   *
   * @param settingsService {AppSettingsService} the settings service
   * @param div {ElementRef} the element reference this directive is attached
   */
  constructor(private settingsService: AppSettingsService,
              @Optional() @Host() private div: ElementRef) {
    this.subs.add(this.settingsService.scrollTo$.pipe(delay(0)).subscribe({
      next: (pos) => {
        /*const directiveRef = this.bar;
        if (directiveRef && !directiveRef.disabled) {
          void directiveRef.scrollTo({[pos]: 0, duration: 300});
          /!* switch (pos) {
             case 'bottom':
               void directiveRef.scrollTo({bottom: 0, duration: 300});
               break;
             case 'left':
               directiveRef.scrollToLeft(0, 300);
               break;
             case 'right':
               directiveRef.scrollToRight(0, 300);
               break;
             default:
             case 'top':
               directiveRef.scrollToTop(0, 300);
               break;
           }*!/
        } else {*/
        const scrollContainer = (this.centerScroll === 'html-tag') ? this.div.nativeElement : window;
        scrollContainer.scroll({
          [pos]: 0,
          behavior: 'smooth',
        });
        //}
      }
    }));
  }

  /**
   * unsubscribes from the subscription when the component been destroyed
   */
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
