<ng-template #mobileTpl>
  <table class="mat-table">
    <thead>
    <tr class="mat-row">
      <th class="mat-header-cell">
        <span>{{ 'code' | translate }}</span>
      </th>
      <th class="mat-header-cell">
        <span [translate]="'code'">{{ transfer.code }}</span>
      </th>
    </tr>
    <tr class="mat-row">
      <th class="mat-header-cell">
        <span>{{ 'date-of-creation' | translate }}</span>
      </th>
      <th class="mat-header-cell">
        <span>{{ transfer.requestedAt | localizedDate:'dd/MM/yyyy HH:mm:ss' }}</span>
      </th>
    </tr>
    <tr class="mat-row">
      <th class="mat-header-cell">
        <span>{{ 'type' | translate }}</span>
      </th>
      <th class="mat-header-cell">
        <span [translate]="'type'">{{ transfer.typeId | formValues:{values: 'networkTransferTypes'} }}</span>
      </th>
    </tr>
    <tr class="mat-row">
      <th class="mat-header-cell">
        <span translate>state</span>
      </th>
      <th class="mat-header-cell">
        <span [translate]="'status'">{{ transfer.stateId | formValues:{values: 'networkTransferStates'} }}</span>
      </th>
    </tr>
    <tr class="mat-row">
      <th class="mat-header-cell">
        <span>{{ 'amount' | translate }}</span>
      </th>
      <th class="mat-header-cell">
        <span>{{ transfer.amount.amount | moneyFormat:data.me.currencies[0]:true }}</span>
      </th>
    </tr>
    <tr class="mat-row">
      <th class="mat-header-cell">
        <span>{{ 'applicant' | translate }}</span>
      </th>
      <th class="mat-header-cell">{{ transfer.applicant.username }}</th>
    </tr>
    <tr class="mat-row">
      <th class="mat-header-cell">
        <span>{{ 'holder' | translate }}</span>
      </th>
      <th class="mat-header-cell">{{ transfer.holder.username }}</th>
    </tr>
    <tr class="mat-row">
      <th class="mat-header-cell">
        <span>{{ 'beneficiary' | translate }}</span>
      </th>
      <th class="mat-header-cell">{{ transfer.beneficiary.username }}</th>
    </tr>
    <tr class="mat-row">
      <th colspan="2">
        <button (click)="print()" mat-button></button>
      </th>
    </tr>
    </thead>
  </table>
</ng-template>

<ng-template #desktopTpl>
  <div class="table-container">
    <table class="mat-table">
      <thead>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <span>{{ 'code' | translate }}</span>
        </th>
        <th class="mat-header-cell">
          <span>{{ 'date-of-creation' | translate }}</span>
        </th>
        <th class="mat-header-cell">
          <span>{{ 'type' | translate }}</span>
        </th>
        <th class="mat-header-cell">
          <span>{{ 'state' | translate }}</span>
        </th>
        <th class="mat-header-cell">
          <span>{{ 'amount' | translate }}</span>
        </th>
        <th class="mat-header-cell">
          <span>{{ 'applicant' | translate }}</span>
        </th>
        <th class="mat-header-cell">
          <span>{{ 'holder' | translate }}</span>
        </th>
        <th class="mat-header-cell">
          <span>{{ 'beneficiary' | translate }}</span>
        </th>
        <th class="mat-header-cell"></th>
      </tr>
      </thead>
      <tbody>
      <tr class="mat-row">
        <td class="mat-cell">{{ transfer.code }}</td>
        <td class="mat-cell">{{ transfer.requestedAt | localizedDate:'dd/MM/yyyy HH:mm:ss' }}</td>
        <td class="mat-cell">
          {{ transfer.typeId | formValues:{values: 'networkTransferTypes'} }}
        </td>
        <td class="mat-cell">
          {{ transfer.stateId | formValues:{values: 'networkTransferStates'} }}
        </td>
        <td class="mat-cell">
          {{ transfer.amount.amount | moneyFormat:data.me.currencies[0]:true }}
        </td>
        <td class="mat-cell">{{ transfer.applicant.username }}</td>
        <td class="mat-cell">{{ transfer.holder.username }}</td>
        <td class="mat-cell">{{ transfer.beneficiary.username }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<div class="dialog">
  <mat-card class="new-card flat-card">

    <mat-card-title class="box-header">
      <span>{{ 'withdrawal-from-shop-request' | translate }}</span>
      <button (click)="dialogRef.close()" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>

    <div class="dialog-wrapper">
      <div class="scrollbar">

        <div class="dialog-body">

          @if (transfer) {
            <div class="row p-b-10">
              <div class="col-12">
                <div class="alert alert-success">
                  <span class="d-block">
                    <input class="code" type="text" readonly [(ngModel)]="transfer.code" #inputElement>
                    <button mat-button-flat matSuffix mat-icon-button (click)="copy()" [matTooltip]="'tooltip-copy-shop-withdrawal' | translate" matTooltipPosition="right"></button>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row p-b-10">
                  <div class="col-6">
                    <span>{{ 'date-of-creation' | translate }}</span>
                  </div>
                  <div class="col-6 text-right">
                    <span>{{ transfer.requestedAt | localizedDate:'dd/MM/yyyy HH:mm:ss' }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row p-b-10">
                  <div class="col-6">
                    <span>{{ 'type' | translate }}</span>
                  </div>
                  <div class="col-6 text-right">
                    <span [translate]="'type'">{{ transfer.typeId | formValues:{values: 'networkTransferTypes'} }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row p-b-10">
                  <div class="col-6">
                    <span>{{ 'state' | translate }}</span>
                  </div>
                  <div class="col-6 text-right">
                    <span [translate]="'status'">{{ transfer.stateId | formValues:{values: 'networkTransferStates'} }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row p-b-10">
                  <div class="col-6">
                    <span>{{ 'amount' | translate }}</span>
                  </div>
                  <div class="col-6 text-right">
                    <span>{{ transfer.amount.amount | moneyFormat:data.me.currencies[0]:true }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!--            <ng-container [ngTemplateOutlet]="data.isMobile ? mobileTpl : desktopTpl"></ng-container>-->
            <div mat-dialog-actions align="end">
              <button (click)="print()" color="primary" mat-flat-button>
                <span>{{ 'print' | translate }}</span>
              </button>
            </div>
          } @else {
            <div class="row gx-0">
              <div class="col-12">
                <div class="alert alert-info m-b-30">
                  <span class="d-block">{{ 'your-withdrawable-balance' | translate }}</span>
                  <strong>&nbsp;{{ data.me?.wallets[0].balance.withdrawable | moneyFormat:data.me?.currencies[0]:true }}</strong>
                </div>
              </div>
            </div>
            <div class="row gx-0">
              <div class="col">
                <mat-form-field class="w-100">
                  <mat-label>{{ 'selectCityWithShopAvailable' | translate }}</mat-label>
                  <mat-select [(ngModel)]="selectedCity" disableOptionCentering>
                    <div class="scrollbar" [class.max-dropdown]="!data.isMobile" [class.disabled]="data.isMobile">
                      @for (city of cities; track city) {
                        <mat-option [value]="city">{{ city.key }}</mat-option>
                      }
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row gx-0">
              <div class="col">
                <mat-form-field class="w-100">
                  <mat-label>{{ 'selectShop' | translate }}</mat-label>
                  <mat-select [(ngModel)]="selectedShop" disableOptionCentering placeholder="Select an option">
                    <div class="scrollbar" [class.max-dropdown]="!data.isMobile" [class.disabled]="data.isMobile">
                      @for (shop of selectedCity?.value || []; track shop) {
                        <mat-option [value]="shop">{{ shop.name }} - {{ shop.address }}</mat-option>
                      }
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6 ui-lg-12">
              <mat-form-field class="w-100">
                <mat-label>{{ 'amount' | translate }}</mat-label>
                <input [disabled]="true" [ngModel]="data.amount" matInput>
              </mat-form-field>
            </div>
            <div class="row p-t-15">
              <div class="col-12 text-right">
                <button (click)="handleSubmit()" [disabled]="!selectedShop" color="primary" mat-flat-button type="button">{{ 'confirm' | translate }}</button>
              </div>
            </div>
          }


          @if (isLoading) {
            <div class="loading-overlay">
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
          }
        </div>
      </div>
    </div>

  </mat-card>
</div>
