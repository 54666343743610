import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@bart/environments/environment';
import { AppSettings, EnvironmentConfig, IMe, IMenuItem, IMenuStatus, MaggieMenu } from '@bs/models';
import { AppSettingsService, AuthService, CmsService, IdentityWalletService, MenuResolver } from '@bs/services';
import { WindowService } from '@bs/universal';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, first } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html'
})

export class HeaderComponent implements OnInit, OnDestroy {

  @Input()
  isMobile = false;

  me: IMe;
  settings: AppSettings
  showBalance = true;
  homePage = this.config.homePage;
  subs = new Subscription();
  menuStatus: IMenuStatus;
  userMenu: IMenuItem;
  currentLang: string;
  totalBalance: number;
  totalBonuses: number = 0;
  hasChat = environment.liveChats?.liveChatInc;
  depositRoute: string[];

  constructor(authService: AuthService, appSettingsService: AppSettingsService,
              private menuService: MenuResolver,
              private cmsService: CmsService,
              private config: EnvironmentConfig, private identityWalletService: IdentityWalletService, private windowService: WindowService,
              translateService: TranslateService) {

    windowService.device$.pipe(first()).subscribe(({isDesktop}) => {
      if (isDesktop) {
        this.menuService.status = {main: false, section: true, side: false};
      }
    });

    this.subs.add(menuService.menuStatus$.subscribe({
      next: status => this.menuStatus = status
    }));

    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    }));

    this.currentLang = translateService.currentLang;

    if (!config.piqMerchantId) {
      this.depositRoute = [this.currentLang, 'me', 'balances', 'deposit']
    } else {
      this.depositRoute = [this.currentLang, 'me', 'cashier']
    }

    this.subs.add(authService.accountLogged$.subscribe(
      {
        next: me => {
          if (me) {
            this.me = me;
            this.calculateTotalBalance();
            this.calculateTotalBonuses();
            this.loadUserMenu();
            this.showBalance = 'showBalance' in config.features && config.features['showBalance'];
          } else {
            this.me = null
          }
        }
      }));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  reloadBalance(): void {
    void this.identityWalletService.wallets();
  }

  openMenu(status: 'side' | 'section' | 'main') {
    Object.keys(this.menuStatus).forEach(k => {
      if (k === status) {
        this.menuStatus[k] = !this.menuStatus[k];
      } else {
        this.menuStatus[k] = false;
      }
    })
    this.menuService.menuStatus$.next(this.menuStatus);
  }

  private calculateTotalBalance() {
    this.totalBalance = this.me.wallets[0].balance.available;
    this.me.wallets[0].balance.bonuses?.forEach(x => this.totalBalance = x.available + this.totalBalance)
  }

  private calculateTotalBonuses() {
    this.totalBonuses = 0;
    this.me.wallets[0].balance.bonuses?.forEach(x => {
      this.totalBonuses += x.available
    })
  }

  private loadUserMenu() {
    this.cmsService.getMenu(MaggieMenu.MeNavigationLogged).subscribe({
      next: menu => {
        this.userMenu = menu;
      },

      error: error => console.error(error)
    });
  }
}
