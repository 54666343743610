<div class="scrollbar"> <!--[config]="{suppressScrollX: false}" [autoPropagation]="true"-->
  <div class="row gx-0 menu-container text-center mobile quick-links">
    <ul>
      @if (selectedBand) {
        <li class="nav-item">
          <div>
            <a [queryParamsHandling]="'merge'" [queryParams]="{brand:null}" [routerLink]="['.']" class="nav-link">{{'all' | translate}}</a>
          </div>
        </li>
      }
      @for (brand of brands; track brand) {
        <li class="nav-item">
          <div>
            <a [queryParams]="{brand:brand.id}" [routerLink]="['.']" class="nav-link" routerLinkActive="active"><span>{{brand.name | translate}}</span></a>
          </div>
        </li>
      }
    </ul>
  </div>
</div>

