@if (token) {
  <div class="container-fluid my-3">
    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="content">
          <mat-card class="new-card flat-card me-card">
            <mat-card-title class="box-header">
              <div class="title">
                <span>{{ 'changePassword' | translate }}</span>
              </div>
            </mat-card-title>
            <mat-card-content class="p-3">

              @if (loading) {
                <div class="loading-overlay">
                  <mat-spinner [diameter]="50"></mat-spinner>
                </div>
              }
              <form-builder #builderComponent (submits)="submit($event)" [config]="config">
                <div class="button-container col-12">
                  <button [disabled]="!builderComponent.form.valid || loading" color="primary" mat-flat-button type="submit">
                    <span translate>update</span>
                  </button>
                </div>
              </form-builder>
              <div class="row">
                <div class="col-md-12">
                  @if (formGroupMessage) {
                    <div class="alert alert-success">
                      <span>{{ formGroupMessage }}</span>
                    </div>
                  }
                  @if (formGroupErrorMessage) {
                    <div class="alert alert-danger">
                      <span>{{ formGroupErrorMessage }}</span>
                    </div>
                  }
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
}
