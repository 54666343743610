import { Injectable } from '@angular/core';
import { DictionarySport, EnvironmentConfig, LiveViewerSettings, LiveViewerSettingsKey } from '@bs/models';
import { LocalStorage } from '@bs/universal';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LiveViewerService {

  private readonly STORAGE_KEY = 'LiveViewerSettings';
  liveViewerSettings = new BehaviorSubject<{ settings: Array<LiveViewerSettings>, valueChanged: LiveViewerSettingsKey }>(null);

  constructor(private config: EnvironmentConfig, private localStorage: LocalStorage) {
  }

  get settings(): Array<LiveViewerSettings> {
    return JSON.parse(this.localStorage.getItem(this.STORAGE_KEY));
  }

  set settings(settings: Array<LiveViewerSettings>) {
    this.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(settings));
  }

  createSettings(sports: Array<DictionarySport>) {
    const liveViewerSettings = JSON.parse(this.localStorage.getItem(this.STORAGE_KEY));
    const settings: Array<LiveViewerSettings> = [];
    sports.forEach(x => {
      const lvSettings = liveViewerSettings?.find(s => s.sportId === x.id);
      settings.push(lvSettings ? lvSettings : {sportId: x.id, items: 0, duration: 3000, includeInView: false});
    });
    this.settings = settings;
  }

  saveSetting(sportId: number, settingKey: LiveViewerSettingsKey, settingValue: any) {
    const currentSettings = this.settings;
    currentSettings.find(s => s.sportId === sportId)[settingKey.toString()] = settingValue;
    this.setSetting(currentSettings, settingKey);
  }

  private setSetting(settings: Array<LiveViewerSettings>, key: LiveViewerSettingsKey) {
    if (JSON.stringify(settings) !== JSON.stringify(this.liveViewerSettings.getValue())) {
      this.liveViewerSettings.next({settings, valueChanged: key});
    }
    this.settings = settings;
  }
}
