import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { integerValidator } from '@bs/forms';
import { AppSettings, IMe, IPaymentMethod } from '@bs/models';
import { AppSettingsService, IdentityWalletService } from '@bs/services';
import { WINDOW, WindowService } from '@bs/universal';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BankTransferRequestDialog } from '../../dialogs/bank-transfer-request/bank-transfer-request.dialog';
import { CryptoWithdrawalRequestDialog } from '../../dialogs/crypto-withdrawal-request/crypto-withdrawal-request.dialog';
import { DepositCheckOut } from '../../dialogs/deposit-check-out/deposit-check-out-dialog.component';
import { ManualWithdrawalDialog } from '../../dialogs/manual-withdrawal/manual-withdrawal.dialog';
import { PixWithdrawalRequestDialog } from '../../dialogs/pix-withdrawal-request/pix-withdrawal-request.dialog';
import { ShopWithdrawalRequestDialog } from '../../dialogs/shop-withdrawal-request/shop-withdrawal-request.dialog';

interface IConfig {
  amount: number;
  isMobile: boolean;
  me: IMe,
  provider: IPaymentMethod;
}

@Component({
  selector: 'payments-methods-withdrawal',
  templateUrl: 'payments-methods-withdrawal.component.html'
})

export class PaymentsMethodsWithdrawalComponent implements OnInit {
  @Input()
  me: IMe;
  loading: boolean;
  isMobile: boolean;
  hostname: string;
  settings: AppSettings;

  paymentMethods: Array<IPaymentMethod> = [];
  paymentMethodsFiltered: Array<IPaymentMethod> = [];
  selected: IPaymentMethod;
  form: FormGroup;

  subs = new Subscription();
  selectedProviderGroupId: number;

  constructor(appSettingsService: AppSettingsService, windowService: WindowService, @Inject(WINDOW) private window: any, private sanitizer: DomSanitizer,
              private route: ActivatedRoute, private dialog: MatDialog,
              private identityWalletService: IdentityWalletService, private translate: TranslateService, private snackBar: MatSnackBar) {
    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    }));
    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));

    this.form = new FormGroup({
      amount: new FormControl(null, [
        Validators.required
      ])
    });
  }

  showSnackbar(key: string, panelClass: string): void {
    this.snackBar.open(this.translate.instant(key), this.translate.instant('close'), {panelClass});
  }

  ngOnInit(): void {
    this.identityWalletService.getPaymentProviders(false).subscribe({
      next: data => {
        this.paymentMethods = data
        this.selectedProviderGroupId = this.paymentMethods[0].groupId
        this.selectMethodForSelect(this.selectedProviderGroupId)
      }
    }).add(() => {
      //this.selected = this.paymentMethods[0];
      //this.setAmount(this.selected.minValue)
      //this.loading = true;
    })
  }

  selectMethod(pmId: number) {
    //console.log($event)
    this.selected = this.paymentMethods.find(x => x.id === pmId)
    //this.setAmount(this.selected.minValue)
    this.form.get('amount').setValidators([
      Validators.required,
      Validators.min(this.selected.minValue / 100),
      Validators.max(this.selected.maxValue / 100)
    ]);

    // only for mobile money
    if (this.selected.groupId === 5) {
      this.form.get('amount').addValidators(integerValidator);
    }
  }

  generateRange(base: number, increment: number, count: number) {
    const range = [];
    for (let i = 0; i < count; i++) {
      range.push(base * Math.max(1, (increment * i)));
    }
    return range;
  }

  setAmount(amount: number) {
    this.form.get('amount').setValue(amount / 100);
    this.form.get('amount').markAsTouched();
  }

  submit() {
    this.loading = true;

    let dialogComponent: any;
    const config: MatDialogConfig<IConfig> = {
      data: {
        amount: this.form.value.amount,
        isMobile: this.isMobile,
        me: this.me,
        provider: this.selected
      },
      minWidth: this.isMobile ? '100%' : '500px',
      panelClass: 'withdrawal-dialog'
    };

    switch (this.selected.openingTypeId) {
      case 1:
        this.checkOutPage()
        break
      case 3:
        dialogComponent = ManualWithdrawalDialog;
        this.dialog.open(dialogComponent, config)
        break;
      case 4:
        dialogComponent = ShopWithdrawalRequestDialog;
        this.dialog.open(dialogComponent, config)
        break;
      case 5:
        dialogComponent = BankTransferRequestDialog;
        this.dialog.open(dialogComponent, config)
        break;
      case 6:
        dialogComponent = PixWithdrawalRequestDialog;
        this.dialog.open(dialogComponent, config)
        break;
      case 8:
        dialogComponent = CryptoWithdrawalRequestDialog;
        this.dialog.open(dialogComponent, config)
        break;
    }

    this.loading = false;
  }

  selectMethodForSelect($event: any) {
    this.paymentMethodsFiltered = this.paymentMethods.filter(x => x.groupId === $event)
    if (this.paymentMethodsFiltered.length === 1) {
      this.selected = this.paymentMethodsFiltered[0]
    } else {
      this.selected = null
    }
  }

  private checkOutPage() {
    this.identityWalletService.withdrawCheckOut({
      amount: this.form.value.amount * 100,
      paymentMethodId: this.selected.id,
      redirectSuccessUrl: `${this.hostname}/${this.settings.languageCode}/payment/success`,
      redirectFailingUrl: `${this.hostname}/${this.settings.languageCode}/payment/declined`,
    }).subscribe({
      next: item => {
        this.dialog.open(DepositCheckOut, {
          data: {
            url: this.sanitizer.bypassSecurityTrustResourceUrl(item.url),
            isMobile: this.isMobile
          }
        });
      },

      error: error => {
        this.showSnackbar(error.message, 'error');
        console.log(error)
      }
    })
  }


}
