import { IGameProvider } from './game';

export interface IGameExternalSystem {
  id: number;
  name: string;
  isHidden: boolean;
  bookmakerId: number;
  providers?: Array<IGameProvider>;
}


export interface IGameExternalSystemSearchRequest {
  p: number;
  pp: number;
  bookmakerId: number;
  accountId?: number;
  isHidden?: boolean;
}
