import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
/* SHARED */
import { BsCommonModule } from '@bs/common';
import { EnvironmentConfig } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { HomerFormsModule } from '@homer/forms';
import { MaterialComponentsModule } from '@homer/mat-components';
import { LiveChatWidgetModule } from '@livechat/widget-angular';
/* VENDOR */
/* COMPONENTS */
import { BackComponent } from './components/back/back.component';
import { BottomBarComponent } from './components/bottom-bar/bottom-bar.component';
import { ClockComponent } from './components/clock/clock.component';
import { CommonSearchComponent } from './components/common-search/common-search.component';
import { Countdown } from './components/contdown/countdown.component';
import { CountDownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { DropdownLanguageComponent } from './components/dropdown-language/dropdown-language.component';
import { DropdownOddFormatComponent } from './components/dropdown-odd-format/dropdown-odd-format.component';
import { DropdownTimezonesComponent } from './components/dropdown-timezones/dropdown-timezones.component';
import { FooterComponent } from './components/footer/footer.component';
import { LicenseComponent } from './components/license/license.component';
import { LoginComponent } from './components/login/login.component';
import { MenuListComponent } from './components/menu-list/menu-list.component';
import { MenuComponent } from './components/menu/menu.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MobileChatBtnComponent } from './components/mobile-chat-btn/mobile-chat-btn.component';
import { ReadMoreContentComponent } from './components/read-more-content/read-more-content.component';
import { SharerComponent } from './components/sharer/sharer.component';
import { SwiperMainComponent } from './components/swiper-main/swiper-main.component';
import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component';

import { ToTopComponent } from './components/to-top/to-top.component';
import { WalletsViewerComponent } from './components/wallets-viewer/wallets-viewer.component';
/* DIALOGS */
import { ConfirmationDialog } from './dialogs/confirmation/confirmation-dialog.component';
import { ConfirmationService } from './dialogs/confirmation/confirmation.service';
import { ForgotPasswordDialog } from './dialogs/forgot-password/forgot-password-dialog.component';
import { LoginDialog } from './dialogs/login/login-dialog.component';
import { PasswordRecoveryDialog } from './dialogs/password-recovery/password-recovery-dialog.component';
import { RegistrationDialog } from './dialogs/registration/registration-dialog.component';
import { SettingsDialog } from './dialogs/settings/settings-dialog.component';
import { ZeroBalanceDialog } from './dialogs/zero-balance/zero-balance.dialog';
/* DIRECTIVES */
import { CenterScrollDirective } from './directives/center-scroll.directive';
/* PIPES */
import { FindPipe } from './pipes/find.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';


/* SERVICES */

/**
 * array of all the components that goes in NgModule exports and declaration
 */
const components = [
  BackComponent,
  BottomBarComponent,
  CenterScrollDirective,
  ClockComponent,
  CommonSearchComponent,
  ConfirmationDialog,
  Countdown,
  CountDownTimerComponent,
  DropdownLanguageComponent,
  DropdownOddFormatComponent,
  DropdownTimezonesComponent,

  FooterComponent,
  LicenseComponent,
  MenuComponent,
  MessagesComponent,
  MobileChatBtnComponent,

  LoginComponent,
  SettingsDialog,
  SharerComponent,
  ThemeSwitcherComponent,
  WalletsViewerComponent,
  ReadMoreContentComponent,

  LoginDialog,
  RegistrationDialog,
  ForgotPasswordDialog,
  PasswordRecoveryDialog,
  ZeroBalanceDialog,
];

/**
 * array of all the pipes that goes in NgModule exports and declaration
 */
const pipes = [
  FindPipe,
  KeysPipe
];

@NgModule({
  imports: [
    RouterModule,
    MaterialComponentsModule,
    HomerFormsModule,
    BsCommonModule,
    LiveChatWidgetModule,
    SwiperMainComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatCardModule,
    MatRadioModule,
    MatInputModule,
  ],
  declarations: [
    ...components,
    ...pipes,
    MenuListComponent,
    ToTopComponent
  ],
  exports: [
    HomerFormsModule,
    BsCommonModule,
    MaterialComponentsModule,
    SwiperMainComponent,
    ...pipes,
    ...components
  ]
})
export class HomerCommonModule {
  static forRoot(config: any): ModuleWithProviders<HomerCommonModule> {
    const optionalProviders = []

    return {
      ngModule: HomerCommonModule,
      providers: [
        ...optionalProviders,
        AppSettingsService,
        ConfirmationService,
        {
          provide: EnvironmentConfig,
          useValue: config
        }
      ]
    };
  }
}

