import { DictionarySport } from './dictionary';

export interface LiveViewerSettingSports extends DictionarySport, LiveViewerSettings {

}

export class LiveViewerSettings {
  sportId: number;
  includeInView: boolean;
  items: number;
  duration: number;

  constructor(sportId: number) {
    this.sportId = sportId;
    this.includeInView = false;
    this.items = 0;
    this.duration = 3000
  }
}

export type LiveViewerSettingsKey =
  'sportId'
  | 'includeInView'
  | 'items'
  | 'duration';
