<div class="container-fluid">
  @if (favourites.length) {
    <div class="my-4">
      <button class="btn-back" [routerLink]="['../']" mat-flat-button color="primary">
        <svg icon="chevron-left" lib="global" [size]="{w:8, h:8}" class="me-2"></svg>
        <span>{{ 'back-to-games' | translate }}</span>
      </button>
    </div>

    <casino-filter class="mt-5" [showProvidersFilter]="true" [showSort]="true" (sortBy)="sortSelected=$event"></casino-filter>
    <h2 class="title mb-2 mb-lg-4 mt-5">{{ 'favourites' | translate }}</h2>
    <casino-games-grid [games]="favourites | sort: sortSelected?.name: 'name'"></casino-games-grid>
  } @else {
    <div class="row no-gutters my-5">
      <div class="my-4 d-flex">
        <button class="btn-back" [routerLink]="['../']" mat-flat-button color="primary">
          <svg icon="chevron-left" lib="global" [size]="{w:8, h:8}" class="me-2"></svg>
          <span>{{ 'back-to-games' | translate }}</span>
        </button>
      </div>

      <div class="col mx-auto">
        <mat-card>
          <mat-card-content class="py-4 d-flex aligh-items-center justify-content-center">
            <div class="d-flex flex-column align-items-center justify-content-center p-3 text-center">
              <svg icon="heart" lib="global" [size]="{w:16, h:16}" class="mb-1" color="red"></svg>
              <span class="card-title">{{ 'noFavourites' | translate }}</span>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  }
</div>
@if (isMobile) {
  <bottom-bar [menuId]="maggieMenu.Main" [menuLoggedId]="maggieMenu.Main" class="row"></bottom-bar>
}

