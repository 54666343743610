import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DictionarySport, EnvironmentConfig } from '@bs/models';
import { Observable, of } from 'rxjs';
import { SportsMenuService } from './sports-menu.service';

@Injectable()
export class DictionariesService {


  readonly url = `${this.config.api.sportsbook}/dictionaries`;

  constructor(private config: EnvironmentConfig, private sportsMenu: SportsMenuService, private client: HttpClient) {

  }

  dictionaries(): Observable<{ sports: Array<DictionarySport> }> {

   return this.client.get<{ sports: Array<DictionarySport> }>('assets/mocks/dictonary.json');
   //return this.client.get<{ sports: Array<DictionarySport> }>(`${this.url}/sports`);

  }

  // marketsSport(): Observable<Array<DictionaryMarketSport>> {
  //   return this.client.get<Array<DictionaryMarketSport>>(`${this.url}/markets-sport`);
  // }

}
