import { BookmakersResolver, CurrenciesResolver, LangGuard, SlidersResolver, TranslateGuard, UserLoggedGuard } from '@bs/services';
import { RecoveryPasswordPage } from '@maggie/app/pages/recovery-password/recovery-password.page';
import { CashbackPage } from './pages/cashback/cashback.page';
import { EmailConfirmationPage } from './pages/email-confirmation/email-confirmation.page';
import { DownloadPage } from './pages/download/download.page';
import { LogoutPage } from './pages/logout/logout.page';
import { NgModule } from '@angular/core';
import { RegisterPage } from './pages/register/register.page';
import { RouterModule, Routes } from '@angular/router';
import { VipPage } from './pages/vip/vip.page';
import { environment } from '@maggie/environments/environment';

const optionalRoutes: Routes = [];

optionalRoutes.push({
  path: 'sports',
  loadChildren: () => import('./pages/sports/sports.module').then(m => m.SportsModule)
})

optionalRoutes.push({
  path: 'e-sports',
  loadChildren: () => import('./pages/e-sports/e-sports.module').then(m => m.ESportsModule)
})

if (environment.api.games) {
  optionalRoutes.push({
    path: 'games',
    loadChildren: () => import('./pages/games/games.module').then(m => m.GamesModule)
  });
}

if (environment.api.sportsbook) {
  optionalRoutes.push({
    path: 'sportsbook',
    loadChildren: () => import('./pages/sportsbook/sportsbook.module').then(m => m.SportsbookModule)
  })
}

if (environment.api.promo) {
  optionalRoutes.push({
    path: 'promotions',
    data: {
      section: 'promotions'
    },
    loadChildren: () => import('./pages/promotions/promotions.module').then(m => m.PromotionsModule)
  });
}

const routes: Routes = [
  {
    path: '',
    canActivate: [TranslateGuard],
    children: [],
    pathMatch: 'full'
  },
  {
    path: ':lang',
    resolve: {currencies: CurrenciesResolver, sliders: SlidersResolver},
    data: {
      langs: environment.langs,
    },
    canActivate: [LangGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: environment.homePage
      },
      {
        path: 'logout',
        component: LogoutPage,
        title: 'seo-logout-page-title'
      },
      {
        path: 'email-confirmation',
        component: EmailConfirmationPage,
        title: 'seo-email-confirmation-page-title'
      },
      {
        path: 'rp',
        component: RecoveryPasswordPage,
        title: 'seo-recovery-password-page-title'
      },
      {
        path: 'download',
        component: DownloadPage,
        title: 'seo-download-page-title'
      },
      {
        path: 'loyalty',
        loadComponent: () => import('./pages/loyalty/loyalty-system.page').then(c => c.LoyaltySystemPage),
        title: 'seo-loyalty-page-title'
      },
      {
        path: 'cashback',
        component: CashbackPage,
        title: 'seo-cashback-page-title'
      },
      {
        path: 'vip',
        component: VipPage,
        title: 'seo-vip-page-title',
        data: {
          name: 'vip'
        }
      },
      {
        path: 'affiliates',
        loadComponent: () => import('./pages/affiliate/affiliate.page').then(c => c.AffiliatePage),
        title: 'seo-affiliate-page-title',
        resolve: {
          sliders: SlidersResolver
        }
      },
      {
        path: 'not-authorized',
        loadComponent: () => import('./pages/errors/errors.page').then(c => c.ErrorsPage),
        //title: 'seo-not-authorized-page-title',
        data: {
          icon: 'ban',
          backBtn: true,
          title: 'not-authorized-page-title',
          description: 'not-authorized-page-description'
        }
      },
      {
        path: 'auto-logout',
        data: {
          backBtn: true,
          //description: 'auto-login-page-description',
          icon: 'auto-logout',
          //title: 'auto-login-page-title'
        },
        loadComponent: () => import('./pages/errors/errors.page').then(c => c.ErrorsPage),
      },
      {
        path: 'register',
        component: RegisterPage,
        canActivate: [UserLoggedGuard],
        title: 'seo-register'
      },
      {
        path: 'me',
        loadChildren: () => import('./pages/me/me.module').then(m => m.MeModule)
      },
      {
        path: 'accounts',
        resolve: {
          bookmakers: BookmakersResolver
        },
        loadChildren: () => import('./pages/accounts/accounts.module').then(m => m.AccountsModule)
      },
      {
        path: 'cms',
        data: {
          section: 'cms'
        },
        loadChildren: () => import('./pages/cms/cms.module').then(m => m.CmsModule)
      },
      ...optionalRoutes,
      {
        path: '**',
        redirectTo: '404',
      },
      {
        path: '404',
        data: {
          backBtn: true,
          description: 'not-found-page-description',
          icon: '404',
          title: 'not-found-page-title'
        },
        loadComponent: () => import('./pages/errors/errors.page').then(c => c.ErrorsPage),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
