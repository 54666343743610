import { KeyValue } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentConfig, IMe, IPaymentMethod, IWithdrawalRequest } from '@bs/models';
import { AuthService, IdentityWalletService } from '@bs/services';
import { WithdrawalBaseDialog } from '../withdrawal-base.dialog';

interface MyData {
  me: IMe;
  amount: number;
  provider: IPaymentMethod;
}

@Component({
  selector: 'pix-withdrawal-request-dialog',
  templateUrl: 'pix-withdrawal-request.dialog.html'
})
export class PixWithdrawalRequestDialog extends WithdrawalBaseDialog {
  form: FormGroup;
  pixKeys: KeyValue<string, any>[] = [];
  selectedPixKey: any;

  constructor(private config: EnvironmentConfig, public identityWalletService: IdentityWalletService, public authService: AuthService,
              public dialogRef: MatDialogRef<PixWithdrawalRequestDialog>,
              fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: MyData) {
    super(identityWalletService, authService);

    this.pixKeys = []

    if (this.authService.accountLogged$.value.documents.find(x => x.typeId === 9)) {
      this.pixKeys.push({key: 'cpf', value: this.authService.accountLogged$.value.documents.find(x => x.typeId === 9).documentNumber})
    }

    // if (this.authService.accountLogged$.value.deliveries.find(x => x.typeId === DeliveryType.Email)) {
    //   this.pixKeys.push({key: 'email', value: this.authService.accountLogged$.value.deliveries.find(x => x.typeId === DeliveryType.Email).delivery})
    // }
    //
    // if (this.authService.accountLogged$.value.deliveries.find(x => x.typeId === DeliveryType.Mobile)) {
    //   this.pixKeys.push({key: 'celPhone', value: this.authService.accountLogged$.value.deliveries.find(x => x.typeId === DeliveryType.Mobile).delivery})
    // }

    this.form = fb.group({
      selectedPixKey: [this.pixKeys[0], Validators.required],
      amount: [{value: data.amount, disabled: true}],
    });
  }

  submit() {
    const request: IWithdrawalRequest = {
      amount: this.data.amount,
      paymentMethodId: this.data.provider.id,
      additionalData: JSON.stringify({
        pixKey: this.form.value.selectedPixKey
      })
    };

    this.createMovement(request);
  }
}
