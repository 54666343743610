import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

import { IdName } from '@bs/models';

@Component({
  selector: 'brands-dropdown',
  templateUrl: './brands-dropdown.component.html'
})
export class BrandsDropdownComponent implements OnInit, OnChanges {
  @ViewChild('brandsMenu') menu: MatMenu;

  @Input() brands: Array<IdName<string>>;
  @Input() onlyIcon;

  @Output() brandSelected: EventEmitter<IdName<string>> = new EventEmitter();

  take = 10;
  chunk: IdName<string>[] = [];

  constructor(private elRef: ElementRef,
              private render: Renderer2) {
  }

  ngOnInit(): void {
    this.render.setStyle(this.elRef.nativeElement, 'display', 'block');
    this.render.setStyle(this.elRef.nativeElement, 'width', '100%');
  }

  onBrandSelected(brand: IdName<string>) {
    this.brandSelected.emit(brand);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.brands.currentValue === undefined || changes.brands.currentValue.length === 0) {
      this.chunk = [];
    } else {
      const values: IdName<string>[] = changes.brands.currentValue;
      this.chunk = values.slice(0, this.take);
    }
  }

  addBrands($event: MouseEvent) {
    this.chunk.push(...this.brands.slice(this.chunk.length, this.chunk.length + this.take));
    $event.stopImmediatePropagation();
    $event.stopPropagation();
  }
}
