import { IWallet } from './account';

export interface ICheckOutResponse {
  openingTypeId: number;
  data: string;
  url: string;
}

export interface IDepositOtpSendResponse {
  transactionId: number;
}

export interface IDepositInitResponse {
  transactionId: number;
}

export interface IWithdrawalResponse {
  movementId: number;
  wallets: Array<IWallet>;
}

export interface InitDepositRequest {
  paymentMethodId: number;
  amount: number;
  promoCode?: string;
  additionalData?: any;
}

export interface InitDepositResponse {
  transactionId: number;
  data: any;
}

export interface IDepositRequest {
  amount: number;
  paymentMethodId: number;
  promoCode?: string;
}

export interface IDepositOtpCheckRequest {
  paymentMethodId: number;
  transactionId: number;
  otp: string;
}

export interface IDepositOtpCheckResponse {
  success: boolean;
}

export interface IDepositCheckOutRequest extends IDepositRequest {
  additionalData?: any;
  redirectFailingUrl?: string;
  redirectSuccessUrl?: string;
}

export interface IWithdrawalRequestCheckOutRequest {
  paymentMethodId: number;
  amount: number;
  redirectFailingUrl?: string;
  redirectSuccessUrl?: string;
}

export interface IWithdrawalRequest {
  amount: number;
  paymentMethodId: number;
  additionalData?: any;
}

export interface IWithdrawalOtpCheckRequest {
  paymentMethodId: number;
  transactionId: number;
  otp: string;
}

export interface IWithdrawalOtpSendResponse {
  transactionId: number;
}

export interface IWithdrawalOtpSendRequest {
  amount: number;
  paymentMethodId: number;
}

export interface IWithdrawalOtpCheckResponse {
  success: boolean;
}
