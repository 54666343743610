import { IdName } from '../common/id-name';

export interface ISearchSessionRequest {
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  displayCurrencyId?: number;
  orderBy?: string;
  orderByDirection?: string;
  ipAddress?: string;
  isClosed?: boolean;
  accountId?: number;
  bookmakers: Array<number>
}


export interface ISession {
  id: number;
  account: ISessionAccount;
  creationDate: string;
  lastUpdated: string;
  closingDate: string;
  ipAddress: ISessionIpAddress;
  tpl?:string;
}

interface ISessionIpAddress {
  ipAddress: string;
  counter: number;
  accounts: Array<ISessionAccount>;
}

interface ISessionAccount {
  bookmakerId: number;
  id: number;
  username: string;
  typeId: number;
  status: IdName<string>;
  registrationDate: Date;
  counter?: number;
}
