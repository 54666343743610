<div class="d-flex flex-wrap flex-lg-nowrap align-items-center casino-filter">
  <div class="d-flex align-items-center flex-grow-1">
    <a class="nav-link" mat-flat-button routerLink="/{{currLang}}/games/{{section}}/favourites" routerLinkActive="active" [matBadge]="favourites?.length" [matBadgeHidden]="favourites?.length === 0" matBadgePosition="above after">
      <svg icon='heart' lib="global" class="p-1" [size]="{w:20, h:20}"></svg>
      <span class="favourites d-none d-lg-inline" translate>favourites</span>
    </a>
    <mat-form-field class="ms-3">
      <input matInput type="text" [(ngModel)]="searchGame" (ngModelChange)="searchForGame($event)" placeholder="{{'search-game' | translate}}">
      <svg icon="search" matSuffix lib="global" [size]="{w:18, h:18}"></svg>
    </mat-form-field>
    <!-- <button class="ms-3 d-md-none" mat-flat-button (click)="openProvidersFilter()" *ngIf="showProvidersFilter">
  </button> -->
</div>
@if (showSort) {
  <div class="d-flex align-items-center right-col mt-3 mt-md-0">
    <sort-by-dropdown class="ms-md-3 flex-grow-1" (sortByChange)="sortBy.emit($event)" ></sort-by-dropdown>
    @if (showProvidersFilter) {
      <button class="ms-3" mat-flat-button (click)="openProvidersFilter()">
        <svg icon='filter-variant' lib="global" class="p-1" [size]="{w:30, h:30}"></svg>
        <span class="filter-title d-none">{{'filters' | translate}}</span>
      </button>
    }
  </div>
}
</div>
