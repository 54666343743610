import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationData } from './confirmation';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['confirmation-dialog.component.scss']
})
export class ConfirmationDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationData,
              private dialogRef: MatDialogRef<ConfirmationDialog>) {

  }

  cancel() {
    this.dialogRef.close();
    if (this.data.reject && typeof (this.data.reject) === 'function') {
      this.data.reject();
    }
  }

  confirm() {
    this.dialogRef.close();
    this.data.accept();
  }
}
