import { Component } from '@angular/core';

@Component({
  templateUrl: './clock.component.html',
  selector: 'clock',
  styles: [`:host {
    display: block;
  }`]
})
export class ClockComponent {

  currentTime = new Date();

  constructor() {
    setInterval(() => this.currentTime = new Date(), 1000);
  }
}
