@if (isMobile) {
  <div class="row m-0 play-mob-header align-items-center" id="header">
    <div class="col logo-container">
      <div class="bck"></div>
    </div>
    <div class="col text-right">
      <back (backEvent)="dialogRef.close()" [customAction]="true"></back>
    </div>
  </div>
}
@if (depositUrl) {
  <iframe
    #frame
    (load)="onLoad()"
    [ngStyle]="{height: isMobile? 'calc(100vh - 40px)' : '550px'}"
    [src]="depositUrl" frameborder="0"
    scrolling="yes">
  </iframe>
}

