@if (loading) {
  <div class="loading-overlay">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
}
<form-builder #builderComponent (submits)="submit($event)" [config]="autoexclusionConfig" [vertical]="true">
  <div class="button-container">
    <button class="ms-auto mt-2" [disabled]="!builderComponent.form?.valid || loading" color="primary"
      mat-flat-button type="submit">
      <span>{{'save' | translate}}</span>
    </button>
  </div>
</form-builder>
