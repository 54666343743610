import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { LocalizedDatePipe } from '@bs/common';
import { IBetOffer, IBetOfferInProgressRequest } from '@bs/models';
import { DateFactoryService } from '@bs/services';
import { SportsbookService } from '../services/sportsbook.service';

interface FilterDate {
  fromDate: string | null,
  toDate: string | null,
  label?: string
}

/**
 * Resolver gets us the bet ofers
 */
export const SportsBookResolver: ResolveFn<IBetOffer> = (route: ActivatedRouteSnapshot) => {
  const values: Array<FilterDate> = [];
  //const hours: Array<FilterDate>;

  /**
   * The constructor
   * @param sportsBookService
   * @param dfs
   */
  const sportsBookService = inject(SportsbookService);
  const dfs = inject(DateFactoryService)
  const localizeDatePipe = inject(LocalizedDatePipe);

  const now = new Date();
  const date = now.getDate();
  const hour = now.getHours();
  Array.from(Array(7), (_, i) => {
    values.push({
      fromDate: dfs.toTz(new Date(new Date().setDate(date - 1 + i)), true),
      toDate: dfs.toTz(new Date(new Date().setDate(date + i)), true),
      label: localizeDatePipe.transform(new Date(new Date().setDate(date + i)), 'E').toLowerCase()
    })
  });

  values.push(
    {label: '3h', fromDate: dfs.toTz(now), toDate: dfs.toTz(new Date(new Date().setHours(hour + 3)))},
    {label: '24h', fromDate: dfs.toTz(now), toDate: dfs.toTz(new Date(new Date().setHours(hour + 24)))},
    {label: '72h', fromDate: dfs.toTz(now), toDate: dfs.toTz(new Date(new Date().setHours(hour + 72)))},
    {label: 'all', fromDate: undefined, toDate: undefined}
  )


  //const filters: IBetOfferInProgressRequest = {eventTypeId: 4, offerTypeId: 1, fromDate, toDate}
  const filters: IBetOfferInProgressRequest = {};

  if (route.queryParamMap.has('toDate')) {
    const label = route.queryParamMap.get('toDate');
    const v = values.find(v => v.label === label);
    if (v) {
      filters.fromDate = v.fromDate;
      filters.toDate = v.toDate;
    }
  }

  if (route.queryParamMap.has('offerTypeId')) {
    filters.offerTypeId = route.queryParamMap.get('offerTypeId');
  }

  if (route.queryParamMap.has('eventTypeId')) {
    filters.eventTypeId = route.queryParamMap.get('eventTypeId');
  }

  return sportsBookService.get(filters);

}
