import { IAuthor } from '../common/author';

export interface IGetCatalogItemResponse {
  catalogId: number;
  catalog: string;
  typeCatalogId: number;
  typeCatalog: string;
}


// export interface TranslationsResponse {
//   records: Array<{ key: string, source: string, target: string }>;
//   total: number;
// }

export interface ICatalog {
  id: number;
  name: string;
}

export interface ICatalogTranslation {
  catalogId: number;
  bookmakerId: number;
  catalogKeyId: number;
  sourceLanguageId: number;
  targetLanguageId: number;
  source: string;
  target: string;
  author: IAuthor;
}

export interface ICatalogTranslationUpdateRequest {
  bookmakerId: number;
  catalogKeyId: number;
  targetLanguageId: number;
  value: string;
}
export interface ICatalogTranslationRequest {
  catalogId: number;
  bookmakerId: number;
  sourceLanguageId: number;
  targetLanguageId: number;
}
