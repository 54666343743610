import { DictionaryMarketSport } from '@bs/models';
import { ResolveFn } from '@angular/router';

/**
 * Resolver returning us a list of DictionaryMarketSport list items
 *
 * see {@link DictionaryMarketSport} for more information on the model
 */
export const SportDictionaryMarketsSportResolver: ResolveFn<Array<DictionaryMarketSport>> = () => {
  // return inject(DictionariesService).marketsSport();
  return []
}
