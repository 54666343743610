import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentConfig, IPromoInfo, IPromoPlayer, IPromoPlayerDetails, IPromoSearchRequest, IPromotion, IPromotionBase, IPromoType, Pager, PromoStatus } from '@bs/models';
import { CatalogService } from '@bs/services';
import { Observable, of } from 'rxjs';

@Injectable()
export class PromoService {

  readonly url = `${this.config.api.promo}/promotions`;

  constructor(private config: EnvironmentConfig, private http: HttpClient, private catalogService: CatalogService) {
  }

  all(): Observable<IPromotion[]> {
    const cid = this.catalogService.currentCurrency.id.toString();
    return this.http.get<IPromotion[]>(`${this.url}`, {params: {cid}});
  }

  get(promoId: number): Observable<IPromotion> {
    const cid = this.catalogService.currentCurrency.id.toString();
    return this.http.get<IPromotion>(`${this.url}/${promoId}`, {params: {cid}});
  }

  getByIdDetails(promoId: number): Observable<IPromotion> {
    return this.http.get<IPromotion>(`${this.url}/${promoId}/details`);
  }

  claim(promoId: number, params: Params) {
    return this.http.post<boolean>(`${this.url}/${promoId}/claim`, params).toPromise();
  }

  create(promo: Partial<IPromotion>) {
    return this.http.post<IPromotion>(`${this.url}`, promo);
  }

  /***
   * Patch Status
   */
  patchStatus(promoId: number, params: { statusId: PromoStatus }) {
    return this.http.patch(`${this.url}/${promoId}/status`, params);
  }

  patchEndDate(promoId: number, params: { endDate: string }) {
    return this.http.patch(`${this.url}/${promoId}/endDate`, params);
  }

  patchStartDate(promoId: number, params: { startDate: string }) {
    return this.http.patch(`${this.url}/${promoId}/startDate`, params);
  }

  patchPromoInfo(promoId: number, info: IPromoInfo) {
    return this.http.patch(`${this.url}/${promoId}/infos`, info);
  }

  forfeit(promoId: number, params: Params) {
    return this.http.post<boolean>(`${this.url}/${promoId}/forfeit`, params);
  }

  search(filters: IPromoSearchRequest): Observable<Pager<IPromotionBase>> {
    return this.http.post<Pager<IPromotionBase>>(`${this.url}/search`, filters);
  }

  createStep(promoId: number, actionTypeId: number, data: any) {
    return this.http.post(`${this.url}/${promoId}/steps`, {actionTypeId, data});
  }

  searchPlayers(body: Params): Observable<Pager<IPromoPlayer>> {
    return this.http.post<Pager<IPromoPlayer>>(`${this.url}/${body.promoId}/players`, body);
  }

  getPromoPlayer(promoId: number, playerId: number): Observable<IPromoPlayerDetails> {
    return this.http.get<IPromoPlayerDetails>(`${this.url}/${promoId}/players/${playerId}`);
  }

  getPromoTypes(): Observable<Array<IPromoType>> {
    return of([
      {
        id: 100, name: 'registrations', steps: [
          {
            id: 1, name: 'entry',
            actionTypes: [
              {id: 1, name: 'playersSignUp'}
            ]
          },
          {
            id: 2, name: 'deposits',
            actionTypes: [
              {id: 3, name: 'firstDeposit'},
              {id: 3, name: 'deposits'},
            ]
          }],
      },
      {
        id: 101, name: 'firstDeposits', steps: [
          {
            id: 1, name: 'entry',
            actionTypes: [
              {id: 3, name: 'firstDeposits'}
            ]
          }],
      },
      {
        id: 102, name: 'deposits', steps: [
          {
            id: 1, name: 'entry',
            actionTypes: [
              {id: 4, name: 'deposits'}
            ]
          }
        ],
      },
      {
        id: 103, name: 'sportsbook', steps: [
          {
            id: 1, name: 'entry',
            actionTypes: [
              {id: 5, name: 'playSportsBook'}
            ]
          }
        ],
      },
      {
        id: 104, name: 'games', steps: [
          {
            id: 1, name: 'entry',
            actionTypes: [
              {id: 6, name: 'playGames'}]
          },
        ],
      }
    ])
    //return this.http.get<Array<IPromoType>>(`${this.url}/catalogs/promo-types`);
  }
}
