import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IFixtureDeny } from '@bs/models';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FixtureDenyService {
  readonly url = `${this.config.api.sportsbook}/fixtures`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  create(id: number, data: IFixtureDeny): Observable<IFixtureDeny> {
    return this.http.post<IFixtureDeny>(`${this.url}/${id}/deny`, data)
  }

  update(data: IFixtureDeny): Observable<IFixtureDeny> {
    return this.http.put<IFixtureDeny>(`${this.url}/${data.fixtureId}/deny`, data);
  }
}
