import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ISportEvent } from '@bs/models';
import { of } from 'rxjs';
import { SportsbookService } from '../services/sportsbook.service';

/**
 * Resolver gets the sports daily matches
 */
export const DailyMatchesResolver: ResolveFn<Array<ISportEvent>> = (route: ActivatedRouteSnapshot) => {
  if (!route.queryParamMap.keys.length) {
    return of([]);
  }
  const params = Object.assign({}, route.queryParams, {eventTypeId: 1, offerTypeId: 1});
  return inject(SportsbookService).getSportEvents(params);
}
