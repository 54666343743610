import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from './is-present.validator';

export const limitationsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (isPresent(Validators.required(control))) {
    return null;
  }

  const values: Array<{ name: string, amount: number, active: boolean }> = control.value;

  const activeValues = values.filter(v => v.active);

  if (!activeValues.length) { // no one is active
    return {'invalid-limitation': true};
  } else {
    const isValid = activeValues.every((v, i, all) => {
      if (i === 0) {
        return true;
      }
      return v.amount >= all[i - 1].amount
    });
    return isValid ? null : {'invalid-limitation': true};
  }

};
