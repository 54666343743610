import { Bonus } from './bonus';
import { MyMath } from './mymath';
import { Winning } from './winning';

export class Combination {
  constructor(public type: number = 0,
              public combinations: number = 0,
              public amount: number = 0,
              public bonus: Bonus = new Bonus(),
              public winning: Winning = new Winning(),
              public values: Array<any> = [],
              public enabled: boolean = false) {
  }

  calculateBonus(): void {
    const minMultiplier = this.values.reduce((p, c) => (p.multiplier < c.multiplier) ? p : c);
    this.bonus.min = minMultiplier.bonus ? MyMath.round(this.amount * minMultiplier.bonus, 4) : 0;

    const maxMultiplier = this.values.reduce((p, c) => (p.multiplier > c.multiplier) ? p : c);
    this.bonus.max = maxMultiplier.bonus
      ? MyMath.round(this.amount * this.values.reduce((sum, v) => sum + v.bonus, 0), 4)
      : 0;
  }

  calculateWinning(): void {
    const minMultiplier = this.values.reduce((p, c) => (p.multiplier < c.multiplier) ? p : c);
    this.winning.min = MyMath.round(this.amount * minMultiplier.multiplier + this.bonus.min, 4);
    this.winning.max = MyMath.round(this.amount * this.values.reduce((sum, value) => sum + value.multiplier, 0) + this.bonus.max, 4);
  }
}
