import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ICasinoTournament, ISlider } from '@bs/models';
import { AuthService, SmarticoService } from '@bs/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'section.casino-tournaments',
  templateUrl: 'casino-tournaments.page.html'
})

export class CasinoTournamentsPage implements OnInit, OnDestroy {
  subs = new Subscription();
  sliderHero: ISlider;

  constructor(private smarticoService: SmarticoService, authService: AuthService, private route: ActivatedRoute) {

    this.subs.add(route.data.subscribe({
      next: data => {
        this.sliderHero = data.sliders.find(x => x.name === data.section + '-hero');
      }
    }));
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

    this.smarticoService.showWidget('tournaments', 'iframe-tournaments');
  }

}
