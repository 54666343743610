<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span [translate]="'qr-code'"></span>
      <button (click)="dialogRef.close(false)" class="close-icon me-2" mat-icon-button tabindex="-1">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>
    <div class="dialog-wrapper">
      <div class="row gx-0">
        <div class="col-12">
          <div class="px-5 py-3">
            <div>
              <span>{{ data.paymentMethod.textContent }}</span>
            </div>
            <span class="d-block">
              <div class="my-3">
                <span>{{ 'deposit-value' | translate }}</span>:
                <span>{{ data.amount | currencyAmount:currentCurrency.symbol }}</span>
              </div>
              <button class="w-100" color="primary"
                      mat-flat-button (click)="copy()" [matTooltip]="'tooltip-copy-deposit-qr-code' | translate" matTooltipPosition="right">
                <span class="me-1" translate>copy</span>
              </button>
            </span>
            <div class="text-center pt-3">
              <div class="d-flex">
                <div class="divider-container flex-grow-1">
                  <mat-divider [inset]="true"></mat-divider>
                </div>
                <div class="text-uppercase px-3">{{ 'or' | translate }}</div>
                <div class="divider-container flex-grow-1">
                  <mat-divider [inset]="true"></mat-divider>
                </div>
              </div>
              <div class="my-3">
                <qrcode [qrdata]=qrCode [allowEmptyString]="false" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
