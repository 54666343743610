import { Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeService } from '@bs/common';
import { IBaseGame, MaggieMenu } from '@bs/models';
import { GamesFavouritesService } from '@bs/services';
import { WindowService } from '@bs/universal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'section.favourites',
  templateUrl: 'favourites.page.html'
})
export class FavouritesPage extends UnsubscribeService {
  favourites: IBaseGame[];
  sortSelected: any;
  isMobile: boolean;
  maggieMenu = MaggieMenu;
  subs = new Subscription();

  constructor(route: ActivatedRoute,
              protected windowService: WindowService,
              private gamesFavouritesService: GamesFavouritesService) {
    super();
    this.gamesFavouritesService.favourites$.subscribe({
      next: data => this.favourites = data
    })

    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));

  }
}
