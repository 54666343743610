<div class="mat-horizontal-content-container">
  <form [formGroup]="form" (ngSubmit)="submit()">
    @if (loading) {
      <div class="loading-overlay">
        <mat-spinner [diameter]="22" color="accent"></mat-spinner>
      </div>
    }

    <field [input]="{label: 'password', name:'password', type: 'mobile-prefix'}" formControlName="phone"></field>
    <field [input]="{label: 'password', name:'password', type: 'password'}" formControlName="password"></field>
    <field [input]="{label: 'repeatPassword', name:'repeatPassword', type: 'password'}" formControlName="repeatPassword"></field>
    <field [input]="{label: 'referralCode', name:'referralCode', type: 'text'}" formControlName="referralCode"></field>
    @if ((currencies$ | async); as currencies) {
      <field [input]="{label: 'currency', name:'currencyId', type: 'select'}" [values]="currencies" formControlName="currencyId"></field>
    }

    <field [input]="{label: 'confirmation', name: 'confirmation', type: 'checkbox'}" formControlName="confirmation"></field>

    <div class="mt-4 w-100">
      <button class="w-100" mat-raised-button color="primary" [disabled]="!form.valid">{{'submit' | translate}}</button>
    </div>
  </form>
</div>

<!--<pre>{{form.value | json}}</pre>-->
