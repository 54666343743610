<mat-menu #brandsMenu="matMenu">
  @for (brand of chunk; track brand) {
    <a mat-menu-item (click)="onBrandSelected(brand)">
      {{ brand.name }}
    </a>
  }
  @if (chunk.length < brands.length) {
    <button mat-flat-button (click)="addBrands($event)">
      <span [translate]="'view-more'">view more</span>
    </button>
  }
</mat-menu>
<a [matMenuTriggerFor]="brandsMenu">
  @if (!onlyIcon) {
    <span [translate]="'brands'">Brands</span>
  } @else {
  }
</a>
