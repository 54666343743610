<img [lazyLoad]="game.thumbnail" defaultImage="./assets/images/casino/placeholder-games.webp" class="img-fluid">
<ng-template [ngTemplateOutlet]="isMobile? mobileTpl:deskTpl"></ng-template>

<ng-template #deskTpl>
  <div class="overlay text-center game-container">
    <div class="overlay-container d-flex flex-column">
      @if (showProviderName) {
        <div class="provider-name mt-4 d-none d-md-block">{{ game.provider.name }}</div>
      }
      <div (click)="toggleFavorite(game)" class="favourites-icon">
        <svg [icon]="isFavorite(game) ? 'star' : 'star-outline'" lib="global" [size]="{w:20, h:20}"></svg>
      </div>
      <div class="play-container d-flex align-items-center justify-content-center flex-column">
        <ng-template [ngTemplateOutlet]="[5323,5322,5318].includes(game.id) ? virtualTpl : buttonsTpl"></ng-template>
      </div>
    </div>
  </div>
  <strong class="game-name-grid ellipsis">{{ game.name }}</strong>
</ng-template>

<ng-template #mobileTpl>
  <div class="mobile-overlay">
    <ng-template [ngTemplateOutlet]="[5323,5322,5318].includes(game.id) ? virtualTpl : buttonsTpl"></ng-template>
  </div>
  <div class="game-info">
    <strong class="game-name d-block ellipsis">{{ game.name }}</strong>
  </div>
</ng-template>


<ng-template #buttonsTpl>
  <a [routerLink]="[baseUrl, 'mode', 'real']" class="as-button" color="primary" mat-flat-button>{{ 'play-now' | translate }}</a>
  @if (game.forFun && forFun) {
    <a [routerLink]="[baseUrl, 'mode', 'fun']" class="as-button" mat-flat-button>{{ 'try-now' | translate }}</a>
  }
</ng-template>


<ng-template #virtualTpl>
  <a [routerLink]="[baseVirtual]" class="as-button" color="primary" mat-flat-button>{{ 'play-now' | translate }}</a>
  @if (game.forFun) {
    <a [queryParams]="{logout:true}" [routerLink]="[baseVirtual]" class="as-button" mat-flat-button>{{ 'try-now' | translate }}</a>
  }
</ng-template>
