import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AppSettingsService } from '@bs/services';
import { SwiperContainer } from 'swiper/swiper-element';
import { SwiperOptions } from 'swiper/types';

@Directive({
  selector: '[fmSwiper]',
  standalone: true
})
export class SwiperDirective implements OnInit, AfterViewInit {

  private readonly swiperElement: SwiperContainer;

  @Input('config')
  config?: SwiperOptions;

  style = '';

  isDarkTheme: boolean;

  constructor(el: ElementRef<SwiperContainer>, private appSettingsService: AppSettingsService) {
    this.swiperElement = el.nativeElement;
    this.appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.isDarkTheme = settings.darkTheme
    });
  }

  ngOnInit() {
    const textColor = getComputedStyle(document.documentElement).getPropertyValue('--text-color');
    const buttonColor = this.isDarkTheme ? getComputedStyle(document.documentElement).getPropertyValue('--gray') ?? '#474747' : getComputedStyle(document.documentElement).getPropertyValue('--swiper-buttons') || '#FFF';
    this.style = `
      .swiper-button-next,
      .swiper-button-prev {
      background-color: ${buttonColor};
        border-radius: 50% !important;
        height: 28px;
        top: 26px;
        width: 28px;
      }

      .swiper-button-next svg,
      .swiper-button-prev svg {
        color: ${textColor};
        height: 10px;
        font-weight: bold;
      }

      .swiper-button-next {
        right: 90px;
      }

      .swiper-button-prev {
        left: auto;
        right: 124px;
      }

     .no-btn-prev {
       right: 38px;
      }

     .no-btn-next {
       right: 0;
      }

    .swiper-new-prev {
      left: 50%;
      transform: translatex(-100px);
      }

    .swiper-new-next {
      right: 50%;
      transform: translatex(100px);
    }
    `;
  }

  ngAfterViewInit() {
    Object.assign(this.swiperElement, Object.assign(this.config, {injectStyles: [this.style]}));

    this.swiperElement.initialize();
    if (this.config.autoplay) {
      this.swiperElement?.swiper.autoplay.start();
    }

    // Styling no-btn elements
    if (this.swiperElement.classList.contains('no-btn')) {
      const shadowRoot = this.swiperElement.shadowRoot;
      shadowRoot.querySelector('.swiper-button-prev').classList.add('no-btn-prev');
      shadowRoot.querySelector('.swiper-button-next').classList.add('no-btn-next');
    }

    // Styling swiper-new elements
    if (this.swiperElement.classList.contains('swiper-new')) {
      const shadowRoot = this.swiperElement.shadowRoot;
      shadowRoot.querySelector('.swiper-button-prev').classList.add('swiper-new-prev');
      shadowRoot.querySelector('.swiper-button-next').classList.add('swiper-new-next');
    }
  }
}
