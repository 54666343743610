import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[infiniteGamesScroll]',
})
export class InfiniteScrollDirective {

  @Output('loadMoreItems') loadItems = new EventEmitter(false);

  @HostListener("scroll", ['$event.target'])
  scrollHandler(target: HTMLElement) {
    const gamesGridEl = target.querySelector('.grid-games');
    if(gamesGridEl && gamesGridEl.getAttribute('data-loadMoreGames') === 'true') {
      const scrolled = (gamesGridEl.scrollHeight - 270) - target.scrollTop < 1;

      if (scrolled) {
        this.loadItems.emit(true);
      }
    }
  }
}
