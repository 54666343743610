import { Component, Input } from '@angular/core';
import { UnsubscribeService } from '@bs/common';
import { EnvironmentConfig, IMe, IMenuItem, IMenuStatus, MaggieMenu } from '@bs/models';
import { AuthService, CmsService, MenuResolver } from '@bs/services';
import { WidgetStateService, WidgetStateSubject } from '@livechat/widget-angular';
import { EMPTY, map, Subscription, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'sidebar-menu-maggie',
  templateUrl: 'sidebar-menu.component.html'
})

export class SidebarMenuComponent extends UnsubscribeService {

  menuStatus: IMenuStatus;

  @Input()
  loadMenu: 'main' | 'side' | 'section';

  @Input()
  isMobile: boolean;

  mainMenu: IMenuItem;
  serviceMenu: IMenuItem;
  accountMenu: IMenuItem;
  subs = new Subscription();
  me: IMe;
  hasChat = this.config.liveChats?.tawkUrl || this.config.liveChats?.liveChatInc;

  constructor(cmsService: CmsService, authService: AuthService, menuService: MenuResolver,
              private config: EnvironmentConfig) {

    super();
    menuService.menuStatus$.subscribe({
      next: status => this.menuStatus = status
    });

    authService.accountLogged$.pipe(
      takeUntil(this.unsubscribe$),
      switchMap(account => {
        if (account) {
          return cmsService.getMenu(MaggieMenu.MeNavigationLogged).pipe(map(menu => ({menu, account})));
        }
        return EMPTY;
      })).subscribe({
      next: ({account, menu}) => {
        this.me = account;
        this.accountMenu = menu;
        //cdr.markForCheck();
      },
      error: err => console.error(err)
    });

    cmsService.getMenu(MaggieMenu.Main).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: menu => {
        this.mainMenu = menu;
        //cdr.markForCheck();
      }
    });

    cmsService.getMenu(MaggieMenu.Services).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: menu => {
        this.serviceMenu = menu;
        //cdr.markForCheck();
      }
    });
  }
}
