import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentConfig } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  readonly url = `${this.config.api.identity}/recovery-passwords`;

  constructor(private config: EnvironmentConfig, private client: HttpClient) {
  }

  recoveryPasswordCheck(token: string): Promise<boolean> {
    return this.client.post<boolean>(`${this.url}/check`, {token}).toPromise();
  }

  recoveryPassword(password, token: string): Observable<boolean> {
    return this.client.post<boolean>(`${this.url}`, {token, password})
  }

  recoveryEmail(params: Params) {
    return this.client.post<boolean>(`${this.url}/request/email`, params).toPromise();
  }

  /**
   * initialize a request of recovery password by phone
   * @param params {phone: IMobilePrefix}
   * @return a string containing
   */
  recoveryPhone(params: Params): Promise<string> {
    return this.client.post<string>(`${this.url}/request/phone`, params).toPromise();
  }

  /**
   * complete the request of recovery phone
   * @param params    otp: num   phone: IMobileprefix   transactionId: string   validationType: "ivr" or "sms"
   * @return a string token
   */
  recoveryPhoneCheck(params: Params) {
    return this.client.post<string>(`${this.url}/request/phone/check`, params).toPromise();
  }
}
