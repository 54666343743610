import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ILanguage } from '@bs/models';


@Injectable({
  providedIn: 'root'
})
export class BetOfferLanguageService {
  readonly url = `${this.config.api.sportsbook}/bet-offer`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  patch(id: number, item: Array<ILanguage>): Promise<Array<ILanguage>> {
    return this.http.patch<Array<ILanguage>>(`${this.url}/${id}/languages`, item).toPromise();
  }
}
