import { Component, Input } from '@angular/core';
import { MessageModel } from './message-model';

export enum Severity {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger'
}


@Component({
  templateUrl: 'messages.component.html',
  selector: 'messages'
})
export class MessagesComponent {
  @Input()
  severity: Severity;
  @Input()
  messages: MessageModel[];

}
