import { Injectable, Optional } from '@angular/core';

import { GeoLocation } from '@bs/models';
import { Observable } from 'rxjs';
import { GeoIpService } from '../../services/core/geoIp.service';

/**
 * Resolver returning us the GeoLocation if it finds any
 */
@Injectable({
  providedIn: 'root'
})
export class GeoInfoResolver  {
  /**
   * The constructor
   */
  constructor(@Optional() private geoIpService: GeoIpService) {
  }

  /**
   * the resolve function retrieves us the data needed to activate for the requested route
   *
   * see {@link GeoLocation} for more details of properties
   */
  resolve(): Observable<GeoLocation | null> | Promise<GeoLocation | null> | GeoLocation | null {

    if (this.geoIpService) {
      return this.geoIpService.get();
    }
    return null;

  }

}
