<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <div>
        @if (data.icon) {
        }
        <span [translate]="'are-you-sure'">Are you Sure?</span>
      </div>
      <a (click)="cancel()" class="close-icon me-2">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </a>
    </mat-card-title>

    <div class="dialog-wrapper">
      <div class="scrollbar">
        <div class="dialog-body px-3">{{ data.message | translate }}</div>
      </div>
    </div>
    <div class="row p-10">
      <div class="col-12 text-center">
        <button type="button" (click)="confirm()" mat-flat-button color="primary"
                value="confirm">
          <span [translate]="'confirm'">Confirm</span>
        </button>
      </div>
    </div>
  </mat-card>
</div>
