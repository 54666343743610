import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocalizedDatePipe } from '@bs/common';

import { EnvironmentConfig, timezones } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { WindowService } from '@bs/universal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dropdown-timezones',
  templateUrl: 'dropdown-timezones.component.html',
  providers: [
    LocalizedDatePipe
  ]
})
export class DropdownTimezonesComponent implements OnDestroy, OnInit {

  tzsByGMT: {
    label: string,
    value: string,
    items: { label: string, value: string }[]
  }[] = [];
  selectedZone: string;
  langCode: string;
  date: string;
  isMobile: boolean;

  headerLayout = this.config.features?.headerLayout || 'header1';

  subs = new Subscription();

  constructor(windowService: WindowService, private appSettingsService: AppSettingsService, private config: EnvironmentConfig,
              private localizedDatePipe: LocalizedDatePipe) {
    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));
    this.subs.add(this.appSettingsService.appSettings$.subscribe({
      next: ({settings}) => {
        this.langCode = settings.languageCode;
        if (settings.timeZone && this.selectedZone !== settings.timeZone) {
          this.selectedZone = settings.timeZone;
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    const patterna = '\\(GMT([-|+]?\\d{2}:\\d{2})\\)';
    const patternb = /\(GMT[-|+]?[0-9]{2}:[0-9]{2}\)\s/g;

    Object.entries(timezones).map(([k, v]) => {
      const tz = v.match(patterna)[1]; // -11:00
      const idx = this.tzsByGMT.findIndex(t => t.label === tz);
      const l = v.replace(patternb, '');

      if (idx === -1) {
        this.tzsByGMT.push({
          label: tz,
          value: tz,
          items: [
            {label: l, value: k}
          ]
        })
      } else {
        this.tzsByGMT[idx].items = this.tzsByGMT[idx].items.concat({label: l, value: k});
      }
    });
  }

  changeZone(zone): void {
    this.selectedZone = zone;
    this.date = this.localizedDatePipe.transform(this.getCurrentTimeInTimezone(zone), 'E dd MMM YYYY HH:mm');
    this.appSettingsService.saveSetting('timeZone', zone);
  }

  getCurrentTimeInTimezone(timezone: string) {
    const now = new Date();
    const timeZoneOptions = { timeZone: timezone };
    const timeZoneString = now.toLocaleString('en-US', timeZoneOptions);
    const timeZoneDate = new Date(timeZoneString);
    return timeZoneDate;
  }
}
