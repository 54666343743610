import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { IPaymentMethod } from '@bs/models';
import { IdentityWalletService } from '../../services/identity/identity.wallet.service';
import { inject } from '@angular/core';

/**
 * Resolver returns us all the payment providers of a bookmakerId
 */
export const PaymentMethodsResolver: ResolveFn<Array<IPaymentMethod>> = (route: ActivatedRouteSnapshot) => {
  const isDeposit = route.data.section === 'deposit';
  return inject(IdentityWalletService).getPaymentProviders(isDeposit);
}
