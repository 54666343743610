import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ISlider } from '@bs/models';
import { WindowService } from '@bs/universal';
import { map } from 'rxjs';

type ICashback = {
  name: string
  percentage?: number
  depositRange?: { min: number; max?: number }
}

@Component({
  selector: 'section.cashback',
  templateUrl: 'cashback.page.html'
})

export class CashbackPage {

  isMobile = toSignal(inject(WindowService).device$.pipe(map(({isMobile}) => isMobile)));

  sliderHero: ISlider = {
    "id": 305,
    "sizeId": 1,
    "bookmakerId": 22,
    "name": "casino-hero",
    "webAppId": 3,
    "banners": [
      {
        "id": 120,
        "sizeId": 1,
        "statusId": 2,
        "creationDate": "2023-12-11T10:43:23.699Z",
        "name": "homebanner",
        "bookmakerId": 22,
        "backgroundColor": "#000",
        "images": [
          {
            "id": 444,
            "languageId": 40,
            "desktop": "https://res.cloudinary.com/datm0xyxd/image/upload/v1697298616/banners/13/40/20-cash-back-weekly.webp.webp",
            "tablet": "https://res.cloudinary.com/datm0xyxd/image/upload/v1702291323/banners/22/40/1024x400.png.png",
            "mobile": "https://res.cloudinary.com/datm0xyxd/image/upload/v1702291323/banners/22/40/480x150.png.png"
          }
        ],
        "ctaVisible": false,
        "position": {
          "vertical": 1,
          "horizontal": 1
        },
        "timing": {
          "range": {
            "from": "2023-12-09T23:00:00Z",
            "to": "2030-12-31T22:59:59Z"
          }
        },
      }
    ],
    "config": {
      "interval": 5000,
      "pauseOnHover": false,
      "showNavigationArrows": false,
      "showNavigationIndicators": false,
      "autoPlay": false,
      "sizes": {
        "desktop": {
          "width": 1920,
          "height": 400
        },
        "tablet": {
          "width": 1024,
          "height": 400
        },
        "mobile": {
          "width": 480,
          "height": 150
        }
      }
    }
  }
  items: ICashback[] = [
    {name: 'tier'},
    {name: 'bronze', percentage: 0.05, depositRange: {min: 2000, max: 50000}},
    {name: 'silver', percentage: 0.06, depositRange: {min: 50100, max: 100000}},
    {name: 'diamond', percentage: 0.08, depositRange: {min: 100100, max: 250000}},
    {name: 'gold', percentage: 0.10, depositRange: {min: 250100, max: 500000}},
    {name: 'gold', percentage: 0.12, depositRange: {min: 500100}},
    {name: 'vip', percentage: 0.25},
  ]

}
