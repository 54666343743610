import { Component, OnDestroy } from '@angular/core';
import { OddFormat } from '@bs/models';

import { AppSettingsService } from '@bs/services';
import { WindowService } from '@bs/universal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dropdown-odd-format',
  templateUrl: 'dropdown-odd-format.component.html'
})
export class DropdownOddFormatComponent implements OnDestroy {

  isMobile: boolean;
  oddFormat: OddFormat;
  formats = OddFormat.values();

  subs = new Subscription();

  constructor(windowService: WindowService, private appSettingsService: AppSettingsService) {
    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));

    this.subs.add(this.appSettingsService.appSettings$.subscribe({
      next: ({settings}) => {
        this.oddFormat = settings.oddFormat
      }
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  changeFormat(value) {
    this.appSettingsService.saveSetting('oddFormat', value);
  }
}
