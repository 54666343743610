import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';

@Pipe({
  name: 'updateParams',
  pure: false
})
export class UpdateParamsPipe implements PipeTransform {

  constructor(private route: ActivatedRoute) {
  }

  transform(currentParam: Params): Params {

    if (!currentParam) {
      return {};
    }
    const newParams = {};

    const queryParamMap: ParamMap = this.route.snapshot.queryParamMap;


    Object.entries(currentParam).forEach(([key, val]) => {
      const oldParams = queryParamMap.getAll(key).map(x => x);

      // if currentParam is in queryParam (remove it)
      if (oldParams.includes(val)) {
        const idx = oldParams.findIndex(i => i === val);

        if (idx > -1) {
          oldParams.splice(idx, 1);
          newParams[key] = oldParams;
        }

      } else { // else add it
        newParams[key] = [currentParam[key], ...oldParams];
      }

    })


    return newParams;
  }
}
