import { IBookmaker } from '../me/account';

export interface ILanguage {
  languageId: number;
  name: string;
  code: string;
}

export interface IFixtureLanguage extends ILanguageLanguage {
  fixtureId: number;
}

export interface ICompetitorLanguage extends ILanguageLanguage {
  competitorId: number;
}

export interface IMarketLanguage extends ILanguageLanguage {
  marketId: number;
}

export interface ISportEventLanguage extends ILanguageLanguage {
  sportEventId: number;
}

export interface ILanguageLanguage {
  bookmaker: IBookmaker;
  language: ILanguage;
  value: string;
}

export interface ILanguageEntityLabel extends ILanguageLanguage {
  entityLabelTypeId: number;
  entityLabelId: number;
}
