import { DOCUMENT, KeyValue } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrinterService } from '@bs/common';
import { IMe, INetworkTransfer, INetworkTransfersRequest, NetworkTransferTypes } from '@bs/models';
import { AccountsService, AuthService, IdentityWalletService, WalletNetworkTransfersService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

interface MyData {
  me: IMe;
  amount: number;
  isMobile: boolean;
}

@Component({
  selector: 'shop-withdrawal-request-dialog',
  templateUrl: 'shop-withdrawal-request.dialog.html',
  styleUrls: ['shop-withdrawal-request.dialog.scss']
})
export class ShopWithdrawalRequestDialog implements OnDestroy {
  @ViewChild('inputElement')
  inputElement: ElementRef;

  cities: KeyValue<string, any>[] = [];
  selectedShop: any;
  selectedCity: any;
  shopslocations: Array<KeyValue<string, any>>;

  transfer: INetworkTransfer;
  isLoading: boolean;

  subs = new Subscription();

  constructor(private viewRef: ViewContainerRef, public walletNetworkTransfersService: WalletNetworkTransfersService,
              public authService: AuthService, private printerService: PrinterService,
              private identityWalletService: IdentityWalletService,
              public dialogRef: MatDialogRef<ShopWithdrawalRequestDialog>, accountService: AccountsService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              @Inject(DOCUMENT) private document: Document,
              @Inject(MAT_DIALOG_DATA) public data: MyData) {

    accountService.searchShops().then(locations => {
      this.shopslocations = locations.reduce((h, a) => Object.assign(h, {
        [a.city]: (h[a.city] || []).concat({
          id: a.id,
          name: a.name,
          address: a.address
        })
      }), []);
      this.cities = Object.keys(this.shopslocations).map(s => ({key: s, value: this.shopslocations[s]}));
    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  handleSubmit() {
    this.isLoading = true;

    const req: INetworkTransfersRequest = {amount: this.data.amount * 100, typeId: NetworkTransferTypes.shopPayoutRequest, beneficiaryId: this.data.me.id, holderId: this.selectedShop.id}
    this.subs.add(this.walletNetworkTransfersService.create(req).subscribe({
      next: data => {
        this.transfer = data
        void this.identityWalletService.wallets();
      },

      error: error => {
        this.snackBar.open(error.message, this.translate.instant('close'),
          {panelClass: 'error', horizontalPosition: 'end', duration: 3500});
      }
    }));
    this.isLoading = false;

  }

  print(): void {
    //this.printerService.printTransfer(this.viewRef, this.transfer);
  }

  copy() {
    this.inputElement.nativeElement.select();
    this.inputElement.nativeElement.setSelectionRange(0, this.transfer.code.length);
    const copy = this.document.execCommand('copy');

    if (copy) {
      this.snackBar.open(this.translate.instant('receiptCopied', {code: this.transfer.code}), this.translate.instant('close'),
        {panelClass: 'success', horizontalPosition: 'end', duration: 3500});
    } else {
      this.snackBar.open(this.translate.instant('receiptCopyFailed'), this.translate.instant('close'),
        {panelClass: 'error', horizontalPosition: 'end', duration: 3500});
    }
  }

}
