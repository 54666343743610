import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';

interface MyData {
  url: SafeResourceUrl;
  isMobile: boolean;
}

@Component({
  selector: 'deposit-checkout-dialog',
  templateUrl: 'deposit-check-out-dialog.component.html',
  styles: [`
    iframe {
      width: 100%;
      min-width: 400px;
      background: white;
    }

    #header {
      height: 45px;
      background: #1E1E1E;
      color: #FFF;
    }

    .bck {
      height: 28px;
      max-width: 120px;
      background-repeat: no-repeat;
      background-position: left;
    }

    .logo-container .bck {
      /* background-image: url('../../../../assets/images/logo-white.svg');*/
    }
  `]
})
export class DepositCheckOut implements OnInit {
  depositUrl: SafeResourceUrl;
  iframeLoaded: boolean;
  depositFormGroup: FormGroup;
  responseType: number;
  isMobile: any;

  constructor(public dialogRef: MatDialogRef<DepositCheckOut>, @Inject(MAT_DIALOG_DATA) private data: MyData) {
    this.depositFormGroup = new FormGroup({});
    this.isMobile = data.isMobile;
  }

  ngOnInit(): void {
    this.depositUrl = this.data.url;
  }

  onLoad() {
    this.iframeLoaded = true;
  }
}
