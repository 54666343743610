import { Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings, IMenu, ICmsMenuLink } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { WINDOW } from '@bs/universal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'menu-list',
  template: `
    <h3>{{menu?.name}}</h3>
    <ul>
      @for (link of menu?.items; track link) {
        <li>
          <ng-template [ngTemplateOutlet]="link.isExternal ? externalTpl:linkTpl" [ngTemplateOutletContext]="{$implicit: link}"></ng-template>
        </li>
      }
    </ul>
    <ng-template #externalTpl let-link>
      <a target="_blank" [href]="link.url">{{link.text}}</a>
    </ng-template>
    <ng-template #linkTpl let-link>
      <a [routerLink]="'/' + settings.languageCode + link.url">{{link.text}}</a>
    </ng-template>
    `
})
export class MenuListComponent implements OnDestroy {
  @Input() menu: IMenu;
  @Output() menuLinkClicked: EventEmitter<ICmsMenuLink> = new EventEmitter();
  @Input() handleRedirect = true;

  settings: AppSettings;

  subs = new Subscription();

  constructor(public router: Router, public appSettingsService: AppSettingsService, @Inject(WINDOW) public window: any) {
    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: data => this.settings = data.settings
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
