import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UnsubscribeService } from '@bs/common';
import { BetslipService } from '@bs/sportsbook';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'stake',
  templateUrl: './stake.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StakeComponent),
      multi: true
    }
  ]
})
export class StakeComponent extends UnsubscribeService implements ControlValueAccessor {

  @Output()
  blur = new EventEmitter<any>(null);

  @Input()
  placeholder: '';

  @Input()
  readonly: '';

  @Input()
  hideStakeEdition: boolean;

  private _value: number;
  private onChange: any = () => {
  };
  private onTouched: any = () => {
  };

  stakeButtons: Array<number>;

  Number = Number;

  constructor(betslipService: BetslipService) {
    super();
    betslipService.settings().pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: settings => this.stakeButtons = this.generateButtons(settings.stake)
      });
  }

  private generateButtons(stake: number) {
    const arr = Array.from(Array(4), (_, i) => stake * (i + 1) * 2);
    return arr;
  }

  setAmount(amount) {
    this._value += amount;
    this.onChange(this._value);
  }

  setStakeValue(stake: number) {
    this._value = stake;
    this.onChange(this._value);
  }

  get value(): string {
    return this._value ? (this._value / 100).toFixed(2) : '';
  }

  set value(val: string) {
    const value = parseFloat(val.replace(',', '.'));
    this._value = isNaN(value) ? null : Math.round(value * 100);
    this.onChange(this._value);
  }

  onBlur(event) {
    this.onTouched();
    this.blur.emit(event);
  }

  onInput() {
    this.onChange(this._value);
  }

  writeValue(value: number): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
