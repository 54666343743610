<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span>{{ data.paymentMethod.name }}</span>
      <button (click)="dialogRef.close()" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>

    <div class="dialog-wrapper">
      <div class="scrollbar">
        <div class="dialog-body">
          <div class="row gx-0">
            <div class="col-12">
              <div class="alert alert-success">
                    <span class="d-block">
                      <strong [translate]="'deposit-requested-message'" class="ms-1">Deposit request submitted. Please approve payment on your phone</strong>
                    </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
