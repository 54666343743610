import { CurrencyConversion } from '../common/currency';
import { ISearchBaseSportsBookFilters } from './common';
import { ITicketBase } from './ticket';

export interface ISettlement {
  id: number;
  creationDate: string;
  count: number;
  totals: ISettlementTotals;
  tickets?: Array<ITicketBase>;
  stats: ISettlementStats;
}

export interface ISettlementStats {
  won: number;
  lost: number;
  void: number;
  refunded: number;
}

export interface ISettlementTotals extends CurrencyConversion {
  stake: number;
  paid: number;
  profit: number;
}

export interface ISettlementSearchFilters extends ISearchBaseSportsBookFilters {
  fromDate: string;
  toDate: string;
}
