import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcceptOddsChangesTypes, BetSlipError, BookBetRequest, EnvironmentConfig, IBetSlip, IBetSlipBonus, IBetslipGroup, IBetSlipSelection, IBetSlipSettings, IBetSlipType, IMe, IPromoTournament, OddFormat, Warning } from '@bs/models';

import { AccountsService, AuthService, CatalogService, CentrifugeService } from '@bs/services';
import { LocalStorage } from '@bs/universal';

import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const findDuplicates = (arr, attribute) => {
  const duplicates = [];
  const seen = new Set();

  for (const item of arr) {
    const value = item[attribute];
    if (seen.has(value)) {
      duplicates.push(item);
    } else {
      seen.add(value);
    }
  }
  return duplicates;
}


@Injectable()
export class BetslipService {
  readonly url = this.config.api.sportsbook;

  betslip$: BehaviorSubject<IBetSlip>;
  betslipErrors$: Subject<BetSlipError> = new Subject<BetSlipError>();
  betslipWarnings$: Subject<Warning> = new Subject<Warning>();
  me: IMe;

  constructor(private config: EnvironmentConfig, private storage: LocalStorage, private client: HttpClient, private authService: AuthService,
              private accountService: AccountsService, private catalogService: CatalogService, private centrifuge: CentrifugeService) {

    const localSlip = this.betslip;

    this.betslip$ = localSlip ? new BehaviorSubject<IBetSlip>(localSlip) : new BehaviorSubject<IBetSlip>(new IBetSlip());

    this.betslip$.subscribe({
      next: slip => {
        slip.status = slip.selections.length ? 'selections' : 'empty';
        this.betslip = slip;
      }
    });

    this.authService.accountLogged$.subscribe({
      next: account => this.me = account
    });
  }

  get betslip(): IBetSlip {
    const slip = this.storage.getItem('slip');
    return JSON.parse(slip);
  }

  set betslip(betslip: IBetSlip) {
    this.storage.setItem('slip', JSON.stringify(betslip));
  }

  init(jackpot?: IPromoTournament) {
    const betslip = this.betslip$.getValue();

    if (jackpot) {
      betslip.jackpotId = jackpot.id;
      betslip.betTypeId = IBetSlipType.Multiple;
      betslip.amounts = {
        winning: {min: jackpot.prizePool[0].amount, max: jackpot.prizePool[0].amount},
        stake: jackpot.sportsBook.stakes[0].stake
      };
    }

    betslip.selections.forEach(sel => this.centrifuge.subscribe(`match-${sel.sportEvent.id}`));
  }

  getByCode(code: string): Observable<IBetSlip> {
    return this.client.get<IBetSlip>(`${this.url}/betslip/${code}`).pipe(tap(slip => this.betslip$.next(slip)));
  }

  toggleSelection(newSelection: IBetSlipSelection) {
    const currentSlip = this.betslip;

    // if (!this.checkCompatibility(currentSlip, newSelection)) {
    //   return;
    // }

    if (!this.checkUnique(currentSlip, newSelection)) {
      return;
    }

    if (this.checkIntegral(currentSlip, newSelection)) {
      return;
    }

    const unique = newSelection.marketSelection.oddId;

    const idx = currentSlip.selections.findIndex(s => s.marketSelection.oddId === unique);

    if (idx > -1) {
      this.centrifuge.unsubscribe(`match-${currentSlip.selections[idx].sportEvent.id}`);
      currentSlip.selections.splice(idx, 1);
    } else {
      if (currentSlip.jackpotId) {
        // multiple not allowed
        const ids = currentSlip.selections.flatMap(x => x.sportEvent.id);

        if (ids.includes(newSelection.sportEvent.id)) {
          return this.betslipWarnings$.next({message: 'selection-not-allowed', dismissible: true, delay: 3500});
        }
      }
      this.centrifuge.subscribe(`match-${newSelection.sportEvent.id}`);
      currentSlip.selections.push(newSelection);
    }

    if (currentSlip.selections.length) { // guess bet type
      this.guessBetType(currentSlip);
    } else {
      currentSlip.betTypeId = IBetSlipType.Single;
    }

    this.betslip$.next(currentSlip);

  }

  clean(jackpot?: IPromoTournament) {
    let betslip = null;
    if (jackpot) {
      betslip = {
        betTypeId: IBetSlipType.Multiple,
        amounts: {
          winning: {min: jackpot.prizePool[0].amount, max: jackpot.prizePool[0].amount},
          stake: jackpot.sportsBook.stakes[0].stake
        }
      }
    }
    const oldSlip = this.betslip;
    this.betslip$.getValue().selections.forEach(sel => this.centrifuge.unsubscribe(`match-${sel.sportEvent.id}`));
    this.betslip$.next(new IBetSlip(betslip, oldSlip));
  }

  bookingBet(form) {
    const selections = form.selections.flatMap(v => ({oddId: v.marketSelection.oddId, blocked: v.blocked}));
    const reqSlip: BookBetRequest = {
      betTypeId: form.betTypeId,
      // betGroupId,
      stake: form.amounts.stake,
      selections
    }

    return this.client.post(`${this.url}/tickets/booking`, reqSlip);
  }

  settings(): Observable<IBetSlipSettings> {
    const accountId = this.me ? this.me.id : this.config.bookmakerId;
    const currencyId = this.catalogService.currentCurrency.id;

    const slip = this.betslip;
    const oddFormat = slip.settings?.oddFormat || OddFormat.Decimal;
    const acceptOddsChanges = slip.settings?.acceptOddsChanges || AcceptOddsChangesTypes.always;
    const stake = slip.settings?.stake || 0;

    this.betslip$.next(Object.assign(slip, {settings: {oddFormat, acceptOddsChanges, stake}}));

    return forkJoin([
      this.getBetBonus(accountId),
      this.accountService.getTemplateSportsBook(accountId, currencyId)
    ]).pipe(map(([bonus, template]) => ({bonus, template, oddFormat, acceptOddsChanges, stake})));
  }

  saveSettings(setting: IBetSlipSettings) {
    const slip = this.betslip;
    slip.settings = setting;
    this.betslip$.next(slip);
  }

  private getBetBonus(a: number): Observable<IBetSlipBonus> {
    return this.client.get<IBetSlipBonus>(`${this.config.api.boe}/betbonus`, {params: {a}});
  }

  private guessBetType(currentSlip: IBetSlip) {
    if (currentSlip.selections.length === 1) {
      currentSlip.betTypeId = IBetSlipType.Single;
    } else {
      const sportEvents = currentSlip.selections.flatMap(s => s.sportEvent);
      const isIntegral = Boolean(findDuplicates(sportEvents, 'id').length);

      if (isIntegral) {
        currentSlip.betTypeId = IBetSlipType.Integral;
      } else if (currentSlip.betTypeId === IBetSlipType.System) {
        // currentSlip.betTypeId = IBetSlipType.System
      } else {
        currentSlip.betTypeId = IBetSlipType.Multiple;
      }
    }
  }

  private checkCompatibility(currentSlip: IBetSlip, newSelection: IBetSlipSelection): boolean {
    if (currentSlip.selections.length) {
      const compatibility = currentSlip.selections.every(s => s.sportEvent.typeId === newSelection.sportEvent.typeId);
      if (!compatibility) {
        this.betslipWarnings$.next({message: 'error-incompatible-types', dismissible: true, delay: 3500});
        return false;
      }
    }
    return true;
  }

  /**
   * block user to place integral bets
   * @param currentSlip
   * @param newSelection
   * @private
   */
  private checkIntegral(currentSlip: IBetSlip, newSelection: IBetSlipSelection): boolean {
    if (currentSlip.selections.length) {
      const isIntegral = currentSlip.selections.some(s => s.sportEvent.id === newSelection.sportEvent.id && s.marketSelection.id !== newSelection.marketSelection.id);
      if (isIntegral) {
        this.betslipWarnings$.next({message: 'error-integral-selection', dismissible: true, delay: 3500});
        return true;
      }

    }
    return false;
  }

  private checkUnique(currentSlip: IBetSlip, newSelection: IBetSlipSelection): boolean {
    const currentUnique = currentSlip.selections.find(sel => sel.marketSelection.oddId === newSelection.marketSelection.oddId);
    if (currentUnique) {
      this.betslipWarnings$.next(null);
      return true
    }

    if (currentSlip.selections.length) {
      //push current selection in
      const uniqueSelections = [...currentSlip.selections, newSelection];

      const unique = uniqueSelections.find(sel => sel.marketSelection.playability === 0);
      if (unique) {
        this.betslipWarnings$.next({message: 'error-unique-selection', value: `${unique.sportEvent.name} | ${unique.marketSelection.oddId}`, dismissible: true, delay: 3500});
        return false;
      }
    }

    return true;
  }
}
