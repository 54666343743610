<!--<pre>{{limitations | json}}</pre>-->

<div class="d-flex align-items-center flex-wrap">
  @for (limitation of selfLimitationForms; track limitation; let j = $index) {
    <div class="wrapper">
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2 class="title">{{ limitation.name | translate}}</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!--<pre>{{limitation.form.value | json}}</pre>-->
          <form class="p-4" [formGroup]="limitation.form" (ngSubmit)="submit(limitation)">
            <div formArrayName="periods">
              @for (limitForm of periods(limitation.name).controls; track limitForm; let i = $index) {
                <div class="d-flex align-items-center mb-4" [formGroup]="limitForm">
                  <!--{{!limitForm.get('active').value}}-->
                  <mat-checkbox class="cb-row" formControlName="active">{{limitationValues[i].name}}</mat-checkbox>
                  <mat-form-field>
                    <mat-label>
                      <span>{{'amount' | translate}}</span>
                      <span matPrefix>({{currency.code}})</span>
                    </mat-label>
                    <input type="number" formControlName="amount" matInput>
                  </mat-form-field>
                </div>
              }
              <mat-error [control]="limitation.form.get('periods')"></mat-error>
            </div>
            <div class="button-container">
              <button class="me-auto mt-2" [disabled]="!limitation.form?.valid || loading || limitation.form.untouched " color="primary"
                mat-flat-button type="submit">
                <span>{{'save' | translate}}</span>
              </button>
            </div>
            <mat-error [control]="limitation.form"></mat-error>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  }
</div>

