import { Selection } from '../selection';
import { Bonus } from './bonus';
import { Combination } from './combination';
import { Multiplier } from './multiplier';
import { MyMath } from './mymath';
import { Winning } from './winning';
import { WinTax } from './wintax';

/**
 * @todo this is a collection of methods not a model, must be moved in a component or somewhere
 */
export class BetType {

  public combinations: Array<Combination>;
  public totalStake?: { value: number };

  constructor(public amount: number = 0,
              public stakeTax: number = 0,
              public winTax: WinTax = new WinTax(),
              public winning: Winning = new Winning(),
              public multiplier: Multiplier = new Multiplier(),
              public hydrated: boolean = false) {
  }

  calculateBonus(percentages: any, minimumRequiredMultiplier: number, selections: Array<Selection>, bonus: Bonus): Bonus {

    let multiplier = 1;
    let validOdds = 0;

    selections.forEach((s) => {
      if (s.oddValue >= minimumRequiredMultiplier) {
        multiplier *= s.oddValue;
        validOdds++;
      }
    });

    if (validOdds) {
      bonus.percentage = percentages[validOdds];
      bonus.min = null;
      bonus.max = MyMath.round((this.amount - this.stakeTax) * multiplier * (bonus.percentage / 100), 4);
    }

    return bonus;
  }

  calculateCombinations(_selections: Array<Selection>, _percentagesSetting: any, _minimumRequiredMultiplier: number): void {
  }

  calculateMultiplier(selections: Array<Selection>): Multiplier {
    const max = selections.reduce((sum, el) => {
      return sum * el.oddValue;
    }, 1);

    this.multiplier.max = MyMath.round(max, 4);

    return this.multiplier;
  }

  calculateTotal(): number {
    return this.amount;
  }

  getTotalForBetRequest(): number {
    return this.calculateTotal();
  }

  calculateWinning(bonus: Bonus): Winning {
    this.winning.max = MyMath.round((this.multiplier.max * (this.amount  - this.stakeTax)) + bonus.max, 4);
    return this.winning;
  }
}
