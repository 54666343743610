import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '@bs/services';

@Component({
  selector: 'section.logout',
  templateUrl: 'logout.page.html'
})

export class LogoutPage implements OnDestroy {

  constructor(private authService: AuthService) {
    authService.delete().subscribe();
    /*this.authService.reset();
    this.authService.accountLogged$.next(null);*/
  }

  ngOnDestroy(): void {
  }

}
