export interface IAffiliateRegistration {
  hostname: string;
  first_name: string;
  last_name: string;
  bo_user_email: string;
  phone_number: string;
  username: string;
  password: string;
  country: string;
  tc: boolean;
  token: string;
}
