import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Confirmation } from './confirmation';
import { ConfirmationDialog } from './confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(private dialog: MatDialog) {

  }

  confirm(confirmation: Confirmation): void {
    this.dialog.open(ConfirmationDialog, {
      width: confirmation.width,
      data: confirmation.data
    });
  }
}
