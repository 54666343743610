import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'back',
  templateUrl: './back.component.html'
})
export class BackComponent {
  @Input() toRoute;
  @Input() customAction;
  @Output() backEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router, private location: Location) {
  }

  back() {
    /**
     * temporary solution, to many route when showing tournament
     */
    if (this.customAction) {
      this.backEvent.emit(true);
      return;
    }

    if (this.toRoute) {
      this.router.navigate([this.toRoute]);
    } else {
      this.location.back();
    }
  }
}
