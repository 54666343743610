import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'timer',
  templateUrl: 'countdown-timer.component.html',
})
export class CountDownTimerComponent implements OnInit {
  @Input() value: number;
  @Output('onComplete') timerOver: EventEmitter<any> = new EventEmitter<any>();


  ngOnInit() {
    const interval = setInterval(() => {
      this.value--;

      if (this.value < 0) {
        clearInterval(interval);
        this.timerOver.emit(true);
      }
    }, 1000);
  }
}
