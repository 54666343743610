import { Component } from '@angular/core';
import { AppSettingsService } from '@bs/services';

@Component({
  selector: 'to-top',
  templateUrl: './to-top.component.html',
  styles: [`:host {
    display: block
  }`]
})
export class ToTopComponent {

  constructor(private settingsService: AppSettingsService) {
  }

  toTop() {
    this.settingsService.scrollTo$.next('top');
  }
}
