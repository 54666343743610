<!--<pre>{{form.value | json}}</pre>-->
<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span>
        <svg icon="store" lib="global" [size]="{w:14, h:14}" class="me-2"></svg>
        <span [translate]="'registration'">Registration</span>
      </span>
      <button (click)="dialogRef.close()" [routerLink]="[]" [fragment]="null" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>
    <div class="dialog-wrapper" [ngClass]="{mobile: isMobile}">
      <div class="scrollbar" [class.disabled]="!isMobile">
        <div class="registration-wrapper">
          <form [formGroup]="form" (ngSubmit)="submit()">
            @if (loading) {
              <div class="loading-overlay">
                <mat-spinner [diameter]="22" color="accent"></mat-spinner>
              </div>
            }

            <field [input]="{label: 'username', name:'username', type: 'text'}" formControlName="username"></field>
            <field [input]="{label: 'password', name:'password', type: 'password'}" formControlName="password"></field>
            <field [input]="{label: 'repeatPassword', name:'repeatPassword', type: 'password'}" formControlName="repeatPassword"></field>

            <field [input]="{label: 'email', name: 'email', type: 'email'}" formControlName="email"></field>

            <div class="mt-4 w-100">
              <button class="w-100" mat-raised-button color="primary" [disabled]="!form.valid">{{ 'submit' | translate }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-card>
</div>
