import { Component, EventEmitter, Output } from '@angular/core';

export interface ISortByDropdown {
  name: string;
  label: string;
  icon: string;
  id: number;
}

@Component({
  selector: 'sort-by-dropdown',
  templateUrl: 'sort-by-dropdown.component.html',
})

export class SortByDropdownComponent {

  @Output()
  sortByChange: EventEmitter<ISortByDropdown> = new EventEmitter(null);

  sortOptions: Array<ISortByDropdown> = [
    {icon: 'sort-alphabetical-ascending', label: 'A-Z', name: 'asc', id: 1},
    {icon: 'sort-alphabetical-descending', label: 'Z-A', name: 'desc', id: 2},
    {icon: 'crown', label: 'popular', name: 'popular', id: 3}
  ];

  currentOption: ISortByDropdown;

  constructor() {
    this.currentOption = this.sortOptions[2];
    this.sortByChange.emit(this.currentOption);
  }

  onChange(option: ISortByDropdown) {
    this.currentOption = option;
    this.sortByChange.emit(this.currentOption);
  }
}
