import { BsCommonModule } from '@bs/common';
import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { HomerCommonModule } from '@homer/common';
import { HomerGamesModule } from '@homer/games';
import { HomerSmarticoModule } from '@homer/smartico';
import { RegistrationDialogComponent } from '@maggie/app/components/dialogs/registration/registration.dialog';
import { FaqComponent } from '@maggie/app/components/faq/faq.component';
import { PreFooterComponent } from '@maggie/app/components/pre-footer/pre-footer.component';
import { RegistrationComponent } from '@maggie/app/components/registration/registration.component';

/* Pipes */
import { DeliveryTabsValuesPipe } from '../pipes/delivery-tabs-values.pipe';

// components
import { CasinoFilterComponent } from './casino-filter/casino-filter.component';
import { HeaderComponent } from './header/header.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { BalanceNavigationComponent } from './balance-navigation/balance-navigation.component';
import { LoyaltyLevelsDialog } from './dialogs/loyalty-levels/loyalty-levels.dialog';

/* VIEWS */

// Directive

const components = [
  BalanceNavigationComponent,
  CasinoFilterComponent,
  HeaderComponent,
  SidebarMenuComponent,
  RegistrationComponent,
  // Dialog
  LoyaltyLevelsDialog,
  RegistrationDialogComponent,
  // Pipes
  DeliveryTabsValuesPipe,
  PreFooterComponent,
  FaqComponent
]

@NgModule({
  imports: [
    BsCommonModule,
    HomerCommonModule,
    RouterModule,
    HomerGamesModule,
    HomerSmarticoModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatBadgeModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatMenuModule
  ],
  declarations: components,
  exports: components,
  providers: [
    CurrencyPipe
  ]
})
export class AppComponentsModule {

}
