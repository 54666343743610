<ng-template #linkTpl let-payment>
  <a [href]="payment.link" [target]="payment.icon" class="logo-payment">
    <svg [icon]="payment.icon" lib="payments" [size]="{w:41, h:33}"></svg>
  </a>
</ng-template>

<ng-template #staticTpl let-payment>
  <svg [icon]="payment.icon" lib="payments" [size]="{w:70, h:50}"></svg>
</ng-template>

<div class="footer-container" [ngClass]="{mobile:isMobile}">
  <to-top class="row gx-0"></to-top>

  <!-- payments -->
  <div class="row m-0 pt-4">
    <div class="col-12 payments-container d-flex flex-wrap justify-content-center">
      @for (payment of footer?.payments; track payment) {
        @if (payment.icon) {
          <div class="logo-wrapper">
            <ng-template [ngTemplateOutlet]="payment.link ? linkTpl : staticTpl" [ngTemplateOutletContext]="{$implicit: payment}"></ng-template>
          </div>
        }
      }
    </div>
  </div>

  <!-- socials -->
  <div class="row m-0 pt-4 pb-1">
    <div class="col-12 socials-container d-flex justify-content-center">
      @for (social of footer?.socials; track social) {
        <div class="logo-wrapper">
          <a class="logo-social {{social.icon}}" [href]="social.link" target="_blank">
            <svg [icon]="social.icon + '-' + (settings.darkTheme ? 'light' : 'dark')" lib="social-icons" [size]="{w:25, h:25}"></svg>
          </a>
        </div>
      }
    </div>
  </div>

  <!-- footer desktop -->
  <div class="row gx-0 menu-row py-3 d-none d-lg-block">
    <div class="padded d-flex links-container">
      @for (menu of footer?.menus; track menu) {
        <div class="padded ">
          <h5>{{ menu.name }}</h5>
          <div [menu]="menu"></div>
        </div>
      }
      <div class="padded disclaimer-container">
        <h5 [translate]="'disclaimer'">Disclaimer</h5>
        <span [innerHTML]="footer?.disclaimer"></span>
      </div>
    </div>
  </div>

  <!-- footer mobile -->
  <div class="row gx-0 p-3 d-lg-none">
    @for (menu of footer?.menus; track menu) {
      <div class="col-12 footer-links mb-2">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="align-items-center">
              <h5 class="footer-links-title">{{ menu.name }}</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [menu]="menu"></div>
        </mat-expansion-panel>
      </div>
    }
    <div class="col-12">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="align-items-center">
            <h5 [translate]="'disclaimer'" class="footer-links-title">disclaimer</h5>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="disclaimer-container">
          <span [innerHTML]='footer?.disclaimer'></span>
        </div>
      </mat-expansion-panel>
    </div>
  </div>

  <div class="row m-0 pt-3">
    @if (footer?.license?.active) {
      <license style="width: 100%;" [license]="footer.license"></license>
    }
  </div>

  <!-- play responsibly -->
  <div class="row m-0 pt-3">
    <div class="col-12 license-area d-flex justify-content-center align-items-center">
      <div class="plus-18 me-1" title="+{{minAge}}">+{{ minAge }}</div>
      <span [translate]="'play-responsibly'">Gambling can be addictive. Play responsibly.</span>
    </div>
  </div>

  <!-- gambleAware -->
   <div class="row m-0 pt-3">
      <div class="col-12 d-flex flex-wrap justify-content-center">
        <a target="_blank" href="https://www.begambleaware.org/">
          <div class="logo-gambleAware"></div>
        </a>
      </div>
    </div>


  <!-- copyright -->
  @if (footer?.copyright) {
    <div class="row m-0 py-4">
      <div class="d-flex flex-nowrap justify-content-center w-100">
        <a class="logo-wrapper d-flex flex-nowrap" href="https://www.betstarters.com/">
          <div class="bs-logo"></div>
        </a>
      </div>
    </div>
  }

  <!-- version -->
  <div class="row m-0 py-4">
    <div class="d-flex flex-nowrap justify-content-center w-100">
      <p [translate]="'version'"></p>
      <p> - {{ vrs }}</p>
    </div>
  </div>
</div>
