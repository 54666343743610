import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppSettings, EnvironmentConfig, IBaseGame, IGame, Webapps } from '@bs/models';
import { AppSettingsService, AuthService, GamesService } from '@bs/services';
import { Subscription } from 'rxjs';
import { GamesFavouritesService } from '@bs/services';

@Component({
  selector: 'game-tile',
  styleUrls: ['./game-tile.component.scss'],
  templateUrl: './game-tile.component.html'
})
export class GameTileComponent implements OnDestroy, OnInit {

  @Input()
  game: IBaseGame;

  @Input()
  isMobile: boolean;

  @Input()
  showProviderName: boolean;

  section: string;

  baseUrl: string;
  baseVirtual: string;

  settings: AppSettings;

  subs = new Subscription();
  forFun: boolean;

  constructor(private gamesService: GamesService, private gamesFavouritesService: GamesFavouritesService, private config: EnvironmentConfig, protected dialog: MatDialog, authService: AuthService, route: ActivatedRoute, settingsService: AppSettingsService) {
    this.subs.add(settingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    }));
    this.section = this.config.webAppId === Webapps.maggie ? 'casino' : route.snapshot.params.section;
    this.forFun = this.config.features.games?.forFun;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.baseUrl = this.section ? `/${this.settings.languageCode}/games/${this.section}/play/${this.game.id}` : `play/${this.game.id}`;
    this.baseVirtual = `/${this.settings.languageCode}/games/n-virtual/${this.getVirtualName()}`;
  }

  toggleFavorite(game: IGame) {
    this.gamesFavouritesService.toggle(game);
  }

  isFavorite(game: IGame) {
    const fav = this.gamesFavouritesService.favourites$.getValue();
    return !!fav.find(g => g.id === game.id);
  }

  getVirtualName() {
    switch (this.game.id) {
      case 5323:
        return 'vhorse';
      case 5322:
        return 'vgreyhound';
      case 5318:
        return 'greyhound';
      default:
        return '';
    }
  }
}
