<mat-form-field>
  @if (headerLayout === 'header1') {
    <mat-label>
      <span [translate]="'timezone'">Timezone</span>
    </mat-label>
  }
  <mat-select disableOptionCentering [value]="selectedZone" (valueChange)="changeZone($event)">
    @if (!isMobile) {
      <div class="scrollbar max-dropdown-h">
        <ng-container *ngTemplateOutlet="options"></ng-container>
      </div>
    }
    @if (isMobile) {
      <ng-container *ngTemplateOutlet="options"></ng-container>
    }
    <ng-template #options>
      @for (group of tzsByGMT; track group) {
        <mat-optgroup [label]="group.label">
          @for (tz of group.items; track tz) {
            <mat-option [value]="tz.value">
              {{ tz.label }}
            </mat-option>
          }
        </mat-optgroup>
      }
    </ng-template>
    @if (headerLayout === 'header2' || headerLayout === 'header3') {
      <mat-select-trigger></mat-select-trigger>
    }
  </mat-select>
</mat-form-field>
