<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <div class="flex w-100 flex-column">
        <span [translate]="'tfa-setup'"></span>
        @if (data) {
          @switch (setup.type) {
            @case (TfaType.authenticator) {
              <h5 class="py-2" translate>tfa-instructions-authenticator</h5>
              <hr>
              <p class="subtitle mb-2" [innerHTML]="'tfa-instructions-app-steps' | translate"></p>
              <h5 class="py-2" translate>tfa-instructions-scan</h5>
              <hr>
              @if (data.authUrl) {
                <qrcode class="text-center" [qrdata]="data.authUrl" [allowEmptyString]="false"
                        [width]="256"
                        [errorCorrectionLevel]="'M'"></qrcode>
              }
              <h5 class="py-2" translate>tfa-instructions-code</h5>
              <hr>
              <p class="subtitle mb-2">{{ 'tfa-secret-key'|translate:{value: data.base32} }}</p>
            }
            @case (TfaType.email) {
              <p class="py-2" translate>tfa-instructions-email</p>
            }
          }
          <h5 class="py-2" translate>tfa-verify-code</h5>
          <hr>
          <p class="subtitle mb-2" translate>tfa-verify-code-desc</p>
          <form-builder [vertical]="true" class="w-100" (submits)="submit($event)" #setOtp
                        [config]="{inputs:[{type:'text', placeholder: 'otp', label:'otp', name:'otp', restrict:['integer'],  validations: { required: true, minLength:6, maxLength:6 }}]}">
            <div class="button-container">
              <button [disabled]="!setOtp.form.valid" type="submit" class="ms-auto m-t-20"
                      color="primary" mat-flat-button translate>validate
              </button>
            </div>
          </form-builder>
        } @else {
          <span translate>tfa-cannot-generate</span>
        }

      </div>
      <button (click)="dialogRef.close(false)" class="close-icon me-2" mat-icon-button tabindex="-1">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </button>
    </mat-card-title>
  </mat-card>
</div>
