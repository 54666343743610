import { Component, HostBinding, OnDestroy } from '@angular/core';
import { ValidationService } from '@bs/forms';
import { AppSettings, EnvironmentConfig, IFooter } from '@bs/models';
import { AppSettingsService, CmsService } from '@bs/services';
import { WindowService } from '@bs/universal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'footer',
  templateUrl: 'footer.component.html'
})
export class FooterComponent implements OnDestroy {

  @HostBinding('class')
  layoutRender = this.config.layout?.footer;

  footer: IFooter;

  isMobile: boolean;
  settings: AppSettings;
  vrs: string;
  minAge: number;

  subs = new Subscription();

  constructor(private config: EnvironmentConfig, private cmsService: CmsService, windowService: WindowService, appSettingsService: AppSettingsService, validationService: ValidationService) {
    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({settings, valueChanged}) => {
        this.settings = settings;
        if (['languageCode'].includes(valueChanged)) {
          this.loadData();
        }
      }
    }));
    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));
    this.vrs = this.config.appVersion;
    this.minAge = validationService.minAge;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private loadData() {
    this.subs.add(this.cmsService.getFooter().subscribe({
      next: footer => this.footer = footer
    }));
  }
}
