<div class="balance-links d-flex">
  <a class="link d-flex justify-content-center align-items-center"
     [routerLink]="['/',settings.languageCode,'me','balances','deposit']" routerLinkActive="active">
    <span class="label" translate>deposit</span>
  </a>
  <a class="link d-flex justify-content-center align-items-center"
     [routerLink]="['/',settings.languageCode,'me','balances','withdrawal']" routerLinkActive="active" translate>
    <span class="label" translate>withdrawal</span>
  </a>
  <a class="link d-flex justify-content-center align-items-center"
     [routerLink]="['/',settings.languageCode,'me','balances','withdrawal-history']" routerLinkActive="active" translate>
    <span class="label" translate>withdrawal-history</span>
  </a>
</div>
