import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentConfig, ICasinoTournament } from '@bs/models';
import { LocalStorage } from '@bs/universal';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GamesCasinoTournamentService {
  readonly url = `${this.config.api.games}/games/tournaments`;

  casinoTournament$ = new BehaviorSubject<Array<number>>([]);
  private STORAGE_KEY = 'CasinoTournaments';

  constructor(private config: EnvironmentConfig, private http: HttpClient, private localStorage: LocalStorage) {


    this.restoreFavourites();

    this.casinoTournament$.subscribe({
      next: casinoTournament => {
        this.casinoTournament = casinoTournament
      }
    })
  }

  set casinoTournament(casinoTournament: Array<number>) {
    this.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(casinoTournament));
  }

  toggle(casinoTournamentId: number) {
    const casinoTournament = this.casinoTournament$.getValue();
    const idx = casinoTournament.findIndex(id => id === casinoTournamentId);

    if (idx > -1) {
      casinoTournament.splice(idx, 1);
    } else {
      casinoTournament.push(casinoTournamentId);
    }
    this.casinoTournament$.next(casinoTournament);
  }

  private restoreFavourites() {
    const casinoTournament = JSON.parse(this.localStorage.getItem(this.STORAGE_KEY)) ?? [];
    this.casinoTournament$.next(casinoTournament);
  }

  all(bookmakerId = this.config.bookmakerId): Observable<Array<ICasinoTournament>> {
    const params: Params = {bId: bookmakerId};
    return of([{
      id: 1,
      title: 'Tournament 1',
      status: 'active',
      leaderboards: [
        {position: '#1 Jas****', score: 3783453, price: 250000},
        {position: '#1 Jas****', score: 3783453, price: 250000},
        {position: '#1 Jas****', score: 3783453, price: 250000},
        {position: '#1 Jas****', score: 3783453, price: 250000},
        {position: '#1 Jas****', score: 3783453, price: 250000},
        {position: '#1 Jas****', score: 3783453, price: 250000},
        {position: '#1 Jas****', score: 3783453, price: 250000},
        {position: '#1 Jas****', score: 3783453, price: 250000}
      ],
      endDate: new Date().toISOString(),
      logo: 'assets/images/promotions/placeholder.jpg',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur cupiditate, natus suscipit temporibus ullam velit.',
      minimumConsecutiveRounds: 100,
      minimumRound: 1,
      minimumStake: 1000,
      pricePool: 500000,
      startDate: new Date().toISOString(),
      games: []
    },
      {
        id: 43,
        title: 'Tournament 2',
        status: 'upcoming',
        leaderboards: [
          {position: '#1 Jas****', score: 3783453, price: 250000},
          {position: '#1 Jas****', score: 3783453, price: 250000},
          {position: '#1 Jas****', score: 3783453, price: 250000},
          {position: '#1 Jas****', score: 3783453, price: 250000},
          {position: '#1 Jas****', score: 3783453, price: 250000},
          {position: '#1 Jas****', score: 3783453, price: 250000},
          {position: '#1 Jas****', score: 3783453, price: 250000},
          {position: '#1 Jas****', score: 3783453, price: 250000}
        ],
        endDate: new Date().toISOString(),
        logo: 'assets/images/promotions/placeholder.jpg',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur cupiditate, natus suscipit temporibus ullam velit.',
        minimumConsecutiveRounds: 100,
        minimumRound: 1,
        minimumStake: 1000,
        pricePool: 500000,
        startDate: new Date().toISOString(),
        games: []
      }])
    //return this.http.get<Array<ICasinoTournament>>(`${this.url}`, {params});
  }
}
