import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IAffiliateRegistration } from '@bs/models';
import { WINDOW } from '@bs/universal';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AffiliateRegistrationService {

  private readonly url = 'https://boapi3.smartico.ai/api/register-aff';
  private readonly reCaptchaSiteKey = '6LeR49UfAAAAAO7J7vKDCD4vM-Lq5ZA_NsquS9J_';

  constructor(private http: HttpClient, @Inject(WINDOW) private window: Window) {
  }

  reCaptchaToken(): Observable<string> {
    const grecaptcha = this.window['grecaptcha'];
    return from(new Promise<string>((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha.execute(this.reCaptchaSiteKey, { action: 'affregistration' })
          .then((token: string) => {
            resolve(token);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    }));
  }

  registerAffiliate(request: IAffiliateRegistration): Observable<boolean> {
    return this.http.post<boolean>(this.url, request);
  }
}
