import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IDrawer } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {
  readonly url = `${this.config.api.identity}/me/drawers`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  get(): Observable<IDrawer> {
    return this.http.get<IDrawer>(`${this.url}`)
  }

}
