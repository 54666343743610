<mat-tab-group mat-align-tabs="center">
  @for (tab of tabs; track tab) {
    @switch (tab) {
      @case ('email') {
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="me-1">{{ 'email' | translate }}</div>
          </ng-template>
          <ng-template matTabContent>
            <form [formGroup]="emailGroup" class="d-flex justify-content-center align-items-center flex-column flex-md-row">
              <div class="wrapper">
                <mat-form-field>
                  <mat-label>{{ 'email' | translate }}*</mat-label>
                  <input type="email" matInput formControlName="email">
                </mat-form-field>
                @if (!emailGroup.valid) {
                  <mat-error [control]="emailGroup.get('email')"></mat-error>
                }
              </div>
              <button (click)="submit(emailGroup.value)" type="submit" mat-raised-button color="primary" class="m-1 button-container" [disabled]="!emailGroup.valid">
                <span class="me-2">{{ 'send' | translate }}</span>
              </button>
            </form>
          </ng-template>
        </mat-tab>
      }
      @case ('phone') {
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="me-1">{{ 'sms' | translate }}</div>
          </ng-template>
          <ng-template matTabContent>
            <form [formGroup]="phoneGroup" class="d-flex justify-content-center align-items-center flex-column flex-md-row">
              <div class="wrapper">
                <mobile-prefix formControlName="phone" class="mat-form-field mat-primary mat-form-field-appearance-standard mat-form-field-can-float mat-form-field-should-float mat-form-field-has-label"></mobile-prefix>
                @if (!phoneGroup.valid) {
                  <mat-error [control]="phoneGroup.get('phone')"></mat-error>
                }
              </div>
              <button (click)="submit(phoneGroup.value)" type="submit" mat-raised-button color="primary" class="m-1 button-container" [disabled]="!phoneGroup.valid || !phoneGroup.value.phone?.phone">
                <span class="me-2">{{ 'send' | translate }}</span>
              </button>
            </form>
          </ng-template>
        </mat-tab>
      }
      @case ('socials') {
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="me-1">{{ tab | translate }}</div>
            <svg icon="share-variant" lib="global" [size]="{w:13, h:13}"></svg>
          </ng-template>
          <ng-template matTabContent>
            <div class="d-flex justify-content-center align-items-center">
              @for (social of socialButtons; track social) {
                <button (click)="share(social)" class="m-1 button-container" color="primary" mat-mini-fab type="submit">
                  <svg [icon]="social === 'telegram' ? 'telegram' : 'social'" lib="global" [size]="{w:13, h:13}"></svg>
                </button>
              }
            </div>
          </ng-template>
        </mat-tab>
      }
    }
  }
</mat-tab-group>
