<ng-template #noGamesTpl>
  <mat-card>
    <div class="no-games d-flex flex-column justify-content-center align-items-center py-5">
      <img class="mb-4" src="./assets/images/no-available-games.svg">
      <strong class="mb-2" translate>noAvailableGames</strong>
      <span translate>noGamesText</span>
    </div>
  </mat-card>
</ng-template>

<div class="container-fluid mt-lg-2">
  <banner-slider [slider]="sliderHero"></banner-slider>
  <nav class="casino-navbar mt-lg-2">
    <ul class="navbar">
      <!--      <li *ngIf="casinoRouteName==='casino'" class="nav-item">-->
      <!--        <a routerLink="/{{settings.languageCode}}/games" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">-->
      <!--          <svg icon='home' lib="global" class="p-1" [size]="{w:30, h:30}"></svg>-->
      <!--          <span class="ellipsis" translate>home</span></a>-->
      <!--      </li>-->
      <!--      <li *ngIf="me() && casinoRouteName==='casino'" class="nav-item">-->
      <!--        <a routerLink="/{{settings.languageCode}}/games/{{casinoRouteName}}/tournaments" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">-->
      <!--          <svg icon='trophy' lib="global" class="p-1" [size]="{w:30, h:30}"></svg>-->
      <!--          <span class="ellipsis" translate>tournaments</span></a>-->
      <!--      </li>-->
      <li class="nav-item">
        <a routerLink="/{{settings.languageCode}}/games/{{casinoRouteName}}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <svg icon='apps' lib="global" class="p-1" [size]="{w:30, h:30}"></svg>
          <span class="ellipsis" translate>all-games</span></a>
      </li>
      <li class="nav-item" *ngFor="let category of navMenu?.items">
        <a routerLink="/{{settings.languageCode}}/games/{{casinoRouteName}}" [queryParams]="{category: (category.routerLink)}" queryParamsHandling="merge" routerLinkActive="active" class="nav-link">
          <svg [icon]='category.icon | sluggify | lowercase' lib="games-categories" class="p-1" [size]="{w:30, h:30}"></svg>
          <span class="ellipsis">{{ category.label }}</span>
        </a>
      </li>
      <li *ngIf="casinoRouteName==='casino'" class="nav-item">
        <a routerLink="/{{settings.languageCode}}/games/{{casinoRouteName}}/brands" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <svg icon='blur' lib="global" class="p-1" [size]="{w:30, h:30}"></svg>
          <span class="ellipsis" translate>brands</span></a>
      </li>
    </ul>
  </nav>

  <ng-container [ngSwitch]="casinoSection">

    <ng-container *ngSwitchCase="'brands'">
      <section class="brands m-2">
        <app-swiper [config]="{grid: {rows: 1e4, fill:'row'},
      slidesPerView: device().isDesktop ? 6 : 2, spaceBetween: 5, navigation: false}"
                    [sliderItems]="gameType.providers | slice: 0 :gameType.providers.length">
          <ng-template #headerTpl>
            <div class="pt-2 pb-3">
              <h1 class="title">{{ 'seo-brands-page-h1-title' | translate }}</h1>
              <p>{{ 'seo-brands-page-description' | translate }}</p>
            </div>
          </ng-template>
          <ng-template #slidesTpl let-item>
            <a [routerLink]="['../']" [queryParams]="{providers: item.id}" queryParamsHandling="merge">
              <mat-card class="brand-card" routerLinkActive="active">
                <span class="brand-name m-3">{{ item.name }}</span>
                <!--                <svg [icon]="item.icon" lib="games-providers-color" class="img-fluid p-3 grayscale"></svg>-->
              </mat-card>
            </a>
          </ng-template>
        </app-swiper>
      </section>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <casino-filter class="my-4" [showProvidersFilter]="true" [showSort]="true" (sortBy)="sortSelected=$event"></casino-filter>

      <section class="mt-1">
        <ng-container *ngIf="featured?.length">
          <app-swiper class="no-btn"
                      [config]="{
          grid: {rows:1},
          slidesPerView: device().isDesktop? 6 : device().isTablet ? 5 : 3,
          spaceBetween: 5,
          autoplay: true,
          navigation: true,
        }" [sliderItems]="featured">
            <ng-template #headerTpl>
              <div *ngIf="featured.length" class="d-flex p-2">
                <h2 class="title">{{ 'featured' | translate }}</h2>
              </div>
            </ng-template>
            <ng-template #slidesTpl let-game>
              <casino-game-tile [game]="game"></casino-game-tile>
            </ng-template>
          </app-swiper>
        </ng-container>
      </section>

      <section class="mt-1">
        <ng-container *ngIf="new?.length">
          <app-swiper class="no-btn"
                      [config]="{
              grid: {rows:1},
              slidesPerView: device().isDesktop ? 6 : device().isTablet ? 3 : 2,
              spaceBetween: 5,
              navigation: true
            }" [sliderItems]="new">
            <ng-template #headerTpl>
              <div *ngIf="new.length" class="d-flex p-2">
                <h2 class="title">{{ 'new' | translate }}</h2>
              </div>
            </ng-template>
            <ng-template #slidesTpl let-game>
              <casino-game-tile [game]="game"></casino-game-tile>
            </ng-template>
          </app-swiper>
        </ng-container>
      </section>

      <div *ngFor="let gs of (gameType.gameSubTypes|filter:categoryFilter); let i = index">

        <ng-container *ngIf="(gamesLimit[i] ?? defaultGamesLimit) as limit">
          <ng-container *ngIf="(gs.games|filter:gamesFilter)|sort:sortSelected?.name: 'name' as filteredGames">
            <ng-container *ngIf="filteredGames|slice:0:limit as limitedGames">

              <ng-template *ngIf="i===0 && !limitedGames.length" [ngTemplateOutlet]="noGamesTpl"></ng-template>

              <ng-container *ngIf="limitedGames?.length">
                <app-swiper class="no-btn" [config]="{grid: (filters.category ? {rows: 1e4, fill:'row'}:{rows:1}),
              slidesPerView: device().isDesktop ? 8 : device().isTablet ? 3 : 2,
              spaceBetween: 5, navigation: true
            }" [sliderItems]="limitedGames">
                  <ng-template #headerTpl>
                    <div *ngIf="limitedGames.length" class="d-flex align-items-center mb-3 mb-lg-1 justify-content-between">
                      <div class="d-flex align-items-center">
                        <h2 class="title">{{ gs.name | translate }}</h2>
                        <div *ngIf="!filters.category" class="badge ms-3">
                          <span>{{ limitedGames.length }} / </span>
                          <a [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{category:gs.name}">{{ filteredGames.length }}</a>
                        </div>
                      </div>
                      <!--                      <a *ngIf="!filters.category" [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{category:gs.name}" mat-raised-button class="rounded-btn me-2">{{ 'view-all' | translate }}</a>-->
                    </div>
                  </ng-template>
                  <ng-template #slidesTpl let-game>
                    <casino-game-tile [game]="game"></casino-game-tile>
                  </ng-template>
                </app-swiper>
                <div class="d-flex justify-content-center mt-2" *ngIf="limitedGames.length<filteredGames.length">
                  <button *ngIf="filters.category" (click)="addGames(i)" color="primary" mat-flat-button class="rounded-btn mt-5 ">{{ 'load-more' | translate }}</button>
                </div>
              </ng-container>

            </ng-container>
          </ng-container>
        </ng-container>

      </div>

      <app-swiper class="no-btn my-4" [config]="{grid: {rows:1},
            slidesPerView: device().isDesktop ? 10 : 2, spaceBetween: 10, navigation: true}"
                  [sliderItems]="gameType.providers | slice: 0 : gameType.providers.length">
        <ng-template #headerTpl>
          <div class="d-flex align-items-center mb-1 mb-lg-1 justify-content-between">
            <h2 class="title">{{ 'brands' | translate }}</h2>
            <!--            <a [routerLink]="['brands']" mat-raised-button class="rounded-btn">{{'view-all' | translate}}</a>-->
          </div>
        </ng-template>
        <ng-template #slidesTpl let-item>
          <a [routerLink]="['.']" [queryParams]="{providers: item.id}" queryParamsHandling="merge">
            <mat-card class="brand-card" routerLinkActive="active">
              <div class="px-2 py-4">{{ item.name }}</div>
              <!--              <svg [icon]="item.icon" lib="games-providers" [size]="{w: 110, h: 110}"></svg>-->
            </mat-card>
          </a>
        </ng-template>
      </app-swiper>

      <section class="mt-5">
        <faq [faqSectionId]="faqSectionId"></faq>
      </section>

    </ng-container>

  </ng-container>
</div>
@if (isMobile) {
  <bottom-bar [menuId]="maggieMenu.Main" [menuLoggedId]="maggieMenu.Main" class="row"></bottom-bar>
}
